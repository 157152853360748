import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

const CurrencyTextField = forwardRef(({ onChange, ...other }, ref) => (
  <NumberFormat
    {...other}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange(values.floatValue);
    }}
    thousandSeparator={'.'}
    decimalSeparator={','}
    decimalScale={2}
    fixedDecimalScale={true}
    prefix="R$ "
  />
));

export default CurrencyTextField;
