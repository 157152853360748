import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from '../../../../components/form/OutlinedInput';
import CpfCnpjTextField from '../../../../components/form/CpfCnpjTextField';
import { useSelector } from 'react-redux';
import ControlledAutocomplete from 'components/form/Autocomplete';
import DateTextField from 'components/form/DateTextField';
import CurrencyTextField from 'components/form/CurrencyTextField';

const DadosDaEmpresa = ({ formProps }) => {
  const bancos = useSelector((state) => state.dominios.listarBancos);
  const tiposEmpresa = useSelector((state) => state.dominios.tiposEmpresa);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeParticipante">Razão Social</InputLabel>
          <OutlinedInput
            id="nomeParticipante"
            name="nomeParticipante"
            placeholder="Digite a razão social"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfCnpj">CNPJ</InputLabel>
          <OutlinedInput
            id="cpfCnpj"
            name="cpfCnpj"
            placeholder="Digite o CNPJ"
            fullWidth
            required
            type="cnpj"
            formProps={formProps}
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 18
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="tipoEmpresa">Tipo de Empresa</InputLabel>
          <ControlledAutocomplete
            id="tipoEmpresa"
            name="tipoEmpresa"
            placeholder="Selecione o Tipo de Empresa"
            required
            fullWidth
            formProps={formProps}
            labelField="label"
            idField="value"
            domain={tiposEmpresa}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataRegistroEmpresa">
            Data de Registro
          </InputLabel>
          <OutlinedInput
            id="dataRegistroEmpresa"
            name="dataRegistroEmpresa"
            fullWidth
            required
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
              maxLength: 14
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="idBanco">Banco</InputLabel>
          <ControlledAutocomplete
            id="idBanco"
            name="idBanco"
            placeholder="Selecione o Banco"
            required
            fullWidth
            formProps={formProps}
            labelField={'nomeBanco'}
            idField={'idBanco'}
            domain={bancos.data}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoAgencia">Agência</InputLabel>
          <OutlinedInput
            id="codigoAgencia"
            name="codigoAgencia"
            placeholder="Digite a agência"
            fullWidth
            formProps={formProps}
            type="number"
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoContaCorrente">Conta Corrente</InputLabel>
          <OutlinedInput
            id="codigoContaCorrente"
            name="codigoContaCorrente"
            placeholder="Digite a conta corrente"
            fullWidth
            formProps={formProps}
            type="number"
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="digitoContaCorrente">Dígito da Conta</InputLabel>
          <OutlinedInput
            id="digitoContaCorrente"
            name="digitoContaCorrente"
            placeholder="Digite o dígito da conta"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 1 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="faturamentoEmpresa">
            Valor Faturamento
          </InputLabel>
          <OutlinedInput
            id="faturamentoEmpresa"
            name="faturamentoEmpresa"
            placeholder="Digite o valor de faturamento"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="patrimonioLiquidoEmpresa">
            Valor Patrimônio Líquido
          </InputLabel>
          <OutlinedInput
            id="patrimonioLiquidoEmpresa"
            name="patrimonioLiquidoEmpresa"
            placeholder="Digite o valor do patrimônio líquido"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="capitalSocialEmpresa">
            Valor Capital Social
          </InputLabel>
          <OutlinedInput
            id="capitalSocialEmpresa"
            name="capitalSocialEmpresa"
            placeholder="Digite o valor do capital social"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DadosDaEmpresa;
