import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { UserAddOutlined } from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from 'reducers/vendedores.reducer';
import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import DataGrid from 'components/grid/DataGrid';
import formatters from 'utils/formatters';
import useTheme from '@mui/system/useTheme';

const Representantes = () => {
  const dispatch = useDispatch();

  const theme = useTheme();

  const pessoaJuridica = useSelector(
    (state) => state.vendedores.pessoaJuridica.data
  );
  const idOportunidade = useSelector(
    (state) => state.vendedores.idOportunidade
  );

  const representantes = useSelector(
    (state) => state.vendedores.pessoaJuridica.data.representantes
  );

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAdd = () => {
    dispatch(
      routeActions.redirectTo(routes.VENDEDOR_REPRESENTANTE, {
        idPessoaJuridica: pessoaJuridica.idParticipante,
        id: idOportunidade,
        idRepresentante: 'novo'
      })
    );
  };

  const columns = [
    {
      field: 'cpf',
      headerName: 'CPF',
      flex: 1,
      valueFormatter: (value) => {
        return formatters.strings.cpf(value);
      }
    },
    { field: 'nomeRepresentanteLegal', headerName: 'Nome', flex: 1 },
    {
      field: 'celular',
      headerName: 'Celular',
      flex: 1,

      valueFormatter: (value) => {
        return value ? formatters.numbers.formatPhone(value) : '';
      }
    },
    { field: 'email', headerName: 'Email', flex: 1 }
  ];

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleEdit = (id) => {
      if (!id) {
        return;
      }
      dispatch(
        routeActions.redirectTo(routes.VENDEDOR_REPRESENTANTE, {
          idPessoaJuridica: pessoaJuridica.idParticipante,
          id: idOportunidade,
          idRepresentante: id
        })
      );
    };

    const handleDelete = () => {
      dispatch(
        actions.removerRepresentanteRequest({
          value: { id: selectedRowData[0]?.idRepresentante }
        })
      );
      handleClose();
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Comprador</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o Representante{' '}
              {selectedRowData[0]?.nomRepresentante}? Ao excluir um
              representante, não será mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDelete} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            onClick={handleAdd}
            startIcon={<UserAddOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Representante
          </Button>
          <Button
            color="secondary"
            variant="text"
            disabled={selectedRows.size !== 1}
            onClick={() => handleEdit(selectedRowData[0]?.idRepresentante)}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="secondary"
            disabled={selectedRows.size !== 1}
            onClick={() => handleClickOpen(selectedRowData[0]?.row)}
            startIcon={<DeleteOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Excluir
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <>
      <Box sx={{ flex: 1 }}>
        <DataGrid
          rows={representantes}
          columns={columns}
          getRowId={(row) => row.idRepresentante}
          toolbar={CustomToolbar}
          style={{ minHeight: 400 }}
          hideFooterPagination
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          autoHeight={false}
          infiniteScroll
          noLineText="Nenhum representante cadastrado"
        />
      </Box>
    </>
  );
};

export default Representantes;
