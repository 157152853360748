import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/documentos.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { types as routes } from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarEntidadesRelacionadas = (values) => {
  return authenticatedRequest({
    url: `/documentos/list/grid?idOportunidade=${values}`,
    method: 'get'
  });
};

const apiListarDocumentos = ({ value }) => {
  return authenticatedRequest({
    url: `/documentos/list/grid/documentos`,
    method: 'post',
    body: value
  });
};

const apiDeletarArquivo = (id) => {
  return authenticatedRequest({
    url: `/documentos/delete/arquivo/${id}`,
    method: 'delete'
  });
};

const apiAtualizarDocumento = ({ value }) => {
  return authenticatedRequest({
    url: `/documentos/update/${value.idDocumentoOportunidade}`,
    method: 'put',
    body: value
  });
};

const apiUploadArquivo = ({ value }) => {
  const formData = new FormData();
  formData.append('idDocumento', value.idDocumento);
  formData.append('arquivo', value.file, value.file.name);

  return authenticatedRequest({
    url: `/documentos/upload`,
    method: 'post',
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const apiMostrarDocumento = (id) => {
  return authenticatedRequest({
    url: `/documentos/show/${id}`,
    method: 'get'
  });
};

const mostrarDocumento = basicFlow({
  actionGenerator: actions.mostrarDocumentoRequest,
  actionFailure: actions.mostrarDocumentoFailure,
  actionSuccess: actions.mostrarDocumentoSuccess,
  api: apiMostrarDocumento,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const uploadArquivo = basicFlow({
  actionGenerator: actions.uploadArquivoRequest,
  actionFailure: actions.uploadArquivoFailure,
  actionSuccess: actions.uploadArquivoSuccess,
  api: apiUploadArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo enviado com sucesso.');
    const { id, idDocumento } = yield select(getPayload);
    if (!id) return;
    if (idDocumento) {
      yield put(actions.mostrarDocumentoRequest(idDocumento));
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const deletarArquivo = basicFlow({
  actionGenerator: actions.deletarArquivoRequest,
  actionFailure: actions.deletarArquivoFailure,
  actionSuccess: actions.deletarArquivoSuccess,
  api: apiDeletarArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo deletado com sucesso.');
    const { id, idDocumento } = yield select(getPayload);
    if (!id) return;
    if (idDocumento) {
      yield put(actions.mostrarDocumentoRequest(idDocumento));
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const atualizarDocumento = basicFlow({
  actionGenerator: actions.atualizarDocumentoRequest,
  actionFailure: actions.atualizarDocumentoFailure,
  actionSuccess: actions.atualizarDocumentoSuccess,
  api: apiAtualizarDocumento,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Documento atualizado com sucesso.');
    const { id, idDocumento } = yield select(getPayload);
    if (!id) return;
    if (idDocumento) {
      yield put(actions.mostrarDocumentoRequest(idDocumento));
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarDocumentos = basicFlow({
  actionGenerator: actions.listarDocumentosRequest,
  actionFailure: actions.listarDocumentosFailure,
  actionSuccess: actions.listarDocumentosSuccess,
  api: apiListarDocumentos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarEntidadesRelacionadas = basicFlow({
  actionGenerator: actions.listarEntidadesRelacionadasRequest,
  actionFailure: actions.listarEntidadesRelacionadasFailure,
  actionSuccess: actions.listarEntidadesRelacionadasSuccess,
  api: apiListarEntidadesRelacionadas,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarDocumento = ({ value }) => {
  return authenticatedRequest({
    url: `/documentos/create`,
    method: 'POST',
    body: value
  });
};

const criarDocumentos = basicFlow({
  actionGenerator: actions.criarDocumentoRequest,
  actionFailure: actions.criarDocumentoFailure,
  actionSuccess: actions.criarDocumentoSuccess,
  api: apiCriarDocumento,
  postSuccess: function* ({ response, original, values }) {
    yield toast.success('Documento incluído com sucesso.');
    const { id } = yield select(getPayload);
    yield put(actions.listarEntidadesRelacionadasRequest(id));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarEntidadesRelacionadasRouteWatcher() {
  yield routeWatcher(routes.DOCUMENTOS, function* () {
    const { id } = yield select(getPayload);
    if (!id) return;
    yield put(actions.listarEntidadesRelacionadasRequest(id));
    yield put(actions.adicionarOportunidade(id));
    yield put(actions.adicionarReferencia(null));
  });
}

function* mostrarDocumentoWatcher() {
  yield routeWatcher(routes.DOCUMENTO, function* () {
    const { id, idDocumento } = yield select(getPayload);
    if (!id) return;
    if (idDocumento) {
      yield put(actions.mostrarDocumentoRequest(idDocumento));
      yield put(actions.adicionarOportunidade(id));
    }
  });
}

export const sagas = [
  listarEntidadesRelacionadas.watcher(),
  criarDocumentos.watcher(),
  listarDocumentos.watcher(),
  listarEntidadesRelacionadasRouteWatcher(),
  mostrarDocumentoWatcher(),
  atualizarDocumento.watcher(),
  deletarArquivo.watcher(),
  uploadArquivo.watcher(),
  mostrarDocumento.watcher()
];
