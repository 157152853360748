import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  regionais: {
    loading: false,
    error: false,
    data: []
  },
  regional: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  buscarCep: {
    loading: false,
    error: null
  },
  parceiros: {
    loading: false,
    error: null,
    data: []
  }
};

const RegionaisSlice = createSlice({
  name: 'regionais',
  initialState,
  reducers: {
    listarRegionaisRequest: (state) => {
      state.regionais.loading = true;
      state.regionais.error = null;
      state.regionais.data = [];
    },
    listarRegionaisSuccess: (state, action) => {
      state.regionais.loading = false;
      state.regionais.error = false;
      state.regionais.data = action.payload.response.data;
    },
    listarRegionaisFailure: (state, action) => {
      state.regionais.loading = false;
      state.regionais.error = action.payload;
    },
    criarRegionalRequest: (state, action) => {
      state.regional.loading = true;
      state.regional.loading = true;
      state.regional.error = null;
    },
    criarRegionalSuccess: (state, action) => {
      state.regional.loading = false;
      state.regional.loading = false;
      state.regional.error = false;
    },
    criarRegionalFailure: (state, action) => {
      state.regional.loading = false;
      state.regional.loading = false;
      state.regional.error = action.payload;
    },
    editarRegionalRequest: (state, action) => {
      state.regional.loading = true;
      state.regional.loading = true;
      state.regional.error = null;
    },
    editarRegionalSuccess: (state, action) => {
      state.regional.loading = false;
      state.regional.loading = false;
      state.regional.error = false;
    },
    editarRegionalFailure: (state, action) => {
      state.regional.loading = false;
      state.regional.loading = false;
      state.regional.error = action.payload;
    },
    mostrarRegionalRequest: (state, action) => {
      state.regional.loading = true;
      state.regional.data = null;
      state.regional.error = null;
    },
    mostrarRegionalSuccess: (state, action) => {
      state.regional.loading = false;
      state.regional.data = action.payload.response.data;
      state.regional.error = false;
    },
    mostrarRegionalFailure: (state, action) => {
      state.regional.loading = false;
      state.regional.loading = false;
      state.regional.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.regional.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.regional.isEditMode = true;
    },
    limparRegional: (state) => {
      state.regional.data = null;
    },
    excluirRegionalRequest: (state) => {
      state.regionais.loading = true;
      state.regionais.error = null;
    },
    excluirRegionalSuccess: (state) => {
      state.regionais.loading = false;
      state.regionais.error = false;
    },
    excluirRegionalFailure: (state, action) => {
      state.regionais.loading = false;
      state.regionais.error = action.payload;
    },
    buscarCepRequest: (state) => {
      state.buscarCep.loading = true;
      state.buscarCep.error = null;
    },
    buscarCepSuccess: (state) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = false;
    },
    buscarCepFailure: (state, action) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = action.payload;
    },
    listarParceirosRequest: (state) => {
      state.parceiros.loading = true;
      state.parceiros.error = null;
      state.parceiros.data = [];
    },
    listarParceirosSuccess: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = false;
      state.parceiros.data = action.payload.response.data;
    },
    listarParceirosFailure: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = action.payload;
    }
  }
});

export const {
  listarRegionaisRequest,
  listarRegionaisSuccess,
  listarRegionaisFailure,
  adicionarOportunidade,
  criarRegionalRequest,
  criarRegionalSuccess,
  criarRegionalFailure,
  mostrarRegionalRequest,
  mostrarRegionalSuccess,
  mostrarRegionalFailure,
  editarRegionalRequest,
  editarRegionalSuccess,
  editarRegionalFailure,
  adicionarEtapa,
  reverterRegionais,
  desativarModoEdicao,
  ativarModoEdicao,
  limparRegional,
  excluirRegionalRequest,
  excluirRegionalSuccess,
  excluirRegionalFailure,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  listarParceirosRequest,
  listarParceirosSuccess,
  listarParceirosFailure
} = RegionaisSlice.actions;

export default RegionaisSlice.reducer;
