import React, { useState } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { useSelector } from 'react-redux';

const DadosDoIntervenienteQuitante = ({ formProps }) => {
  const oportunidade = useSelector((state) => state.oportunidade.data);

  const [comentario, setComentario] = useState(
    oportunidade.descricaoIntervenienteQuitante
  );

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} sm={12} lg={12} style={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Stack spacing={0}>
              <InputLabel htmlFor="nomeIntervenienteQuitante">Nome</InputLabel>
              <OutlinedInput
                id="nomeIntervenienteQuitante"
                name="nomeIntervenienteQuitante"
                placeholder="Nome"
                fullWidth
                required
                multiline
                formProps={formProps}
                inputProps={{ maxLength: 100 }}
              />
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={12} sm={12} style={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <Stack spacing={0}>
              <InputLabel htmlFor="descricaoIntervenienteQuitante">
                Comentário sobre o Processo
              </InputLabel>
              <OutlinedInput
                id="descricaoIntervenienteQuitante"
                name="descricaoIntervenienteQuitante"
                placeholder="Comentário sobre o Processo"
                fullWidth
                required
                multiline
                rows={4}
                formProps={formProps}
                inputProps={{ maxLength: 2000 }}
                value={comentario}
                onChange={handleComentarioChange}
              />
            </Stack>
            <Typography variant="body2" color="textSecondary" align="right">
              {comentario?.length}/{2000}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DadosDoIntervenienteQuitante;
