import basicFlow, { genericErrorHandler } from './asyncHandler';
import {
  operacaoRequest,
  operacaoFailure,
  operacaoSuccess,
  regionalRequest,
  regionalFailure,
  regionalSuccess,
  usuariosRequest,
  usuariosFailure,
  usuariosSuccess,
  parceiroRequest,
  parceiroFailure,
  parceiroSuccess,
  usuariosParceirosRequest,
  usuariosParceirosFailure,
  usuariosParceirosSuccess,
  bancosRequest,
  bancosFailure,
  bancosSuccess,
  etapasSuccess,
  etapasFailure,
  etapasRequest
} from '../reducers/dominios.reducer';
import { authenticatedRequest } from '../utils/api';
import { routeWatcher } from './rotas.saga';
import { put } from 'redux-saga/effects';

import { types as routes } from '../reducers/rotas.actions';

const listarOperacoesApi = () => {
  return authenticatedRequest({
    url: '/operacao/list',
    method: 'POST'
  });
};

const listarOperacoes = basicFlow({
  actionGenerator: operacaoRequest,
  actionFailure: operacaoFailure,
  actionSuccess: operacaoSuccess,
  api: listarOperacoesApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarBancosApi = () => {
  return authenticatedRequest({
    url: '/banco/list',
    method: 'GET'
  });
};

const listarBancos = basicFlow({
  actionGenerator: bancosRequest,
  actionFailure: bancosFailure,
  actionSuccess: bancosSuccess,
  api: listarBancosApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarBancosRouteWatcher() {
  yield routeWatcher(
    [
      routes.COMPRADORES,
      routes.PESSOA_FISICA,
      routes.PESSOA_JURIDICA,
      routes.VENDEDOR_PESSOA_FISICA,
      routes.VENDEDOR_PESSOA_JURIDICA
    ],
    function* () {
      yield put(bancosRequest());
    }
  );
}

const listarRegionalApi = () => {
  return authenticatedRequest({
    url: '/regional/list',
    method: 'POST'
  });
};

const listarRegional = basicFlow({
  actionGenerator: regionalRequest,
  actionFailure: regionalFailure,
  actionSuccess: regionalSuccess,
  api: listarRegionalApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarUsuariosApi = () => {
  return authenticatedRequest({
    url: '/usuario/list',
    method: 'POST'
  });
};

const listarUsuarios = basicFlow({
  actionGenerator: usuariosRequest,
  actionFailure: usuariosFailure,
  actionSuccess: usuariosSuccess,
  api: listarUsuariosApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarParceirosApi = (values) => {
  return authenticatedRequest({
    url: '/parceiro/list',
    method: 'POST',
    body: values
  });
};

const listarParceiros = basicFlow({
  actionGenerator: parceiroRequest,
  actionFailure: parceiroFailure,
  actionSuccess: parceiroSuccess,
  api: listarParceirosApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarUsuariosParceirosApi = () => {
  return authenticatedRequest({
    url: '/usuario/usuario-parceiro/list',
    method: 'POST'
  });
};

const listarUsuariosParceiros = basicFlow({
  actionGenerator: usuariosParceirosRequest,
  actionFailure: usuariosParceirosFailure,
  actionSuccess: usuariosParceirosSuccess,
  api: listarUsuariosParceirosApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarEtapasApi = () => {
  return authenticatedRequest({
    url: '/etapa/list',
    method: 'POST'
  });
};

const listarEtapas = basicFlow({
  actionGenerator: etapasRequest,
  actionFailure: etapasFailure,
  actionSuccess: etapasSuccess,
  api: listarEtapasApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

export const sagas = [
  listarOperacoes.watcher(),
  listarRegional.watcher(),
  listarUsuarios.watcher(),
  listarParceiros.watcher(),
  listarUsuariosParceiros.watcher(),
  listarBancos.watcher(),
  listarBancosRouteWatcher(),
  listarEtapas.watcher()
];
