// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import MainLayout from '../../../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import MainCard from '../../../components/MainCard';
import {
  DeleteOutlined,
  EditOutlined,
  ApartmentOutlined,
  TeamOutlined
} from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { Button, Box } from '@mui/material';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

import * as actions from 'reducers/regionais.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { toast } from 'react-toastify';
import formatters from 'utils/formatters';

const OportunidadesPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const regionais = useSelector((state) => state.regionais.regionais.data);
  const loading = useSelector((state) => state.regionais.regionais.loading);

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const rows = regionais;

  const columns = [
    {
      field: 'cnpj',
      headerName: 'CNPJ',
      flex: 1,
      valueFormatter: (value) => {
        return value ? formatters.strings.cnpj(value) : '';
      }
    },
    {
      field: 'nomeRegional',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'municipio',
      headerName: 'Cidade',
      flex: 1
    },
    {
      field: 'uf',
      headerName: 'UF',
      flex: 1,
      valueFormatter: (value) => {
        return value ? ufs.find((uf) => uf.codigo === value)?.nome : '';
      }
    }
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewRegional = () => {
    dispatch(
      routeActions.redirectTo(routes.REGIONAL, {
        idRegional: 'novo'
      })
    );
  };

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleDeleteClick = () => {
      dispatch(actions.excluirRegionalRequest({ value: selectedRowData[0] }));
      setOpen(false);
    };

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.REGIONAL, {
            idRegional: selectedRowData[0].idRegional
          })
        );
      }
    };

    const handleClickParceiros = () => {
      dispatch(
        routeActions.redirectTo(routes.REGIONAL_PARCEIROS, {
          idRegional: selectedRowData[0].idRegional
        })
      );
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Vendedor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir a regional{' '}
              {selectedRowData[0]?.nomeRegional}? Ao excluir uma regional, não
              será mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleNewRegional}
            startIcon={<ApartmentOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Regional
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Excluir
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickParceiros}
            startIcon={<TeamOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Parceiros
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <MainCard
        title="Configuração de Regionais"
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            getRowId={(row) => row.idRegional}
            toolbar={CustomToolbar}
            loading={loading}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              density: 'standard'
            }}
          />
        </Box>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
