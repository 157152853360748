import React from 'react';
import { Stack, Grid } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from 'components/form/OutlinedInput';
import Autocomplete from 'components/form/Autocomplete';

const DadosDoDocumento = ({
  formProps,
  tipoSituacao,
  tipoGeracao,
  disabled
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={1}>
          <InputLabel htmlFor="nome">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeDocumento"
            name="nomeDocumento"
            placeholder="Nome do documento"
            fullWidth
            required
            formProps={formProps}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="tipoGeracao">Tipo de Geração</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="tipoGeracao"
            name="tipoGeracao"
            placeholder="Escolha o Status"
            fullWidth
            labelField={'label'}
            idField={'value'}
            domain={tipoGeracao}
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="tipoSituacao">Situação</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="tipoSituacao"
            name="tipoSituacao"
            placeholder="Escolha o Status"
            fullWidth
            labelField={'label'}
            idField={'value'}
            domain={tipoSituacao}
            formProps={formProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoDocumento;
