// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import AuthTrocarSenha from './auth-forms/TrocarSenha';
import AuthWrapper from './AuthWrapper';
import { useSelector } from 'react-redux';

// ================================|| LOGIN ||================================ //
const Login = () => {
  const type = useSelector((state) => state.auth['trocar-senha'].type);

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">{type === 'P' ? 'Primeiro Acesso' : 'Redefinição de Senha'}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthTrocarSenha />
        </Grid>
      </Grid>
    </AuthWrapper>
  )
};

export default Login;
