import { DataGrid } from '../../components/grid/DataGrid';
import Box from '@mui/material/Box';

import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  actions as routeActions,
  types as routes
} from '../../reducers/rotas.actions';
import { Card } from '../../../node_modules/@mui/material/index';
import formatters from 'utils/formatters';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { Launch } from '@mui/icons-material';

const PesquisaParticipantes = () => {
  const participantes = useSelector(
    (state) => state.pesquisa.pesquisa.data.participantes
  );

  const representantes = useSelector(
    (state) => state.pesquisa.pesquisa.data.representantes
  );

  const loading = useSelector((state) => state.pesquisa.pesquisa.loading);

  const rows = [...(participantes ?? []), ...(representantes ?? [])].map(
    (i) => {
      return {
        ...i,
        idOportunidade: i?.idOportunidade ?? i?.participante?.idOportunidade,
        id: i?.participante ? 'R.' + i?.idRepresentante : i?.idParticipante,
        participante: i?.nomeParticipante ?? i?.nomeRepresentanteLegal,
        codigoOportunidade:
          i?.oportunidade?.codigoOportunidade ??
          i.participante?.oportunidade?.codigoOportunidade,
        cpfCnpj: i?.cpfCnpj ?? i?.cpf,
        representante: i?.nomeRepresentanteLegal,
        tipoPessoa: i?.tipoPessoa ?? i?.participante?.tipoPessoa,
        tipoQualificacao:
          i?.tipoQualificacao ?? i?.participante?.tipoQualificacao,
        tipo: i?.tipoQualificacao
          ? i?.tipoQualificacao === 'CO'
            ? 'Comprador'
            : 'Vendedor'
          : 'Representante'
      };
    }
  );

  const dispatch = useDispatch();

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      flex: 1
    },
    {
      field: 'codigoOportunidade',
      headerName: 'Oportunidade',
      flex: 1
    },
    {
      field: 'participante',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'cpfCnpj',
      headerName: 'CPF/CNPJ',
      flex: 1,
      valueFormatter: (value) => {
        return formatters.strings.cpfCnpj(
          value,
          value.length === 11 ? 'F' : 'J'
        );
      }
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'Comprador', nome: 'Comprador' },
              { codigo: 'Vendedor', nome: 'Vendedor' },
              { codigo: 'Representante', nome: 'Representante' }
            ])
        }
      ]
    },
    {
      field: 'celular',
      headerName: 'Celular',
      type: 'number',
      flex: 1,
      valueFormatter: (value) => {
        if (value) return formatters.numbers.formatPhone(value, true);
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      flex: 1
    }
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false
  });

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        if (
          selectedRowData[0].idRepresentante &&
          selectedRowData[0].tipoQualificacao === 'VD'
        ) {
          return dispatch(
            routeActions.redirectTo(routes.VENDEDOR_REPRESENTANTE, {
              idPessoaJuridica: selectedRowData[0].idParticipante,
              id: selectedRowData[0].idOportunidade,
              idRepresentante: selectedRowData[0].idRepresentante
            })
          );
        }

        if (
          selectedRowData[0].idRepresentante &&
          selectedRowData[0].tipoQualificacao === 'CO'
        ) {
          return dispatch(
            routeActions.redirectTo(routes.REPRESENTANTE, {
              idPessoaJuridica: selectedRowData[0].idParticipante,
              id: selectedRowData[0].idOportunidade,
              idRepresentante: selectedRowData[0].idRepresentante
            })
          );
        }

        if (selectedRowData[0].tipoQualificacao === 'CO') {
          if (selectedRowData[0].tipoPessoa === 'F') {
            return dispatch(
              routeActions.redirectTo(routes.PESSOA_FISICA, {
                idPessoaFisica: selectedRowData[0].idParticipante,
                id: selectedRowData[0].idOportunidade
              })
            );
          } else {
            return dispatch(
              routeActions.redirectTo(routes.PESSOA_JURIDICA, {
                idPessoaJuridica: selectedRowData[0].idParticipante,
                id: selectedRowData[0].idOportunidade
              })
            );
          }
        }

        if (selectedRowData[0].tipoQualificacao === 'VD') {
          if (selectedRowData[0].tipoPessoa === 'F') {
            return dispatch(
              routeActions.redirectTo(routes.VENDEDOR_PESSOA_FISICA, {
                idPessoaFisica: selectedRowData[0].idParticipante,
                id: selectedRowData[0].idOportunidade
              })
            );
          } else {
            return dispatch(
              routeActions.redirectTo(routes.VENDEDOR_PESSOA_JURIDICA, {
                idPessoaJuridica: selectedRowData[0].idParticipante,
                id: selectedRowData[0].idOportunidade
              })
            );
          }
        }
      }
    };

    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<Launch />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Acessar Participante
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Card>
      <Box sx={{ marginTop: '5px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          density={'standard'}
          pageSize={10}
          pageSizeOptions={[10]}
          loading={loading}
          isOportunidade={true}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          toolbar={CustomToolbar}
          noLineText="Nenhum participante localizado"
        />
      </Box>
    </Card>
  );
};

export default PesquisaParticipantes;
