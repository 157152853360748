import React, { useState, useCallback } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {
  ZoomInOutlined,
  ZoomOutOutlined,
  RedoOutlined,
  UndoOutlined,
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  ExportOutlined
} from '@ant-design/icons';
import Button from 'components/Button';
import { Box, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import styles from './styles.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const ViewerContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden'
});

const ControlsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  marginBottom: '20px',
  flexWrap: 'wrap'
}));

const Image = styled('img')({
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain'
});

const DocumentViewer = ({ arquivo }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [rotation, setRotation] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber((prevPage) => Math.max(prevPage - 1, 1));
  const goToNextPage = () =>
    setPageNumber((prevPage) => Math.min(prevPage + 1, numPages));
  const rotateLeft = () => setRotation((prevRotation) => prevRotation - 90);
  const rotateRight = () => setRotation((prevRotation) => prevRotation + 90);

  const onZoomInClick = useCallback((zoomIn) => {
    zoomIn();
  }, []);

  const onZoomOutClick = useCallback((zoomOut) => {
    zoomOut();
  }, []);

  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = `data:application/octet-stream;base64,${arquivo.conteudo}`;
    link.download = arquivo.nomeArquivo;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const openInNewTab = () => {
    const byteCharacters = atob(arquivo.conteudo);
    const byteNumbers = new Array(byteCharacters.length)
      .fill()
      .map((_, i) => byteCharacters.charCodeAt(i));
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {
      type: arquivo.nomeArquivo.endsWith('.pdf')
        ? 'application/pdf'
        : 'image/jpeg'
    });
    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  };

  const renderPdfControls = (zoomIn, zoomOut, resetTransform) => (
    <ControlsContainer>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Página anterior">
          <Box>
            <Button variant="contained" onClick={goToPrevPage}>
              <LeftOutlined />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title="Próxima página">
          <Box>
            <Button variant="contained" onClick={goToNextPage}>
              <RightOutlined />
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Aumentar">
          <Box>
            <Button variant="contained" onClick={() => onZoomInClick(zoomIn)}>
              <ZoomInOutlined />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title="Diminuir">
          <Box>
            <Button variant="contained" onClick={() => onZoomOutClick(zoomOut)}>
              <ZoomOutOutlined />
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Girar para a esquerda">
          <Box>
            <Button variant="contained" onClick={rotateLeft}>
              <UndoOutlined />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title="Girar para a direita">
          <Box>
            <Button variant="contained" onClick={rotateRight}>
              <RedoOutlined />
            </Button>
          </Box>
        </Tooltip>
      </Box>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Tooltip title="Download">
          <Box>
            <Button variant="contained" onClick={downloadFile}>
              <DownloadOutlined />
            </Button>
          </Box>
        </Tooltip>
        <Tooltip title="Abrir em nova aba">
          <Box>
            <Button variant="contained" onClick={openInNewTab}>
              <ExportOutlined />
            </Button>
          </Box>
        </Tooltip>
      </Box>
    </ControlsContainer>
  );

  const renderImageControls = (zoomIn, zoomOut, resetTransform) => (
    <ControlsContainer>
      <Tooltip title="Aumentar">
        <Box>
          <Button variant="contained" onClick={() => onZoomInClick(zoomIn)}>
            <ZoomInOutlined />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title="Diminuir">
        <Box>
          <Button variant="contained" onClick={() => onZoomOutClick(zoomOut)}>
            <ZoomOutOutlined />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title="Download">
        <Box>
          <Button variant="contained" onClick={downloadFile}>
            <DownloadOutlined />
          </Button>
        </Box>
      </Tooltip>
      <Tooltip title="Abrir em nova aba">
        <Box>
          <Button variant="contained" onClick={openInNewTab}>
            <ExportOutlined />
          </Button>
        </Box>
      </Tooltip>
    </ControlsContainer>
  );

  const renderContent = (zoomIn, zoomOut, resetTransform) => {
    if (arquivo.nomeArquivo.endsWith('.pdf')) {
      return (
        <>
          {renderPdfControls(zoomIn, zoomOut, resetTransform)}
          <TransformComponent>
            <div className={styles.documentWrapper}>
              <Document
                file={`data:application/pdf;base64,${arquivo.conteudo}`}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  className={styles.page}
                  pageNumber={pageNumber}
                  rotate={rotation}
                  scale={1.5}
                />
              </Document>
            </div>
          </TransformComponent>
        </>
      );
    } else {
      return (
        <>
          {renderImageControls(zoomIn, zoomOut, resetTransform)}
          <TransformComponent>
            <div>
              <Image
                src={`data:image/jpeg;base64,${arquivo.conteudo}`}
                alt={arquivo.nomeArquivo}
              />
            </div>
          </TransformComponent>
        </>
      );
    }
  };

  return (
    <ViewerContainer className={styles.documentWrapper}>
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        wheel={{ step: 0.1 }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>{renderContent(zoomIn, zoomOut, resetTransform)}</>
        )}
      </TransformWrapper>
    </ViewerContainer>
  );
};

export default DocumentViewer;
