import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';
import PhoneTextField from 'components/form/PhoneTextField';

const DadosDoProprietario = ({ formProps, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeProprietario">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeProprietario"
            name="nomeProprietario"
            placeholder="Nome do Proprietário"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="emailProprietario">Email</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="emailProprietario"
            name="emailProprietario"
            type="email"
            placeholder="Digite o e-mail"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="celularProprietario">Celular</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="celularProprietario"
            name="celularProprietario"
            placeholder="Digite o número do celular"
            fullWidth
            required
            formProps={formProps}
            maxLength={15}
            InputProps={{
              inputComponent: PhoneTextField,
              inputProps: { isMobile: true }
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoProprietario;
