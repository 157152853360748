import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';
import Autocomplete from 'components/form/Autocomplete';
import CpfCnpjTextField from 'components/form/CpfCnpjTextField';

const DadosDoParceiro = ({
  formProps,
  regionais,
  origensParceiro,
  perfis,
  disabled
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeParceiro">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeParceiro"
            name="nomeParceiro"
            placeholder="Nome do Parceiro"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="origem">Origem</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="origem"
            name="origem"
            placeholder="Escolha a Origem"
            fullWidth
            labelField={'nome'}
            idField={'codigo'}
            domain={origensParceiro}
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="banco">Regional</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="regional"
            name="regional"
            placeholder="Escolha a Regional"
            fullWidth
            labelField={'nomeRegional'}
            idField={'idRegional'}
            domain={regionais}
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfCnpj">CPF/CNPJ</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="cpfCnpj"
            name="cpfCnpj"
            placeholder="Digite o CPF/CNPJ"
            fullWidth
            formProps={formProps}
            type="both"
            InputProps={{
              inputComponent: CpfCnpjTextField
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="banco">Perfil do Parceiro</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="perfil"
            name="perfil"
            placeholder="Escolha o Perfil"
            fullWidth
            labelField={'nome'}
            idField={'codigo'}
            domain={perfis}
            formProps={formProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoParceiro;
