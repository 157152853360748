import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/configuracaoDocumentos.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarDocumentos = () => {
  return authenticatedRequest({
    url: `/configuracao-documentos/list`,
    method: 'get'
  });
};

const listarDocumentos = basicFlow({
  actionGenerator: actions.listarDocumentosRequest,
  actionFailure: actions.listarDocumentosFailure,
  actionSuccess: actions.listarDocumentosSuccess,
  api: apiListarDocumentos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarDocumento = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-documentos/create`,
    method: 'POST',
    body: value
  });
};

const criarDocumentos = basicFlow({
  actionGenerator: actions.criarDocumentoRequest,
  actionFailure: actions.criarDocumentoFailure,
  actionSuccess: actions.criarDocumentoSuccess,
  api: apiCriarDocumento,
  postSuccess: function* ({ response }) {
    toast.success('Documento incluído com sucesso.');
    yield put(
      routeActions.redirectTo(routes.CONFIGURACAO_DOCUMENTO, {
        idDocumento: response.data.idDocumento
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarDocumento = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-documentos/update/${value.idDocumento}`,
    method: 'PUT',
    body: value
  });
};

const editarDocumentos = basicFlow({
  actionGenerator: actions.editarDocumentoRequest,
  actionFailure: actions.editarDocumentoFailure,
  actionSuccess: actions.editarDocumentoSuccess,
  api: apiEditarDocumento,
  postSuccess: function* (value) {
    yield toast.success('Documento editado com sucesso.');
    const { idDocumento } = yield select(getPayload);
    yield put(actions.mostrarDocumentoRequest(idDocumento));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarDocumento = (id) => {
  return authenticatedRequest({
    url: `/configuracao-documentos/show/${id}`,
    method: 'get'
  });
};

const mostrarDocumento = basicFlow({
  actionGenerator: actions.mostrarDocumentoRequest,
  actionFailure: actions.mostrarDocumentoFailure,
  actionSuccess: actions.mostrarDocumentoSuccess,
  api: apiMostrarDocumento,
  postFailure: function* ({ error }) {
    yield toast.error('Documento não encontrado.');
  }
});

const apiExcluirDocumento = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-documentos/delete/${value.idDocumento}`,
    method: 'delete'
  });
};

const excluirDocumento = basicFlow({
  actionGenerator: actions.excluirDocumentoRequest,
  actionFailure: actions.excluirDocumentoFailure,
  actionSuccess: actions.excluirDocumentoSuccess,
  api: apiExcluirDocumento,
  postSuccess: function* () {
    yield toast.success('Documento excluído com sucesso.');
    yield put(actions.listarDocumentosRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarDocumentoRouteWatcher() {
  yield routeWatcher(routes.CONFIGURACAO_DOCUMENTO, function* () {
    const { idDocumento } = yield select(getPayload);
    if (idDocumento === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparDocumento());
      return;
    }
    if (!idDocumento) return;
    yield put(actions.mostrarDocumentoRequest(idDocumento));
    yield put(actions.ativarModoEdicao());
  });
}

function* listarDocumentosRouteWatcher() {
  yield routeWatcher(routes.CONFIGURACAO_DOCUMENTOS, function* () {
    yield put(actions.listarDocumentosRequest());
  });
}

export const sagas = [
  listarDocumentos.watcher(),
  criarDocumentos.watcher(),
  editarDocumentos.watcher(),
  mostrarDocumento.watcher(),
  excluirDocumento.watcher(),
  listarDocumentosRouteWatcher(),
  mostrarDocumentoRouteWatcher()
];
