import React from 'react';
import { Funnel } from '@ant-design/charts';
import { useDispatch, useSelector } from 'react-redux';
import MainCard from '../../components/MainCard';
import MainLayout from '../../layout/MainLayout';
import Loader from 'components/Loader';
import formatters from 'utils/formatters';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';

import { Grid } from '@mui/material';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

const FunilDeVendas = () => {
  const funilDeVendas = useSelector(
    (state) => state.oportunidade.funilDeVendas.data
  );

  const loading = useSelector(
    (state) => state.oportunidade.funilDeVendas.loading
  );

  const config = {
    data: funilDeVendas,
    xField: 'nomeEtapa',
    yField: 'quantidadeOportunidades',
    padding: [0, 22, 0, 0],

    label: [
      {
        text: (d) => {
          return `R$ ${formatters.numbers.currency(d.valorTotalFinanciado)}`;
        },
        position: 'inside',
        fontSize: 18,
        style: {
          fill: 'black',
          fontWeight: 'bold'
        }
      },
      {
        render: ($, _, i) => {
          if (i)
            return (
              <div
                style={{
                  height: 1,
                  width: 30,
                  background: '#aaa',
                  margin: '0 20'
                }}
              ></div>
            );
        },
        position: 'top-right'
      },
      {
        text: (d) => {
          return d.quantidadeOportunidades;
        },
        position: 'top-right',
        textAlign: 'left',
        textBaseline: 'middle',
        offsetX: 0,
        dy: (d, index) => (index === 0 ? -6 : 2),
        dx: (d, index) => (index === 0 ? -30 : 33),
        style: {
          fill: 'black',
          fontWeight: 'bold',
          fontSize: 18
        }
      }
    ]
  };

  const dispatch = useDispatch();

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.OPORTUNIDADES));
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <MainCard
        border={false}
        sx={{ paddingBottom: '0px' }}
        title="Funil de Vendas"
      >
        <div
          style={{
            padding: '20px'
          }}
        >
          <Funnel {...config} />
        </div>
        <Grid container spacing={2} sx={{ justifyContent: 'end', p: 1 }}>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default FunilDeVendas;
