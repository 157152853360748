import React from "react";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";
import { Autocomplete, FormControl, Skeleton, TextField } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";

const ControlledAutocomplete = ({
                                  formProps,
                                  domain,
                                  idField = "id",
                                  labelField,
                                  getOptionLabel = (option) =>
                                    option[labelField] ??
                                    domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
                                    "",
                                  name,
                                  ignoreError = false,
                                  autocompleteProps = {},
                                  noOptionsText = "",
                                  disabled,
                                  skeleton = false,
                                  loading = false,
                                  ...otherProps
                                }) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;

  const isError = getValueFromObject(errors, name) !== undefined;

  if (loading && skeleton) {
    return (
      <Skeleton sx={{ height: 35 }} animation="wave" variant={"rounded"} />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      render={({ field: { onChange, onBlur, value } }) => (
        <FormControl error={isError} fullWidth>
          <Autocomplete
            options={domain}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) =>
              option?.[idField] === value?.[idField]
            }
            value={value}
            {...autocompleteProps}
            onChange={(e, v) => {
              onChange(v);
              if (otherProps.onChange) {
                otherProps.onChange(v);
              }
            }}
            onBlur={() => {
              onBlur();
              if (autocompleteProps.onBlur) {
                autocompleteProps.onBlur();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                error={isError}
                size="small"
                helperText={!ignoreError ? errors[name]?.message : undefined}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option[idField]}>
                {getOptionLabel(option)}
              </li>
            )}
            noOptionsText={noOptionsText}
            disabled={disabled}
          />
        </FormControl>
      )}
    />
  );
};

export default ControlledAutocomplete;
