import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import { useSelector } from 'react-redux';
import ControlledAutocomplete from 'components/form/Autocomplete';
import OutlinedInput from 'components/form/OutlinedInput';
import ZipCodeTextField from 'components/form/ZipCodeTextField';

const DadosDoImovel = ({ formProps, handleZipCodeBlurred }) => {
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const tiposImovel = useSelector((state) => state.dominios.tiposImovel);
  const tiposUtilizacao = useSelector(
    (state) => state.dominios.tiposUtilizacao
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoImovel">Tipo do imóvel</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoImovel"
            name="tipoImovel"
            placeholder="Selecione o Tipo do Imóvel"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposImovel}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoUtilizacaoImovel">
          Utilização do Imóvel
        </InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoUtilizacaoImovel"
            name="tipoUtilizacaoImovel"
            placeholder="Selecione o Tipo do Imóvel"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposUtilizacao}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cep">CEP</InputLabel>
          <OutlinedInput
            id="cep"
            name={'cep'}
            formProps={formProps}
            onBlur={() => handleZipCodeBlurred(formProps.getValues('cep'))}
            InputProps={{
              inputComponent: ZipCodeTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="logradouro">Endereço</InputLabel>
          <OutlinedInput
            id="logradouro"
            name="logradouro"
            placeholder="Digite o endereço"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="numeroLogradouro">Número</InputLabel>
          <OutlinedInput
            id="numeroLogradouro"
            name="numeroLogradouro"
            placeholder="Digite o número"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="complementoLogradouro">Complemento</InputLabel>
          <OutlinedInput
            id="complementoLogradouro"
            name="complementoLogradouro"
            placeholder="Digite o complemento"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 30 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="bairro">Bairro</InputLabel>
          <OutlinedInput
            id="bairro"
            name="bairro"
            placeholder="Digite o bairro"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="municipio">Cidade</InputLabel>
          <OutlinedInput
            id="municipio"
            name="municipio"
            placeholder="Digite a cidade"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 60 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`uf`}>UF</InputLabel>
          <ControlledAutocomplete
            id="uf"
            name="uf"
            placeholder="Selecione a UF"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DadosDoImovel;
