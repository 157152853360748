// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import MainLayout from '../../../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import MainCard from '../../../components/MainCard';
import {
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  FileTextOutlined,
  CommentOutlined
} from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { Button, Box } from '@mui/material';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

import * as actions from 'reducers/usuariosParceiros.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { toast } from 'react-toastify';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { Grid, Typography } from '../../../../node_modules/@mui/material/index';
import AnimateButton from 'components/@extended/AnimateButton';

const OportunidadesPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const parceiros = useSelector(
    (state) => state.usuariosParceiros.parceiros.data
  );

  const parceiro = useSelector((state) => state.parceiros.parceiro.data);

  const loadingParceiros = useSelector(
    (state) => state.usuariosParceiros.parceiros.loading
  );

  const idParceiro = useSelector((state) => state.usuariosParceiros.idParceiro);

  const loadingUsuariosParceiros = useSelector(
    (state) => state.usuariosParceiros.parceiros.loading
  );

  const loading = loadingParceiros || loadingUsuariosParceiros;

  const parceirosData = parceiros.map((item) => ({
    ...item,
    nomeRegional: item.regional.nomeRegional
  }));

  const rows = parceirosData;

  const regionais = [];

  for (let parceiro of rows) {
    const item = {
      nome: parceiro.nomeRegional,
      codigo: parceiro.nomeRegional
    };

    if (!regionais.find((regional) => regional.codigo === item.codigo)) {
      regionais.push(item);
    }
  }

  const columns = [
    {
      field: 'nomeUsuarioParceiro',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'nomeRegional',
      headerName: 'Regional',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, [...regionais])
        }
      ]
    },
    {
      field: 'tipoOrigem',
      headerName: 'Origem',
      flex: 1,
      valueFormatter: (value) => {
        return [
          { codigo: 'RM', nome: 'RE/MAX' },
          { codigo: 'IN', nome: 'Indicação' },
          { codigo: 'PR', nome: 'Prospecção' },
          { codigo: 'ST', nome: 'Site' },
          { codigo: 'MK', nome: 'Marketing' }
        ].find((item) => item.codigo === value)?.nome;
      },

      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'RM', nome: 'RE/MAX' },
              { codigo: 'IN', nome: 'Indicação' },
              { codigo: 'PR', nome: 'Prospecção' },
              { codigo: 'ST ', nome: 'Site' },
              { codigo: 'MK', nome: 'Marketing' }
            ])
        }
      ]
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      valueFormatter: (value) => {
        return value === 'A' ? 'Ativo' : value === 'I' ? 'Inativo' : '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'A', nome: 'Ativo' },
              { codigo: 'I', nome: 'Inativo' }
            ])
        }
      ]
    }
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewParceiro = () => {
    dispatch(
      routeActions.redirectTo(routes.USUARIOS_PARCEIRO, {
        idParceiro: idParceiro,
        idUsuarioParceiro: 'novo'
      })
    );
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.PARCEIROS));
  };

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleDeleteClick = () => {
      dispatch(actions.excluirParceiroRequest({ value: selectedRowData[0] }));
      setOpen(false);
    };

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.USUARIOS_PARCEIRO, {
            idParceiro: selectedRowData[0].idParceiro,
            idUsuarioParceiro: selectedRowData[0].idUsuarioParceiro
          })
        );
      }
    };

    const handleDocumentos = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.USUARIOS_PARCEIRO_DOCUMENTO, {
            idUsuarioParceiro: selectedRowData[0].idUsuarioParceiro,
            idParceiro: idParceiro
          })
        );
      }
    };

    const handleFup = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.USUARIOS_PARCEIRO_FUP, {
            idUsuarioParceiro: selectedRowData[0].idUsuarioParceiro,
            idParceiro: idParceiro
          })
        );
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Vendedor</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o Usuário Parceiro{' '}
              {selectedRowData[0]?.nomeUsuarioParceiro}? Ao excluir um Usuário
              Parceiro, não será mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleNewParceiro}
            disabled={parceiro?.tipoSituacao !== 'A'}
            startIcon={<UserOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Usuário Parceiro
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1 || parceiro?.tipoSituacao !== 'A'}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1 || parceiro?.tipoSituacao !== 'A'
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Excluir
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleDocumentos}
            startIcon={<FileTextOutlined />}
            disabled={selectedRows.size !== 1}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Documentos
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleFup}
            startIcon={<CommentOutlined />}
            disabled={selectedRows.size !== 1}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Comentários
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <MainCard
        title={
          <Box>
            <Typography variant="h2">{parceiro?.nomeParceiro}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Usuários Parceiros
            </Typography>
          </Box>
        }
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            getRowId={(row) => row.idUsuarioParceiro}
            toolbar={CustomToolbar}
            loading={loading}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              density: 'standard'
            }}
            noLineText="Nenhum usuário parceiro encontrado"
          />
        </Box>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
