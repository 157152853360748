import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import Autocomplete from 'components/form/Autocomplete';

import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';

const DadosDaAtividadeBanco = ({ formProps, disabled }) => {
  const bancos = useSelector(
    (state) => state.produtosAtividadesBanco.bancos.data
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="banco">Bancos</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="banco"
            name="banco"
            placeholder="Escolha o Status"
            fullWidth
            labelField={'nomeBanco'}
            idField={'idBanco'}
            domain={bancos}
            formProps={formProps}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={8}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeAtividadeBanco">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeAtividadeBanco"
            name="nomeAtividadeBanco"
            placeholder="Nome"
            fullWidth
            formProps={formProps}
            required
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDaAtividadeBanco;
