// third party
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import React, { useEffect, useState } from 'react';
import validators from '../../../utils/validators';
import {
  enviarNovoTokenRequest,
  loginRequest,
  validarTokenRequest
} from '../../../reducers/autenticacao.reducer';
import OutlinedInput from '../../../components/form/OutlinedInput';
import Button from '../../../components/Button';

// assets

import { Grid, Link, Stack } from '@mui/material';
import {
  actions as routeActions,
  types as routes
} from '../../../reducers/rotas.actions';
import { styled } from '@mui/system';
import moment from 'moment';
import { getPass, getUser } from '../../../utils/localStorage';

const StyledLink = styled(Link)`
  font-size: 14px;
  cursor: pointer;
  color: #3f484a;
`;
const StyledLinkGrid = styled(Grid)`
  text-align: right;
  align-items: end;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const AuthToken = () => {
  const dispatch = useDispatch();
  const [time, setTime] = useState(Date.now());

  const loading = useSelector((state) => state.auth['token'].submitting);
  const limiteToken = useSelector((state) => state.auth.token.tempoMsg);
  const usuario = useSelector((state) => state.auth.token.usuario);

  const rules = {
    token: validators.number({ required: true, min: 6 })
  };
  const initialValues = {
    token: ''
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const handleSubmit = (values) => {
    dispatch(validarTokenRequest(values));
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  };

  const handleClickNewToken = () => {
    dispatch(enviarNovoTokenRequest({usuario}));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 1000); // Update every second

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <OutlinedInput
                id="token"
                value=""
                name="token"
                placeholder="Digitar o token"
                fullWidth
                formProps={formProps}
              />
            </Stack>
          </Grid>
          {limiteToken && moment().isAfter(moment(limiteToken)) && (
            <StyledLinkGrid item xs={12} md={12}>
              <StyledLink underline="hover" onClick={handleClickNewToken}>
                Enviar novo token
              </StyledLink>
            </StyledLinkGrid>
          )}
          <Grid item xs={6}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={loading}
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                onClick={handleGoBack}
              >
                Voltar
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={6}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={loading}
                isLoading={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
              >
                Enviar
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AuthToken;
