import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  idEtapa: null,
  etapas: {
    loading: false,
    error: false,
    data: []
  },
  atividades: {
    loading: false,
    error: false,
    data: []
  }
};

const AtividadesSlice = createSlice({
  name: 'atividades',
  initialState,
  reducers: {
    listarEtapasRequest: (state) => {
      state.etapas.loading = true;
      state.etapas.error = null;
      state.atividades.data = [];
    },
    listarEtapasSuccess: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = false;
      state.etapas.data = action.payload.response.data;
    },
    listarEtapasFailure: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = action.payload;
    },
    listarAtividadesRequest: (state) => {
      state.atividades.loading = true;
      state.atividades.error = null;
    },
    listarAtividadesSuccess: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = false;
      state.atividades.data = action.payload.response.data;
    },
    listarAtividadesFailure: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = action.payload;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    atualizarAtividadeRequest: (state, action) => {
      state.atividades.loading = true;
      state.etapas.loading = true;
      state.atividades.error = null;
    },
    atualizarAtividadeSuccess: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = false;
    },
    atualizarAtividadeFailure: (state, action) => {
      state.atividades.loading = false;
      state.etapas.loading = false;
      state.atividades.error = action.payload;
    },
    adicionarEtapa: (state, action) => {
      state.idEtapa = Number(action.payload);
    }
  }
});

export const {
  listarEtapasRequest,
  listarEtapasSuccess,
  listarEtapasFailure,
  listarAtividadesRequest,
  listarAtividadesSuccess,
  listarAtividadesFailure,
  adicionarOportunidade,
  atualizarAtividadeRequest,
  atualizarAtividadeSuccess,
  atualizarAtividadeFailure,
  adicionarEtapa
} = AtividadesSlice.actions;

export default AtividadesSlice.reducer;
