import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  etapas: {
    loading: false,
    error: false,
    data: []
  },
  etapa: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  idOperacao: null
};

const EtapasSlice = createSlice({
  name: 'produtosEtapas',
  initialState,
  reducers: {
    listarEtapasRequest: (state) => {
      state.etapas.loading = true;
      state.etapas.error = null;
      state.etapas.data = [];
    },
    listarEtapasSuccess: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = false;
      state.etapas.data = action.payload.response.data;
    },
    listarEtapasFailure: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = action.payload;
    },
    criarEtapaRequest: (state, action) => {
      state.etapa.loading = true;
      state.etapa.loading = true;
      state.etapa.error = null;
    },
    criarEtapaSuccess: (state, action) => {
      state.etapa.loading = false;
      state.etapa.loading = false;
      state.etapa.error = false;
    },
    criarEtapaFailure: (state, action) => {
      state.etapa.loading = false;
      state.etapa.loading = false;
      state.etapa.error = action.payload;
    },
    editarEtapaRequest: (state, action) => {
      state.etapa.loading = true;
      state.etapa.loading = true;
      state.etapa.error = null;
    },
    editarEtapaSuccess: (state, action) => {
      state.etapa.loading = false;
      state.etapa.loading = false;
      state.etapa.error = false;
    },
    editarEtapaFailure: (state, action) => {
      state.etapa.loading = false;
      state.etapa.loading = false;
      state.etapa.error = action.payload;
    },
    mostrarEtapaRequest: (state, action) => {
      state.etapa.loading = true;
      state.etapa.data = null;
      state.etapa.error = null;
    },
    mostrarEtapaSuccess: (state, action) => {
      state.etapa.loading = false;
      state.etapa.data = action.payload.response.data;
      state.etapa.error = false;
    },
    mostrarEtapaFailure: (state, action) => {
      state.etapa.loading = false;
      state.etapa.loading = false;
      state.etapa.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.etapa.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.etapa.isEditMode = true;
    },
    limparEtapa: (state) => {
      state.etapa.data = null;
    },
    excluirEtapaRequest: (state) => {
      state.etapas.loading = true;
      state.etapas.error = null;
    },
    excluirEtapaSuccess: (state) => {
      state.etapas.loading = false;
      state.etapas.error = false;
    },
    excluirEtapaFailure: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = action.payload;
    },
    adicionarIdOperacao: (state, action) => {
      state.idOperacao = Number(action.payload);
    }
  }
});

export const {
  listarEtapasRequest,
  listarEtapasSuccess,
  listarEtapasFailure,
  adicionarOportunidade,
  criarEtapaRequest,
  criarEtapaSuccess,
  criarEtapaFailure,
  mostrarEtapaRequest,
  mostrarEtapaSuccess,
  mostrarEtapaFailure,
  editarEtapaRequest,
  editarEtapaSuccess,
  editarEtapaFailure,
  desativarModoEdicao,
  ativarModoEdicao,
  limparEtapa,
  excluirEtapaRequest,
  excluirEtapaSuccess,
  excluirEtapaFailure,
  adicionarIdOperacao
} = EtapasSlice.actions;

export default EtapasSlice.reducer;
