// third party
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import React, { useCallback } from 'react';
import validators from '../../../utils/validators';
import { forgotPasswordRequest } from '../../../reducers/autenticacao.reducer';
import OutlinedInput from '../../../components/form/OutlinedInput';

// assets

import { Button, Grid, InputLabel, Stack } from '@mui/material';
import { actions as routeActions, types as routes } from '../../../reducers/rotas.actions';
import Reaptcha from 'reaptcha';

const AuthForgotPassword = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth['esqueci-senha'].loading);

  const rules = {
    user: validators.string({ required: true })
  };
  const initialValues = {
    user: ''
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const handleSubmit = (values) => {
    if (token) {
      dispatch(forgotPasswordRequest(values));
    }
  };

  const [token, setToken] = React.useState(false);
  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  };

  const verifyCallback = useCallback((t) => {
    setToken(t ?? null);
  }, []);

  return (
    <>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="email-login">Usuário</InputLabel>
              <OutlinedInput
                id="email-login"
                type="email"
                value=""
                name="user"
                placeholder="Digitar o usuário"
                fullWidth
                formProps={formProps}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Reaptcha
              onVerify={verifyCallback}
              onExpire={verifyCallback}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
          </Grid>
          <Grid item xs={6}>
            <AnimateButton>
              <Button disableElevation disabled={loading} fullWidth size="large" variant="contained" color="primary" onClick={handleGoBack}>
                Voltar
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={6}>
            <AnimateButton>
              <Button disableElevation disabled={loading} fullWidth size="large" type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AuthForgotPassword;
