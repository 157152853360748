import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { CheckOutlined } from '@ant-design/icons';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { stepLabelClasses } from "@mui/material";
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 18,
    fontWeight: '600',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        theme.palette.grey[500],
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor:
        theme.palette.grey[500],
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: '#4CAF50'
    //backgroundImage: 'linear-gradient(135deg, hsla(233, 78%, 59%, 1) 38%, hsla(251, 83%, 21%, 1) 100%)',
  }),
  ...(ownerState.active && {
    backgroundColor: '#1976D2'
    //backgroundImage: 'linear-gradient(135deg, hsla(109, 83%, 26%, 1) 38%, hsla(109, 90%, 12%, 1) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className} style={{fontWeight: '700'}}>
      {completed ? <CheckOutlined style={{fontSize: '24px', fontWeight: 900}}/> :
        active ? <AdjustOutlinedIcon style={{fontSize: '38px', fontWeight: 900}}/> : ''}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const CustomStepLabel = styled(StepLabel)({
  [`&.${stepLabelClasses.label}`]: {
    fontWeight: '700',
    marginTop: '2px !important',
  },
  '& .MuiStepLabel-alternativeLabel': {
    marginTop: '2px !important',
    fontWeight: '600',
  },
  '& .Mui-active': {
    fontWeight: '800 !important',
    marginTop: '2px !important'
  },
  '& .Mui-completed': {
    fontWeight: '600 !important',
    marginTop: '2px !important'
  },
  '& .Mui-disabled': {
    fontWeight: '600',
    marginTop: '2px !important'
  },
});

export default function CustomizedSteppers({items}) {
  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel connector={<ColorlibConnector />} >
        {items.map((i) => (
          <Step key={i.id} active={i.active} completed={i.completed} sx={{p:0}}>
            <CustomStepLabel
              StepIconComponent={ColorlibStepIcon}
              active={i.active}
              completed={i.completed}
            >{i.nome}</CustomStepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}