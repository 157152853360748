import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/fup.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { types as routes } from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarFups = (values) => {
  return authenticatedRequest({
    url: `/fup/list?idOportunidade=${values}`,
    method: 'get'
  });
};

const listarFups = basicFlow({
  actionGenerator: actions.listarFupsRequest,
  actionFailure: actions.listarFupsFailure,
  actionSuccess: actions.listarFupsSuccess,
  api: apiListarFups,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarFup = ({ value }) => {
  return authenticatedRequest({
    url: `/fup/create`,
    method: 'POST',
    body: value
  });
};

const criarFups = basicFlow({
  actionGenerator: actions.criarFupRequest,
  actionFailure: actions.criarFupFailure,
  actionSuccess: actions.criarFupSuccess,
  api: apiCriarFup,
  postSuccess: function* (value) {
    toast.success('Comentário incluído com sucesso.');
    const { id } = yield select(getPayload);
    yield put(actions.listarFupsRequest(id));
    yield put(actions.adicionarOportunidade(id));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarFupsRouteWatcher() {
  yield routeWatcher(routes.FUP, function* () {
    const { id } = yield select(getPayload);
    if (!id) return;
    yield put(actions.listarFupsRequest(id));
    yield put(actions.adicionarOportunidade(id));
  });
}

export const sagas = [
  listarFups.watcher(),
  criarFups.watcher(),
  listarFupsRouteWatcher()
];
