import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import MainCard from 'components/MainCard';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useCallback, useEffect } from 'react';
import DadosDaEmpresa from './DadosDaEmpresa';
import Endereco from './Endereco';
import Contatos from './Contatos';
import Representantes from './Representantes';
import * as actions from 'reducers/compradores.reducer';
import Loader from 'components/Loader';
import { removeNonDigitsFromString } from 'utils/basic';
import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import MainLayout from 'layout/MainLayout/index';

const PessoaJuridica = () => {
  const dispatch = useDispatch();

  const pessoaJuridica = useSelector(
    (state) => state.compradores.pessoaJuridica
  );
  const idOportunidade = useSelector(
    (state) => state.compradores.idOportunidade
  );
  const isEditMode = useSelector(
    (state) => state.compradores.pessoaJuridica.isEditMode
  );
  const loading = useSelector(
    (state) => state.compradores.pessoaJuridica.loading
  );

  const usuario = useSelector((state) => state.usuario);

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const initialValues = {
    // Dados da Empresa
    nomeParticipante: '',
    cpfCnpj: '',
    tipoEmpresa: null,
    dataRegistroEmpresa: '',
    idBanco: null,
    codigoAgencia: null,
    codigoContaCorrente: null,
    digitoContaCorrente: '',
    faturamentoEmpresa: '',
    patrimonioLiquidoEmpresa: '',
    capitalSocialEmpresa: '',

    // Endereço
    cep: '',
    logradouro: '',
    numeroLogradouro: '',
    complementoLogradouro: '',
    bairro: '',
    municipio: '',
    uf: null,

    // Contatos
    email: '',
    celular: ''
  };

  const rules = {
    // Dados da Empresa
    nomeParticipante: validators.string({ required: true }),
    cpfCnpj: validators.cnpj({ required: true }),
    tipoEmpresa: validators.string({ required: true }),
    dataRegistroEmpresa: validators.date({ required: true }),
    idBanco: validators.string({ required: false }),
    codigoAgencia: validators.number({ required: false, max: 9999 }),
    codigoContaCorrente: validators.number({
      required: false,
      max: 999999999999999
    }),
    digitoContaCorrente: validators.string({ required: false }),
    faturamentoEmpresa: validators.number({ required: true, min: 0 }),
    patrimonioLiquidoEmpresa: validators.number({ required: false, min: 0 }),
    capitalSocialEmpresa: validators.number({ required: false, min: 0 }),

    // Endereço
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),

    // Contatos
    email: validators.email({ required: false }),
    celular: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
    defaultValues: isEditMode ? pessoaJuridica.data : initialValues
  };

  useEffect(() => {
    if (isEditMode) {
      formProps.reset(pessoaJuridica.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, pessoaJuridica.data]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      idOportunidade: values.idOportunidade || idOportunidade,
      tipoSituacao: 'A',
      tipoQualificacao: 'CO',
      tipoPessoa: 'J',
      idUsuarioCriacao: usuario.id,
      idUsuarioAlteracao: usuario.id
    };

    isEditMode
      ? dispatch(
          actions.editarCompradorPessoaJuridicaRequest({ value: payload })
        )
      : dispatch(
          actions.criarCompradorPessoaJuridicaRequest({ value: payload })
        );
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', { shouldValidate: true });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });
      setValue('uf', ufs.find((uf) => uf?.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', { shouldValidate: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, {
        id: idOportunidade
      })
    );
  };

  if (!loading && isEditMode && !pessoaJuridica?.data?.idParticipante) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Comprador não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
            <MainCard
              contentSX={{
                paddingLeft: 1,
                paddingRight: 1
              }}
              border={false}
              title={
                !isEditMode ? (
                  'Novo Comprador'
                ) : (
                  <Box>
                    <Typography variant="h2">
                      {pessoaJuridica.data.nomeParticipante}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Comprador
                    </Typography>
                  </Box>
                )
              }
            >
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS DA EMPRESA" style={{ flex: 1 }}>
                    <DadosDaEmpresa formProps={formProps} />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                    <Endereco
                      formProps={formProps}
                      handleZipCodeBlurred={handleZipCodeBlurred}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="CONTATO" style={{ flex: 1 }}>
                    <Contatos formProps={formProps} />
                  </FormCard>
                </Grid>
                {isEditMode ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sm={12}
                    style={{ display: 'flex' }}
                  >
                    <FormCard title="REPRESENTANTES" style={{ flex: 1 }}>
                      <Representantes formProps={formProps} />
                    </FormCard>
                  </Grid>
                ) : null}
              </Grid>
            </MainCard>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    SALVAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default PessoaJuridica;
