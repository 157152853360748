import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  atividadesBanco: {
    loading: false,
    error: false,
    data: []
  },
  atividadeBanco: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  bancos: {
    loading: false,
    error: false,
    data: null
  },
  idOperacao: null,
  idEtapa: null,
  idAtividade: null
};

const AtividadesBancoSlice = createSlice({
  name: 'produtosAtividadesBanco',
  initialState,
  reducers: {
    listarAtividadesBancoRequest: (state) => {
      state.atividadesBanco.loading = true;
      state.atividadesBanco.error = null;
      state.atividadesBanco.data = [];
    },
    listarAtividadesBancoSuccess: (state, action) => {
      state.atividadesBanco.loading = false;
      state.atividadesBanco.error = false;
      state.atividadesBanco.data = action.payload.response.data;
    },
    listarAtividadesBancoFailure: (state, action) => {
      state.atividadesBanco.loading = false;
      state.atividadesBanco.error = action.payload;
    },
    criarAtividadeBancoRequest: (state, action) => {
      state.atividadeBanco.loading = true;
      state.atividadeBanco.loading = true;
      state.atividadeBanco.error = null;
    },
    criarAtividadeBancoSuccess: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.loading = false;
      state.atividadeBanco.error = false;
    },
    criarAtividadeBancoFailure: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.loading = false;
      state.atividadeBanco.error = action.payload;
    },
    editarAtividadeBancoRequest: (state, action) => {
      state.atividadeBanco.loading = true;
      state.atividadeBanco.loading = true;
      state.atividadeBanco.error = null;
    },
    editarAtividadeBancoSuccess: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.loading = false;
      state.atividadeBanco.error = false;
    },
    editarAtividadeBancoFailure: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.loading = false;
      state.atividadeBanco.error = action.payload;
    },
    mostrarAtividadeBancoRequest: (state, action) => {
      state.atividadeBanco.loading = true;
      state.atividadeBanco.data = null;
      state.atividadeBanco.error = null;
    },
    mostrarAtividadeBancoSuccess: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.data = action.payload.response.data;
      state.atividadeBanco.error = false;
    },
    mostrarAtividadeBancoFailure: (state, action) => {
      state.atividadeBanco.loading = false;
      state.atividadeBanco.loading = false;
      state.atividadeBanco.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.atividadeBanco.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.atividadeBanco.isEditMode = true;
    },
    limparAtividadeBanco: (state) => {
      state.atividadeBanco.data = null;
    },
    excluirAtividadeBancoRequest: (state) => {
      state.atividadesBanco.loading = true;
      state.atividadesBanco.error = null;
    },
    excluirAtividadeBancoSuccess: (state) => {
      state.atividadesBanco.loading = false;
      state.atividadesBanco.error = false;
    },
    excluirAtividadeBancoFailure: (state, action) => {
      state.atividadesBanco.loading = false;
      state.atividadesBanco.error = action.payload;
    },
    adicionarIdOperacao: (state, action) => {
      state.idOperacao = Number(action.payload);
    },
    adicionarIdEtapa: (state, action) => {
      state.idEtapa = Number(action.payload);
    },
    adicionarIdAtividade: (state, action) => {
      state.idAtividade = Number(action.payload);
    },
    listarBancosRequest: (state) => {
      state.bancos.loading = true;
      state.bancos.error = null;
      state.bancos.data = [];
    },
    listarBancosSuccess: (state, action) => {
      state.bancos.loading = false;
      state.bancos.error = false;
      state.bancos.data = action.payload.response.data;
    },
    listarBancosFailure: (state, action) => {
      state.bancos.loading = false;
      state.bancos.error = action.payload;
    }
  }
});

export const {
  listarAtividadesBancoRequest,
  listarAtividadesBancoSuccess,
  listarAtividadesBancoFailure,
  adicionarOportunidade,
  criarAtividadeBancoRequest,
  criarAtividadeBancoSuccess,
  criarAtividadeBancoFailure,
  mostrarAtividadeBancoRequest,
  mostrarAtividadeBancoSuccess,
  mostrarAtividadeBancoFailure,
  editarAtividadeBancoRequest,
  editarAtividadeBancoSuccess,
  editarAtividadeBancoFailure,
  desativarModoEdicao,
  ativarModoEdicao,
  limparAtividadeBanco,
  excluirAtividadeBancoRequest,
  excluirAtividadeBancoSuccess,
  excluirAtividadeBancoFailure,
  adicionarIdOperacao,
  adicionarIdEtapa,
  adicionarIdAtividade,
  listarBancosRequest,
  listarBancosSuccess,
  listarBancosFailure
} = AtividadesBancoSlice.actions;

export default AtividadesBancoSlice.reducer;
