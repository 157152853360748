import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Chip,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Badge
} from '@mui/material';

// project import
import { activeItem } from 'reducers/menu';
import { actions as routeActions } from '../../../../../reducers/rotas.actions';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

const NavItem = ({ item, level }) => {
  const perfil = useSelector((state) => state.usuario.perfil);

  const possuiNotificacoes = useSelector(
    (state) => state.usuario.possuiNotificacao
  );

  const isMinhasAtividades = item.title === 'Minhas Tarefas';

  const theme = useTheme();
  const dispatch = useDispatch();

  const { drawerOpen, openItem } = useSelector((state) => state.menu);

  let itemTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps = {
    component: forwardRef((props, ref) => (
      <Link ref={ref} {...props} to={item.url} target={itemTarget} />
    ))
  };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }

  const itemHandler = (id) => {
    if (item.urlToOpen) {
      return window.open(item.urlToOpen, '_blank', 'noopener,noreferrer');
    }

    dispatch(activeItem({ openItem: [id] }));
    dispatch(routeActions.redirectTo(item.action, { ...item.params }));
  };

  const Icon = item.icon;
  const itemIcon = item.icon ? (
    <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} />
  ) : (
    false
  );

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  const textColor = 'text.primary';
  const iconSelectedColor = 'primary.main';

  if (item.restricted && !item.restricted.includes(perfil)) {
    return null;
  }

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={() => itemHandler(item.id)}
      selected={isSelected}
      sx={{
        zIndex: 1201,
        borderLeft: `3px solid transparent`,
        pl: drawerOpen ? `${level * 28}px` : 1.5,
        py: !drawerOpen && level === 1 ? 1.25 : 1,
        ...(drawerOpen && {
          '&:hover': {
            bgcolor: '#fff'
          },
          '&.Mui-selected': {
            bgcolor: '#fff',
            borderLeft: `3px solid ${theme.palette.primary.main}`,
            color: '#475BE8',
            '&:hover': {
              color: '#475BE8',
              bgcolor: 'primary.lighter'
            }
          },
          '&.MuiButtonBase-root': {
            color: '#475BE8',
            '&.MuiListItemText-root': {
              '&.MuiTypography-root': {
                color: '#475BE8'
              }
            }
          }
        }),
        ...(!drawerOpen && {
          '&:hover': {
            bgcolor: 'transparent'
          },
          '&.Mui-selected': {
            '&:hover': {
              bgcolor: 'transparent'
            },
            bgcolor: 'transparent'
          }
        })
      }}
    >
      {itemIcon && (
        <ListItemIcon
          sx={{
            minWidth: 28,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              width: 36,
              height: 36,
              alignItems: 'center',
              justifyContent: 'center',
              '&:hover': {
                bgcolor: '#fff'
              }
            }),
            ...(!drawerOpen &&
              isSelected && {
                bgcolor: '#fff',
                '&:hover': {
                  bgcolor: '#fff'
                }
              })
          }}
        >
          {itemIcon}
        </ListItemIcon>
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && (
        <ListItemText
          primary={
            <Typography
              variant="h6"
              sx={{ color: isSelected ? iconSelectedColor : textColor }}
            >
              {item.title}
              {isMinhasAtividades && possuiNotificacoes ? (
                <Badge
                  color="error"
                  variant="dot"
                  overlap="circular"
                  sx={{
                    marginLeft: '8px',
                    marginBottom: '8px'
                  }}
                ></Badge>
              ) : null}
            </Typography>
          }
        />
      )}
      {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
        <Chip
          color={item.chip.color}
          variant={item.chip.variant}
          size={item.chip.size}
          label={item.chip.label}
          avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
        />
      )}
    </ListItemButton>
  );
};

NavItem.propTypes = {
  item: PropTypes.object,
  level: PropTypes.number
};

export default NavItem;
