import { format as formatCpf } from '@fnando/cpf';
import { format as formatCnpj } from '@fnando/cnpj';
import { padHour, removeNonDigitsFromString } from './basic';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// numbers
const simpleNumber = (number, options) => {
  if (number !== undefined && !isNaN(number)) {
    return Number(number).toLocaleString('pt-BR', options);
  }
  return '';
};
const nFixed = (number, fixed) => {
  return simpleNumber(number, {
    minimumFractionDigits: fixed,
    maximumFractionDigits: fixed
  });
};
const currency = (number) => {
  if (number !== undefined && !isNaN(number)) return nFixed(number, 2);
  else return number;
};

const simpleCurrency = (value) => {
  let formattedValue = '';

  if (value >= 1_000_000) {
    formattedValue = (value / 1_000_000).toFixed(1) + 'M';
  } else if (value >= 1_000) {
    formattedValue = (value / 1_000).toFixed(1) + 'K';
  } else {
    formattedValue = value.toFixed(2);
  }

  if (formattedValue.endsWith('.0K') || formattedValue.endsWith('.0M')) {
    formattedValue = formattedValue.replace('.0K', 'K');
    formattedValue = formattedValue.replace('.0M', 'M');
  }

  return `R$ ${formattedValue}`;
};

const percent = (number) => {
  return nFixed(number, 4);
};
const formatPhone = (value, isMobile) => {
  const stripped = removeNonDigitsFromString(value);

  let finalValue = '(' + stripped.slice(0, 2);
  if (stripped.length > 2) {
    finalValue += ') ' + stripped.slice(2, isMobile ? 7 : 6);
  }
  if (stripped.length > (isMobile ? 7 : 6)) {
    finalValue += '-' + stripped.slice(isMobile ? 7 : 6, isMobile ? 11 : 10);
  }
  return finalValue;
};

const formatPhoneV2 = (value) => {
  const stripped = removeNonDigitsFromString(value);
  const isMobile = stripped.length > 10;

  let finalValue = '(' + stripped.slice(0, 2);
  if (stripped.length > 2) {
    finalValue += ') ' + stripped.slice(2, isMobile ? 7 : 6);
  }
  if (stripped.length > (isMobile ? 7 : 6)) {
    finalValue += '-' + stripped.slice(isMobile ? 7 : 6, isMobile ? 11 : 10);
  }
  return finalValue;
};

// dates
const simpleDate = (date, options) => {
  return date
    .toLocaleDateString('pt-BR', options)
    .replace('erça', 'erca')
    .replace('ábado', 'abado')
    .replace('arço', 'arco')
    .replace(/[^ -~/]/g, '')
    .replace('abado', 'ábado')
    .replace('erca', 'erça')
    .replace('arco', 'arço');
};
const simpleHours = (date, options) => {
  return date.toLocaleTimeString('pt-BR', options);
};
const iso = (date) => {
  return date.toISOString().slice(0, 10);
};
const short = (date) => {
  return simpleDate(date);
};
const shortNoBarsNoHour = (date) => {
  let mes = simpleDate(date).replace('/', '').replace('/', '').slice(2, 4);
  let ano = simpleDate(date).replace('/', '').replace('/', '').slice(4, 8);
  return mes + '-' + ano;
};

const long = (date) => {
  return simpleDate(date, {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric'
  }).replace(/ de /g, '/');
};

const longFirstLetterCapitalized = (date) => {
  const formattedDate = format(
    new Date(date),
    "EEEE, d 'de' MMMM 'de' yyyy 'às' HH:mm",
    { locale: ptBR }
  );
  return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
};

const longMonth = (date) => {
  const d = simpleDate(date, { year: 'numeric', month: 'long' });
  return d.charAt(0).toUpperCase() + d.slice(1);
};
const justMonth = (date, type) => {
  return simpleDate(date, { month: type });
};
const withHours = (date) => {
  if (!date) return '';
  return simpleDate(date, {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  });
};
const withHoursNoSpace = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  return `${padHour(newDate.getDate())}${padHour(
    newDate.getMonth() + 1
  )}${newDate.getFullYear()}${padHour(newDate.getHours())}${padHour(
    newDate.getMinutes()
  )}${padHour(newDate.getSeconds())}`;
};
const onlyHours = (date) => {
  if (!date) return '';
  return simpleHours(date);
};
const revert = (date) => {
  if (!date) return '';
  if (date.indexOf('-') >= 0) {
    const [ano, mes, dia] = date.split('-');
    return `${dia}/${mes}/${ano}`;
  } else {
    const [dia, mes, ano] = date.split('/');
    return `${ano}-${mes}-${dia}`;
  }
};
const stringDateToDate = (date) => {
  if (date) {
    const formattedDate = date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$2/$1/$3');
    const parsedDate = new Date(formattedDate);
    if (isNaN(parsedDate.getTime())) {
      return null;
    }
    return parsedDate.toISOString();
  }
  return null;
};
// strings
const capitalizeFirstLetter = (text) => {
  return text.slice(0, 1).toUpperCase() + text.slice(1);
};
const zipCode = (text) => {
  const full = ('00000000000000' + text).slice(-8);
  return full.slice(0, 5) + '-' + full.slice(5);
};
const cpf = (text) => {
  return formatCpf(('00000000000000' + text).slice(-11));
};
const cnpj = (text) => {
  return formatCnpj(('00000000000000' + text).slice(-14));
};
const cpfCnpj = (text, tipo) => {
  if (tipo === 'F') {
    return cpf(text);
  }
  if (tipo === 'J') {
    return cnpj(text);
  }
  return '';
};
const formatters = {
  numbers: {
    simple: simpleNumber,
    currency,
    percent,
    nFixed,
    formatPhone,
    formatPhoneV2,
    simpleCurrency
  },
  dates: {
    simple: simpleDate,
    short,
    long,
    justMonth,
    iso,
    longMonth,
    withHours,
    withHoursNoSpace,
    onlyHours,
    revert,
    shortNoBarsNoHour,
    stringDateToDate,
    longFirstLetterCapitalized
  },
  strings: {
    capitalizeFirstLetter,
    cpf,
    cnpj,
    cpfCnpj,
    zipCode
  }
};

export default formatters;
