import {
  DataGridPremium as DG,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-premium';
import { ptBR } from '@mui/x-data-grid/locales';
import { Box } from '@mui/material';
import React, { useState } from 'react';

export const DataGrid = ({
  rows,
  columns,
  pageSize = 10,
  toolbar,
  loading,
  density,
  handleRowClick,
  isOportunidade = false,
  noLineText = 'Nenhuma linha',
  infiniteScroll = false,
  autoHeight = true,
  initialState,
  ...otherProps
}) => {
  const [selectionModel, setSelectionModel] = useState([]);

  const CustomToolbar = () => {
    if (!toolbar) {
      return (
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{
              includeHeaders: true,
              allColumns: true,
              utf8WithBom: true
            }}
          />
        </GridToolbarContainer>
      );
    } else {
      return toolbar({ apiRef });
    }
  };

  const apiRef = React.useRef(null);

  return (
    <Box
      sx={
        infiniteScroll && rows.length > 8
          ? { height: 600, width: '100%' }
          : { width: '100%' }
      }
    >
      <DG
        rows={rows}
        columns={columns}
        pagination
        autoHeight={rows.length === 0 ? true : autoHeight}
        initialState={
          initialState ?? {
            pagination: {
              paginationModel: {
                pageSize: pageSize
              }
            },

            density: density
          }
        }
        pageSizeOptions={[pageSize]}
        apiRef={apiRef}
        checkboxSelection={false}
        selectionModel={selectionModel}
        onSelectionModelChange={(newSelection) => {
          if (newSelection.length > 0) {
            setSelectionModel([newSelection[newSelection.length - 1]]);
          } else {
            setSelectionModel([]);
          }
        }}
        loading={loading}
        sx={{
          '& .MuiDataGrid-aggregationColumnHeaderLabel': {
            display: 'none'
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#f0f0f0',
            position: 'sticky',
            top: 0,
            zIndex: 1000
          },
          '& .MuiDataGrid-overlay': {
            marginTop: '20px'
          },
          '& .MuiDataGrid-columnHeader': {
            backgroundColor: '#f0f0f0',
            position: 'sticky',
            top: 0,
            zIndex: 1000
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#000',
            fontWeight: 700
          },
          '& .MuiDataGrid-toolbarContainer': {
            color: '#000'
          },
          '& .MuiButton-root': {
            color: '#303030'
          },
          '& .MuiDataGrid-columnSeparator': {
            visibility: 'visible',
            color: '#DADFFB'
          },
          '& .MuiDataGrid-row': {
            borderBottom: '1px solid #e0e0e0',
            fontSize: '0.8rem',
            cursor: 'pointer'
          }
        }}
        localeText={{
          ...ptBR.components.MuiDataGrid.defaultProps.localeText,
          noRowsLabel: noLineText
        }}
        slots={{
          toolbar: () => <CustomToolbar apiRef={apiRef} />
        }}
        slotProps={{
          loadingOverlay: {
            variant: 'skeleton',
            noRowsVariant: 'skeleton'
          }
        }}
        {...otherProps}
      />
    </Box>
  );
};

export default DataGrid;
