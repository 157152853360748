import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/bancos.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarBancos = () => {
  return authenticatedRequest({
    url: `/banco/list`,
    method: 'get'
  });
};

const listarBancos = basicFlow({
  actionGenerator: actions.listarBancosRequest,
  actionFailure: actions.listarBancosFailure,
  actionSuccess: actions.listarBancosSuccess,
  api: apiListarBancos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/banco/create`,
    method: 'POST',
    body: value
  });
};

const criarBancos = basicFlow({
  actionGenerator: actions.criarBancoRequest,
  actionFailure: actions.criarBancoFailure,
  actionSuccess: actions.criarBancoSuccess,
  api: apiCriarBanco,
  postSuccess: function* ({ response }) {
    toast.success('Banco incluído com sucesso.');
    yield put(
      routeActions.redirectTo(routes.BANCO, { idBanco: response.data.idBanco })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/banco/update/${value.idBanco}`,
    method: 'PUT',
    body: value
  });
};

const editarBancos = basicFlow({
  actionGenerator: actions.editarBancoRequest,
  actionFailure: actions.editarBancoFailure,
  actionSuccess: actions.editarBancoSuccess,
  api: apiEditarBanco,
  postSuccess: function* (value) {
    yield toast.success('Banco editado com sucesso.');
    const { idBanco } = yield select(getPayload);
    yield put(actions.mostrarBancoRequest(idBanco));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarBanco = (id) => {
  return authenticatedRequest({
    url: `/banco/show/${id}`,
    method: 'get'
  });
};

const mostrarBanco = basicFlow({
  actionGenerator: actions.mostrarBancoRequest,
  actionFailure: actions.mostrarBancoFailure,
  actionSuccess: actions.mostrarBancoSuccess,
  api: apiMostrarBanco,
  postFailure: function* ({ error }) {
    yield toast.error('Banco não encontrado.');
  }
});

const apiExcluirBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/banco/delete/${value.idBanco}`,
    method: 'delete'
  });
};

const excluirBanco = basicFlow({
  actionGenerator: actions.excluirBancoRequest,
  actionFailure: actions.excluirBancoFailure,
  actionSuccess: actions.excluirBancoSuccess,
  api: apiExcluirBanco,
  postSuccess: function* () {
    yield toast.success('Banco excluído com sucesso.');
    yield put(actions.listarBancosRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarBancoRouteWatcher() {
  yield routeWatcher(routes.BANCO, function* () {
    const { idBanco } = yield select(getPayload);
    if (idBanco === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparBanco());
      return;
    }
    if (!idBanco) return;
    yield put(actions.mostrarBancoRequest(idBanco));
    yield put(actions.ativarModoEdicao());
  });
}

function* listarBancosRouteWatcher() {
  yield routeWatcher(routes.BANCOS, function* () {
    yield put(actions.listarBancosRequest());
  });
}

export const sagas = [
  listarBancos.watcher(),
  criarBancos.watcher(),
  editarBancos.watcher(),
  mostrarBanco.watcher(),
  excluirBanco.watcher(),
  listarBancosRouteWatcher(),
  mostrarBancoRouteWatcher()
];
