import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/produtosEtapas.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { mostrarProdutoRequest } from 'reducers/produtosOperacoes.reducer';

const apiListarEtapas = (idOperacao) => {
  return authenticatedRequest({
    url: `/produto-etapa/list?idOperacao=${idOperacao}`,
    method: 'get'
  });
};

const listarEtapas = basicFlow({
  actionGenerator: actions.listarEtapasRequest,
  actionFailure: actions.listarEtapasFailure,
  actionSuccess: actions.listarEtapasSuccess,
  api: apiListarEtapas,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarEtapa = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-etapa/create`,
    method: 'POST',
    body: value
  });
};

const criarEtapas = basicFlow({
  actionGenerator: actions.criarEtapaRequest,
  actionFailure: actions.criarEtapaFailure,
  actionSuccess: actions.criarEtapaSuccess,
  api: apiCriarEtapa,
  postSuccess: function* ({ response }) {
    toast.success('Etapa incluída com sucesso.');
    yield put(
      routeActions.redirectTo(routes.PRODUTO_ETAPA, {
        idOperacao: response.data.idOperacao,
        idEtapa: response.data.idEtapa
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarEtapa = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-etapa/update/${value.idEtapa}`,
    method: 'PUT',
    body: value
  });
};

const editarEtapas = basicFlow({
  actionGenerator: actions.editarEtapaRequest,
  actionFailure: actions.editarEtapaFailure,
  actionSuccess: actions.editarEtapaSuccess,
  api: apiEditarEtapa,
  postSuccess: function* (value) {
    yield toast.success('Etapa editada com sucesso.');
    const { idEtapa } = yield select(getPayload);
    yield put(actions.mostrarEtapaRequest(idEtapa));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarEtapa = (id) => {
  return authenticatedRequest({
    url: `/produto-etapa/show/${id}`,
    method: 'get'
  });
};

const mostrarEtapa = basicFlow({
  actionGenerator: actions.mostrarEtapaRequest,
  actionFailure: actions.mostrarEtapaFailure,
  actionSuccess: actions.mostrarEtapaSuccess,
  api: apiMostrarEtapa,
  postFailure: function* ({ error }) {
    yield toast.error('Etapa não encontrada.');
  }
});

const apiExcluirEtapa = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-etapa/delete/${value.idEtapa}`,
    method: 'delete'
  });
};

const excluirEtapa = basicFlow({
  actionGenerator: actions.excluirEtapaRequest,
  actionFailure: actions.excluirEtapaFailure,
  actionSuccess: actions.excluirEtapaSuccess,
  api: apiExcluirEtapa,
  postSuccess: function* () {
    yield toast.success('Etapa excluída com sucesso.');
    const { idOperacao } = yield select(getPayload);
    yield put(actions.listarEtapasRequest(idOperacao));
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarEtapaRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ETAPA, function* () {
    const { idEtapa, idOperacao } = yield select(getPayload);
    if (idEtapa === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparEtapa());
      yield put(actions.adicionarIdOperacao(idOperacao));
      mostrarProdutoRequest(idOperacao);
      return;
    }
    if (!idEtapa) return;
    yield put(actions.mostrarEtapaRequest(idEtapa));
    yield put(actions.ativarModoEdicao());
    yield put(actions.adicionarIdOperacao(idOperacao));
    mostrarProdutoRequest(idOperacao);
  });
}

function* listarEtapasRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ETAPAS, function* () {
    const { idOperacao } = yield select(getPayload);
    yield put(actions.listarEtapasRequest(idOperacao));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(mostrarProdutoRequest(idOperacao));
  });
}

export const sagas = [
  listarEtapas.watcher(),
  criarEtapas.watcher(),
  editarEtapas.watcher(),
  mostrarEtapa.watcher(),
  excluirEtapa.watcher(),
  listarEtapasRouteWatcher(),
  mostrarEtapaRouteWatcher()
];
