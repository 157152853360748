import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Skeleton, Typography } from '@mui/material';
import ThermostatOutlinedIcon from '@mui/icons-material/ThermostatOutlined';
import formatters from '../../utils/formatters';
import Stepper from '../../components/Stepper';
import Navbar from './NavOportunidade';
import EditDateModal from './DataEsperadaContratacaoModal';
import EditTemperaturaModal from './TemperaturaModal';
import EditStatusModal from './StatusModal';

export default function HeaderOportunidade({ menuSelecionado }) {
  const bancos = useSelector((state) => state.dominios.bancos);
  const operacoes = useSelector((state) => state.dominios.operacao);
  const { loading, data } = useSelector((state) => state.oportunidade);
  const tipoSituacoes = useSelector(
    (state) => state.dominios.tipoSituacaoOportunidade
  );

  const { data: atividades, loading: loadingAtividades } = useSelector(
    (state) => state.atividades.etapas
  );

  let atividadeString = 'Detalhe Status: ';

  atividades.forEach((el) => {
    if (el.detalhes.tipoSituacao === 'E')
      atividadeString = atividadeString + el.atividade + ' / ';
  });

  atividadeString.endsWith(' / ') &&
    (atividadeString = atividadeString.slice(0, -2));

  const calculateDaysDifference = (startDate) => {
    const start = moment(startDate);
    const current = moment();

    return current.diff(start, 'days');
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openTemperatura, setOpenTemperatura] = useState(false);
  const handleOpenTemperatura = () => setOpenTemperatura(true);
  const handleCloseTemperatura = () => setOpenTemperatura(false);

  const [openStatus, setOpenStatus] = useState(false);
  const handleOpenStatus = () => setOpenStatus(true);
  const handleCloseStatus = () => setOpenStatus(false);

  if (loading) {
    return (
      <Box sx={{ p: 3 }}>
        <Skeleton
          key={1}
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ flex: 1, margin: '0 4px' }}
        />
      </Box>
    );
  } else {
    const daysActive = calculateDaysDifference(
      data.oportunidade.dataOportunidade
    );

    return (
      <div>
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: 2.6,
            paddingRight: 2.6
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              gap: 2
            }}
          >
            <Typography variant="h2" sx={{ fontSize: '1.4rem' }}>
              Oportunidade {data.oportunidade.codigoOportunidade}
            </Typography>
            <ThermostatOutlinedIcon
              sx={{
                fontSize: '2rem',
                cursor: 'pointer',
                color:
                  data.oportunidade.tipoTemperatura === 'Q'
                    ? '#4CAF50'
                    : data.oportunidade.tipoTemperatura === 'M'
                      ? '#fb8500'
                      : '#3a86ff'
              }}
              onClick={handleOpenTemperatura}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              gap: 4
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {data.oportunidade?.bancoEscolhido?.nomeBanco ?? '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Banco Escolhido</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {formatters.dates.short(
                    new Date(
                      `${data.oportunidade.dataOportunidade.substring(0, 10)}T00:00:00`
                    )
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Inclusão</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: data.oportunidade.idBancoSelecionado ? 'flex' : 'none',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {bancos.length > 0
                    ? bancos.find(
                        (i) =>
                          i.idBanco === data.oportunidade.idBancoSelecionado
                      )?.nomeBanco
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Banco Selecionado</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Box>
                <Typography variant="subtitle1">
                  {formatters.numbers.currency(
                    data.oportunidade.valorTotalFinanciamento ?? data.oportunidade.valorFinanciamentoSimulacao
                  )}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">R$ Financiado</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                cursor: 'pointer'
              }}
              onClick={handleOpen}
            >
              <Box>
                <Typography variant="subtitle1">
                  {data.oportunidade.dataPrevistaEmissaoContrato
                    ? formatters.dates.short(
                        new Date(
                          `${data.oportunidade.dataPrevistaEmissaoContrato.substring(0, 10)}T00:00:00`
                        )
                      )
                    : '-'}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Emissão Prevista</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                cursor: 'pointer'
              }}
              onClick={handleOpenStatus}
            >
              <Box>
                <Typography variant="subtitle1">
                  {data.oportunidade &&
                    tipoSituacoes.find(
                      (i) => i.codigo === data.oportunidade.tipoSituacao
                    )?.nome}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption">Situação</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: '1px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            paddingLeft: 2.8
          }}
        >
          <Typography
            variant="subtitle1"
            color="text.other"
            sx={{ textTransform: 'capitalize' }}
          >
            {operacoes.data.length > 0 &&
              operacoes.data
                .find((i) => i.idOperacao === data.oportunidade.idOperacao)
                ?.nomeOperacao.toLowerCase()}
          </Typography>
          <Typography variant="subtitle1" color="text.other">
            &nbsp;- Ativa há {daysActive + 1} dias
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Box
            sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}
          >
            <Stepper
              items={data.etapa.map((i) => {
                return {
                  id: i.idEtapa,
                  nome: i.nomeEtapa,
                  active: i.active,
                  completed: i.completed
                };
              })}
            />
          </Box>
        </Box>

        {loadingAtividades ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={30}
            sx={{ marginLeft: 2.8, marginTop: 1.4, marginBottom: -1.4 }}
          />
        ) : atividadeString === 'Detalhe Status: ' ? null : (
          <Box
            sx={{
              marginLeft: 2.8,
              marginTop: 1.4,
              marginBottom: -1.4
            }}
          >
            <Typography variant="subtitle1" color="error">
              {atividadeString}
            </Typography>
          </Box>
        )}

        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Navbar
            idOportunidade={data.oportunidade.idOportunidade}
            menuSelecionado={menuSelecionado}
          />
        </Box>
        <EditDateModal
          idOportunidade={data.oportunidade.idOportunidade}
          open={open}
          handleClose={handleClose}
          date={new Date(data.oportunidade.dataPrevistaEmissaoContrato)}
        />
        <EditTemperaturaModal
          idOportunidade={data.oportunidade.idOportunidade}
          open={openTemperatura}
          handleClose={handleCloseTemperatura}
          tipoTemperatura={data.oportunidade.tipoTemperatura}
        />
        <EditStatusModal
          idOportunidade={data.oportunidade.idOportunidade}
          open={openStatus}
          handleClose={handleCloseStatus}
          tipoSituacao={data.oportunidade.tipoSituacao}
        />
      </div>
    );
  }
}
