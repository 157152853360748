import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import MainCard from 'components/MainCard';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import DadosPessoais from './DadosPessoais';
import Endereco from './Endereco';
import Contatos from './Contatos';
import * as actions from 'reducers/compradores.reducer';
import Loader from 'components/Loader';
import { removeNonDigitsFromString } from 'utils/basic';
import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import MainLayout from 'layout/MainLayout/index';
import {
  Box,
  Typography
} from '../../../../../node_modules/@mui/material/index';

const Representante = () => {
  const dispatch = useDispatch();

  const idParticipante = useSelector(
    (state) => state.compradores.idParticipante
  );
  const idOportunidade = useSelector(
    (state) => state.compradores.idOportunidade
  );

  const isEditMode = useSelector(
    (state) => state.compradores.pessoaJuridica.representante.isEditMode
  );

  const representante = useSelector(
    (state) => state.compradores.pessoaJuridica.representante.data
  );

  const loading = useSelector(
    (state) => state.compradores.pessoaJuridica.representante.loading
  );

  const usuario = useSelector((state) => state.usuario);

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const initialValues = {
    nomeParticipante: '',
    cpfCnpj: '',
    dataNascimento: '',
    tipoSexo: null,
    tipoEstadoCivil: null,
    tipoRegimeCasamento: null,
    tipoDocumentoIdentidade: null,
    numeroDocumento: '',
    dataExpedicao: '',
    orgaoExpedidor: '',
    ufExpedicao: null,
    nomeProfissao: '',

    // Endereço
    cep: '',
    logradouro: '',
    numeroLogradouro: '',
    complementoLogradouro: '',
    bairro: '',
    municipio: '',
    uf: null
  };

  const rules = {
    // Dados Pessoais
    nomeRepresentanteLegal: validators.string({ required: true }),
    cpf: validators.cpf({ required: true }),
    dataNascimento: validators.date({ required: false }),
    tipoSexo: validators.string({ required: false }),
    tipoEstadoCivil: validators.string({ required: false }),
    tipoDocumentoIdentidade: validators.string({ required: false }),
    numeroDocumento: validators.string({ required: false }),
    dataExpedicao: validators.date({ required: false }),
    orgaoExpedidor: validators.string({ required: false }),
    ufExpedicao: validators.string({ required: false }),
    nomeProfissao: validators.string({ required: false }),

    // Endereço
    cep: validators.string({ required: false }),
    logradouro: validators.string({ required: false }),
    numeroLogradouro: validators.string({ required: false }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: false }),
    municipio: validators.string({ required: false }),
    uf: validators.string({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
    defaultValues: isEditMode ? representante.data : initialValues
  };

  useEffect(() => {
    if (isEditMode) {
      formProps.reset(representante);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, representante]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      idParticipante: idParticipante,
      tipoSituacao: 'A',
      idUsuarioCriacao: usuario.id,
      idUsuarioAlteracao: usuario.id,
      idOportunidade: idOportunidade
    };

    isEditMode
      ? dispatch(actions.editarRepresentanteRequest({ value: payload }))
      : dispatch(actions.criarRepresentanteRequest({ value: payload }));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.PESSOA_JURIDICA, {
        idPessoaJuridica: idParticipante,
        id: idOportunidade
      })
    );
  };

  if (!loading && isEditMode && !representante?.idRepresentante) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Representante não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
            <MainCard
              contentSX={{
                paddingLeft: 1,
                paddingRight: 1
              }}
              border={false}
              title={
                !isEditMode ? (
                  'Novo Representante'
                ) : (
                  <Box>
                    <Typography variant="h2">
                      {representante.nomeRepresentanteLegal}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Representante
                    </Typography>
                  </Box>
                )
              }
            >
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS PESSOAIS" style={{ flex: 1 }}>
                    <DadosPessoais formProps={formProps} />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                    <Endereco
                      formProps={formProps}
                      handleZipCodeBlurred={handleZipCodeBlurred}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="CONTATO" style={{ flex: 1 }}>
                    <Contatos formProps={formProps} />
                  </FormCard>
                </Grid>
              </Grid>
            </MainCard>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>

              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    SALVAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default Representante;
