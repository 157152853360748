import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from '../../../../components/form/OutlinedInput';
import PhoneTextField from '../../../../components/form/PhoneTextField';

const Contatos = ({ formProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="email">E-mail</InputLabel>
          <OutlinedInput
            id="email"
            name="email"
            placeholder="Digite o e-mail"
            fullWidth
            required
            formProps={formProps}
            type="email"
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="celular">Celular</InputLabel>
          <OutlinedInput
            id="celular"
            name="celular"
            placeholder="Digite o número do celular"
            fullWidth
            required
            formProps={formProps}
            maxLength={15}
            InputProps={{
              inputComponent: PhoneTextField,
              inputProps: { isMobile: true }
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Contatos;
