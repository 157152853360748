import basicFlow, { genericErrorHandler } from './asyncHandler';
import { put, select } from 'redux-saga/effects';
import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';
import {
  bancosRequest,
  operacaoRequest,
  parceiroRequest,
  regionalRequest,
  usuariosParceirosRequest,
  usuariosRequest
} from '../reducers/dominios.reducer';
import {
  simulacaoSuccess,
  simulacaoRequest,
  simulacaoFailure,
  getSimulacaoFailure,
  getSimulacaoSuccess,
  getSimulacaoRequest,
  atualizarBancoSelecionadoFailure,
  atualizarBancoSelecionadoSuccess,
  atualizarBancoSelecionadoRequest,
  atualizarSimulacaoRequest,
  atualizarSimulacaoSuccess,
  atualizarSimulacaoFailure,
  listarSimulacaoFailure,
  listarSimulacaoSuccess,
  listarSimulacaoRequest,
  incluirSimulacaoRequest,
  incluirSimulacaoFailure,
  incluirSimulacaoSuccess,
  incluirPropostaIntegracaoRequest,
  incluirPropostaIntegracaoFailure,
  incluirPropostaIntegracaoSuccess
} from '../reducers/simulacao.reducer';
import {
  atualizarBancoSelecionado as actionAtualizarBancoSelecionado,
  oportunidadeRequest
} from '../reducers/oportunidade.reducer';
import { routeWatcher } from './rotas.saga';
import { authenticatedRequest } from '../utils/api';
import {
  converterDataEnvioApi,
  removeNonDigitsFromString
} from '../utils/basic';
import { toast } from 'react-toastify';
import { getPayload } from '../selectors/routes.selectors';

function* simulacaoRouteWatcher() {
  yield routeWatcher(routes.SIMULACAO, function* () {
    yield put(operacaoRequest());
    yield put(parceiroRequest({tipo: "cadastro"}));
    yield put(regionalRequest());
    yield put(usuariosRequest());
    yield put(usuariosParceirosRequest({tipo: "cadastro"}));
    yield put(bancosRequest());
  });
}

const criarOportunidadeApi = (values) => {
  return authenticatedRequest({
    url: '/oportunidade/create',
    method: 'POST',
    body: values
  });
};

const criarOportunidade = basicFlow({
  actionGenerator: simulacaoRequest,
  actionFailure: simulacaoFailure,
  actionSuccess: simulacaoSuccess,
  api: criarOportunidadeApi,
  transform: (values) => {
    values.cpfCnpj = removeNonDigitsFromString(values.cpfCnpj);
    values.utilizaFgtsSimulacao = values.utilizaFgtsSimulacao ? 'S' : 'N';
    values.dataNascimento = converterDataEnvioApi(values.dataNascimento);
    values.cpfConjuge = removeNonDigitsFromString(values.cpfConjuge);
    values.dataNascimentoConjuge = converterDataEnvioApi(values.dataNascimentoConjuge);
    return values;
  },
  postSuccess: function* (values) {
    toast.success('Oportunidade incluída com sucesso');
    yield put(
      routeActions.redirectTo(routes.OPORTUNIDADE, {
        id: values.response.data.oportunidade.idOportunidade
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const getSimulacaoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/get',
    method: 'POST',
    body: values
  });
};

const getSimulacao = basicFlow({
  actionGenerator: getSimulacaoRequest,
  actionFailure: getSimulacaoFailure,
  actionSuccess: getSimulacaoSuccess,
  api: getSimulacaoApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* simulacaoBancoRouteWatcher() {
  yield routeWatcher(routes.SIMULACAOBANCO, function* () {
    const { id, idSimulacao } = yield select(getPayload);
    yield put(getSimulacaoRequest({ id, idSimulacao }));
    yield put(bancosRequest());
  });
}

const atualizarBancoSelecionadoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/bancoSelecionado',
    method: 'PUT',
    body: values
  });
};

const atualizarBancoSelecionado = basicFlow({
  actionGenerator: atualizarBancoSelecionadoRequest,
  actionFailure: atualizarBancoSelecionadoFailure,
  actionSuccess: atualizarBancoSelecionadoSuccess,
  api: atualizarBancoSelecionadoApi,
  postSuccess: function* (values) {
    toast.success('Banco selecionado com sucesso');
    yield put(actionAtualizarBancoSelecionado(values?.response?.data));
    const { id } = yield select(getPayload);
    yield put(oportunidadeRequest(id));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const atualizarSimulacaoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/',
    method: 'PUT',
    body: values
  });
};

const atualizarSimulacao = basicFlow({
  actionGenerator: atualizarSimulacaoRequest,
  actionFailure: atualizarSimulacaoFailure,
  actionSuccess: atualizarSimulacaoSuccess,
  api: atualizarSimulacaoApi,
  postSuccess: function (values) {
    toast.success('Simulação atualizada com sucesso');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarSimulacaoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/list',
    method: 'POST',
    body: values
  });
};

const listarSimulacao = basicFlow({
  actionGenerator: listarSimulacaoRequest,
  actionFailure: listarSimulacaoFailure,
  actionSuccess: listarSimulacaoSuccess,
  api: listarSimulacaoApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* novaSimulacaoRouteWatcher() {
  yield routeWatcher(routes.NOVASIMULACAO, function* () {
    const { id } = yield select(getPayload);
    yield put(listarSimulacaoRequest({ id }));
    yield put(bancosRequest());
  });
}

const incluirSimulacaoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/new',
    method: 'POST',
    body: values
  });
};

const incluirSimulacao = basicFlow({
  actionGenerator: incluirSimulacaoRequest,
  actionFailure: incluirSimulacaoFailure,
  actionSuccess: incluirSimulacaoSuccess,
  api: incluirSimulacaoApi,
  postSuccess: function* (values) {
    toast.success('Simulação incluída com sucesso');
    yield put(
      routeActions.redirectTo(routes.SIMULACAOBANCO, {
        id: values.response.data.idOportunidade,
        idSimulacao: values.response.data.idSimulacao
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const incluirPropostaIntegracaoApi = (values) => {
  return authenticatedRequest({
    url: '/simulacao/integracao/incluir-proposta',
    method: 'POST',
    body: values
  });
};

const incluirPropostIntegracao = basicFlow({
  actionGenerator: incluirPropostaIntegracaoRequest,
  actionFailure: incluirPropostaIntegracaoFailure,
  actionSuccess: incluirPropostaIntegracaoSuccess,
  api: incluirPropostaIntegracaoApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

export const sagas = [
  simulacaoRouteWatcher(),
  criarOportunidade.watcher(),
  getSimulacao.watcher(),
  simulacaoBancoRouteWatcher(),
  atualizarBancoSelecionado.watcher(),
  atualizarSimulacao.watcher(),
  listarSimulacao.watcher(),
  novaSimulacaoRouteWatcher(),
  incluirSimulacao.watcher(),
  incluirPropostIntegracao.watcher(),
];
