import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import PhoneTextField from 'components/form/PhoneTextField';

const DadosDaAvaliacao = ({ formProps }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="contatoAvaliacao">Nome Contato</InputLabel>
          <OutlinedInput
            id="contatoAvaliacao"
            name="contatoAvaliacao"
            placeholder="Nome do contato"
            fullWidth
            formProps={formProps}
            type="email"
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="telefoneContatoAvaliacao">
            Telefone Contato
          </InputLabel>
          <OutlinedInput
            id="telefoneContatoAvaliacao"
            name="telefoneContatoAvaliacao"
            placeholder="Digite o número do Telefone"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: PhoneTextField,
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DadosDaAvaliacao;
