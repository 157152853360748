import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import MainCard from 'components/MainCard';
import FormCard from 'components/FormCard';
import validators from 'utils/validators';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import DadosPessoais from './DadosPessoais';
import DadosProfissionais from './DadosProfissionais';
import Endereco from './Endereco';
import { removeNonDigitsFromString } from 'utils/basic';
import Contatos from './Contatos';
import Conjuge from './Conjuge';
import * as actions from 'reducers/vendedores.reducer';
import Loader from 'components/Loader';
import MainLayout from '../../../../layout/MainLayout';
import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import {
  Box,
  Typography
} from '../../../../../node_modules/@mui/material/index';

const PessoaFisica = () => {
  const dispatch = useDispatch();

  const pessoaFisica = useSelector((state) => state.vendedores.pessoaFisica);
  const idOportunidade = useSelector(
    (state) => state.vendedores.idOportunidade
  );
  const isEditMode = useSelector(
    (state) => state.vendedores.pessoaFisica.isEditMode
  );
  const loading = useSelector((state) => state.vendedores.pessoaFisica.loading);

  const usuario = useSelector((state) => state.usuario);

  const [estadoCivil, setEstadoCivil] = useState('');

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const handleEstadoCivilChange = (status) => {
    setEstadoCivil(status);
  };

  const initialValues = {
    nomeParticipante: '',
    cpfCnpj: '',
    dataNascimento: '',
    tipoSexo: null,
    tipoEstadoCivil: null,
    tipoRegimeCasamento: null,
    tipoDocumentoIdentidade: null,
    numeroDocumento: '',
    dataExpedicao: '',
    orgaoExpedidor: '',
    ufExpedicao: null,

    // Dados Profissionais
    nomeProfissao: '',
    nomeEmpresaProfissao: '',
    renda: '',
    idBanco: null,
    codigoAgencia: '',
    codigoContaCorrente: '',
    digitoContaCorrente: '',

    // Endereço
    cep: '',
    logradouro: '',
    numeroLogradouro: '',
    complementoLogradouro: '',
    bairro: '',
    municipio: '',
    uf: null,

    // Contatos
    email: '',
    celular: '',

    // Cônjuge
    nomeConjuge: '',
    cpfConjuge: '',
    dataNascimentoConjuge: '',
    tipoEstadoCivilConjuge: null,
    tipoDocumentoIdentidadeConjuge: null,
    numeroDocumentoConjuge: '',
    dataExpedicaoConjuge: null,
    orgaoExpedidorConjuge: '',
    ufExpedicaoConjuge: null
  };

  const rules = {
    // Dados Pessoais
    nomeParticipante: validators.string({ required: true }),
    cpfCnpj: validators.cpf({ required: true }),
    dataNascimento: validators.date({ required: false }),
    tipoSexo: validators.string({ required: false }),
    tipoEstadoCivil: validators.string({ required: false }),
    tipoRegimeCasamento: validators.string({
      required: estadoCivil === 'CA' ? true : false
    }),
    tipoDocumentoIdentidade: validators.string({ required: false }),
    numeroDocumento: validators.string({ required: false }),
    dataExpedicao: validators.date({ required: false }),
    orgaoExpedidor: validators.string({ required: false }),
    ufExpedicao: validators.string({ required: false }),

    // Dados Profissionais
    nomeProfissao: validators.string({ required: false }),
    nomeEmpresaProfissao: validators.string({ required: false }),
    idBanco: validators.string({ required: false }),
    codigoAgencia: validators.number({ required: false, max: 9999 }),
    codigoContaCorrente: validators.number({
      required: false,
      max: 999999999999999
    }),
    digitoContaCorrente: validators.string({ required: false }),

    // Endereço
    cep: validators.string({ required: false }),
    logradouro: validators.string({ required: false }),
    numeroLogradouro: validators.string({ required: false }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: false }),
    municipio: validators.string({ required: false }),
    uf: validators.string({ required: false }),

    // Contatos
    email: validators.email({ required: false }),
    celular: validators.string({ required: false }),

    // Cônjuge
    nomeConjuge: validators.string({ required: false }),
    cpfConjuge: validators.cpf({ required: false }),
    dataNascimentoConjuge: validators.date({ required: false }),
    tipoEstadoCivilConjuge: validators.string({ required: false }),
    tipoDocumentoIdentidadeConjuge: validators.string({ required: false }),
    numeroDocumentoConjuge: validators.string({ required: false }),
    dataExpedicaoConjuge: validators.date({ required: false }),
    orgaoExpedidorConjuge: validators.string({ required: false }),
    ufExpedicaoConjuge: validators.string({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
    defaultValues: isEditMode ? pessoaFisica.data : initialValues
  };

  useEffect(() => {
    if (pessoaFisica.data) {
      setEstadoCivil(
        pessoaFisica.data?.tipoEstadoCivil?.id ??
          pessoaFisica.data?.tipoEstadoCivil
      );
    }
    if (isEditMode) {
      formProps.reset(pessoaFisica.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoaFisica.data]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      idOportunidade: values.idOportunidade || idOportunidade,
      tipoSituacao: 'A',
      tipoQualificacao: 'VD',
      tipoPessoa: 'F',
      idUsuarioCriacao: usuario.id,
      idUsuarioAlteracao: usuario.id,
      dataHoraAlteracao: new Date().toISOString()
    };

    isEditMode
      ? dispatch(actions.editarVendedorPessoaFisicaRequest({ value: payload }))
      : dispatch(actions.criarVendedorPessoaFisicaRequest({ value: payload }));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      formProps.reset(initialValues);
      setEstadoCivil('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, {
        id: idOportunidade
      })
    );
  };

  if (!loading && isEditMode && !pessoaFisica?.data?.idParticipante) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Vendedor não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
            <MainCard
              contentSX={{
                paddingLeft: 1,
                paddingRight: 1
              }}
              border={false}
              title={
                !isEditMode ? (
                  'Novo Vendedor'
                ) : (
                  <Box>
                    <Typography variant="h2">
                      {pessoaFisica.data.nomeParticipante}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Vendedor
                    </Typography>
                  </Box>
                )
              }
            >
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS PESSOAIS" style={{ flex: 1 }}>
                    <DadosPessoais
                      formProps={formProps}
                      handleEstadoCivilChange={handleEstadoCivilChange}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS PROFISSIONAIS" style={{ flex: 1 }}>
                    <DadosProfissionais formProps={formProps} />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                    <Endereco
                      formProps={formProps}
                      handleZipCodeBlurred={handleZipCodeBlurred}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="CONTATO" style={{ flex: 1 }}>
                    <Contatos formProps={formProps} />
                  </FormCard>
                </Grid>
                {estadoCivil === 'CA' && (
                  <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                    <FormCard title="CÔNJUGE" style={{ flex: 1 }}>
                      <Conjuge
                        formProps={formProps}
                        estadoCivil={estadoCivil}
                      />
                    </FormCard>
                  </Grid>
                )}
              </Grid>
            </MainCard>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    SALVAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default PessoaFisica;
