import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect } from 'react';
import DadosDoDocumento from './DadosDoDocumento';
import * as actions from 'reducers/configuracaoDocumentos.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import OpcoesDeGeracao from './OpcoesDeGeracao';

const Documento = () => {
  const dispatch = useDispatch();
  const documento = useSelector(
    (state) => state.configuracaoDocumentos.documento?.data
  );
  const loading = useSelector(
    (state) => state.configuracaoDocumentos.documento?.loading
  );

  const isEditMode = useSelector(
    (state) => state.configuracaoDocumentos.documento?.isEditMode
  );

  const tipoSituacao = [
    { value: 'A', label: 'Ativo' },
    { value: 'I', label: 'Inativo' }
  ];

  const tipoGeracao = [
    { value: 'A', label: 'Automática' },
    { value: 'M', label: 'Manual' }
  ];

  const disabled = !isEditMode ? false : documento?.tipoSituacao !== 'A';

  const initialValues = {
    // Dados do Documento
    nomeDocumento: documento?.nomeDocumento ?? '',
    tipoSituacao:
      documento?.tipoSituacao === 'I'
        ? { value: 'I', label: 'Inativo' }
        : { value: 'A', label: 'Ativo' },
    tipoGeracao:
      documento?.tipoGeracao === 'M'
        ? { value: 'M', label: 'Manual' }
        : { value: 'A', label: 'Automática' },
    flagSimulacao: documento?.flagSimulacao === 'S' ? true : false,
    flagCompradorPF: documento?.flagCompradorPF === 'S' ? true : false,
    flagCompradorPJ: documento?.flagCompradorPJ === 'S' ? true : false,
    flagConjugeComprador:
      documento?.flagConjugeComprador === 'S' ? true : false,
    flagRepresentanteComprador:
      documento?.flagRepresentanteComprador === 'S' ? true : false,
    flagVendedorPF: documento?.flagVendedorPF === 'S' ? true : false,
    flagVendedorPJ: documento?.flagVendedorPJ === 'S' ? true : false,
    flagConjugeVendedor: documento?.flagConjugeVendedor === 'S' ? true : false,
    flagRepresentanteVendedor:
      documento?.flagRepresentanteVendedor === 'S' ? true : false,
    flagImovel: documento?.flagImovel === 'S' ? true : false,
    flagIntervenienteQuitante:
      documento?.flagIntervenienteQuitante === 'S' ? true : false
  };

  const rules = {
    // Dados do Documento
    nomeDocumento: validators.string({ required: true }),
    tipoSituacao: validators.string({ required: true }),
    flagSimulacao: validators.string({ required: false }),
    flagCompradorPF: validators.string({ required: false }),
    flagCompradorPJ: validators.string({ required: false }),
    flagConjugeComprador: validators.string({ required: false }),
    flagRepresentanteComprador: validators.string({ required: false }),
    flagVendedorPF: validators.string({ required: false }),
    flagVendedorPJ: validators.string({ required: false }),
    flagConjugeVendedor: validators.string({ required: false }),
    flagRepresentanteVendedor: validators.string({ required: false }),
    flagImovel: validators.string({ required: false }),
    flagIntervenienteQuitante: validators.string({ required: false }),
    tipoGeracao: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    }
  };

  useEffect(() => {
    if (!!documento) {
      formProps.reset({
        ...documento,
        tipoSituacao:
          documento?.tipoSituacao === 'A'
            ? { value: 'A', label: 'Ativo' }
            : { value: 'I', label: 'Inativo' },
        tipoGeracao:
          documento?.tipoGeracao === 'A'
            ? { value: 'A', label: 'Automática' }
            : { value: 'M', label: 'Manual' },
        flagSimulacao: documento?.flagSimulacao === 'S' ? true : false,
        flagCompradorPF: documento?.flagCompradorPF === 'S' ? true : false,
        flagCompradorPJ: documento?.flagCompradorPJ === 'S' ? true : false,
        flagConjugeComprador:
          documento?.flagConjugeComprador === 'S' ? true : false,
        flagRepresentanteComprador:
          documento?.flagRepresentanteComprador === 'S' ? true : false,
        flagVendedorPF: documento?.flagVendedorPF === 'S' ? true : false,
        flagVendedorPJ: documento?.flagVendedorPJ === 'S' ? true : false,
        flagConjugeVendedor:
          documento?.flagConjugeVendedor === 'S' ? true : false,
        flagRepresentanteVendedor:
          documento?.flagRepresentanteVendedor === 'S' ? true : false,
        flagImovel: documento?.flagImovel === 'S' ? true : false,
        flagIntervenienteQuitante:
          documento?.flagIntervenienteQuitante === 'S' ? true : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documento]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      flagSimulacao: values.flagSimulacao === true ? 'S' : 'N',
      tipoSituacao: values?.tipoSituacao?.value === 'A' ? 'A' : 'I',
      tipoGeracao: values?.tipoGeracao?.value === 'A' ? 'A' : 'M',
      flagCompradorPJ: values?.flagCompradorPJ === true ? 'S' : 'N',
      flagCompradorPF: values?.flagCompradorPF === true ? 'S' : 'N',
      flagConjugeComprador: values?.flagConjugeComprador === true ? 'S' : 'N',
      flagRepresentanteComprador:
        values?.flagRepresentanteComprador === true ? 'S' : 'N',
      flagVendedorPF: values?.flagVendedorPF === true ? 'S' : 'N',
      flagVendedorPJ: values?.flagVendedorPJ === true ? 'S' : 'N',
      flagConjugeVendedor: values?.flagConjugeVendedor === true ? 'S' : 'N',
      flagRepresentanteVendedor:
        values?.flagRepresentanteVendedor === true ? 'S' : 'N',
      flagImovel: values?.flagImovel === true ? 'S' : 'N',
      flagIntervenienteQuitante:
        values?.flagIntervenienteQuitante === true ? 'S' : 'N'
    };

    if (isEditMode) {
      dispatch(actions.editarDocumentoRequest({ value: payload }));
    } else {
      dispatch(actions.criarDocumentoRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.CONFIGURACAO_DOCUMENTOS));
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !documento?.idDocumento) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Documento não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Novo Documento'
            ) : (
              <Box>
                <Typography variant="h2">{documento?.nomeDocumento}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Documento
                </Typography>
              </Box>
            )
          }
        >
          <FormCard title="DADOS DO DOCUMENTO" style={{ flex: 1 }}>
            <DadosDoDocumento
              formProps={formProps}
              tipoGeracao={tipoGeracao}
              tipoSituacao={tipoSituacao}
              disabled={disabled}
            />
          </FormCard>
          <FormCard
            title="OPÇÕES DE GERAÇÃO"
            style={{ flex: 1, marginTop: '20px' }}
          >
            <OpcoesDeGeracao formProps={formProps} disabled={disabled} />
          </FormCard>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disabled={disabled}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Documento;
