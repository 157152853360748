import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  usuarios: {
    loading: false,
    error: false,
    data: []
  },
  usuario: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  usuariosParceiros: {
    loading: false,
    error: false,
    data: []
  },
  regionais: {
    loading: false,
    error: false,
    data: []
  },
  parceiros: {
    loading: false,
    error: false,
    data: []
  }
};

const UsuariosSlice = createSlice({
  name: 'usuarios',
  initialState,
  reducers: {
    listarUsuariosRequest: (state) => {
      state.usuarios.loading = true;
      state.usuarios.error = null;
      state.usuarios.data = [];
    },
    listarUsuariosSuccess: (state, action) => {
      state.usuarios.loading = false;
      state.usuarios.error = false;
      state.usuarios.data = action.payload.response.data;
    },
    listarUsuariosFailure: (state, action) => {
      state.usuarios.loading = false;
      state.usuarios.error = action.payload;
    },
    criarUsuarioRequest: (state, action) => {
      state.usuario.loading = true;
      state.usuario.loading = true;
      state.usuario.error = null;
    },
    criarUsuarioSuccess: (state, action) => {
      state.usuario.loading = false;
      state.usuario.loading = false;
      state.usuario.error = false;
    },
    criarUsuarioFailure: (state, action) => {
      state.usuario.loading = false;
      state.usuario.loading = false;
      state.usuario.error = action.payload;
    },
    editarUsuarioRequest: (state, action) => {
      state.usuario.loading = true;
      state.usuario.loading = true;
      state.usuario.error = null;
    },
    editarUsuarioSuccess: (state, action) => {
      state.usuario.loading = false;
      state.usuario.loading = false;
      state.usuario.error = false;
    },
    editarUsuarioFailure: (state, action) => {
      state.usuario.loading = false;
      state.usuario.loading = false;
      state.usuario.error = action.payload;
    },
    mostrarUsuarioRequest: (state, action) => {
      state.usuario.loading = true;
      state.usuario.data = null;
      state.usuario.error = null;
    },
    mostrarUsuarioSuccess: (state, action) => {
      state.usuario.loading = false;
      state.usuario.data = action.payload.response.data;
      state.usuario.error = false;
    },
    mostrarUsuarioFailure: (state, action) => {
      state.usuario.loading = false;
      state.usuario.loading = false;
      state.usuario.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.usuario.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.usuario.isEditMode = true;
    },
    limparUsuario: (state) => {
      state.usuario.data = null;
    },
    excluirUsuarioRequest: (state) => {
      state.usuarios.loading = true;
      state.usuarios.error = null;
    },
    excluirUsuarioSuccess: (state) => {
      state.usuarios.loading = false;
      state.usuarios.error = false;
    },
    excluirUsuarioFailure: (state, action) => {
      state.usuarios.loading = false;
      state.usuarios.error = action.payload;
    },
    listarUsuariosParceirosRequest: (state) => {
      state.usuariosParceiros.loading = true;
      state.usuariosParceiros.error = null;
      state.usuariosParceiros.data = [];
    },
    listarUsuariosParceirosSuccess: (state, action) => {
      state.usuariosParceiros.loading = false;
      state.usuariosParceiros.error = false;
      state.usuariosParceiros.data = action.payload.response.data;
    },
    listarUsuariosParceirosFailure: (state, action) => {
      state.usuariosParceiros.loading = false;
      state.usuariosParceiros.error = action.payload;
    },
    listarParceirosRequest: (state) => {
      state.parceiros.loading = true;
      state.parceiros.error = null;
      state.parceiros.data = [];
    },
    listarParceirosSuccess: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = false;
      state.parceiros.data = action.payload.response.data;
    },
    listarParceirosFailure: (state, action) => {
      state.parceiros.loading = false;
      state.parceiros.error = action.payload;
    },
    listarRegionaisRequest: (state) => {
      state.regionais.loading = true;
      state.regionais.error = null;
      state.regionais.data = [];
    },
    listarRegionaisSuccess: (state, action) => {
      state.regionais.loading = false;
      state.regionais.error = false;
      state.regionais.data = action.payload.response.data;
    },
    listarRegionaisFailure: (state, action) => {
      state.regionais.loading = false;
      state.regionais.error = action.payload;
    }
  }
});

export const {
  listarUsuariosRequest,
  listarUsuariosSuccess,
  listarUsuariosFailure,
  adicionarOportunidade,
  criarUsuarioRequest,
  criarUsuarioSuccess,
  criarUsuarioFailure,
  mostrarUsuarioRequest,
  mostrarUsuarioSuccess,
  mostrarUsuarioFailure,
  editarUsuarioRequest,
  editarUsuarioSuccess,
  editarUsuarioFailure,
  adicionarEtapa,
  reverterUsuarios,
  desativarModoEdicao,
  ativarModoEdicao,
  limparUsuario,
  excluirUsuarioRequest,
  excluirUsuarioSuccess,
  excluirUsuarioFailure,
  listarUsuariosParceirosRequest,
  listarUsuariosParceirosSuccess,
  listarUsuariosParceirosFailure,
  listarParceirosRequest,
  listarParceirosSuccess,
  listarParceirosFailure,
  listarRegionaisRequest,
  listarRegionaisSuccess,
  listarRegionaisFailure
} = UsuariosSlice.actions;

export default UsuariosSlice.reducer;
