import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

// material-ui
import { Box, Grid, Stack, Typography } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import MainLayout from '../../../layout/MainLayout';
import validators from '../../../utils/validators';
import { useForm } from 'react-hook-form';
import AnimateButton from '../../../components/@extended/AnimateButton';
import Button from '../../../components/Button';
import OutlinedInput from '../../../components/form/OutlinedInput';
import CurrencyTextField from '../../../components/form/CurrencyTextField';
import FloatTextField from '../../../components/form/FloatTextField';
import InputLabel from '../../../components/form/InputLabel';

import { atualizarSimulacaoRequest, incluirSimulacaoRequest } from '../../../reducers/simulacao.reducer';
import { actions as routeActions, types as routes } from '../../../reducers/rotas.actions';
import { replaceNullishsWithEmptys } from '../../../utils/basic';
import FormCard from '../../../components/FormCard';
import Autocomplete from '../../../components/form/Autocomplete';
import Switch from '../../../components/form/Switch';

// ==============================|| SAMPLE PAGE ||============================== //

const Simulacao = () => {
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.simulacao.submitting);
  const { loading, data, list } = useSelector((state) => state.simulacao);
  const tiposImovel = useSelector((state) => state.dominios.tiposImovel);
  const tiposUtilizacaoImovel = useSelector((state) => state.dominios.tiposUtilizacao);
  const sistemasAmortizacao = useSelector((state) => state.dominios.sistemaAmortizacao);
  const situacaoImovel = useSelector((state) => state.dominios.situacaoImovel);
  const { type: rota } = useSelector((state) => state.location);
  const { data: bancos } = useSelector((state) => state.dominios.listarBancos);
  const situacaoIntegracao = useSelector((state) => state.simulacao.situacao);

  const initialValues = {
    codigoOportunidadeBanco: '',
    valorImovel: '',
    valorFinanciamento: '',
    prazo: '',
    valorParcelaBanco: '',
    taxaJurosAnoBanco: '',
    codigoSistemaAmortizacaoBanco: '',
    codigoIndexadorBanco: '',
    valorIofBanco: '',
    valorFinanciamentoBancoMax: '',
    valorParcelaBancoMax: '',
    prazoPagamentoBancoMax: '',
    banco: '',
  };

  const [vlImovel, setVlImovel] = React.useState(0);
  const [operacao, setOperacao] = React.useState(0);
  const [situacao, setSituacao] = React.useState("");
  const [bancosComSimulacao, setBancosComSimulacao] = React.useState([]);
  const [isDespesaFinanciada, setIsDespesaFinanciada] = React.useState(false);

  const rules = {
    valorImovel: validators.number({ required: true, min: 0 }),
    valorFinanciamento: validators.number({
      required: true,
      min: 0,
      custom: {
        rules: (value) => {
          return (value <= vlImovel || `Valor deve ser menor/igual ao valor do imóvel`);
        }
      }
    }),
    prazo: validators.number({
      required: true,
      custom: {
        rules: (value) => {
          if(operacao === 2) {
            return ((value >= 20 && value <= 240) || `Prazo deve ser um número entre 20 e 240`)
          }
          return ((value >= 20 && value <= 420) || `Prazo deve ser um número entre 20 e 420`)
        }
      }
    }),
    banco: validators.string({required: true}),
    codigoSistemaAmortizacaoBanco: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const handleSubmit = (values) => {
    values.fgFinanciarDespesas = values.fgFinanciarDespesas ? "S" : "N";
    if (rota === "@@route/novaSimulacao") {
      dispatch(incluirSimulacaoRequest({idOportunidade: list.data[0].oportunidade.idOportunidade, ...values}));
    } else {
      dispatch(atualizarSimulacaoRequest(values));
    }
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, {
        id: data?.oportunidade?.idOportunidade ?? list?.data[0]?.oportunidade?.idOportunidade
      })
    );
  };

  useEffect(() => {
    if (!!data && !loading) {
      formProps.reset(
        replaceNullishsWithEmptys(
          {
            value: {
              ...data,
              valorFinanciamento: data.valorFinanciamentoSimulacao,
              fgFinanciarDespesas: data.fgFinanciarDespesas === "S",
              prazo: data.prazoPagamentoSimulacao,
              operacao: data.oportunidade.operacao?.nomeOperacao,
              codigoSistemaAmortizacaoBanco: data.codigoSistemaAmortizacaoBanco === 'S' ? {
                id: 'S',
                nome: 'SAC'
              } : { id: 'P', nome: 'Price' },
              tipoImovel: tiposImovel.find((i) => i.id === data.oportunidade.tipoImovel)?.nome,
              utilizacaoImovel: tiposUtilizacaoImovel.find((i) => i.id === data.oportunidade.tipoUtilizacaoImovel)?.nome,
              situacaoImovel: situacaoImovel.find((f) => f.codigo === data.oportunidade.situacaoImovel)?.nome
            }
          }
        )
      );
      setIsDespesaFinanciada(data.fgFinanciarDespesas === "S");
      setVlImovel(data.valorImovel);
      setOperacao(data.oportunidade.idOperacao);
      setSituacao(situacaoIntegracao.find((f) => f.id === data.tipoSituacao).nome);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if(list.data.length > 0) {
      formProps.reset(
        replaceNullishsWithEmptys(
          {
            value: {
              banco: "",
              operacao: list.data[0].oportunidade.operacao?.nomeOperacao,
              codigoSistemaAmortizacaoBanco: "",
              tipoImovel: tiposImovel.find((i) => i.id === list.data[0].oportunidade.tipoImovel)?.nome,
              utilizacaoImovel: tiposUtilizacaoImovel.find((i) => i.id === list.data[0].oportunidade.tipoUtilizacaoImovel)?.nome,
              valorImovel: list.data[0].oportunidade.valorImovel,
              situacaoImovel: situacaoImovel.find((f) => f.codigo === list.data[0].oportunidade.situacaoImovel)?.nome
            }
          }
        )
      )
      setVlImovel(list.data[0].oportunidade.valorImovel);
      setOperacao(list.data[0].oportunidade.idOperacao);
      setBancosComSimulacao(list.data.map((a) => { return a.idBanco}))
    }
  }, [list.data])

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          title={
            <Box>
              <Typography variant="h2">
                Simulação
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {data?.banco?.nomeBanco}
              </Typography>
            </Box>
          }
          border={false}
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormCard title={'DADOS DA SIMULAÇÃO'} border={false}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sx={{ display: rota === "@@route/novaSimulacao" ? "block" : "none"}}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="banco" loading={loading}>Banco</InputLabel>
                      <Autocomplete
                        id="banco"
                        name="banco"
                        placeholder="Selecione o Banco"
                        fullWidth
                        formProps={formProps}
                        labelField={'nomeBanco'}
                        idField={'idBanco'}
                        domain={bancos.filter((f) => f.flagSimulacao === 'S' && !bancosComSimulacao.includes(f.idBanco))}
                        loading={loading}
                        skeleton={true}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="operacao" loading={loading}>Operação</InputLabel>
                      <OutlinedInput
                        id="operacao"
                        name="operacao"
                        disabled={true}
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="utilizacaoImovel" loading={loading}>Utilização do Imóvel</InputLabel>
                      <OutlinedInput
                        id="utilizacaoImovel"
                        name="utilizacaoImovel"
                        disabled={true}
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="tipoImovel" loading={loading}>Tipo do Imóvel</InputLabel>
                      <OutlinedInput
                        id="tipoImovel"
                        name="tipoImovel"
                        disabled={true}
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="tipoImovel" loading={loading}>Situação do Imóvel</InputLabel>
                      <OutlinedInput
                        id="situacaoImovel"
                        name="situacaoImovel"
                        disabled={true}
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="valorImovel" loading={loading}>Valor do Imóvel</InputLabel>
                      <OutlinedInput
                        id="valorImovel"
                        name="valorImovel"
                        placeholder="Digite o Valor do Imóvel"
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                        InputProps={{
                          inputComponent: CurrencyTextField
                        }}
                        onChange={(v) => setVlImovel(v)}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="valorFinanciamento" loading={loading}>Valor do Financiamento</InputLabel>
                      <OutlinedInput
                        id="valorFinanciamento"
                        name="valorFinanciamento"
                        placeholder="Digite o Valor do Financiamento"
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                        onChange={(v)=> formProps.setValue("valorTotalFinanciamento", v + formProps.getValues("valorDespesasFinanciadas"))}
                        InputProps={{
                          inputComponent: CurrencyTextField
                        }}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="prazo" loading={loading}>Prazo de Pagamento (em meses)</InputLabel>
                    <OutlinedInput
                      id="prazo"
                      name="prazo"
                      placeholder="Digite o Prazo"
                      fullWidth
                      formProps={formProps}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="codigoSistemaAmortizacaoBanco" loading={loading}>Sistema de Amortização</InputLabel>
                    <Autocomplete
                      id="codigoSistemaAmortizacaoBanco"
                      name="codigoSistemaAmortizacaoBanco"
                      placeholder="Selecione o Sistema de Amortização"
                      fullWidth
                      formProps={formProps}
                      labelField={'nome'}
                      idField={'id'}
                      domain={sistemasAmortizacao}
                      loading={loading}
                      skeleton={true}
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <Stack spacing={0}>
                      <InputLabel htmlFor="fgFinanciarDespesas" loading={loading}>
                        Gostaria de Financiar as Despesas Cartorárias?
                      </InputLabel>
                      <Switch
                        formProps={formProps}
                        fullWidth
                        name="fgFinanciarDespesas"
                        id="fgFinanciarDespesas"
                        loading={loading}
                        onChange={(e) => {setIsDespesaFinanciada(e.target.checked); formProps.setValue("valorDespesasFinanciadas", 0)}}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormCard title={'RESULTADO DA SIMULAÇÃO'} border={false}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="codigoOportunidadeBanco" loading={loading}>Número da Proposta</InputLabel>
                      <OutlinedInput
                        id="codigoOportunidadeBanco"
                        name="codigoOportunidadeBanco"
                        placeholder="Digite o Código"
                        fullWidth
                        formProps={formProps}
                        loading={loading}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="valorParcelaBanco" loading={loading}>Valor da Parcela</InputLabel>
                    <OutlinedInput
                      id="valorParcelaBanco"
                      name="valorParcelaBanco"
                      placeholder="Digite o Valor da Parcela"
                      fullWidth
                      formProps={formProps}
                      loading={loading}
                      InputProps={{
                        inputComponent: CurrencyTextField
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="taxaJurosAnoBanco" loading={loading}>Taxa de Juros Efetiva a.a</InputLabel>
                    <OutlinedInput
                      id="taxaJurosAnoBanco"
                      name="taxaJurosAnoBanco"
                      placeholder="Digite a Taxa de Juros ao Ano"
                      fullWidth
                      formProps={formProps}
                      loading={loading}
                      InputProps={{
                        inputComponent: FloatTextField
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="codigoIndexadorBanco" loading={loading}>Código do Indexador</InputLabel>
                    <OutlinedInput
                      id="codigoIndexadorBanco"
                      name="codigoIndexadorBanco"
                      placeholder="Digite o Código do Indexador"
                      fullWidth
                      formProps={formProps}
                      loading={loading}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="valorIofBanco" loading={loading}>Valor do IOF</InputLabel>
                    <OutlinedInput
                      id="valorIofBanco"
                      name="valorIofBanco"
                      placeholder="Digite o Valor do IOF"
                      fullWidth
                      formProps={formProps}
                      loading={loading}
                      InputProps={{
                        inputComponent: CurrencyTextField
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="valorDespesasFinanciadas" loading={loading}>Valor Despesas Financiadas</InputLabel>
                    <OutlinedInput
                      id="valorDespesasFinanciadas"
                      name="valorDespesasFinanciadas"
                      placeholder="Digite o Valor das Despesas Financiadas"
                      fullWidth
                      formProps={formProps}
                      disabled={!isDespesaFinanciada}
                      loading={loading}
                      onChange={(v)=> formProps.setValue("valorTotalFinanciamento", v + formProps.getValues("valorFinanciamento"))}
                      InputProps={{
                        inputComponent: CurrencyTextField
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <InputLabel htmlFor="valorTotalFinanciamento" loading={loading}>Valor Total do Financiamento</InputLabel>
                    <OutlinedInput
                      id="valorTotalFinanciamento"
                      name="valorTotalFinanciamento"
                      placeholder="Digite o Total do Financiamento"
                      fullWidth
                      disabled={true}
                      formProps={formProps}
                      loading={loading}
                      InputProps={{
                        inputComponent: CurrencyTextField
                      }}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
            <Grid item xs={12} sx={{display: situacao === "Erro ao Enviar Proposta" ? "block" : "none"}}>
              <FormCard title={'INTEGRAÇÃO'} subtitle={situacao} border={false}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel htmlFor="retornoIntegracao" loading={loading}>Descrição do Erro</InputLabel>
                    <OutlinedInput
                      id="retornoIntegracao"
                      name="retornoIntegracao"
                      fullWidth
                      disabled={true}
                      formProps={formProps}
                      loading={loading}
                      multiline={true}
                    />
                  </Grid>
                </Grid>
              </FormCard>
            </Grid>
          </Grid>
        </MainCard>
        <Grid container spacing={2} sx={{ justifyContent: 'end', p: 1 }}>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                isLoading={submitting}
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Simulacao;
