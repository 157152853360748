// third party
import React from 'react';

// assets
import OutlinedInput from '../../components/form/OutlinedInput';
import Switch from '../../components/form/Switch';
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useSelector } from 'react-redux';
import useTheme from '@mui/system/useTheme';

const Bancos = ({ formProps }) => {

  const { data } = useSelector((state) => state.dominios.listarBancos);
  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="bancos" >
        <TableHead sx={{ backgroundColor: theme.palette.grey[200] }}>
          <TableRow sx={{ maxHeight: 50, height: 50}}>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pt: 0, pb: 0, width: "30%" }} >Banco</TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pt: 0, pb: 0, width: "15%" }} >Simular?</TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pr: 2, pt: 0, pb: 0, width: "15%" }} >Número da Proposta</TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pr: 2, pt: 0, pb: 0, width: "15%" }} >Agência</TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pr: 2, pt: 0, pb: 0, width: "20%" }} >Conta Corrente</TableCell>
            <TableCell sx={{ fontSize: "12px", fontWeight: 700, pl: 2, pr: 2, pt: 0, pb: 0 }} >Dígito</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.filter((i) => i.flagSimulacao === 'S').map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0, maxHeight: 50, height: 50} }}
            >
              <TableCell align="left" sx={{ pl: 2, pt: 1, pb: 1 }} >{row.nomeBanco}</TableCell>
              <TableCell align="left" sx={{ pt: 1, pl: 2, pb: 1 }} >
                <Switch
                  formProps={formProps}
                  id={`bancos[${index}].fgSimular`}
                  name={`bancos[${index}].fgSimular`}
                />
              </TableCell>
              <TableCell align="left" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }} >
                <OutlinedInput
                  name={`bancos[${index}].proposta`}
                  formProps={formProps}
                  sx={{ maxWidth: "300px"}}
                />
              </TableCell>
              <TableCell align="left" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }} >
                <OutlinedInput
                  name={`bancos[${index}].agencia`}
                  formProps={formProps}
                  sx={{ maxWidth: "300px"}}
                  inputProps={{ maxLength: 5 }}
                />
              </TableCell>
              <TableCell align="left" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }} >
                <OutlinedInput
                  name={`bancos[${index}].conta`}
                  formProps={formProps}
                  inputProps={{ maxLength: 10 }}
                />
              </TableCell>
              <TableCell align="left" sx={{ pr: 2, pl: 2, pt: 1, pb: 1 }} >
                <OutlinedInput
                  name={`bancos[${index}].digito`}
                  formProps={formProps}
                  inputProps={{ maxLength: 1 }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Bancos;
