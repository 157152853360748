import InputLabel from 'components/form/InputLabel';
import { MenuItem, Select } from '../../node_modules/@mui/material/index';

export const opcoesFiltro = (props, itens) => {
  return (
    <>
      <InputLabel htmlFor="opcoes">Opções</InputLabel>
      <Select
        multiple
        value={props.item.value || []}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
        fullWidth
        id={'opcoes'}
        name={'opcoes'}
      >
        <MenuItem value="">
          <em>Nenhum</em>
        </MenuItem>
        {itens.map((option) => (
          <MenuItem key={option.codigo} value={option.codigo}>
            {option.nome}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
