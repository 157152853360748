import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/pesquisa.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';

import { types as routes } from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import {
  bancosRequest,
  etapasRequest,
  operacaoRequest,
  usuariosRequest
} from 'reducers/dominios.reducer';
import { activeItem } from 'reducers/menu';

const apiPesquisa = (values) => {
  return authenticatedRequest({
    url: `/pesquisa?query=${values}`,
    method: 'get'
  });
};

const pesquisar = basicFlow({
  actionGenerator: actions.pesquisaRequest,
  actionFailure: actions.pesquisaFailure,
  actionSuccess: actions.pesquisaSuccess,
  api: apiPesquisa,
  postSuccess: function* ({ response }) {
    const { data } = response;

    let menuSelecionado = 0;

    if (data?.oportunidades?.length > 0) {
      menuSelecionado = 0;
    } else if (data?.participantes?.length > 0) {
      menuSelecionado = 1;
    } else if (data?.representantes?.length > 0) {
      menuSelecionado = 1;
    } else if (data?.atividades?.length > 0) {
      menuSelecionado = 2;
    } else if (data?.fups?.length > 0) {
      menuSelecionado = 3;
    }

    yield put(actions.alterarMenuSelecionado(menuSelecionado));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* pesquisarRouteWatcher() {
  yield routeWatcher(routes.PESQUISA, function* () {
    const { termo } = yield select(getPayload);
    yield put(bancosRequest());
    yield put(operacaoRequest());
    yield put(etapasRequest());
    yield put(usuariosRequest());
    yield put(activeItem({ openItem: [null] }));
    yield put(actions.alterarQueryString(termo));
  });
}

function* limparQueryRouteWatcher() {
  yield routeWatcher(
    Object.values(routes).filter((route) => route !== routes.PESQUISA),
    function* () {
      yield put(actions.alterarQueryString(''));
    }
  );
}

export const sagas = [
  pesquisar.watcher(),
  pesquisarRouteWatcher(),
  limparQueryRouteWatcher()
];
