import React from 'react';
import { Chip }  from '@mui/material';

const MChip = ({ ...props }) => (
  <Chip
    size={props.size ?? "small"}
    variant={props.variant ?? "outlined"}
    label={props.label}
    color={props.color}
    sx={{...props.sx}}
  />
);

export default MChip;
