import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography, Grid, useMediaQuery } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import FormCard from 'components/FormCard';
import DadosFup from './DadosFup';
import validators from 'utils/validators';
import formatters from 'utils/formatters';
import Button from 'components/Button';
import * as actions from 'reducers/fup.reducer';
import { useTheme } from '@mui/material/styles';
import Loader from 'components/Loader';

const Fup = () => {
  const dispatch = useDispatch();

  const fups = useSelector((state) => state.fup.fups.data);
  const { perfil } = useSelector((state) => state.usuario);
  const loading = useSelector((state) => state.fup.fups.loading);
  const oportunidade = useSelector((state) => state.fup.idOportunidade);

  const [orderUp, setOrderUp] = useState(true);

  const handleChangeOrder = () => {
    setOrderUp((prev) => !prev);
    dispatch(actions.reverterFups());
  };

  const initialValues = {
    // Dados do Interveniente Quitante
    tipoFup:
      perfil === 'RG' || perfil === 'PC' || perfil === 'UP' ? 'Externo' : '',
    titulo: '',
    comentario: ''
  };

  const rules = {
    // Dados do Interveniente Quitante
    tipoFup: validators.string({ required: true }),
    titulo: validators.string({ required: true }),
    comentario: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    }
  };

  const handleSubmit = (values) => {
    const comentario = values.comentario.replace(/\n/g, '<br>');

    const payload = {
      comentario,
      ...values,
      tipoFup: values.tipoFup.toLowerCase(),
      idOportunidade: oportunidade,
      isImovel: true
    };

    dispatch(actions.criarFupRequest({ value: payload }));
    formProps.reset(initialValues);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      gap={2}
      sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        justifyContent: 'space-between',
        gap: 2,
        width: '100%'
      }}
    >
      <Box sx={{ flex: '1' }}>
        <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
          <FormCard
            title="INCLUIR COMENTÁRIO"
            style={{ flex: 1, marginTop: '20px' }}
          >
            <DadosFup formProps={formProps} perfil={perfil} />
          </FormCard>
        </form>
      </Box>
      <Box sx={{ flex: '1' }}>
        <FormCard
          title="HISTÓRICO DE COMENTÁRIOS"
          style={{ flex: 1, marginTop: '20px' }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button
              variant="outlined"
              onClick={handleChangeOrder}
              endIcon={!orderUp ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
            >
              Ordem
            </Button>
          </Box>
          <Box
            sx={{
              height: '283px',
              overflowY: 'scroll',
              p: 2
            }}
          >
            <Grid container>
              {fups.map((item) => (
                <Grid item xs={12}>
                  <Typography sx={{ fontWeight: '800' }} variant="h5">
                    {item.titulo}{' '}
                    {`(${item.tipoFup === 'I' ? 'Interno' : 'Externo'})`}{' '}
                  </Typography>
                  <Typography variant="h6" color="secondary">
                    {formatters.dates.longFirstLetterCapitalized(
                      new Date(item.dataHoraCriacao)
                    )}{' '}
                    - Por: {item?.usuarioCriacao?.nome}
                  </Typography>
                  <Typography
                    sx={{ mt: 1, mb: 1 }}
                    variant="h5"
                    color="secondary"
                    style={{ whiteSpace: 'pre-wrap' }}
                    dangerouslySetInnerHTML={{ __html: item.comentario }}
                  ></Typography>
                  <Divider sx={{ mt: 2, mb: 2 }} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </FormCard>
      </Box>
    </Box>
  );
};

export default Fup;
