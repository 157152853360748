import React from 'react';
import { Modal, Box, Stack } from "@mui/material";
import { useForm } from 'react-hook-form';
import OutlinedInput from '../../components/form/OutlinedInput';
import InputLabel from '../../components/form/InputLabel';
import Button from '../../components/Button';
import validators from "../../utils/validators";
import { useDispatch } from 'react-redux';
import { editarDataPrevistaRequest } from "../../reducers/oportunidade.reducer";
import DateTextField from "../../components/form/DateTextField";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const EditDateModal = ({ open, handleClose, date, idOportunidade }) => {
  const rules = {
    dataPrevistaEmissaoContrato: validators.date({ required: true }),
  };
  const initialValues = {
    dataPrevistaEmissaoContrato: date,
  };
  const dispatch = useDispatch();

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const onSubmit = async (data) => {
    const body = {
      idOportunidade: idOportunidade,
      dataPrevistaEmissaoContrato: data.dataPrevistaEmissaoContrato
    }
    dispatch(editarDataPrevistaRequest(body))
    handleClose()
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <InputLabel htmlFor="dataPrevistaEmissaoContrato">Data Prevista Emissão Contrato</InputLabel>
              <OutlinedInput
                id="dataPrevistaEmissaoContrato"
                name="dataPrevistaEmissaoContrato"
                placeholder="Digitar a data prevista para a contratação"
                fullWidth
                formProps={formProps}
                InputProps={{
                  inputComponent: DateTextField,
                  maxLength: 14
                }}
              />
            </Stack>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Confirmar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default EditDateModal;
