// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import MainLayout from '../../../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import MainCard from '../../../components/MainCard';
import {
  PoweroffOutlined,
  EditOutlined,
  UserOutlined,
  CheckCircleOutlined,
  LockOutlined
} from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { Button, Box } from '@mui/material';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

import * as actions from 'reducers/usuarios.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '../../../../node_modules/@mui/material/index';
import useTheme from '@mui/system/useTheme';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { toast } from 'react-toastify';
import { forgotPasswordRequest } from 'reducers/autenticacao.reducer';

const OportunidadesPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const usuarios = useSelector((state) => state.usuarios.usuarios.data);
  const loading = useSelector((state) => state.usuarios.usuarios.loading);

  const rows = usuarios;

  const perfilUsuario = [
    {
      codigo: 'AD',
      nome: 'Administrador'
    },
    {
      codigo: 'GT',
      nome: 'Gestor'
    },
    {
      codigo: 'CS',
      nome: 'Consultor'
    },
    {
      codigo: 'AL',
      nome: 'Analista'
    },
    {
      codigo: 'RG',
      nome: 'Regional'
    },
    {
      codigo: 'PC',
      nome: 'Parceiro'
    },
    {
      codigo: 'UP',
      nome: 'Usuário Parceiro'
    }
  ];

  const columns = [
    {
      field: 'codigo',
      headerName: 'Código',
      flex: 1
    },
    {
      field: 'nome',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'perfil',
      headerName: 'Perfil',
      flex: 1,
      valueFormatter: (value) => {
        return perfilUsuario.find((perfil) => perfil.codigo === value).nome;
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, perfilUsuario)
        }
      ]
    },
    {
      field: 'situacao',
      headerName: 'Situação',
      flex: 1,
      valueFormatter: (value) => {
        return value === 'A' ? 'Ativo' : value === 'I' ? 'Inativo' : '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'A', nome: 'Ativo' },
              { codigo: 'I', nome: 'Inativo' }
            ])
        }
      ]
    }
  ];

  const [open, setOpen] = useState(false);
  const [openAtivar, setOpenAtivar] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpenAtivar = (row) => {
    setOpenAtivar(true);
  };

  const handleCloseAtivar = () => {
    setOpenAtivar(false);
  };

  const handleNewUsuario = () => {
    dispatch(
      routeActions.redirectTo(routes.USUARIO, {
        idUsuario: 'novo'
      })
    );
  };

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleDeleteClick = () => {
      dispatch(actions.excluirUsuarioRequest({ value: selectedRowData[0] }));
      setOpen(false);
    };

    const handleClickAtivar = () => {
      dispatch(actions.excluirUsuarioRequest({ value: selectedRowData[0] }));
      setOpenAtivar(false);
    };

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.USUARIO, {
            idUsuario: selectedRowData[0].id
          })
        );
      }
    };

    const handleResetarSenha = () => {
      dispatch(
        forgotPasswordRequest({
          user: selectedRowData[0].codigo,
          isConfiguracao: true
        })
      );
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Inativação de Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja inativar o usuário{' '}
              {selectedRowData[0]?.nome}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Inativar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openAtivar} onClose={handleCloseAtivar}>
          <DialogTitle>Confirmação para Ativação de Usuário</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja ativar o usuário{' '}
              {selectedRowData[0]?.nome}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAtivar} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleClickAtivar} color="success">
              Ativar
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleNewUsuario}
            startIcon={<UserOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Usuário
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={
              selectedRows.size !== 1 || selectedRowData[0]?.situacao !== 'A'
            }
            onClick={handleClickOpen}
            startIcon={<PoweroffOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1 || selectedRowData[0]?.situacao !== 'A'
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Inativar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={
              selectedRows.size !== 1 || selectedRowData[0].situacao === 'A'
            }
            onClick={handleClickOpenAtivar}
            startIcon={<CheckCircleOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1 || selectedRowData[0].situacao === 'A'
                  ? theme.palette.grey.lighter
                  : theme.palette.success.main
              } !important`
            }}
          >
            Ativar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={
              selectedRows.size !== 1 || selectedRowData[0]?.situacao !== 'A'
            }
            onClick={handleResetarSenha}
            startIcon={<LockOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1 || selectedRowData[0].situacao !== 'A'
                  ? theme.palette.grey.lighter
                  : theme.palette.info.main
              } !important`
            }}
          >
            Resetar Senha
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <MainCard
        title="Configuração de Usuários"
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            getRowId={(row) => row.id}
            toolbar={CustomToolbar}
            loading={loading}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              density: 'standard'
            }}
          />
        </Box>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
