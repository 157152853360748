import basicFlow, { genericErrorHandler } from './asyncHandler';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  forgotPasswordRequest,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  validarTokenSenhaRequest,
  validarTokenSenhaFailure,
  validarTokenSenhaSuccess,
  trocarSenhaRequest,
  trocarSenhaFailure,
  trocarSenhaSuccess,
  validarTokenRequest,
  validarTokenFailure,
  validarTokenSuccess,
  enviarNovoTokenSuccess,
  enviarNovoTokenFailure,
  enviarNovoTokenRequest,
  logout
} from '../reducers/autenticacao.reducer';
import {
  atualizarUsuarioLogado,
  checarNotificacaoRequest
} from '../reducers/user.reducer';
import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';
import { unauthenticatedRequest } from '../utils/api';
import {
  storeJwt,
  storeRefreshToken,
  saveState,
  cleanState,
  cleanJwt,
  cleanRefreshToken
} from '../utils/localStorage';
import { toast } from 'react-toastify';
import { routeWatcher } from './rotas.saga';
import { getPayload } from '../selectors/routes.selectors';
import { put, select, delay, takeLatest } from 'redux-saga/effects';
import { activeItem } from 'reducers/menu';

const forgotPasswordApi = (values) => {
  return unauthenticatedRequest({
    url: '/auth/esqueci-senha',
    method: 'POST',
    body: values
  });
};

function* atualizacaoAutomaticaTimer() {
  const WAIT = 60;
  try {
    while (true) {
      yield delay(WAIT * 1000);
      yield put(checarNotificacaoRequest());
    }
  } catch (e) {
    console.error('Error in atualizacaoAutomaticaTimer:', e);
  }
}

function* watchAtualizacaoAutomaticaTimer() {
  yield takeLatest('START_ATUALIZACAO_AUTOMATICA', atualizacaoAutomaticaTimer);
}

const forgotPasswordFlow = basicFlow({
  actionGenerator: forgotPasswordRequest,
  actionFailure: forgotPasswordFailure,
  actionSuccess: forgotPasswordSuccess,
  transform: ({ ...values }) => {
    return values;
  },
  api: forgotPasswordApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  },
  postSuccess: function* ({ original }) {
    if (original?.isConfiguracao === true) {
      toast.info(
        'Enviamos um e-mail para o usuário com os passos para alterar a senha'
      );
    } else {
      yield put(routeActions.redirectTo(routes.LOGIN));
      toast.info(
        'Enviamos um e-mail para você com os passos para alterar sua senha'
      );
    }
  }
});

const loginApi = (values) => {
  return unauthenticatedRequest({
    url: '/auth/login',
    method: 'post',
    body: values
  });
};
const login = basicFlow({
  actionGenerator: loginRequest,
  actionFailure: loginFailure,
  actionSuccess: loginSuccess,
  transform: ({ ...values }) => {
    return values;
  },
  api: loginApi,
  postSuccess: function* ({ response, original }) {
    if (response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield put(atualizarUsuarioLogado(response.data.usuario));
      yield put(checarNotificacaoRequest());
      yield put(routeActions.redirectTo(routes.OPORTUNIDADES));
    }
    if (response.data.token) {
      toast.info('Sua senha expirou!\nPor favor escolha uma nova senha.');
      yield put(
        routeActions.redirectTo(routes.TROCAR_SENHA, {
          token: response.data.token
        })
      );
    }
    if (response.data.data_expiracao && !response.data.jwt) {
      yield put(
        routeActions.redirectTo(routes.TOKEN, {
          data_expiracao: response.data.data_expiracao
        })
      );
      toast.info('Enviamos um e-mail para você com um Token de acesso');
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  }
});

const validarTokenSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: `/auth/validar-token/${values.token}`,
    method: 'get'
  });
};

const validarTokenSenhaFlow = basicFlow({
  actionGenerator: validarTokenSenhaRequest,
  actionFailure: validarTokenSenhaFailure,
  actionSuccess: validarTokenSenhaSuccess,
  transform: ({ ...values }) => {
    return values;
  },
  api: validarTokenSenhaApi,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* trocarSenhaRouteWatcher() {
  yield routeWatcher(routes.TROCAR_SENHA, function* () {
    const { token } = yield select(getPayload);
    yield put(validarTokenSenhaRequest({ token }));
  });
}

const trocarSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: `/auth/trocar-senha`,
    method: 'post',
    body: values
  });
};

const trocarSenhaFlow = basicFlow({
  actionGenerator: trocarSenhaRequest,
  actionFailure: trocarSenhaFailure,
  actionSuccess: trocarSenhaSuccess,
  transform: function* ({ ...values }) {
    const { token } = yield select(getPayload);
    return {
      ...values,
      token: token
    };
  },
  api: trocarSenhaApi,
  postSuccess: function* () {
    yield put(routeActions.redirectTo(routes.LOGIN));
    toast.success('Senha alterada com sucesso');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const validarTokenApi = (values) => {
  return unauthenticatedRequest({
    url: `/auth/login-token`,
    method: 'post',
    body: { ...values }
  });
};

const validarTokenFlow = basicFlow({
  actionGenerator: validarTokenRequest,
  actionFailure: validarTokenFailure,
  actionSuccess: validarTokenSuccess,
  transform: ({ ...values }) => {
    return values;
  },
  api: validarTokenApi,
  postSuccess: function* ({ response }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield put(atualizarUsuarioLogado(response.data.usuario));
      yield put(routeActions.redirectTo(routes.OPORTUNIDADES));
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  }
});

const enviarNovoTokenApi = (values) => {
  return unauthenticatedRequest({
    url: '/auth/enviar-novo-token',
    method: 'post',
    body: values
  });
};
const enviarNovoToken = basicFlow({
  actionGenerator: enviarNovoTokenRequest,
  actionFailure: enviarNovoTokenFailure,
  actionSuccess: enviarNovoTokenSuccess,
  transform: ({ ...values }) => {
    return values;
  },
  api: enviarNovoTokenApi
});

function* logoutWatcher() {
  yield routeWatcher(logout, function* () {
    yield cleanState();
    yield cleanJwt();
    yield cleanRefreshToken();
    yield put(routeActions.redirectTo(routes.LOGIN));
    yield put(activeItem({ openItem: ['oportunidades'] }));
  });
}

export const sagas = [
  login.watcher(),
  forgotPasswordFlow.watcher(),
  trocarSenhaRouteWatcher(),
  validarTokenSenhaFlow.watcher(),
  trocarSenhaFlow.watcher(),
  validarTokenFlow.watcher(),
  watchAtualizacaoAutomaticaTimer(),
  enviarNovoToken.watcher(),
  logoutWatcher()
];
