import { authenticatedRequest, unauthenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/vendedores.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { removeNonDigitsFromString } from 'utils/basic';
import formatters from 'utils/formatters';
import { dominioSelectors } from 'selectors/index';

const apiMostrarParticipante = ({ value }) => {
  return authenticatedRequest({
    url: `/participante/show?idParticipante=${value.idParticipante}&idOportunidade=${value.idOportunidade}&tipoPessoa=${value.tipoPessoa}&tipoSituacao=A&tipoQualificacao=VD`,
    method: 'get'
  });
};

const apiRemoverParticipante = ({ value }) => {
  return authenticatedRequest({
    url: `/participante/delete/${value.id}`,
    method: 'put',
    body: value
  });
};

const apiListarVendedores = (values) => {
  return authenticatedRequest({
    url: `/participante/list?idOportunidade=${values}&tipoQualificacao=VD&tipoSituacao=A`,
    method: 'get'
  });
};

const apiCriarParticipante = ({ value }) => {
  const payload = {
    ...value,
    dataHoraAlteracao: new Date().toISOString(),
    dataRegistroEmpresa: formatters.dates.stringDateToDate(
      value.dataRegistroEmpresa
    ),
    cpfCnpj: removeNonDigitsFromString(value.cpfCnpj),
    cep: removeNonDigitsFromString(value.cep),
    celular: removeNonDigitsFromString(value.celular),
    dataNascimento: formatters.dates.stringDateToDate(value.dataNascimento),
    dataNascimentoConjuge: formatters.dates.stringDateToDate(
      value.dataNascimentoConjuge
    ),
    dataExpedicaoConjuge: formatters.dates.stringDateToDate(
      value.dataExpedicaoConjuge
    ),
    dataExpedicao: formatters.dates.stringDateToDate(value.dataExpedicao),
    cpfConjuge: removeNonDigitsFromString(value.cpfConjuge)
  };

  if (typeof payload.uf === 'object') {
    payload.uf = payload?.uf?.codigo;
  }

  if (typeof payload.idBanco === 'object') {
    payload.idBanco = payload?.idBanco?.idBanco ?? null;
  }

  if (typeof payload.tipoEmpresa === 'object') {
    payload.tipoEmpresa = payload?.tipoEmpresa?.value ?? null;
  }

  if (typeof payload.ufExpedicao === 'object') {
    payload.ufExpedicao = payload?.ufExpedicao?.codigo ?? null;
  }

  if (typeof payload.ufExpedicaoConjuge === 'object') {
    payload.ufExpedicaoConjuge = payload?.ufExpedicaoConjuge?.codigo ?? null;
  }

  if (typeof payload.tipoEstadoCivil === 'object') {
    payload.tipoEstadoCivil = payload?.tipoEstadoCivil?.id ?? null;
  }

  if (typeof payload.tipoEstadoCivilConjuge === 'object') {
    payload.tipoEstadoCivilConjuge =
      payload?.tipoEstadoCivilConjuge?.id ?? null;
  }

  if (typeof payload.tipoSexo === 'object') {
    payload.tipoSexo = payload?.tipoSexo?.id ?? null;
  }

  if (typeof payload.tipoRegimeCasamento === 'object') {
    payload.tipoRegimeCasamento = payload?.tipoRegimeCasamento?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidadeConjuge === 'object') {
    payload.tipoDocumentoIdentidadeConjuge =
      payload?.tipoDocumentoIdentidadeConjuge?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidade === 'object') {
    payload.tipoDocumentoIdentidade =
      payload?.tipoDocumentoIdentidade?.id ?? null;
  }

  if (typeof payload.idBanco === 'object') {
    payload.idBanco = payload?.idBanco?.idBanco ?? null;
  }

  return authenticatedRequest({
    url: '/participante/create',
    method: 'post',
    body: payload
  });
};

const apiEditarParticipante = ({ value }) => {
  const payload = {
    ...value,
    dataHoraAlteracao: new Date().toISOString(),
    dataRegistroEmpresa: formatters.dates.stringDateToDate(
      value.dataRegistroEmpresa
    ),
    cpfCnpj: removeNonDigitsFromString(value.cpfCnpj),
    cep: removeNonDigitsFromString(value.cep),
    celular: removeNonDigitsFromString(value.celular),
    dataNascimento: formatters.dates.stringDateToDate(value.dataNascimento),
    dataNascimentoConjuge: formatters.dates.stringDateToDate(
      value.dataNascimentoConjuge
    ),
    dataExpedicaoConjuge: formatters.dates.stringDateToDate(
      value.dataExpedicaoConjuge
    ),
    dataExpedicao: formatters.dates.stringDateToDate(value.dataExpedicao),
    cpfConjuge: removeNonDigitsFromString(value.cpfConjuge)
  };

  if (typeof payload.uf === 'object') {
    payload.uf = payload?.uf?.codigo;
  }

  if (typeof payload.idBanco === 'object') {
    payload.idBanco = payload?.idBanco?.idBanco ?? null;
  }

  if (typeof payload.tipoEmpresa === 'object') {
    payload.tipoEmpresa = payload?.tipoEmpresa?.value ?? null;
  }

  if (typeof payload.ufExpedicao === 'object') {
    payload.ufExpedicao = payload?.ufExpedicao?.codigo ?? null;
  }

  if (typeof payload.ufExpedicaoConjuge === 'object') {
    payload.ufExpedicaoConjuge = payload?.ufExpedicaoConjuge?.codigo ?? null;
  }

  if (typeof payload.tipoEstadoCivil === 'object') {
    payload.tipoEstadoCivil = payload?.tipoEstadoCivil?.id ?? null;
  }

  if (typeof payload.tipoEstadoCivilConjuge === 'object') {
    payload.tipoEstadoCivilConjuge =
      payload?.tipoEstadoCivilConjuge?.id ?? null;
  }

  if (typeof payload.tipoSexo === 'object') {
    payload.tipoSexo = payload?.tipoSexo?.id ?? null;
  }

  if (typeof payload.tipoRegimeCasamento === 'object') {
    payload.tipoRegimeCasamento = payload?.tipoRegimeCasamento?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidadeConjuge === 'object') {
    payload.tipoDocumentoIdentidadeConjuge =
      payload?.tipoDocumentoIdentidadeConjuge?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidade === 'object') {
    payload.tipoDocumentoIdentidade =
      payload?.tipoDocumentoIdentidade?.id ?? null;
  }

  if (typeof payload.idBanco === 'object') {
    payload.idBanco = payload?.idBanco?.idBanco ?? null;
  }

  return authenticatedRequest({
    url: `/participante/update/${value.idParticipante}`,
    method: 'put',
    body: payload
  });
};

const apiRemoverRepresentante = ({ value }) => {
  return authenticatedRequest({
    url: `/representante/delete/${value.id}`,
    method: 'put',
    body: value
  });
};

const apiCriarRepresentante = ({ value }) => {
  const payload = {
    ...value,
    dataHoraAlteracao: new Date().toISOString(),
    dataNascimento: formatters.dates.stringDateToDate(value.dataNascimento),
    dataExpedicao: formatters.dates.stringDateToDate(value.dataExpedicao),
    cpf: removeNonDigitsFromString(value.cpf),
    cep: removeNonDigitsFromString(value.cep),
    celular: removeNonDigitsFromString(value.celular)
  };

  if (typeof payload.uf === 'object') {
    payload.uf = payload?.uf?.codigo;
  }

  if (typeof payload.ufExpedicao === 'object') {
    payload.ufExpedicao = payload?.ufExpedicao?.codigo ?? null;
  }

  if (typeof payload.tipoEstadoCivil === 'object') {
    payload.tipoEstadoCivil = payload?.tipoEstadoCivil?.id ?? null;
  }

  if (typeof payload.tipoSexo === 'object') {
    payload.tipoSexo = payload?.tipoSexo?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidade === 'object') {
    payload.tipoDocumentoIdentidade =
      payload?.tipoDocumentoIdentidade?.id ?? null;
  }

  return authenticatedRequest({
    url: '/representante/create',
    method: 'post',
    body: payload
  });
};

const apiEditarRepresentante = ({ value }) => {
  const payload = {
    ...value,
    dataHoraAlteracao: new Date().toISOString(),
    dataNascimento: formatters.dates.stringDateToDate(value.dataNascimento),
    dataExpedicao: formatters.dates.stringDateToDate(value.dataExpedicao),
    cpf: removeNonDigitsFromString(value.cpf),
    cep: removeNonDigitsFromString(value.cep),
    celular: removeNonDigitsFromString(value.celular)
  };

  if (typeof payload.uf === 'object') {
    payload.uf = payload?.uf?.codigo;
  }

  if (typeof payload.ufExpedicao === 'object') {
    payload.ufExpedicao = payload?.ufExpedicao?.codigo ?? null;
  }

  if (typeof payload.tipoEstadoCivil === 'object') {
    payload.tipoEstadoCivil = payload?.tipoEstadoCivil?.id ?? null;
  }

  if (typeof payload.tipoSexo === 'object') {
    payload.tipoSexo = payload?.tipoSexo?.id ?? null;
  }

  if (typeof payload.tipoDocumentoIdentidade === 'object') {
    payload.tipoDocumentoIdentidade =
      payload?.tipoDocumentoIdentidade?.id ?? null;
  }

  return authenticatedRequest({
    url: `/representante/update/${value.idRepresentante}`,
    method: 'put',
    body: payload
  });
};

const apiMostrarRepresentante = ({ value }) => {
  return authenticatedRequest({
    url: `/representante/show?idRepresentante=${value.idRepresentante}&idParticipante=${value.idParticipante}&tipoSituacao=${value.tipoSituacao}`,
    method: 'get'
  });
};

const criarRepresentante = basicFlow({
  actionGenerator: actions.criarRepresentanteRequest,
  actionFailure: actions.criarRepresentanteFailure,
  actionSuccess: actions.criarRepresentanteSuccess,
  api: apiCriarRepresentante,
  postSuccess: function* ({ response, original }) {
    const { id, idPessoaJuridica } = yield select(getPayload);

    yield put(
      routeActions.redirectTo(routes.VENDEDOR_REPRESENTANTE, {
        id: id,
        idPessoaJuridica: idPessoaJuridica,
        idRepresentante: response.data.idRepresentante
      })
    );
    toast.info('Representante criado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const editarRepresentante = basicFlow({
  actionGenerator: actions.editarRepresentanteRequest,
  actionFailure: actions.editarRepresentanteFailure,
  actionSuccess: actions.editarRepresentanteSuccess,
  api: apiEditarRepresentante,
  postSuccess: function* ({ response, original }) {
    const { id, idPessoaJuridica, idRepresentante } = yield select(getPayload);

    yield put(
      routeActions.redirectTo(routes.VENDEDOR_REPRESENTANTE, {
        id: id,
        idPessoaJuridica: idPessoaJuridica,
        idRepresentante: idRepresentante
      })
    );

    toast.info('Representante editado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const removerRepresentante = basicFlow({
  actionGenerator: actions.removerRepresentanteRequest,
  actionFailure: actions.removerRepresentanteFailure,
  actionSuccess: actions.removerRepresentanteSuccess,
  api: apiRemoverRepresentante,
  postSuccess: function* ({ response, original }) {
    const { id, idPessoaJuridica } = yield select(getPayload);
    yield put(
      actions.mostrarVendedorPessoaJuridicaRequest({
        value: {
          idParticipante: idPessoaJuridica,
          idOportunidade: id,
          tipoPessoa: 'J'
        }
      })
    );
    toast.info('Representante deletado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const removerParticipante = basicFlow({
  actionGenerator: actions.removerParticipanteRequest,
  actionFailure: actions.removerParticipanteFailure,
  actionSuccess: actions.removerParticipanteSuccess,
  api: apiRemoverParticipante,
  postSuccess: function* ({ response, original }) {
    const { id } = yield select(getPayload);
    yield put(actions.listarVendedoresRequest(id));
    toast.info('Participante deletado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const mostrarRepresentate = basicFlow({
  actionGenerator: actions.mostrarRepresentanteRequest,
  actionFailure: actions.mostrarRepresentanteFailure,
  actionSuccess: actions.mostrarRepresentanteSuccess,

  api: apiMostrarRepresentante,
  postSuccess: function* ({ response, original }) {
    const ufs = yield select(dominioSelectors.getUfs);
    const tiposSexo = yield select(dominioSelectors.getTiposSexo);
    const tiposDocumentos = yield select(dominioSelectors.getIiposDocumentos);
    const tiposEstadoCivil = yield select(dominioSelectors.getTiposEstadoCivil);

    const uf = ufs.find((el) => el.codigo === response.data.uf);
    const ufExpedicao = ufs.find(
      (el) => el.codigo === response.data.ufExpedicao
    );
    const tipoSexo = tiposSexo.find((el) => el.id === response.data.tipoSexo);
    const tipoDocumentoIdentidade = tiposDocumentos.find(
      (el) => el.id === response.data.tipoDocumentoIdentidade
    );
    const dataNascimento = formatters.dates.revert(
      response.data.dataNascimento
    );
    const dataExpedicaoConjuge = formatters.dates.revert(
      response.data.dataExpedicaoConjuge
    );
    const dataExpedicao = formatters.dates.revert(response.data.dataExpedicao);
    const tipoEstadoCivil = tiposEstadoCivil.find(
      (el) => el.id === response.data.tipoEstadoCivil
    );

    const formattedResponse = {
      ...response.data,
      uf,
      ufExpedicao,
      tipoSexo,
      tipoDocumentoIdentidade,
      dataNascimento,
      dataExpedicao,
      tipoEstadoCivil,
      dataExpedicaoConjuge
    };

    response.data = formattedResponse;

    yield put(actions.formatarRepresentante(formattedResponse));

    if (original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarVendedores = basicFlow({
  actionGenerator: actions.listarVendedoresRequest,
  actionFailure: actions.listarVendedoresFailure,
  actionSuccess: actions.listarVendedoresSuccess,
  api: apiListarVendedores,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const mostrarVendedorPessoaFisica = basicFlow({
  actionGenerator: actions.mostrarVendedorPessoaFisicaRequest,
  actionFailure: actions.mostrarVendedorPessoaFisicaFailure,
  actionSuccess: actions.mostrarVendedorPessoaFisicaSuccess,
  api: apiMostrarParticipante,
  postSuccess: function* ({ response, original }) {
    const ufs = yield select(dominioSelectors.getUfs);
    const tiposBanco = yield select(dominioSelectors.getBancos);
    const tiposEstadoCivil = yield select(dominioSelectors.getTiposEstadoCivil);
    const tiposSexo = yield select(dominioSelectors.getTiposSexo);
    const tiposDocumentos = yield select(dominioSelectors.getIiposDocumentos);
    const tiposRegimeCasamento = yield select(
      dominioSelectors.getTiposRegimeCasamento
    );

    const uf = ufs.find((el) => el.codigo === response.data.uf);
    const idBanco = tiposBanco.find(
      (el) => el.idBanco === response.data.idBanco
    );

    const ufExpedicao = ufs.find(
      (el) => el.codigo === response.data.ufExpedicao
    );

    const ufExpedicaoConjuge = ufs.find(
      (el) => el.codigo === response.data.ufExpedicaoConjuge
    );

    const tipoEstadoCivil = tiposEstadoCivil.find(
      (el) => el.id === response.data.tipoEstadoCivil
    );

    const tipoEstadoCivilConjuge = tiposEstadoCivil.find(
      (el) => el.id === response.data.tipoEstadoCivilConjuge
    );

    const tipoSexo = tiposSexo.find((el) => el.id === response.data.tipoSexo);

    const tipoDocumentoIdentidadeConjuge = tiposDocumentos.find(
      (el) => el.id === response.data.tipoDocumentoIdentidadeConjuge
    );

    const tipoDocumentoIdentidade = tiposDocumentos.find(
      (el) => el.id === response.data.tipoDocumentoIdentidade
    );

    const tipoRegimeCasamento = tiposRegimeCasamento.find(
      (el) => el.id === response.data.tipoRegimeCasamento
    );

    const dataNascimento = formatters.dates.revert(
      response.data.dataNascimento
    );

    const dataNascimentoConjuge = formatters.dates.revert(
      response.data.dataNascimentoConjuge
    );

    const dataExpedicao = formatters.dates.revert(response.data.dataExpedicao);

    const dataExpedicaoConjuge = formatters.dates.revert(
      response.data.dataExpedicaoConjuge
    );

    const formattedResponse = {
      ...response.data,
      uf,
      ufExpedicao,
      ufExpedicaoConjuge,
      dataNascimento,
      dataNascimentoConjuge,
      dataExpedicao,
      dataExpedicaoConjuge,
      tipoEstadoCivil,
      tipoEstadoCivilConjuge,
      tipoSexo,
      tipoDocumentoIdentidadeConjuge,
      tipoDocumentoIdentidade,
      tipoRegimeCasamento,
      idBanco,
      renda: formatters.numbers.simple(response.data.renda)
    };

    response.data = formattedResponse;

    yield put(actions.formatarPessoaFisica(formattedResponse));

    if (original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const criarVendedorPessoaFisica = basicFlow({
  actionGenerator: actions.criarVendedorPessoaFisicaRequest,
  actionFailure: actions.criarVendedorPessoaFisicaFailure,
  actionSuccess: actions.criarVendedorPessoaFisicaSuccess,
  api: apiCriarParticipante,
  postSuccess: function* ({ response, original, values }) {
    if (original.successCallback) {
      yield original.successCallback(response, original.data);
    }
    yield put(
      routeActions.redirectTo(routes.VENDEDOR_PESSOA_FISICA, {
        id: values.value.idOportunidade,
        idPessoaFisica: response.data.idParticipante
      })
    );
    toast.info('Vendedor cadastrado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const editarVendedorPessoaFisica = basicFlow({
  actionGenerator: actions.editarVendedorPessoaFisicaRequest,
  actionFailure: actions.editarVendedorPessoaFisicaFailure,
  actionSuccess: actions.editarVendedorPessoaFisicaSuccess,
  api: apiEditarParticipante,
  postSuccess: function* ({ response, original, values }) {
    if (original.successCallback) {
      yield original.successCallback(response, original.data);
    }
    yield put(
      routeActions.redirectTo(routes.VENDEDOR_PESSOA_FISICA, {
        id: values.value.idOportunidade,
        idPessoaFisica: values.value.idParticipante
      })
    );
    toast.info('Vendedor editado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const mostrarVendedorPessoaJuridica = basicFlow({
  actionGenerator: actions.mostrarVendedorPessoaJuridicaRequest,
  actionFailure: actions.mostrarVendedorPessoaJuridicaFailure,
  actionSuccess: actions.mostrarVendedorPessoaJuridicaSuccess,
  api: apiMostrarParticipante,
  postSuccess: function* ({ response, original }) {
    const ufs = yield select(dominioSelectors.getUfs);
    const tiposBanco = yield select(dominioSelectors.getBancos);
    const tiposEmpresa = yield select(dominioSelectors.getTiposEmpresa);

    const uf = ufs.find((el) => el.codigo === response.data.uf);
    const idBanco = tiposBanco.find(
      (el) => el.idBanco === response.data.idBanco
    );
    const dataRegistroEmpresa = formatters.dates.revert(
      response.data.dataRegistroEmpresa
    );
    const tipoEmpresa = tiposEmpresa.find(
      (el) => el.value === response.data.tipoEmpresa
    );

    const formattedResponse = {
      ...response.data,
      uf,
      idBanco,
      tipoEmpresa,
      dataRegistroEmpresa,
      faturamentoEmpresa: formatters.numbers.simple(
        response.data.faturamentoEmpresa
      ),
      patrimonioLiquidoEmpresa: formatters.numbers.simple(
        response.data.patrimonioLiquidoEmpresa
      ),
      capitalSocialEmpresa: formatters.numbers.simple(
        response.data.capitalSocialEmpresa
      )
    };

    response.data = formattedResponse;

    yield put(actions.formatarPessoaJuridica(formattedResponse));

    if (original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const criarVendedorPessoaJuridica = basicFlow({
  actionGenerator: actions.criarVendedorPessoaJuridicaRequest,
  actionFailure: actions.criarVendedorPessoaJuridicaFailure,
  actionSuccess: actions.criarVendedorPessoaJuridicaSuccess,
  api: apiCriarParticipante,
  postSuccess: function* ({ response, original, values }) {
    if (original.successCallback) {
      yield original.successCallback(response, original.data);
    }
    yield put(
      routeActions.redirectTo(routes.VENDEDOR_PESSOA_JURIDICA, {
        id: values.value.idOportunidade,
        idPessoaJuridica: response.data.idParticipante
      })
    );
    toast.info('Vendedor cadastrado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const editarVendedorPessoaJuridica = basicFlow({
  actionGenerator: actions.editarVendedorPessoaJuridicaRequest,
  actionFailure: actions.editarVendedorPessoaJuridicaFailure,
  actionSuccess: actions.editarVendedorPessoaJuridicaSuccess,
  api: apiEditarParticipante,
  postSuccess: function* ({ response, original, values }) {
    if (original.successCallback) {
      yield original.successCallback(response, original.data);
    }
    yield put(
      routeActions.redirectTo(routes.VENDEDOR_PESSOA_JURIDICA, {
        id: values.value.idOportunidade,
        idPessoaJuridica: values.value.idParticipante
      })
    );
    toast.info('Vendedor editado com sucesso!');
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const buscarCep = basicFlow({
  actionGenerator: actions.buscarCepRequest,
  actionFailure: actions.buscarCepFailure,
  actionSuccess: actions.buscarCepSuccess,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: 'get',
      isCep: true
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  }
});

function* mostrarPessoaFisicaRouteWatcher() {
  yield routeWatcher(routes.VENDEDOR_PESSOA_FISICA, function* () {
    const { idPessoaFisica, id } = yield select(getPayload);
    if (idPessoaFisica === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.adicionarOportunidade(id));
      return;
    }
    if (!idPessoaFisica) return;
    yield put(actions.limparPessoaFisica());
    yield put(
      actions.mostrarVendedorPessoaFisicaRequest({
        value: {
          idParticipante: idPessoaFisica,
          idOportunidade: id,
          tipoPessoa: 'F'
        }
      })
    );
    yield put(actions.adicionarOportunidade(id));
    yield put(actions.adicionarParticipante(idPessoaFisica));
  });
}

function* mostrarRepresentanteRouteWatcher() {
  yield routeWatcher(routes.VENDEDOR_REPRESENTANTE, function* () {
    const { idRepresentante, id, idPessoaJuridica } = yield select(getPayload);
    if (idRepresentante === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.adicionarOportunidade(id));
      yield put(actions.adicionarParticipante(idPessoaJuridica));
      return;
    }
    if (!idRepresentante) return;
    yield put(actions.limparRepresentante());
    yield put(
      actions.mostrarRepresentanteRequest({
        value: {
          idParticipante: idPessoaJuridica,
          idRepresentante: idRepresentante,
          tipoSituacao: 'A'
        }
      })
    );
    yield put(actions.adicionarOportunidade(id));
    yield put(actions.adicionarParticipante(idPessoaJuridica));
  });
}

function* mostrarPessoaJuridicaRouteWatcher() {
  yield routeWatcher(
    [routes.VENDEDOR_PESSOA_JURIDICA, routes.VENDEDOR_REPRESENTANTE],
    function* () {
      const { idPessoaJuridica, id } = yield select(getPayload);
      if (idPessoaJuridica === 'novo') {
        yield put(actions.desativarModoEdicao());
        yield put(actions.adicionarOportunidade(id));
        return;
      }
      if (!idPessoaJuridica) return;
      yield put(actions.limparPessoaJuridica());
      yield put(
        actions.mostrarVendedorPessoaJuridicaRequest({
          value: {
            idParticipante: idPessoaJuridica,
            idOportunidade: id,
            tipoPessoa: 'J'
          }
        })
      );
      yield put(actions.adicionarOportunidade(id));
      yield put(actions.adicionarParticipante(idPessoaJuridica));
    }
  );
}

function* listarVendedoresRouteWatcher() {
  yield routeWatcher(routes.VENDEDORES, function* () {
    const { id } = yield select(getPayload);
    if (!id) return;
    yield put(actions.listarVendedoresRequest(id));
    yield put(actions.adicionarOportunidade(id));
  });
}

export const sagas = [
  buscarCep.watcher(),
  mostrarVendedorPessoaFisica.watcher(),
  criarVendedorPessoaFisica.watcher(),
  editarVendedorPessoaFisica.watcher(),
  mostrarVendedorPessoaJuridica.watcher(),
  criarVendedorPessoaJuridica.watcher(),
  editarVendedorPessoaJuridica.watcher(),
  listarVendedores.watcher(),
  removerRepresentante.watcher(),
  criarRepresentante.watcher(),
  editarRepresentante.watcher(),
  mostrarRepresentate.watcher(),
  removerParticipante.watcher(),
  mostrarPessoaFisicaRouteWatcher(),
  mostrarPessoaJuridicaRouteWatcher(),
  listarVendedoresRouteWatcher(),
  mostrarRepresentanteRouteWatcher()
];
