import { all } from 'redux-saga/effects';
import { sagas as routeSagas } from './rotas.saga';
import { sagas as authSagas } from './autenticacao.saga';
import { sagas as simulacaoSagas } from './simulacao.saga';
import { sagas as dominiosSagas } from './dominios.saga';
import { sagas as oportunidadesSagas } from './oportunidades.saga';
import { sagas as compradoresSagas } from './compradores.saga';
import { sagas as vendedoresSagas } from './vendedores.saga';
import { sagas as atividadesSagas } from './atividades.saga';
import { sagas as fupSagas } from './fup.saga';
import { sagas as documentosSagas } from './documentos.saga';
import { sagas as minhasAtividadesSagas } from './minhasAtividades.saga';
import { sagas as pesquisaSagas } from './pesquisa.saga';
import { sagas as bancosSagas } from './bancos.saga';
import { sagas as regionaisSagas } from './regionais.saga';
import { sagas as configuracaoDocumentosSagas } from './configuracaoDocumentos.saga';
import { sagas as produtosSagas } from './produtosOperacoes.saga';
import { sagas as produtosEtapasSagas } from './produtosEtapas.saga';
import { sagas as produtosAtividadesSagas } from './produtosAtividades.saga';
import { sagas as produtosAtividadesBancoSagas } from './produtosAtividadesBanco.saga';
import { sagas as parceirosSagas } from './parceiros.saga';
import { sagas as usuariosParceirosSagas } from './usuariosParceiros.saga';
import { sagas as usuariosSagas } from './usuarios.saga';

export default function* sagas() {
  yield all([
    ...routeSagas,
    ...authSagas,
    ...simulacaoSagas,
    ...dominiosSagas,
    ...oportunidadesSagas,
    ...compradoresSagas,
    ...vendedoresSagas,
    ...atividadesSagas,
    ...fupSagas,
    ...documentosSagas,
    ...minhasAtividadesSagas,
    ...pesquisaSagas,
    ...bancosSagas,
    ...regionaisSagas,
    ...configuracaoDocumentosSagas,
    ...produtosSagas,
    ...produtosEtapasSagas,
    ...produtosAtividadesSagas,
    ...produtosAtividadesBancoSagas,
    ...parceirosSagas,
    ...usuariosParceirosSagas,
    ...usuariosSagas
  ]);
}
