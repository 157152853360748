// third party
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PasswordValidator from 'password-validator';
import { NotInterested, Check } from '@mui/icons-material';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import React, { useState } from 'react';
import validators from '../../../utils/validators';
import { trocarSenhaRequest } from '../../../reducers/autenticacao.reducer';
import OutlinedInput from '../../../components/form/OutlinedInput';

// assets

import { Button, Grid, IconButton, InputAdornment, InputLabel, Stack } from '@mui/material';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { styled } from '@mui/system';

const StyledCheck = styled(Check)`
  color: #018e06;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;
const StyledNotInterested = styled(NotInterested)`
  color: red;
  font-size: 17px;
  vertical-align: middle;
  margin-top: -2px;
`;

// eslint-disable-next-line react/prop-types
const ValidationItem = ({ state, item, children }) => (
  <div>
    <h5>
      {!!state.dirty && state.list.indexOf(item) === -1 ? <StyledCheck /> : <StyledNotInterested />} {children}
    </h5>
  </div>
);

const AuthForgotPassword = () => {
  const dispatch = useDispatch();
  const [state, setState] = useState({ dirty: false, list: [] });
  const handlePasswordChange = (evt, setList) => {
    setList({
      dirty: true,
      list: schema.validate(evt.target?.value, { list: true })
    });
  };
  const loading = useSelector((state) => state.auth['trocar-senha'].loading);

  // prettier-ignore
  const schema = new PasswordValidator()
    .is().min(8)                                    // Mínimo de 8 caracteres
    .has().uppercase()                              // Deve ter letra maiúscula
    .has().lowercase()                              // Deve ter letra minúscula
    .has().digits()                                 // Deve ter dígitos
    .has().symbols()                                // Deve ter símbolos
    .has().not().spaces()                           // Não deve ter espaços
    .is().not().oneOf(['Password@', 'Password123@']); // Senhas a evitar

  const rules = {
    password: validators.string({
      required: true,
      custom: {
        rules: (value) => {
          return schema.validate(value) || 'A senha informada não obedece a todas as regras descritas abaixo';
        }
      }
    }),
    password2: validators.string({
      required: true,
      custom: {
        same: (value) => {
          const { password } = formProps.getValues();
          return value === password || 'As senhas devem ser iguais';
        }
      }
    })
  };

  const initialValues = {
    user: ''
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const handleSubmit = (values) => {
    dispatch(trocarSenhaRequest(values));
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="password-login">Senha</InputLabel>
              <OutlinedInput
                fullWidth
                id="-password-login"
                type={showPassword ? 'text' : 'password'}
                name="password"
                formProps={formProps}
                onChange={(v) => handlePasswordChange(v, setState)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Digitar a nova senha"
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <InputLabel htmlFor="password-login">Repetir Senha</InputLabel>
              <OutlinedInput
                fullWidth
                id="-password-login-2"
                type={showPassword ? 'text' : 'password'}
                name="password2"
                formProps={formProps}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder="Repetir a senha"
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ marginBottom: '20px', marginTop: '10px' }}>Sua nova senha deve respeitar as seguintes regras:</h4>
            <div>
              <ValidationItem state={state} item={'min'}>
                Ter no mínimo 8 caracteres
              </ValidationItem>
              <ValidationItem state={state} item={'digits'}>
                Ter ao menos um número
              </ValidationItem>
              <ValidationItem state={state} item={'symbols'}>
                Ter ao menos um caracter especial
              </ValidationItem>
            </div>
          </Grid>
          <Grid item xs={12}>
            <AnimateButton>
              <Button disableElevation disabled={loading} fullWidth size="large" type="submit" variant="contained" color="primary">
                Enviar
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AuthForgotPassword;
