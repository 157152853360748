import React from 'react';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { connectRoutes, NOT_FOUND } from 'redux-first-router';
import restoreScroll from 'redux-first-router-restore-scroll';
import { getCurrentRoute } from './selectors/routes.selectors';
import {
  getPerfil,
  isUsuarioLogado,
  solicitouToken
} from './selectors/usuario.selectors';
import {
  types as routes,
  actions as routeActions
} from './reducers/rotas.actions';
import * as pages from './pages/pages';
import { toast } from 'react-toastify';

const routePages = {
  [NOT_FOUND]: {
    component: pages.LoginPage
  },
  [routes.LOGIN]: {
    component: pages.LoginPage
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.ForgotPassword
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenha
  },
  [routes.TOKEN]: {
    component: pages.TokenPage,
    restricted: 'token'
  },
  [routes.OPORTUNIDADES]: {
    component: pages.Oportunidades,
    restricted: true
  },
  [routes.SIMULACAO]: {
    component: pages.Simulacao,
    restricted: true
  },
  [routes.COMPRADORES]: {
    component: pages.Compradores,
    restricted: true
  },
  [routes.PESSOA_FISICA]: {
    component: pages.PessoaFisica,
    restricted: true
  },
  [routes.PESSOA_JURIDICA]: {
    component: pages.PessoaJuridica,
    restricted: true
  },
  [routes.REPRESENTANTE]: {
    component: pages.Representante,
    restricted: true
  },
  [routes.OPORTUNIDADE]: {
    component: pages.Oportunidade,
    restricted: true
  },
  [routes.IMOVEL]: {
    component: pages.Imovel,
    restricted: true
  },
  [routes.INTERVENIENTE_QUITANTE]: {
    component: pages.IntervenienteQuitante,
    restricted: true
  },
  [routes.VENDEDORES]: {
    component: pages.Vendedores,
    restricted: true
  },
  [routes.VENDEDOR_PESSOA_FISICA]: {
    component: pages.VendedorPessoaFisica,
    restricted: true
  },
  [routes.VENDEDOR_PESSOA_JURIDICA]: {
    component: pages.VendedorPessoaJuridica,
    restricted: true
  },
  [routes.VENDEDOR_REPRESENTANTE]: {
    component: pages.VendedorRepresentante,
    restricted: true
  },
  [routes.ATIVIDADES]: {
    component: pages.Atividades,
    restricted: true
  },
  [routes.FUP]: {
    component: pages.Fup,
    restricted: true
  },
  [routes.DOCUMENTOS]: {
    component: pages.Documentos,
    restricted: true
  },
  [routes.DOCUMENTO]: {
    component: pages.Documento,
    restricted: true
  },
  [routes.SIMULACAOBANCO]: {
    component: pages.SimulacaoBanco,
    restricted: true
  },
  [routes.NOVASIMULACAO]: {
    component: pages.SimulacaoBanco,
    restricted: true
  },
  [routes.MINHAS_ATIVIDADES]: {
    component: pages.MinhasAtividades,
    restricted: ['AL', 'CS', 'UP']
  },
  [routes.PESQUISA]: {
    component: pages.Pesquisa,
    restricted: true
  },
  [routes.BANCOS]: {
    component: pages.Bancos,
    restricted: true
  },
  [routes.BANCO]: {
    component: pages.Banco,
    restricted: true
  },
  [routes.REGIONAIS]: {
    component: pages.Regionais,
    restricted: true
  },
  [routes.REGIONAL]: {
    component: pages.Regional,
    restricted: true
  },
  [routes.REGIONAL_PARCEIROS]: {
    component: pages.RegionalParceiros,
    restricted: true
  },
  [routes.CONFIGURACAO_DOCUMENTOS]: {
    component: pages.ConfiguracaoDocumentos,
    restricted: true
  },
  [routes.CONFIGURACAO_DOCUMENTO]: {
    component: pages.ConfiguracaoDocumento,
    restricted: true
  },
  [routes.PRODUTOS]: {
    component: pages.Produtos,
    restricted: true
  },
  [routes.PRODUTO]: {
    component: pages.Produto,
    restricted: true
  },
  [routes.PRODUTO_ETAPAS]: {
    component: pages.ProdutoEtapas,
    restricted: true
  },
  [routes.PRODUTO_ETAPA]: {
    component: pages.ProdutoEtapa,
    restricted: true
  },
  [routes.PRODUTO_ATIVIDADES]: {
    component: pages.ProdutoAtividades,
    restricted: true
  },
  [routes.PRODUTO_ATIVIDADE]: {
    component: pages.ProdutoAtividade,
    restricted: true
  },
  [routes.PRODUTO_ATIVIDADES_BANCO]: {
    component: pages.ProdutoAtividadesBanco,
    restricted: true
  },
  [routes.PRODUTO_ATIVIDADE_BANCO]: {
    component: pages.ProdutoAtividadeBanco,
    restricted: true
  },
  [routes.PARCEIROS]: {
    component: pages.Parceiros,
    restricted: true
  },
  [routes.PARCEIRO]: {
    component: pages.Parceiro,
    restricted: true
  },
  [routes.PARCEIRO_DOCUMENTO]: {
    component: pages.ParceiroDocumentos,
    restricted: true
  },
  [routes.PARCEIRO_FUP]: {
    component: pages.ParceiroFup,
    restricted: true
  },
  [routes.USUARIOS_PARCEIROS]: {
    component: pages.UsuariosParceiros,
    restricted: true
  },
  [routes.USUARIOS_PARCEIRO]: {
    component: pages.UsuariosParceiro,
    restricted: true
  },
  [routes.USUARIOS_PARCEIRO_DOCUMENTO]: {
    component: pages.UsuariosParceiroDocumentos,
    restricted: true
  },
  [routes.USUARIOS_PARCEIRO_FUP]: {
    component: pages.UsuariosParceiroFup,
    restricted: true
  },
  [routes.USUARIOS]: {
    component: pages.Usuarios,
    restricted: true
  },
  [routes.USUARIO]: {
    component: pages.Usuario,
    restricted: true
  },
  [routes.FUNIL_DE_VENDAS]: {
    component: pages.FunilDeVendas,
    restricted: true
  }
};

export const routePaths = {
  [routes.LOGIN]: '/',
  [routes.ESQUECI_SENHA]: '/esqueci-senha',
  [routes.TROCAR_SENHA]: '/trocar-senha/:token',
  [routes.TOKEN]: '/token/',
  [routes.OPORTUNIDADES]: '/oportunidades',
  [routes.SIMULACAO]: '/simulacao',
  [routes.PESSOA_FISICA]:
    '/oportunidades/:id/compradores/pessoa-fisica/:idPessoaFisica',
  [routes.PESSOA_JURIDICA]:
    '/oportunidades/:id/compradores/pessoa-juridica/:idPessoaJuridica',
  [routes.REPRESENTANTE]:
    '/oportunidades/:id/compradores/pessoa-juridica/:idPessoaJuridica/representante/:idRepresentante',
  [routes.OPORTUNIDADE]: '/oportunidade/:id',
  [routes.VENDEDOR_PESSOA_FISICA]:
    '/oportunidades/:id/vendedores/pessoa-fisica/:idPessoaFisica',
  [routes.VENDEDOR_PESSOA_JURIDICA]:
    '/oportunidades/:id/vendedores/pessoa-juridica/:idPessoaJuridica',
  [routes.VENDEDOR_REPRESENTANTE]:
    '/oportunidades/:id/vendedores/pessoa-juridica/:idPessoaJuridica/representante/:idRepresentante',
  [routes.DOCUMENTO]: '/oportunidades/:id/documentos/:idDocumento',
  [routes.NOVASIMULACAO]: '/oportunidade/:id/simulacao/new',
  [routes.SIMULACAOBANCO]: '/oportunidade/:id/simulacao/:idSimulacao',
  [routes.MINHAS_ATIVIDADES]: '/oportunidades/minhas-atividades',
  [routes.PESQUISA]: '/pesquisa/:termo',
  [routes.BANCOS]: '/configuracoes/bancos',
  [routes.BANCO]: '/configuracoes/banco/:idBanco',
  [routes.REGIONAIS]: '/configuracoes/regionais',
  [routes.REGIONAL]: '/configuracoes/regional/:idRegional',
  [routes.REGIONAL_PARCEIROS]: '/configuracoes/regional/:idRegional/parceiros',
  [routes.CONFIGURACAO_DOCUMENTOS]: '/configuracoes/documentos',
  [routes.CONFIGURACAO_DOCUMENTO]: '/configuracoes/documento/:idDocumento',
  [routes.PRODUTOS]: '/configuracoes/operacoes',
  [routes.PRODUTO]: '/configuracoes/operacao/:idOperacao',
  [routes.PRODUTO_ETAPAS]: '/configuracoes/operacao/:idOperacao/etapas',
  [routes.PRODUTO_ETAPA]: '/configuracoes/operacao/:idOperacao/etapa/:idEtapa',
  [routes.PRODUTO_ATIVIDADES]:
    '/configuracoes/operacao/:idOperacao/etapa/:idEtapa/atividades',
  [routes.PRODUTO_ATIVIDADE]:
    '/configuracoes/operacao/:idOperacao/etapa/:idEtapa/atividade/:idAtividade',
  [routes.PRODUTO_ATIVIDADES_BANCO]:
    '/configuracoes/operacao/:idOperacao/etapa/:idEtapa/atividade/:idAtividade/bancos',
  [routes.PRODUTO_ATIVIDADE_BANCO]:
    '/configuracoes/operacao/:idOperacao/etapa/:idEtapa/atividade/:idAtividade/banco/:idAtividadeBanco',
  [routes.PARCEIROS]: '/configuracoes/parceiros',
  [routes.PARCEIRO]: '/configuracoes/parceiro/:idParceiro',
  [routes.PARCEIRO_DOCUMENTO]: '/configuracoes/parceiro/:idParceiro/documentos',
  [routes.PARCEIRO_FUP]: '/configuracoes/parceiro/:idParceiro/fup',
  [routes.USUARIOS_PARCEIROS]:
    '/configuracoes/parceiro/:idParceiro/usuarios-parceiros',
  [routes.USUARIOS_PARCEIRO]:
    '/configuracoes/parceiro/:idParceiro/usuario-parceiro/:idUsuarioParceiro',
  [routes.USUARIOS_PARCEIRO_DOCUMENTO]:
    '/configuracoes/parceiro/:idParceiro/usuario-parceiro/:idUsuarioParceiro/documentos',
  [routes.USUARIOS_PARCEIRO_FUP]:
    '/configuracoes/parceiro/:idParceiro/usuario-parceiro/:idUsuarioParceiro/fup',
  [routes.USUARIOS]: '/configuracoes/usuarios',
  [routes.USUARIO]: '/configuracoes/usuario/:idUsuario',
  [routes.FUNIL_DE_VENDAS]: '/funil-de-vendas'
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: '/erro',
  shouldPerformInitialDispatch: true,
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (route.restricted === 'token') {
        if (solicitouToken(state)) {
          dispatch(routeActions.rejectTo(routes.LOGIN));
        }
      } else {
        if (!isUsuarioLogado(state)) {
          dispatch(routeActions.rejectTo(routes.LOGIN));
        } else {
          const perfil = getPerfil(state);
          if (!canAccessRestrictedRoute(route, perfil)) {
            toast.error('Acesso Negado');
            dispatch(routeActions.rejectTo(routes.LOGIN));
          }
        }
      }
    }
  }
});

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

const canAccessRestrictedRoute = (route, perfil) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (typeof route.restricted === 'function') {
    return route.restricted(perfil);
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export { reducer, middleware, enhancer };
export default Container;
