import { createSelector } from '../../node_modules/reselect/dist/reselect';

const dominiosState = (state) => state.dominios;

const getTiposImovel = createSelector(
  dominiosState,
  (state) => state.tiposImovel
);

const getTiposEmpresa = createSelector(
  dominiosState,
  (state) => state.tiposEmpresa
);

const getTiposSexo = createSelector(dominiosState, (state) => state.tiposSexo);

const getIiposDocumentos = createSelector(
  dominiosState,
  (state) => state.tiposDocumentos
);

const getTiposEstadoCivil = createSelector(
  dominiosState,
  (state) => state.tiposEstadoCivil
);

const getTiposRegimeCasamento = createSelector(
  dominiosState,
  (state) => state.tiposRegimeCasamento
);

const getBancos = createSelector(
  dominiosState,
  (state) => state.listarBancos.data
);

const getUfs = createSelector(
  dominiosState,
  (state) => state.unidadesFederativa
);

const getTiposPessoas = createSelector(
  dominiosState,
  (state) => state.tiposPessoas
);

const getTiposUtilizacao = createSelector(
  dominiosState,
  (state) => state.tiposUtilizacao
);

export {
  getTiposImovel,
  getTiposEmpresa,
  getTiposSexo,
  getIiposDocumentos,
  getTiposEstadoCivil,
  getTiposRegimeCasamento,
  getBancos,
  getUfs,
  getTiposPessoas,
  getTiposUtilizacao
};
