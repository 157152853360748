import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  perfil: null,
  isAuthenticated: false,
  loading: false,
  error: null,
  'trocar-senha': {
    token: null,
    loading: false,
    error: null,
    type: null,
    valid: false
  },
  'esqueci-senha': {
    loading: false,
    error: null
  },
  token: {
    loading: false,
    submitting: false,
    dataLimiteToken: '',
    tempoMsg: ''
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.isAuthenticated = !!action.payload?.response?.data?.usuario;
      state.token = {
        dataLimiteToken: action.payload?.response?.data['data_expiracao'] ?? '',
        tempoMsg: action.payload?.response?.data['tempo_mensagem'] ?? '',
        usuario: action.payload?.response?.data['usuario'] ?? ''
      };
      state.user = action.payload?.response?.data?.usuario?.nome ?? '';
      state.perfil = action.payload?.response?.data?.usuario?.perfil ?? '';
      state.idParceiro = action.payload?.response?.data?.usuario?.idParceiro;
      state.idRegional = action.payload?.response?.data?.usuario?.regional;
      state.idUsuarioParceiro =
        action.payload?.response?.data?.usuario?.idUsuarioParceiro;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    enviarNovoTokenRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    enviarNovoTokenSuccess: (state, action) => {
      state.loading = false;
      state.token = {
        dataLimiteToken: action.payload?.response?.data['data_expiracao'] ?? '',
        tempoMsg: action.payload?.response?.data['tempo_mensagem'] ?? ''
      };
    },
    enviarNovoTokenFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    logout: (state) => {
      Object.assign(state, initialState);
    },
    forgotPasswordRequest: (state) => {
      state['esqueci-senha'].loading = true;
      state['esqueci-senha'].error = null;
    },
    forgotPasswordSuccess: (state) => {
      state['esqueci-senha'].loading = false;
      state['esqueci-senha'].error = null;
    },
    forgotPasswordFailure: (state, action) => {
      state['esqueci-senha'].loading = false;
      state['esqueci-senha'].error = action.payload;
    },
    validarTokenSenhaRequest: (state, action) => {
      state['trocar-senha'].loading = true;
      state['trocar-senha'].token = action.payload.token;
    },
    validarTokenSenhaFailure: (state, action) => {
      state['trocar-senha'].loading = false;
      state['trocar-senha'].error = action.payload;
    },
    validarTokenSenhaSuccess: (state, action) => {
      state['trocar-senha'].loading = false;
      state['trocar-senha'].type = action.payload.response.data.type;
      state['trocar-senha'].valid = action.payload.response.data.valid;
    },
    trocarSenhaRequest: (state) => {
      state['trocar-senha'].loading = true;
    },
    trocarSenhaSuccess: (state, action) => {
      state['trocar-senha'].loading = false;
      state['trocar-senha'].type = action.payload.response.data.type;
      state['trocar-senha'].valid = action.payload.response.data.valid;
    },
    trocarSenhaFailure: (state, action) => {
      state['trocar-senha'].loading = false;
      state['trocar-senha'].error = action.payload;
    },
    validarTokenRequest: (state, action) => {
      state.token.submitting = true;
      state.token.token = action.payload.token;
    },
    validarTokenFailure: (state, action) => {
      state.token.submitting = false;
      state.token.error = action.payload;
    },
    validarTokenSuccess: (state, action) => {
      state.token.submitting = false;
      state.isAuthenticated = !!action.payload?.response?.data?.usuario;
      state.user = action.payload?.response?.data?.usuario?.nome ?? '';
      state.perfil = action.payload?.response?.data?.usuario?.perfil ?? '';
    }
  }
});

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  logout,
  forgotPasswordRequest,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  trocarSenhaRequest,
  trocarSenhaSuccess,
  trocarSenhaFailure,
  validarTokenFailure,
  validarTokenRequest,
  validarTokenSuccess,
  validarTokenSenhaFailure,
  validarTokenSenhaRequest,
  validarTokenSenhaSuccess,
  enviarNovoTokenRequest,
  enviarNovoTokenSuccess,
  enviarNovoTokenFailure
} = authSlice.actions;

export default authSlice.reducer;
