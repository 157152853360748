import React from 'react';
import { Box, Tabs, Tab, Typography, tabsClasses } from '@mui/material';
import {
  actions as routeActions,
  types as routes
} from '../../reducers/rotas.actions';
import {
  alterarMenuSelecionado,
  oportunidadeImovelIqRequest
} from '../../reducers/oportunidade.reducer';
import { useDispatch } from 'react-redux';

const Navbar = ({ idOportunidade, menuSelecionado }) => {
  const menuItems = [
    {
      titulo: 'RESUMO',
      rota: ''
    },
    {
      titulo: 'COMPRADORES',
      rota: ''
    },
    {
      titulo: 'VENDEDORES',
      rota: ''
    },
    {
      titulo: 'IQ',
      rota: ''
    },
    {
      titulo: 'IMÓVEL',
      rota: ''
    },
    {
      titulo: 'DOCUMENTOS',
      rota: ''
    },
    {
      titulo: 'ATIVIDADES',
      rota: ''
    },
    {
      titulo: 'FUP',
      rota: ''
    }
  ];
  //const [value, setValue] = React.useState(0);

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    //setValue(newValue);
    dispatch(alterarMenuSelecionado(newValue));

    if (newValue === 3) {
      dispatch(oportunidadeImovelIqRequest(idOportunidade));
    }
    if (newValue === 4) {
      dispatch(oportunidadeImovelIqRequest(idOportunidade));
    }

    if (menuItems.at(newValue).rota) {
      dispatch(
        routeActions.redirectTo(menuItems.at(newValue).rota, {
          id: idOportunidade
        })
      );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={menuSelecionado ?? 0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          borderTop: '1px solid #262626',
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-around'
          },
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        {menuItems.map((item, index) => (
          <Tab
            key={index}
            label={<Typography variant="subtitle2">{item.titulo}</Typography>}
            sx={{
              '&:hover': {
                backgroundColor: '#f0f0f0'
              }
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default Navbar;
