import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  fups: {
    loading: false,
    error: false,
    data: []
  }
};

const FupsSlice = createSlice({
  name: 'fups',
  initialState,
  reducers: {
    listarFupsRequest: (state) => {
      state.fups.loading = true;
      state.fups.error = null;
      state.fups.data = [];
    },
    listarFupsSuccess: (state, action) => {
      state.fups.loading = false;
      state.fups.error = false;
      state.fups.data = action.payload.response.data;
    },
    listarFupsFailure: (state, action) => {
      state.fups.loading = false;
      state.fups.error = action.payload;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    criarFupRequest: (state, action) => {
      state.fups.loading = true;
      state.fups.loading = true;
      state.fups.error = null;
    },
    criarFupSuccess: (state, action) => {
      state.fups.loading = false;
      state.fups.loading = false;
      state.fups.error = false;
    },
    criarFupFailure: (state, action) => {
      state.fups.loading = false;
      state.fups.loading = false;
      state.fups.error = action.payload;
    },
    reverterFups: (state, action) => {
      state.fups.data = state.fups.data.reverse();
    }
  }
});

export const {
  listarFupsRequest,
  listarFupsSuccess,
  listarFupsFailure,
  adicionarOportunidade,
  criarFupRequest,
  criarFupSuccess,
  criarFupFailure,
  adicionarEtapa,
  reverterFups
} = FupsSlice.actions;

export default FupsSlice.reducer;
