import React, { useState } from 'react';
import { Stack, InputLabel, Grid, Typography } from '@mui/material';
import OutlinedInput from 'components/form/OutlinedInput';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';

const DadosFup = ({ formProps, disabled }) => {
  const [comentario, setComentario] = useState('');

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Stack spacing={0}>
          <InputLabel htmlFor="titulo">Título</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="titulo"
            name="titulo"
            placeholder="Título"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={12} md={12} sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="comentario">Comentário</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="comentario"
            name="comentario"
            placeholder="Comentário sobre o Processo"
            fullWidth
            required
            multiline
            formProps={formProps}
            rows={5}
            inputProps={{ maxLength: 4000 }}
            value={comentario}
            onChange={handleComentarioChange}
          />
          <Typography variant="body2" color="textSecondary" align="right">
            {comentario.length}/{4000}
          </Typography>
        </Stack>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}
        >
          <AnimateButton>
            <Button
              disabled={disabled}
              sx={{ maxWidth: '400px' }}
              disableElevation
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              color="primary"
            >
              INCLUIR COMENTÁRIO
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DadosFup;
