import React from 'react';
import { Stack, Grid } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import Switch from 'components/form/Switch';

const OpcoesDeGeracao = ({ formProps, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagCompradorPF">Comprador PF?</InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagCompradorPF"
            id="flagCompradorPF"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagConjugeComprador">
            Cônjuge Comprador?
          </InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagConjugeComprador"
            id="flagConjugeComprador"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagCompradorPJ">Comprador PJ?</InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagCompradorPJ"
            id="flagCompradorPJ"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="representanteComprador">
            Representante Comprador?
          </InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagRepresentanteComprador"
            id="flagRepresentanteComprador"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagVendedorPF">Vendedor PF?</InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagVendedorPF"
            id="flagVendedorPF"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagConjugeVendedor">
            Cônjuge Vendedor?
          </InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagConjugeVendedor"
            id="flagConjugeVendedor"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagVendedorPJ">Vendedor PJ?</InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagVendedorPJ"
            id="flagVendedorPJ"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagRepresentanteVendedor">
            Representante Vendedor?
          </InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagRepresentanteVendedor"
            id="flagRepresentanteVendedor"
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagImovel">Imóvel?</InputLabel>
          <Switch
            formProps={formProps}
            name="flagImovel"
            id="flagImovel"
            disabled={disabled}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={1}>
          <InputLabel htmlFor="flagIntervenienteQuitante">
            Interveniente Quitante?
          </InputLabel>
          <Switch
            disabled={disabled}
            formProps={formProps}
            name="flagIntervenienteQuitante"
            id="flagIntervenienteQuitante"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default OpcoesDeGeracao;
