import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { Box, Grid, useMediaQuery } from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import FormCard from 'components/FormCard';
import MainLayout from '../../layout/MainLayout';
import validators from '../../utils/validators';
import { useForm } from 'react-hook-form';
import Operacao from './Operacao';
import Cliente from './Cliente';
import Financiamento from './Financiamento';
import Bancos from './Bancos';
import AnimateButton from '../../components/@extended/AnimateButton';
import Button from '../../components/Button';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { simulacaoRequest } from '../../reducers/simulacao.reducer';
import Toast from '../../components/Toast';

// ==============================|| SAMPLE PAGE ||============================== //

const Simulacao = () => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario);
  const submitting = useSelector((state) => state.simulacao.submitting);

  const initialValues = {
    operacao: '',
    parceiro: usuario.idParceiro ?? '',
    regional: usuario.idRegional ?? '',
    usuarioParceiro: usuario.idUsuarioParceiro ? usuario.id : '',
    consultor: '',
    analista: '',

    cpfCnpj: '',
    nome: '',
    rendaTotal: '',
    utilizaFgtsSimulacao: false,
    dataNascimento: '',
    tipoEstadoCivil: '',
    cpfConjuge: '',
    nomeConjuge: '',
    rendaConjuge: '',
    fgCompoeRenda: false,
    dataNascimentoConjuge: '',

    tipoImovel: '',
    usoImovel: '',
    uf: '',
    valorImovel: '',
    valorFinanciamento: '',
    tipoEstadoCivilConjuge: '',
    prazo: '',
    codigoSistemaAmortizacaoBanco: '',
    fgFinanciarDespesas: false,
    situacaoImovel: ''
  };

  const [vlImovel, setVlImovel] = React.useState(0);
  const [operacao, setOperacao] = React.useState(0);
  const [conjuge, setConjuge] = React.useState(false);
  const { data } = useSelector((state) => state.dominios.listarBancos);

  const rules = {
    operacao: validators.string({ required: true }),
    parceiro: validators.string({ required: true }),
    regional: validators.string({ required: true }),
    usuarioParceiro: validators.string({ required: true }),

    cpfCnpj: validators.cpfCnpj({ required: true }),
    nome: validators.string({ required: true }),
    rendaTotal: validators.number({ required: true, min: 0 }),
    dataNascimento: validators.date({ required: true }),
    tipoEstadoCivilConjuge: validators.string({
      custom: {
        rules: (value) => {
          return !conjuge || !!value || `Campo obrigatório`;
        }
      }
    }),
    tipoEstadoCivil: validators.string({ required: true }),
    cpfConjuge: validators.cpfCnpj({
      custom: {
        rules: (value) => {
          return !conjuge || !!value || `Campo obrigatório`;
        }
      }
    }),
    nomeConjuge: validators.string({
      custom: {
        rules: (value) => {
          return !conjuge || !!value || `Campo obrigatório`;
        }
      }
    }),
    rendaConjuge: validators.number({
      custom: {
        rules: (value) => {
          return !conjuge || !!value || `Campo obrigatório`;
        }
      }
    }),
    dataNascimentoConjuge: validators.date({
      custom: {
        rules: (value) => {
          return !conjuge || !!value || `Campo obrigatório`;
        }
      }
    }),

    tipoImovel: validators.string({ required: true }),
    usoImovel: validators.string({ required: true }),
    uf: validators.string({ required: true }),
    valorImovel: validators.number({ required: true, min: 0 }),
    valorFinanciamento: validators.number({
      required: true,
      min: 0,
      custom: {
        rules: (value) => {
          return (
            value <= vlImovel || `Valor deve ser menor/igual ao valor do imóvel`
          );
        }
      }
    }),
    prazo: validators.number({
      required: true,
      custom: {
        rules: (value) => {
          if (operacao === 2) {
            return (
              (value >= 20 && value <= 240) ||
              `Prazo deve ser um número entre 20 e 240`
            );
          }
          return (
            (value >= 20 && value <= 420) ||
            `Prazo deve ser um número entre 20 e 420`
          );
        }
      }
    }),
    codigoSistemaAmortizacaoBanco: validators.string({ required: true }),
    situacaoImovel: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const handleSubmit = (values) => {
    let temSimulacao = false;
    for (const b of values.bancos) {
      if (b.fgSimular === true) {
        b.id = data.filter((i) => i.flagSimulacao === 'S')[
          values.bancos.indexOf(b)
        ].idBanco;
        temSimulacao = true;
        b.fgSimular = 'S';
        if (
          ((b.agencia && (!b.conta || !b.digito)) ||
            (b.conta && (!b.agencia || !b.digito))) &&
          b.id === 45
        ) {
          Toast(
            'error',
            'É necessario preencher os dados da conta para cada banco marcado para simulação'
          );
          return '';
        }
      } else {
        b.fgSimular = 'N';
      }
    }
    if (!temSimulacao) {
      Toast(
        'error',
        'É necessario selecionar ao menos um dos bancos para prosseguir'
      );
    } else {
      data
        .filter((i) => i.flagSimulacao === 'S')
        .map((i, index) => (values.bancos[index].id = i.idBanco));
      values.regional =
        typeof values.regional === 'object'
          ? values.regional
          : { idRegional: usuario.idRegional };
      values.parceiro =
        typeof values.parceiro === 'object'
          ? values.parceiro
          : { idParceiro: usuario.idParceiro };
      values.usuarioParceiro =
        typeof values.usuarioParceiro === 'object'
          ? values.usuarioParceiro
          : { idUsuarioParceiro: usuario.idUsuarioParceiro };
      values.fgFinanciarDespesas = values.fgFinanciarDespesas ? 'S' : 'N';
      dispatch(simulacaoRequest(values));
    }
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          title="Nova Oportunidade"
          border={false}
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DA OPERAÇÃO">
                <Operacao
                  formProps={formProps}
                  setOperacao={setOperacao}
                  isSmallScreen={isSmallScreen}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="INFORMAÇÕES DO CLIENTE">
                <Cliente
                  formProps={formProps}
                  conjuge={conjuge}
                  setConjuge={setConjuge}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="INFORMAÇÕES DO FINANCIAMENTO">
                <Financiamento
                  formProps={formProps}
                  setVlImovel={setVlImovel}
                />
              </FormCard>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row', // change direction based on screen size
              justifyContent: 'space-between',
              gap: 2,
              mt: 2.2
            }}
          >
            <Bancos formProps={formProps} banco={'Todos'} />
          </Box>
        </MainCard>
        <Grid container style={{ marginTop: 20, justifyContent: 'end' }}>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={submitting}
                isLoading={submitting}
              >
                ENVIAR PROPOSTA
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Simulacao;
