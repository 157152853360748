// project import
import dashboard from './dashboard';
import support from './support';
import simulacao from './simulacao';
import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [simulacao, dashboard, support]
};

export default menuItems;
