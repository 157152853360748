import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';

const FloatTextField = forwardRef(({ decimalScale = 2, onChange, ...other }, ref) => (
  <NumberFormat
    {...other}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange(values.floatValue);
    }}
    thousandSeparator={'.'}
    decimalSeparator={','}
    decimalScale={decimalScale}
    fixedDecimalScale={true}
  />
));

export default FloatTextField;
