// third party
import { useSelector } from 'react-redux';
import React from 'react';

// assets
import { Grid, Stack } from '@mui/material';
import OutlinedInput from '../../../components/form/OutlinedInput';
import CurrencyTextField from '../../../components/form/CurrencyTextField';
import Autocomplete from '../../../components/form/Autocomplete';
import InputLabel from '../../../components/form/InputLabel';

const Financiamento = ({ formProps, loading }) => {
  const tipoImoveis = useSelector((state) => state.dominios.tipoImovel);
  const utilizacaoImoveis = useSelector(
    (state) => state.dominios.utilizacaoImovel
  );
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  return (
    <Grid container spacing={2} sx={{p:2}}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`tipoImovel`} loading={loading}>Tipo do Imóvel</InputLabel>
          <Autocomplete
            id="tipoImovel"
            name="tipoImovel"
            placeholder="Selecione o Tipo do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tipoImoveis.data}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`usoImovel`} loading={loading}>Utilização do Imóvel</InputLabel>
          <Autocomplete
            id="usoImovel"
            name="usoImovel"
            placeholder="Selecione o Uso do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={utilizacaoImoveis.data}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`uf`} loading={loading}>UF do Imóvel</InputLabel>
          <Autocomplete
            id="uf"
            name="uf"
            placeholder="Selecione a UF do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`valorImovel`} loading={loading}>Valor do Imóvel</InputLabel>
          <OutlinedInput
            id="valorImovel"
            name="valorImovel"
            placeholder="Digite o Valor do Imóvel"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`valorFinanciamento`} loading={loading}>
            Valor do Financiamento
          </InputLabel>
          <OutlinedInput
            id="valorFinanciamento"
            name="valorFinanciamento"
            placeholder="Digite o Valor do Financiamento"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`prazo`} loading={loading}>
            Prazo de Pagamento (em meses)
          </InputLabel>
          <OutlinedInput
            id="prazo"
            name="prazo"
            placeholder="Digite o Prazo"
            fullWidth
            formProps={formProps}
            loading={loading}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Financiamento;
