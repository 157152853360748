import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Box } from '@mui/material';
import {
  DeleteOutlined,
  EditOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import formatters from 'utils/formatters';
import { DataGrid } from '../../../components/grid/DataGrid';
import * as actions from 'reducers/compradores.reducer';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '../../../../node_modules/@mui/material/index';
import useTheme from '@mui/system/useTheme';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { opcoesFiltro } from 'utils/opcoesFiltro';

const Compradores = () => {
  const idOportunidade = useSelector(
    (state) => state.compradores.idOportunidade
  );

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loading = useSelector((state) => state.compradores.loading);

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleDeleteClick = () => {
      dispatch(
        actions.removerParticipanteRequest({
          value: { id: selectedRowData[0].idParticipante }
        })
      );
      setOpen(false);
    };

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        if (selectedRowData[0].tipoPessoa === 'F') {
          dispatch(
            routeActions.redirectTo(routes.PESSOA_FISICA, {
              idPessoaFisica: selectedRowData[0].idParticipante,
              id: selectedRowData[0].idOportunidade
            })
          );
        } else {
          dispatch(
            routeActions.redirectTo(routes.PESSOA_JURIDICA, {
              idPessoaJuridica: selectedRowData[0].idParticipante,
              id: selectedRowData[0].idOportunidade
            })
          );
        }
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Comprador</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o comprador{' '}
              {selectedRowData[0]?.nomeParticipante}? Ao excluir um comprador,
              não será mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleNewPessoaFisica}
            startIcon={<UserAddOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Pessoa Física
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleNewPessoaJuridica}
            startIcon={<UserAddOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Pessoa Jurídica
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Excluir
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  const columns = [
    {
      field: 'cpfCnpj',
      headerName: 'CPF/CNPJ',
      flex: 1,
      valueFormatter: (value) => {
        return formatters.strings.cpfCnpj(
          value,
          value.length === 11 ? 'F' : 'J'
        );
      }
    },
    {
      field: 'nomeParticipante',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'tipoPessoa',
      headerName: 'Tipo',
      flex: 1,
      valueFormatter: (value) => {
        return value === 'F' ? 'Pessoa Física' : 'Pessoa Jurídica';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'F', nome: 'Pessoa Física' },
              { codigo: 'J', nome: 'Pessoa Jurídica' }
            ])
        }
      ]
    },
    {
      field: 'celular',
      headerName: 'Celular',
      type: 'number',
      flex: 1,
      valueFormatter: (value) => {
        if (value) return formatters.numbers.formatPhone(value, true);
      }
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'number',
      flex: 1
    }
  ];

  const dispatch = useDispatch();
  const compradores = useSelector((state) => state.compradores.data);

  const handleNewPessoaFisica = () => {
    dispatch(
      routeActions.redirectTo(routes.PESSOA_FISICA, {
        idPessoaFisica: 'novo',
        id: idOportunidade
      })
    );
  };

  const handleNewPessoaJuridica = () => {
    dispatch(
      routeActions.redirectTo(routes.PESSOA_JURIDICA, {
        idPessoaJuridica: 'novo',
        id: idOportunidade
      })
    );
  };

  return (
    <>
      <Box sx={{ marginTop: '10px', width: '100%' }}>
        <DataGrid
          rows={compradores}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[100]}
          disableSelectionOnClick
          autoHeight={false}
          infiniteScroll
          hideFooterPagination
          getRowId={(row) => row.idParticipante}
          toolbar={CustomToolbar}
          noLineText="Nenhum comprador cadastrado"
        />
      </Box>
    </>
  );
};

export default Compradores;
