import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: '',
  loading: false,
  error: '',
  submitting: false,
  list: {
    loading: false,
    error: false,
    data: ''
  },
  situacao: [
    {id: "P", nome: "Erro ao Enviar Proposta"},
    {id: "N", nome: "Em Análise Crédito"},
    {id: "A", nome: "Crédito Aprovado"},
    {id: "R", nome: "Crédito Recusado"},
    {id: "X", nome: "Sem Integração"},
  ]
};

const SimulacaoSlice = createSlice({
  name: 'simulacao',
  initialState,
  reducers: {
    simulacaoRequest: (state) => {
      state.submitting = true;
      state.error = null;
    },
    simulacaoSuccess: (state, action) => {
      state.submitting = false;
      state.error = false;
    },
    simulacaoFailure: (state, action) => {
      state.submitting = false;
      state.error = action.payload;
    },
    getSimulacaoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    getSimulacaoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.response.data;
    },
    getSimulacaoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    atualizarBancoSelecionadoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    atualizarBancoSelecionadoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.response.data;
    },
    atualizarBancoSelecionadoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    atualizarSimulacaoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    atualizarSimulacaoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.response.data;
    },
    atualizarSimulacaoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    listarSimulacaoRequest: (state, action) => {
      state.list.loading = true;
      state.list.error = null;
      state.data = "";
    },
    listarSimulacaoSuccess: (state, action) => {
      state.list.loading = false;
      state.list.error = null;
      state.list.data = action.payload.response.data;
    },
    listarSimulacaoFailure: (state, action) => {
      state.list.loading = false;
      state.list.error = action.payload;
    },
    incluirSimulacaoRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    incluirSimulacaoSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.data = action.payload.response.data;
    },
    incluirSimulacaoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    incluirPropostaIntegracaoRequest: (state) => {
      state.list.loading = true;
      state.list.error = null;
    },
    incluirPropostaIntegracaoSuccess: (state, action) => {
      state.list.loading = false;
      state.list.error = null;
      state.data = action.payload.response.data;
    },
    incluirPropostaIntegracaoFailure: (state, action) => {
      state.list.loading = false;
      state.error = action.payload;
    },
  }
});

export const {
  simulacaoRequest,
  simulacaoSuccess,
  simulacaoFailure,
  getSimulacaoRequest,
  getSimulacaoSuccess,
  getSimulacaoFailure,
  atualizarBancoSelecionadoSuccess,
  atualizarBancoSelecionadoFailure,
  atualizarBancoSelecionadoRequest,
  atualizarSimulacaoFailure,
  atualizarSimulacaoSuccess,
  atualizarSimulacaoRequest,
  listarSimulacaoFailure,
  listarSimulacaoSuccess,
  listarSimulacaoRequest,
  incluirSimulacaoFailure,
  incluirSimulacaoSuccess,
  incluirSimulacaoRequest,
  incluirPropostaIntegracaoFailure,
  incluirPropostaIntegracaoRequest,
  incluirPropostaIntegracaoSuccess,
} = SimulacaoSlice.actions;

export default SimulacaoSlice.reducer;
