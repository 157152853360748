import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import Autocomplete from 'components/form/Autocomplete';
import { Grid } from '@mui/material';

const DadosDoUsuario = ({
  formProps,
  perfis,
  handleChangePerfil,
  disabled
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigo">Código</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="codigo"
            name="codigo"
            placeholder="Código do usuario"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nome">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nome"
            name="nome"
            placeholder="Nome do usuario"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="perfil">Perfil</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="perfil"
            name="perfil"
            placeholder="Escolha o Perfil"
            fullWidth
            labelField={'nome'}
            idField={'codigo'}
            domain={perfis}
            formProps={formProps}
            onChange={(e) => handleChangePerfil(e?.codigo ?? null)}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="email">Email</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="email"
            name="email"
            type="email"
            placeholder="Digite o e-mail"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoUsuario;
