// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import {
  actions as routeActions,
  types as routes
} from '../../../../reducers/rotas.actions';
import { useEffect, useState } from 'react';
import { activeItem } from 'reducers/menu';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  const dispatch = useDispatch();

  const queryString = useSelector((state) => state.pesquisa.queryString);

  const [query, setQuery] = useState(queryString);

  const handleSearch = () => {
    if (query === '') return;
    dispatch(activeItem({ openItem: [null] }));
    dispatch(
      routeActions.redirectTo(routes.PESQUISA, {
        termo: query.replaceAll('/', ' ')
      })
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    setQuery(queryString);
  }, [queryString]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 3 } }}>
      <FormControl sx={{ width: { xs: '50%' } }}>
        <OutlinedInput
          value={query}
          size="small"
          id="header-search"
          fullWidth
          startAdornment={
            <InputAdornment position="start" sx={{ mr: -0.5 }}>
              <SearchOutlined />
            </InputAdornment>
          }
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight'
          }}
          placeholder="Pesquisar"
          sx={{ backgroundColor: '#fff' }}
          onBlur={handleSearch}
          onKeyPress={handleKeyPress}
          onChange={(e) => setQuery(e.target.value)}
        />
      </FormControl>
    </Box>
  );
};

export default Search;
