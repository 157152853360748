import { authenticatedRequest, unauthenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/usuariosParceiros.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { regionalRequest } from 'reducers/dominios.reducer';
import { mostrarParceiroRequest } from 'reducers/parceiros.reducer';

const apiListarParceiros = (idParceiro) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/list?idParceiro=${idParceiro}`,
    method: 'get'
  });
};

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceirosRequest,
  actionFailure: actions.listarParceirosFailure,
  actionSuccess: actions.listarParceirosSuccess,
  api: apiListarParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/create`,
    method: 'POST',
    body: value
  });
};

const criarParceiros = basicFlow({
  actionGenerator: actions.criarParceiroRequest,
  actionFailure: actions.criarParceiroFailure,
  actionSuccess: actions.criarParceiroSuccess,
  api: apiCriarParceiro,
  postSuccess: function* ({ response }) {
    toast.success('Usuário Parceiro incluída com sucesso.');
    yield put(
      routeActions.redirectTo(routes.USUARIOS_PARCEIRO, {
        idUsuarioParceiro: response.data.idUsuarioParceiro,
        idParceiro: response.data.idParceiro
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/update/${value.idUsuarioParceiro}`,
    method: 'PUT',
    body: value
  });
};

const editarParceiros = basicFlow({
  actionGenerator: actions.editarParceiroRequest,
  actionFailure: actions.editarParceiroFailure,
  actionSuccess: actions.editarParceiroSuccess,
  api: apiEditarParceiro,
  postSuccess: function* (value) {
    yield toast.success('YUsuário Parceiro editado com sucesso.');
    const { idUsuarioParceiro } = yield select(getPayload);
    yield put(actions.mostrarParceiroRequest(idUsuarioParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarParceiro = (id) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/show/${id}`,
    method: 'get'
  });
};

const mostrarParceiro = basicFlow({
  actionGenerator: actions.mostrarParceiroRequest,
  actionFailure: actions.mostrarParceiroFailure,
  actionSuccess: actions.mostrarParceiroSuccess,
  api: apiMostrarParceiro,
  postFailure: function* ({ error }) {
    yield toast.error('Usuário Parceiro não encontrado.');
  }
});

const apiExcluirParceiro = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/delete/${value.idUsuarioParceiro}`,
    method: 'delete'
  });
};

const excluirParceiro = basicFlow({
  actionGenerator: actions.excluirParceiroRequest,
  actionFailure: actions.excluirParceiroFailure,
  actionSuccess: actions.excluirParceiroSuccess,
  api: apiExcluirParceiro,
  postSuccess: function* () {
    const { idParceiro } = yield select(getPayload);
    yield toast.success('Usuário Parceiro excluído com sucesso.');
    yield put(actions.listarParceirosRequest(idParceiro));
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

const apiListarUsuariosParceiros = (idUsuarioParceiro) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/${idUsuarioParceiro}/usuarios`,
    method: 'get'
  });
};

const listarUsuariosParceiros = basicFlow({
  actionGenerator: actions.listarUsuariosParceirosRequest,
  actionFailure: actions.listarUsuariosParceirosFailure,
  actionSuccess: actions.listarUsuariosParceirosSuccess,
  api: apiListarUsuariosParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarArquivos = (value) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/arquivos/${value}`,
    method: 'get'
  });
};

const listarArquivos = basicFlow({
  actionGenerator: actions.listarArquivosRequest,
  actionFailure: actions.listarArquivosFailure,
  actionSuccess: actions.listarArquivosSuccess,
  api: apiListarArquivos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiUploadArquivo = ({ value }) => {
  const formData = new FormData();
  formData.append('idUsuarioParceiro', value.idUsuarioParceiro);
  formData.append('arquivo', value.file, value.file.name);

  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/upload`,
    method: 'post',
    body: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  });
};

const uploadArquivo = basicFlow({
  actionGenerator: actions.uploadArquivoRequest,
  actionFailure: actions.uploadArquivoFailure,
  actionSuccess: actions.uploadArquivoSuccess,
  api: apiUploadArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo enviado com sucesso.');
    const { idUsuarioParceiro } = yield select(getPayload);
    if (!idUsuarioParceiro) return;
    yield put(actions.listarArquivosRequest(idUsuarioParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiDeletarArquivo = (value) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/arquivos/${value}`,
    method: 'delete'
  });
};

const deletarArquivo = basicFlow({
  actionGenerator: actions.deletarArquivoRequest,
  actionFailure: actions.deletarArquivoFailure,
  actionSuccess: actions.deletarArquivoSuccess,
  api: apiDeletarArquivo,
  postSuccess: function* ({ response, original }) {
    yield toast.success('Arquivo deletado com sucesso.');
    const { idUsuarioParceiro } = yield select(getPayload);
    if (!idUsuarioParceiro) return;
    yield put(actions.listarArquivosRequest(idUsuarioParceiro));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarFups = ({ idUsuarioParceiro, idParceiro }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/fup/list?idUsuarioParceiro=${idUsuarioParceiro}&idParceiro=${idParceiro}`,
    method: 'get'
  });
};

const listarFups = basicFlow({
  actionGenerator: actions.listarFupsRequest,
  actionFailure: actions.listarFupsFailure,
  actionSuccess: actions.listarFupsSuccess,
  api: apiListarFups,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarFup = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/fup/create`,
    method: 'POST',
    body: value
  });
};

const criarFups = basicFlow({
  actionGenerator: actions.criarFupRequest,
  actionFailure: actions.criarFupFailure,
  actionSuccess: actions.criarFupSuccess,
  api: apiCriarFup,
  postSuccess: function* (value) {
    toast.success('Comentário incluído com sucesso.');
    const { idUsuarioParceiro, idParceiro } = yield select(getPayload);
    yield put(actions.listarFupsRequest({ idUsuarioParceiro, idParceiro }));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarUsuarios = () => {
  return authenticatedRequest({
    url: `/configuracao-usuario-parceiro/usuarios/list`,
    method: 'get'
  });
};

const listarUsuarios = basicFlow({
  actionGenerator: actions.listarUsuariosRequest,
  actionFailure: actions.listarUsuariosFailure,
  actionSuccess: actions.listarUsuariosSuccess,
  api: apiListarUsuarios,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* mostrarParceiroRouteWatcher() {
  yield routeWatcher(routes.USUARIOS_PARCEIRO, function* () {
    const { idUsuarioParceiro, idParceiro } = yield select(getPayload);
    if (idUsuarioParceiro === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparParceiro());
      yield put(regionalRequest());
      yield put(actions.listarUsuariosRequest());
      yield put(actions.adicionarIdParceiro(idParceiro));
      yield put(mostrarParceiroRequest(idParceiro));

      return;
    }
    if (!idUsuarioParceiro) return;
    yield put(actions.mostrarParceiroRequest(idUsuarioParceiro));
    yield put(actions.ativarModoEdicao());
    yield put(regionalRequest());
    yield put(actions.listarUsuariosRequest());
    yield put(actions.adicionarIdParceiro(idParceiro));
    yield put(mostrarParceiroRequest(idParceiro));
  });
}

function* listarParceirosRouteWatcher() {
  yield routeWatcher(routes.USUARIOS_PARCEIROS, function* () {
    const { idParceiro } = yield select(getPayload);
    yield put(actions.listarParceirosRequest(idParceiro));
    yield put(actions.adicionarIdParceiro(idParceiro));
    yield put(mostrarParceiroRequest(idParceiro));
  });
}

const buscarCep = basicFlow({
  actionGenerator: actions.buscarCepRequest,
  actionFailure: actions.buscarCepFailure,
  actionSuccess: actions.buscarCepSuccess,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: 'get',
      isCep: true
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  }
});

function* parceiroArquivosRouteWatcher() {
  yield routeWatcher(routes.USUARIOS_PARCEIRO_DOCUMENTO, function* () {
    const { idUsuarioParceiro, idParceiro } = yield select(getPayload);
    if (!idUsuarioParceiro) return;
    yield put(actions.mostrarParceiroRequest(idUsuarioParceiro));
    yield put(actions.listarArquivosRequest(idUsuarioParceiro));
    yield put(actions.adicionarIdParceiro(idParceiro));
    yield put(mostrarParceiroRequest(idParceiro));
  });
}

function* parceiroFupsRouteWatcher() {
  yield routeWatcher(routes.USUARIOS_PARCEIRO_FUP, function* () {
    const { idUsuarioParceiro, idParceiro } = yield select(getPayload);
    if (!idUsuarioParceiro) return;
    yield put(actions.mostrarParceiroRequest(idUsuarioParceiro));
    yield put(actions.listarFupsRequest({ idUsuarioParceiro, idParceiro }));
    yield put(actions.adicionarIdParceiro(idParceiro));
    yield put(mostrarParceiroRequest(idParceiro));
  });
}

export const sagas = [
  listarParceiros.watcher(),
  criarParceiros.watcher(),
  editarParceiros.watcher(),
  mostrarParceiro.watcher(),
  excluirParceiro.watcher(),
  listarParceirosRouteWatcher(),
  mostrarParceiroRouteWatcher(),
  parceiroArquivosRouteWatcher(),
  parceiroFupsRouteWatcher(),
  listarArquivos.watcher(),
  listarUsuariosParceiros.watcher(),
  buscarCep.watcher(),
  uploadArquivo.watcher(),
  deletarArquivo.watcher(),
  listarFups.watcher(),
  criarFups.watcher(),
  listarUsuarios.watcher()
];
