// material-ui
import { Grid, Stack, Typography } from '@mui/material';

// project import
import AuthToken from './auth-forms/AuthToken';
import AuthWrapper from './AuthWrapper';

// ================================|| LOGIN ||================================ //
const Token = () => {

  return (
    <AuthWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="center" alignItems="baseline" sx={{ mb: { xs: -0.5, sm: 0.5 } }}>
            <Typography variant="h3">Informe o Token</Typography>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <AuthToken />
        </Grid>
      </Grid>
    </AuthWrapper>
  )
};

export default Token;
