import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';
import Autocomplete from 'components/form/Autocomplete';
import CpfCnpjTextField from 'components/form/CpfCnpjTextField';

const DadosDoParceiro = ({
  formProps,
  origensParceiro,
  consultores,
  analistas,
  disabled,
  perfis
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeUsuarioParceiro">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeUsuarioParceiro"
            name="nomeUsuarioParceiro"
            placeholder="Nome do Usuário Parceiro"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="origem">Origem</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="origem"
            name="origem"
            placeholder="Escolha a Origem"
            fullWidth
            labelField={'nome'}
            idField={'codigo'}
            domain={origensParceiro}
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfCnpj">CPF/CNPJ</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="cpfCnpj"
            name="cpfCnpj"
            placeholder="Digite o CPF/CNPJ"
            fullWidth
            formProps={formProps}
            type="both"
            InputProps={{
              inputComponent: CpfCnpjTextField
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="banco">Perfil do Parceiro</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="perfil"
            name="perfil"
            placeholder="Escolha o Perfil"
            fullWidth
            labelField={'nome'}
            idField={'codigo'}
            domain={perfis}
            formProps={formProps}
          />
        </Stack>
      </Grid>

      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="consultor">Consultor Responsável</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="consultor"
            name="consultor"
            placeholder="Escolha o Consultor"
            fullWidth
            labelField={'nome'}
            idField={'id'}
            domain={consultores}
            formProps={formProps}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={3}>
        <Stack spacing={0}>
          <InputLabel htmlFor="analista">Analista Responsável</InputLabel>
          <Autocomplete
            disabled={disabled}
            id="analista"
            name="analista"
            placeholder="Escolha o Analista"
            fullWidth
            labelField={'nome'}
            idField={'id'}
            domain={analistas}
            formProps={formProps}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoParceiro;
