import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/produtosOperacoes.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarProdutos = () => {
  return authenticatedRequest({
    url: `/produto/list`,
    method: 'get'
  });
};

const listarProdutos = basicFlow({
  actionGenerator: actions.listarProdutosRequest,
  actionFailure: actions.listarProdutosFailure,
  actionSuccess: actions.listarProdutosSuccess,
  api: apiListarProdutos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarProduto = ({ value }) => {
  return authenticatedRequest({
    url: `/produto/create`,
    method: 'POST',
    body: value
  });
};

const criarProdutos = basicFlow({
  actionGenerator: actions.criarProdutoRequest,
  actionFailure: actions.criarProdutoFailure,
  actionSuccess: actions.criarProdutoSuccess,
  api: apiCriarProduto,
  postSuccess: function* ({ response }) {
    toast.success('Operação incluída com sucesso.');
    yield put(
      routeActions.redirectTo(routes.PRODUTO, {
        idOperacao: response.data.idOperacao
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarProduto = ({ value }) => {
  return authenticatedRequest({
    url: `/produto/update/${value.idOperacao}`,
    method: 'PUT',
    body: value
  });
};

const editarProdutos = basicFlow({
  actionGenerator: actions.editarProdutoRequest,
  actionFailure: actions.editarProdutoFailure,
  actionSuccess: actions.editarProdutoSuccess,
  api: apiEditarProduto,
  postSuccess: function* (value) {
    yield toast.success('Operação editada com sucesso.');
    const { idOperacao } = yield select(getPayload);
    yield put(actions.mostrarProdutoRequest(idOperacao));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarProduto = (id) => {
  return authenticatedRequest({
    url: `/produto/show/${id}`,
    method: 'get'
  });
};

const mostrarProduto = basicFlow({
  actionGenerator: actions.mostrarProdutoRequest,
  actionFailure: actions.mostrarProdutoFailure,
  actionSuccess: actions.mostrarProdutoSuccess,
  api: apiMostrarProduto,
  postFailure: function* ({ error }) {
    yield toast.error('Operação não encontrada.');
  }
});

const apiExcluirProduto = ({ value }) => {
  return authenticatedRequest({
    url: `/produto/delete/${value.idOperacao}`,
    method: 'delete'
  });
};

const excluirProduto = basicFlow({
  actionGenerator: actions.excluirProdutoRequest,
  actionFailure: actions.excluirProdutoFailure,
  actionSuccess: actions.excluirProdutoSuccess,
  api: apiExcluirProduto,
  postSuccess: function* () {
    yield toast.success('Operação excluída com sucesso.');
    yield put(actions.listarProdutosRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarProdutoRouteWatcher() {
  yield routeWatcher(routes.PRODUTO, function* () {
    const { idOperacao } = yield select(getPayload);
    if (idOperacao === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparProduto());
      return;
    }
    if (!idOperacao) return;
    yield put(actions.mostrarProdutoRequest(idOperacao));
    yield put(actions.ativarModoEdicao());
  });
}

function* listarProdutosRouteWatcher() {
  yield routeWatcher(routes.PRODUTOS, function* () {
    yield put(actions.listarProdutosRequest());
  });
}

export const sagas = [
  listarProdutos.watcher(),
  criarProdutos.watcher(),
  editarProdutos.watcher(),
  mostrarProduto.watcher(),
  excluirProduto.watcher(),
  listarProdutosRouteWatcher(),
  mostrarProdutoRouteWatcher()
];
