import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';

const DadosDoProduto = ({ formProps, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeOperacao">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeOperacao"
            name="nomeOperacao"
            placeholder="Nome"
            fullWidth
            formProps={formProps}
            required
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoProduto;
