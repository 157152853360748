import React from 'react';
import { Controller } from 'react-hook-form';
import { styled } from "@mui/system";
import { FormControl, FormControlLabel, FormHelperText, Skeleton, Switch as MSwitch } from '@mui/material';

const ControlledSwitch = ({ formProps, name, loading, ...otherProps }) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined;

  if (loading) {
    return (
      <Skeleton sx={{ height: 37 }} animation="wave" variant={"rounded"}  />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Switch
            {...otherProps}
            value={value}
            error={isError}
            helperText={errors[name]?.message}
            onChange={(v) => {
              onChange(v.target.checked);
              !!otherProps.onChange && otherProps.onChange(v);
            }}
            onBlur={onBlur}
          />
        );
      }}
    />
  );
};

const StyledFormControl = styled(FormControl)`
  margin-bottom: 0;
  text-align: left;
  margin-top: 0 !important;

  & .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;
  }
  & .MuiFormControlLabel-label {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-weight: 800;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
    color: ${({ theme }) => theme.palette.gray};
  }
  & .MuiFormControlLabel-label + .Mui-focused {
    color: ${({ theme }) => theme.palette.gray};
  }
  & .MuiFormControlLabel-label.Mui-error {
    color: ${({ theme }) => theme.palette.error};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: ${({ theme }) => theme.typography.fontSize};
    font-weight: 400;
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.dark};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.palette.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.palette.error};
  }
  & .MuiSwitch-root {
    margin-left: -10px;
    height: 38px; 
    padding-bottom: 5px;  
  } 
  & .MuiSwitch-track {
    margin-top: -2px !important;  
    border-radius: 12px  
  }
  & .MuiSwitch-thumb {
      margin-top: 1px;
  }  
`;

const Switch = ({
                  value,
                  label,
                  disabled,
                  helperText,
                  fullWidth = true,
                  error,
                  labelPlacement = "top",
                  ...otherProps
                }) => {
  return (
    <StyledFormControl fullWidth={fullWidth}>
      <FormControlLabel
        control={
          <MSwitch
            {...otherProps}
            value={true}
            checked={value}
            disabled={disabled}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
      {Boolean(error) && Boolean(helperText) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default ControlledSwitch;
