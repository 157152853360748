import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect } from 'react';
import * as actions from 'reducers/oportunidade.reducer';
import DadosDoIntervenienteQuitante from './DadosDoIntervenienteQuitante';
import Loader from 'components/Loader';

const Imovel = () => {
  const dispatch = useDispatch();

  const { oportunidade } = useSelector(
    (state) => state.oportunidade.imovelIq.data
  );

  const loading = useSelector((state) => state.oportunidade.imovelIq.loading);

  const usuario = useSelector((state) => state.usuario);

  const initialValues = {
    // Dados do Interveniente Quitante
    nomeIntervenienteQuitante: oportunidade?.nomeIntervenienteQuitante ?? '',
    descricaoIntervenienteQuitante:
      oportunidade?.descricaoIntervenienteQuitante ?? ''
  };

  const rules = {
    // Dados do Interveniente Quitante
    nomeIntervenienteQuitante: validators.string({ required: true }),
    descricaoIntervenienteQuitante: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    }
  };

  useEffect(() => {
    formProps.reset(oportunidade);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunidade]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      idUsuarioAlteracao: usuario.id,
      isIq: true
    };

    dispatch(actions.editarOportunidadeImovelIqRequest({ value: payload }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit(handleSubmit)}
      style={{ width: '100%' }}
    >
      <Grid container spacing={2} alignItems="stretch" sx={{ mt: 1 }}>
        <Grid item xs={12} md={12} sm={12} style={{ display: 'flex' }}>
          <FormCard title="DADOS DO INTERVENIENTE QUITANTE" style={{ flex: 1 }}>
            <DadosDoIntervenienteQuitante formProps={formProps} />
          </FormCard>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <AnimateButton>
            <Button
              disableElevation
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="submit"
            >
              SALVAR
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default Imovel;
