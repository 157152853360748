import React from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import { Grid } from '../../../../node_modules/@mui/material/index';
import EntidadesRelacionadas from './EntidadesRelacionadasGrid';
import Loader from 'components/Loader';

const Documentos = () => {
  const loading = useSelector(
    (state) => state.documentos.entidadesRelacionadas.loading
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <Box sx={{ marginTop: '10px' }}>
            <EntidadesRelacionadas />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Documentos;
