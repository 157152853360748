import { isValid as isValidCpf } from '@fnando/cpf';
import { isValid as isValidCnpj } from '@fnando/cnpj';
import { removeNonDigitsFromString } from './basic';

export const validateCpfCnpj = (value) => {
  const formattedValue = removeNonDigitsFromString(value);

  if (formattedValue.length === 11) {
    return isValidCpf(formattedValue) || 'CPF inválido';
  }

  if (formattedValue.length === 14) {
    return isValidCnpj(formattedValue) || 'CNPJ inválido';
  }

  return 'CPF/CNPJ inválido';
};
