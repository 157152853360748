// project import
import Routes from './routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { store } from 'store';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

store.dispatch({ type: 'START_ATUALIZACAO_AUTOMATICA' });

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <Routes />
      <ToastContainer position="bottom-left" />
    </ScrollTop>
  </ThemeCustomization>
);

export default App;
