import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect } from 'react';
import DadosDoBanco from './DadosDoBanco';
import * as actions from 'reducers/bancos.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

const Banco = () => {
  const dispatch = useDispatch();
  const banco = useSelector((state) => state.bancos.banco.data);
  const loading = useSelector((state) => state.bancos.banco.loading);

  const isEditMode = useSelector((state) => state.bancos.banco.isEditMode);

  const initialValues = {
    // Dados do Banco
    nomeBanco: banco?.nomeBanco ?? '',
    codigoBanco: banco?.codigoBanco ?? '',
    flagSimulacao: banco?.flagSimulacao ?? ''
  };

  const rules = {
    // Dados do Banco
    nomeBanco: validators.string({ required: true }),
    codigoBanco: validators.number({ required: true, min: 0, max: 9999 }),
    flagSimulacao: validators.string({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: banco
  };

  useEffect(() => {
    if (!!banco) {
      formProps.reset({
        ...banco,
        flagSimulacao: banco.flagSimulacao === 'S' ? true : false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [banco]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      flagSimulacao: values.flagSimulacao ? 'S' : 'N'
    };

    if (isEditMode) {
      dispatch(actions.editarBancoRequest({ value: payload }));
    } else {
      dispatch(actions.criarBancoRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.BANCOS));
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !banco?.idBanco) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Banco não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Novo Banco'
            ) : (
              <Box>
                <Typography variant="h2">{banco?.nomeBanco}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Banco
                </Typography>
              </Box>
            )
          }
        >
          <FormCard title="DADOS DO BANCO" style={{ flex: 1 }}>
            <DadosDoBanco formProps={formProps} />
          </FormCard>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Banco;
