import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';
import PhoneTextField from 'components/form/PhoneTextField';

const DadosDoFaturamento = ({ formProps, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeFaturamento">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeFaturamento"
            name="nomeFaturamento"
            placeholder="Nome do Faturamento"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="emailFaturamento">Email</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="emailFaturamento"
            name="emailFaturamento"
            type="email"
            placeholder="Digite o e-mail"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="celularFaturamento">Celular</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="celularFaturamento"
            name="celularFaturamento"
            placeholder="Digite o número do celular"
            fullWidth
            required
            formProps={formProps}
            maxLength={15}
            InputProps={{
              inputComponent: PhoneTextField,
              inputProps: { isMobile: true }
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoFaturamento;
