import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/produtosAtividadesBanco.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { mostrarAtividadeRequest } from 'reducers/produtosAtividades.reducer';
import { mostrarEtapaRequest } from 'reducers/produtosEtapas.reducer';
import { mostrarProdutoRequest } from 'reducers/produtosOperacoes.reducer';

const apiListarAtividadesBanco = (idAtividade) => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/list?idAtividade=${idAtividade}`,
    method: 'get'
  });
};

const listarAtividadesBanco = basicFlow({
  actionGenerator: actions.listarAtividadesBancoRequest,
  actionFailure: actions.listarAtividadesBancoFailure,
  actionSuccess: actions.listarAtividadesBancoSuccess,
  api: apiListarAtividadesBanco,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarAtividadeBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/create`,
    method: 'POST',
    body: value
  });
};

const criarAtividadesBanco = basicFlow({
  actionGenerator: actions.criarAtividadeBancoRequest,
  actionFailure: actions.criarAtividadeBancoFailure,
  actionSuccess: actions.criarAtividadeBancoSuccess,
  api: apiCriarAtividadeBanco,
  postSuccess: function* ({ response }) {
    toast.success('Atividade Banco incluída com sucesso.');
    const { idOperacao, idEtapa, idAtividade } = yield select(getPayload);
    yield put(
      routeActions.redirectTo(routes.PRODUTO_ATIVIDADE_BANCO, {
        idAtividadeBanco: response.data.idAtividadeBanco,
        idEtapa,
        idOperacao,
        idAtividade
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarAtividadeBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/update/${value.idAtividadeBanco}`,
    method: 'PUT',
    body: value
  });
};

const editarAtividadesBanco = basicFlow({
  actionGenerator: actions.editarAtividadeBancoRequest,
  actionFailure: actions.editarAtividadeBancoFailure,
  actionSuccess: actions.editarAtividadeBancoSuccess,
  api: apiEditarAtividadeBanco,
  postSuccess: function* (value) {
    yield toast.success('Atividade Banco editada com sucesso.');
    const { idAtividadeBanco } = yield select(getPayload);
    yield put(actions.mostrarAtividadeBancoRequest(idAtividadeBanco));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarAtividadeBanco = (id) => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/show/${id}`,
    method: 'get'
  });
};

const mostrarAtividadeBanco = basicFlow({
  actionGenerator: actions.mostrarAtividadeBancoRequest,
  actionFailure: actions.mostrarAtividadeBancoFailure,
  actionSuccess: actions.mostrarAtividadeBancoSuccess,
  api: apiMostrarAtividadeBanco,
  postFailure: function* ({ error }) {
    yield toast.error('Atividade Banco não encontrada.');
  }
});

const apiExcluirAtividadeBanco = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/delete/${value.idAtividadeBanco}`,
    method: 'delete'
  });
};

const excluirAtividadeBanco = basicFlow({
  actionGenerator: actions.excluirAtividadeBancoRequest,
  actionFailure: actions.excluirAtividadeBancoFailure,
  actionSuccess: actions.excluirAtividadeBancoSuccess,
  api: apiExcluirAtividadeBanco,
  postSuccess: function* () {
    yield toast.success('Atividade Banco excluída com sucesso.');
    const { idOperacao, idEtapa, idAtividade } = yield select(getPayload);
    yield put(actions.listarAtividadesBancoRequest(idAtividade));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(actions.adicionarIdEtapa(idEtapa));
    yield put(actions.adicionarIdAtividade(idEtapa));
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

const apiListarBancos = () => {
  return authenticatedRequest({
    url: `/produto-atividade-banco/list/bancos`,
    method: 'get'
  });
};

const listarBancos = basicFlow({
  actionGenerator: actions.listarBancosRequest,
  actionFailure: actions.listarBancosFailure,
  actionSuccess: actions.listarBancosSuccess,
  api: apiListarBancos,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* mostrarAtividadeBancoRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ATIVIDADE_BANCO, function* () {
    const { idAtividadeBanco, idEtapa, idOperacao, idAtividade } =
      yield select(getPayload);
    if (idAtividadeBanco === 'novo') {
      yield put(actions.listarBancosRequest());
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparAtividadeBanco());
      yield put(actions.adicionarIdEtapa(idEtapa));
      yield put(actions.adicionarIdOperacao(idOperacao));
      yield put(actions.adicionarIdAtividade(idAtividade));
      yield put(mostrarAtividadeRequest(idAtividade));
      yield put(mostrarEtapaRequest(idEtapa));
      yield put(mostrarProdutoRequest(idOperacao));

      return;
    }
    if (!idAtividadeBanco) return;
    yield put(actions.listarBancosRequest());
    yield put(actions.mostrarAtividadeBancoRequest(idAtividadeBanco));
    yield put(actions.ativarModoEdicao());
    yield put(actions.adicionarIdEtapa(idEtapa));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(actions.adicionarIdAtividade(idAtividade));
    yield put(mostrarAtividadeRequest(idAtividade));
    yield put(mostrarEtapaRequest(idEtapa));
    yield put(mostrarProdutoRequest(idOperacao));
  });
}

function* listarAtividadesBancoRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ATIVIDADES_BANCO, function* () {
    const { idEtapa, idOperacao, idAtividade } = yield select(getPayload);
    yield put(actions.listarAtividadesBancoRequest(idAtividade));
    yield put(actions.adicionarIdEtapa(idEtapa));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(actions.adicionarIdAtividade(idAtividade));
    yield put(mostrarAtividadeRequest(idAtividade));
    yield put(mostrarEtapaRequest(idEtapa));
    yield put(mostrarProdutoRequest(idOperacao));
  });
}

export const sagas = [
  listarAtividadesBanco.watcher(),
  criarAtividadesBanco.watcher(),
  editarAtividadesBanco.watcher(),
  mostrarAtividadeBanco.watcher(),
  excluirAtividadeBanco.watcher(),
  listarBancos.watcher(),
  listarAtividadesBancoRouteWatcher(),
  mostrarAtividadeBancoRouteWatcher()
];
