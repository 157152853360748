// assets
import {
  ChromeOutlined,
  QuestionOutlined,
  FileTextOutlined,
  BranchesOutlined,
  SisternodeOutlined,
  SubnodeOutlined,
  ApartmentOutlined,
  BankOutlined,
  ProductOutlined,
  TeamOutlined,
  UserOutlined
} from '@ant-design/icons';

import { types as routes } from '../reducers/rotas.actions';

// icons
const icons = {
  ChromeOutlined,
  QuestionOutlined,
  FileTextOutlined,
  BranchesOutlined,
  SisternodeOutlined,
  SubnodeOutlined,
  ApartmentOutlined,
  BankOutlined,
  ProductOutlined,
  TeamOutlined,
  UserOutlined
};
// ==============================|| MENU ITEMS - SAMPLE PAGE & DOCUMENTATION ||============================== //

const support = {
  id: 'configuracoes',
  title: 'Configurações',
  type: 'group',
  restricted: ['AD'],
  children: [
    {
      id: 'Banco',
      title: 'Bancos',
      type: 'item',
      url: 'configuracoes/bancos',
      icon: icons.BankOutlined,
      breadcrumbs: false,
      action: routes.BANCOS
    },
    {
      id: 'regional',
      title: 'Regionais',
      type: 'item',
      url: 'configuracoes/regionais',
      icon: icons.ApartmentOutlined,
      breadcrumbs: false,
      action: routes.REGIONAIS
    },
    {
      id: 'documento',
      title: 'Documentos',
      type: 'item',
      url: '/configuracoes/documentos',
      icon: icons.FileTextOutlined,
      breadcrumbs: false,
      action: routes.CONFIGURACAO_DOCUMENTOS
    },
    {
      id: 'produto',
      title: 'Operações',
      type: 'item',
      url: '/configuracoes/operacoes',
      icon: icons.ProductOutlined,
      breadcrumbs: false,
      action: routes.PRODUTOS
    },
    {
      id: 'parceiro',
      title: 'Parceiros',
      type: 'item',
      url: '/configuracoes/parceiros',
      icon: icons.TeamOutlined,
      breadcrumbs: false,
      action: routes.PARCEIROS
    },
    {
      id: 'usuario',
      title: 'Usuários',
      type: 'item',
      url: '/configuracoes/usuarios',
      icon: icons.UserOutlined,
      breadcrumbs: false,
      action: routes.USUARIOS
    }
  ]
};

export default support;
