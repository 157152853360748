import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';
import CpfCnpjTextField from 'components/form/CpfCnpjTextField';

const DadosDoBanco = ({ formProps }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
          <OutlinedInput
            id="cnpj"
            name="cnpj"
            placeholder="CNPJ"
            fullWidth
            formProps={formProps}
            type="cnpj"
            required
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 15
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeRegional">Nome</InputLabel>
          <OutlinedInput
            id="nomeRegional"
            name="nomeRegional"
            placeholder="Nome da Regional"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoBanco;
