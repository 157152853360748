import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect, useCallback } from 'react';
import DadosDaRegional from './DadosDaRegional';
import * as actions from 'reducers/regionais.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import { removeNonDigitsFromString } from 'utils/basic';
import Endereco from './Endereco';

const Regional = () => {
  const dispatch = useDispatch();
  const regional = useSelector((state) => state.regionais.regional.data);
  const loading = useSelector((state) => state.regionais.regional.loading);

  const isEditMode = useSelector(
    (state) => state.regionais.regional.isEditMode
  );

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const initialValues = {
    // Dados do Regional
    nomeRegional: regional?.nomeRegional ?? '',
    codigoRegional: regional?.codigoRegional ?? '',
    flagSimulacao: regional?.flagSimulacao ?? '',

    // Endereço
    cep: '',
    logradouro: '',
    numeroLogradouro: '',
    complementoLogradouro: '',
    bairro: '',
    municipio: '',
    uf: null
  };

  const rules = {
    // Dados do Regional
    nomeRegional: validators.string({ required: true }),
    codigoRegional: validators.number({ required: true, min: 0, max: 9999 }),
    flagSimulacao: validators.string({ required: false }),

    // Endereço
    cep: validators.string({ required: false }),
    logradouro: validators.string({ required: false }),
    numeroLogradouro: validators.string({ required: false }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: false }),
    municipio: validators.string({ required: false }),
    uf: validators.string({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: regional
  };

  const { setValue } = formProps;

  useEffect(() => {
    if (!!regional) {
      formProps.reset({
        ...regional,
        flagSimulacao: regional.flagSimulacao === 'S' ? true : false,
        uf: ufs.find((el) => el.codigo === regional.uf) ?? null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regional]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      cnpj: removeNonDigitsFromString(values.cnpj),
      cep: removeNonDigitsFromString(values.cep)
    };

    if (typeof payload.uf === 'object') {
      payload.uf = payload?.uf?.codigo;
    }

    if (isEditMode) {
      dispatch(actions.editarRegionalRequest({ value: payload }));
    } else {
      dispatch(actions.criarRegionalRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.REGIONAIS));
  };

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });

      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !regional?.idRegional) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Regional não encontrada'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Nova Regional'
            ) : (
              <Box>
                <Typography variant="h2">{regional?.nomeRegional}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Regional
                </Typography>
              </Box>
            )
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DA REGIONAL" style={{ flex: 1 }}>
                <DadosDaRegional formProps={formProps} />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                <Endereco
                  formProps={formProps}
                  handleZipCodeBlurred={handleZipCodeBlurred}
                />
              </FormCard>
            </Box>
          </Box>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Regional;
