import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useCallback, useEffect } from 'react';
import DadosDoImovel from './DadosDoImovel';
import * as actions from 'reducers/oportunidade.reducer';
import { removeNonDigitsFromString } from 'utils/basic';
import DadosDaAvaliacao from './DadosDaAvalicao';
import Loader from 'components/Loader';

const Imovel = () => {
  const dispatch = useDispatch();
  const { oportunidade } = useSelector(
    (state) => state.oportunidade.imovelIq.data
  );
  const loading = useSelector((state) => state.oportunidade.imovelIq.loading);
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const tiposImovel = useSelector((state) => state.dominios.tiposImovel);
  const tiposUtilizacao = useSelector(
    (state) => state.dominios.tiposUtilizacao
  );
  const usuario = useSelector((state) => state.usuario);

  const initialValues = {
    // Dados do Imovel
    nomeParticipante: oportunidade?.nomeParticipante ?? '',
    tipoImovel: oportunidade?.tipoImovel ?? '',
    tipoUtilizacaoImovel: oportunidade?.tipoUtilizacaoImovel ?? '',
    cep: oportunidade?.cep ?? '',
    logradouro: oportunidade?.logradouro ?? '',
    numeroLogradouro: oportunidade?.numeroLogradouro ?? '',
    complementoLogradouro: oportunidade?.complementoLogradouro ?? '',
    bairro: oportunidade?.bairro ?? '',
    municipio: oportunidade?.municipio ?? '',
    uf: oportunidade?.uf ?? '',

    // Contatos
    contatoAvaliacao: oportunidade?.contatoAvaliacao ?? '',
    telefoneContatoAvaliacao: removeNonDigitsFromString(
      oportunidade?.telefoneContatoAvaliacao ?? ''
    )
  };

  const rules = {
    // Dados do Imovel
    nomeParticipante: validators.string({ required: true }),
    tipoImovel: validators.string({ required: true }),
    tipoUtilizacaoImovel: validators.string({ required: true }),
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),

    // Contatos
    contatoAvaliacao: validators.string({ required: false }),
    telefoneContatoAvaliacao: validators.string({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: oportunidade
  };

  const { setValue } = formProps;

  useEffect(() => {
    if (!!oportunidade) {
      formProps.reset({
        ...oportunidade,
        telefoneContatoAvaliacao: removeNonDigitsFromString(
          oportunidade.telefoneContatoAvaliacao
        ),
        tipoImovel: tiposImovel.find((i) => i.id === oportunidade.tipoImovel),
        tipoUtilizacaoImovel: tiposUtilizacao.find(
          (i) => i.id === oportunidade.tipoUtilizacaoImovel
        ),
        uf: ufs.find((i) => i.codigo === oportunidade.uf)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunidade, tiposImovel]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      idUsuarioAlteracao: usuario.id,
      isImovel: true
    };

    dispatch(actions.editarOportunidadeImovelIqRequest({ value: payload }));
  };

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', { shouldValidate: true });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });
      setValue('uf', ufs.find((uf) => uf?.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', { shouldValidate: true });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <form
      noValidate
      onSubmit={formProps.handleSubmit(handleSubmit)}
      style={{ width: '100%', marginTop: '20px' }}
    >
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12} md={6} sm={6} style={{ display: 'flex' }}>
          <FormCard title="DADOS DO IMÓVEL" style={{ flex: 1 }}>
            <DadosDoImovel
              formProps={formProps}
              handleZipCodeBlurred={handleZipCodeBlurred}
            />
          </FormCard>
        </Grid>
        <Grid item xs={12} md={6} sm={6} style={{ display: 'flex' }}>
          <FormCard title="DADOS DA AVALIAÇÃO" style={{ flex: 1 }}>
            <DadosDaAvaliacao formProps={formProps} />
          </FormCard>
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
        spacing={2}
      >
        <Grid item xs={12} md={4}>
          <AnimateButton>
            <Button
              disableElevation
              fullWidth
              size="large"
              variant="contained"
              color="primary"
              type="submit"
            >
              SALVAR
            </Button>
          </AnimateButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default Imovel;
