import { select, spawn, takeEvery } from 'redux-saga/effects';
import { actionToPath } from 'redux-first-router';
import { getCurrentRoute } from '../selectors/routes.selectors';
import { actions, types } from '../reducers/rotas.actions';
import { routePaths } from '../routes.js';
import queryString from 'query-string';

export function* routeWatcher(route, worker) {
  const initialRoute = yield select(getCurrentRoute);
  const routes = Array.isArray(route) ? [...route] : [route];
  if (routes.indexOf(initialRoute) >= 0) {
    yield spawn(worker);
  }
  yield takeEvery(routes, worker);
}

function* openWindowWatcher() {
  yield takeEvery(types.OPEN_WINDOW, ({ payload }) => {
    const { route, params } = payload;
    const path = actionToPath(
      actions.redirectTo(route, params),
      routePaths,
      queryString
    );
    window.open(path);
  });
}

export const sagas = [openWindowWatcher()];
