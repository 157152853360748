import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  atividades: {
    loading: false,
    error: false,
    data: []
  },
  atividade: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  idOperacao: null,
  idEtapa: null
};

const AtividadesSlice = createSlice({
  name: 'produtosAtividades',
  initialState,
  reducers: {
    listarAtividadesRequest: (state) => {
      state.atividades.loading = true;
      state.atividades.error = null;
      state.atividades.data = [];
    },
    listarAtividadesSuccess: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = false;
      state.atividades.data = action.payload.response.data;
    },
    listarAtividadesFailure: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = action.payload;
    },
    criarAtividadeRequest: (state, action) => {
      state.atividade.loading = true;
      state.atividade.loading = true;
      state.atividade.error = null;
    },
    criarAtividadeSuccess: (state, action) => {
      state.atividade.loading = false;
      state.atividade.loading = false;
      state.atividade.error = false;
    },
    criarAtividadeFailure: (state, action) => {
      state.atividade.loading = false;
      state.atividade.loading = false;
      state.atividade.error = action.payload;
    },
    editarAtividadeRequest: (state, action) => {
      state.atividade.loading = true;
      state.atividade.loading = true;
      state.atividade.error = null;
    },
    editarAtividadeSuccess: (state, action) => {
      state.atividade.loading = false;
      state.atividade.loading = false;
      state.atividade.error = false;
    },
    editarAtividadeFailure: (state, action) => {
      state.atividade.loading = false;
      state.atividade.loading = false;
      state.atividade.error = action.payload;
    },
    mostrarAtividadeRequest: (state, action) => {
      state.atividade.loading = true;
      state.atividade.data = null;
      state.atividade.error = null;
    },
    mostrarAtividadeSuccess: (state, action) => {
      state.atividade.loading = false;
      state.atividade.data = action.payload.response.data;
      state.atividade.error = false;
    },
    mostrarAtividadeFailure: (state, action) => {
      state.atividade.loading = false;
      state.atividade.loading = false;
      state.atividade.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.atividade.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.atividade.isEditMode = true;
    },
    limparAtividade: (state) => {
      state.atividade.data = null;
    },
    excluirAtividadeRequest: (state) => {
      state.atividades.loading = true;
      state.atividades.error = null;
    },
    excluirAtividadeSuccess: (state) => {
      state.atividades.loading = false;
      state.atividades.error = false;
    },
    excluirAtividadeFailure: (state, action) => {
      state.atividades.loading = false;
      state.atividades.error = action.payload;
    },
    adicionarIdOperacao: (state, action) => {
      state.idOperacao = Number(action.payload);
    },
    adicionarIdEtapa: (state, action) => {
      state.idEtapa = Number(action.payload);
    }
  }
});

export const {
  listarAtividadesRequest,
  listarAtividadesSuccess,
  listarAtividadesFailure,
  adicionarOportunidade,
  criarAtividadeRequest,
  criarAtividadeSuccess,
  criarAtividadeFailure,
  mostrarAtividadeRequest,
  mostrarAtividadeSuccess,
  mostrarAtividadeFailure,
  editarAtividadeRequest,
  editarAtividadeSuccess,
  editarAtividadeFailure,
  desativarModoEdicao,
  ativarModoEdicao,
  limparAtividade,
  excluirAtividadeRequest,
  excluirAtividadeSuccess,
  excluirAtividadeFailure,
  adicionarIdOperacao,
  adicionarIdEtapa
} = AtividadesSlice.actions;

export default AtividadesSlice.reducer;
