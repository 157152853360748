import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarColumnsButton,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { DataGrid } from 'components/grid/DataGrid';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';
import MainLayout from 'layout/MainLayout/index';
import MainCard from 'components/MainCard';
import { toast } from 'react-toastify';
import { Box, Stack, Grid, Typography } from '@mui/material';
import validators from 'utils/validators';
import { useForm } from 'react-hook-form';

import InputLabel from 'components/form/InputLabel';
import OutlinedInput from 'components/form/OutlinedInput';

import { useTheme } from '@mui/material/styles';
import AnimateButton from 'components/@extended/AnimateButton';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import formatters from 'utils/formatters';
import Loader from 'components/Loader';

import * as actions from 'reducers/documentos.reducer';
import DocumentViewer from 'components/viewers/DocumentViewer';
import { alterarMenuSelecionado } from 'reducers/oportunidade.reducer';
import FormCard from 'components/FormCard';
import { invalidarUsuario } from 'utils/invalidarUsuario';

export const getAcceptedTypes = (tipo) => {
  if (tipo === 'P') {
    return '.pdf,.jpg,.jpeg,.png';
  }
  return undefined;
};

const Documentos = () => {
  const [action, setAction] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [comentario, setComentario] = useState('');

  let initialRender = useRef(true);

  const dispatch = useDispatch();

  const documento = useSelector((state) => state.documentos.documento.data);
  const usuario = useSelector((state) => state.usuario);

  const referencia = useSelector(
    (state) => state.documentos.documento.referencia
  );
  const loading = useSelector((state) => state.documentos.documento.loading);
  const idOportunidade = useSelector(
    (state) => state.documentos.idOportunidade
  );

  const situacaoDocumento = documento?.tipoSituacao;
  const isIndexado = situacaoDocumento === 'I';
  const isAnalisado = ['A', 'R', 'D'].includes(situacaoDocumento);

  const initialValues = {
    comentarioAnalise: ''
  };

  const rules = {
    comentarioAnalise: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  useEffect(() => {
    formProps.clearErrors();
    setComentario(documento?.comentarioAnalise ?? '');
    formProps.reset({ comentarioAnalise: documento?.comentarioAnalise ?? '' });
    // eslint-disable-next-line
  }, [documento]);

  const theme = useTheme();

  const handleSalvar = () => {
    initialRender.current = true;
    setSelectedFile(null);
    dispatch(
      actions.atualizarDocumentoRequest({
        value: {
          idDocumentoOportunidade: documento.idDocumentoOportunidade,
          comentarioAnalise: comentario,
          tipoSituacao: documento.tipoSituacao
        }
      })
    );
  };

  const handleAction = () => {
    if (action === 'A') {
      setOpenDialog(false);
      initialRender.current = true;
      dispatch(
        actions.atualizarDocumentoRequest({
          value: {
            idDocumentoOportunidade: documento.idDocumentoOportunidade,
            tipoSituacao: 'A',
            comentarioAnalise: comentario
          }
        })
      );
    }
    if (action === 'D') {
      setOpenDialog(false);
      if (comentario.length === 0 || comentario === null) {
        formProps.setError('comentarioAnalise', {
          type: 'manual',
          message: 'O campo comentário é obrigatório'
        });
        return;
      }
      initialRender.current = true;
      dispatch(
        actions.atualizarDocumentoRequest({
          value: {
            idDocumentoOportunidade: documento.idDocumentoOportunidade,
            tipoSituacao: 'D',
            comentarioAnalise: comentario
          }
        })
      );
    }
    if (action === 'R') {
      setOpenDialog(false);
      if (comentario.length === 0 || comentario === null) {
        formProps.setError('comentarioAnalise', {
          type: 'manual',
          message: 'O campo comentário é obrigatório'
        });
        return;
      }
      initialRender.current = true;
      dispatch(
        actions.atualizarDocumentoRequest({
          value: {
            idDocumentoOportunidade: documento.idDocumentoOportunidade,
            tipoSituacao: 'R',
            comentarioAnalise: comentario
          }
        })
      );
    }
    setOpenDialog(false);
  };

  const [open, setOpen] = useState(false);

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    useEffect(() => {
      if (initialRender.current && documento?.arquivos.length > 0) {
        apiRef.current.selectRow(documento.arquivos[0].idArquivo);
        initialRender.current = false;
      }
      // eslint-disable-next-line
    }, [documento, apiRef]);

    useEffect(() => {
      if (selectedRows.size === 1) {
        setSelectedFile(selectedRowData[0]);
      } else {
        setSelectedFile(null);
      }
      // eslint-disable-next-line
    }, [selectedRows]);

    const handleDeleteClick = () => {
      setOpen(false);
      initialRender.current = true;
      dispatch(actions.deletarArquivoRequest(selectedRowData[0].idArquivo));
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const validExtensions = ['pdf', 'png', 'jpeg', 'jpg'];
        const maxSize = 10 * 1024 * 1024;

        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          toast.error('Tipo de arquivo inválido');
          return;
        }

        if (file.size > maxSize) {
          toast.error('Tamanho do Arquivo não pode exceder 10MB');
          return;
        }
        initialRender.current = true;
        dispatch(
          actions.uploadArquivoRequest({
            value: {
              file,
              idDocumento: documento.idDocumentoOportunidade
            }
          })
        );
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Arquivo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o arquivo{' '}
              {selectedRowData[0]?.nomeArquivo}? Ao excluir um arquivo, não será
              mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".pdf,.png,.jpeg,.jpg"
            />
            <Button
              disabled={isAnalisado}
              variant="text"
              color="primary"
              onClick={handleButtonClick}
              startIcon={<PlusCircleOutlined />}
              sx={{
                maxHeight: 30.75,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '5px',
                paddingRight: '5px'
              }}
            >
              Adicionar Arquivo
            </Button>
            <Button
              variant="text"
              color="primary"
              disabled={isAnalisado || selectedRows.size !== 1}
              onClick={handleClickOpen}
              startIcon={<DeleteOutlined />}
              sx={{
                maxHeight: 30.75,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '5px',
                paddingRight: '5px',
                color: `${
                  isAnalisado || selectedRows.size !== 1
                    ? theme.palette.grey.lighter
                    : theme.palette.error.main
                } !important`
              }}
            >
              Excluir
            </Button>
          </>
        </GridToolbarContainer>
      </>
    );
  };

  const columns = [
    {
      field: 'nomeArquivo',
      headerName: 'Arquivo',
      flex: 1
    },
    {
      field: 'dataHoraCriacao',
      headerName: 'Data',
      flex: 1,
      valueFormatter: (value) => {
        return formatters.dates.short(new Date(value));
      }
    }
  ];

  const handleComentarioChange = (event) => {
    setComentario(event.target.value);
    if (event.target.value.length !== 0) {
      formProps.clearErrors();
    }
  };

  const handleGoBack = () => {
    dispatch(alterarMenuSelecionado(5));
    initialRender.current = true;
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, { id: idOportunidade })
    );
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Dialog open={openDialog} onClose={handleClose}>
            <DialogTitle>Confirmação para Análise de Documento</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Tem certeza de que deseja{' '}
                <strong>
                  {action === 'A'
                    ? 'aprovar'
                    : action === 'R'
                      ? 'recusar'
                      : 'dispensar'}{' '}
                </strong>
                o Documento {documento?.nomeArquivo}? Esta ação é irreversível.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="secondary">
                Não
              </Button>
              <Button onClick={handleAction} color="error">
                Sim
              </Button>
            </DialogActions>
          </Dialog>

          <MainCard
            contentSX={{
              paddingLeft: 1,
              paddingRight: 1
            }}
            style={{
              border: 0
            }}
            title={
              <Box>
                <Typography variant="h2">
                  {documento?.documento?.nomeDocumento}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {documento?.referente} {referencia && ` (${referencia})`}
                </Typography>
              </Box>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                <FormCard title="ARQUIVOS" style={{ flex: 1 }}>
                  <DataGrid
                    rows={documento?.arquivos ?? []}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    getRowId={(row) => row.idArquivo}
                    toolbar={CustomToolbar}
                    noLineText="Nenhum documento cadastrado"
                  />
                </FormCard>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                {selectedFile && (
                  <FormCard title="VISUALIZAÇÃO DO ARQUIVO" style={{ flex: 1 }}>
                    <DocumentViewer arquivo={selectedFile} />
                  </FormCard>
                )}
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                <FormCard title="ANÁLISE" style={{ flex: 1 }}>
                  <Box sx={{ flex: 1, mt: 2, mb: 2 }}>
                    <Stack spacing={0}>
                      <InputLabel htmlFor="comentarioAnalise">
                        Comentário
                      </InputLabel>
                      <OutlinedInput
                        disabled={
                          invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                            ? true
                            : isAnalisado
                        }
                        id="comentarioAnalise"
                        name="comentarioAnalise"
                        placeholder="Comentário sobre o Processo"
                        fullWidth
                        required
                        multiline
                        formProps={formProps}
                        rows={5}
                        inputProps={{ maxLength: 2000 }}
                        value={comentario}
                        onChange={handleComentarioChange}
                      />
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        align="right"
                      >
                        {comentario.length}/{2000}
                      </Typography>
                    </Stack>
                  </Box>
                  {!isAnalisado ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant="contained"
                          disabled={
                            invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                              ? true
                              : !isIndexado
                          }
                          fullWidth
                          color="primary"
                          onClick={() => {
                            setAction('A');
                            setOpenDialog(true);
                          }}
                          sx={{
                            maxHeight: 30.75,
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                          }}
                        >
                          Aprovar
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          disabled={
                            invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                              ? true
                              : !isIndexado
                          }
                          color="primary"
                          onClick={() => {
                            setAction('R');
                            setOpenDialog(true);
                          }}
                          sx={{
                            maxHeight: 30.75,
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                          }}
                        >
                          Recusar
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          disabled={invalidarUsuario(usuario, [
                            'RG',
                            'PC',
                            'UP'
                          ])}
                          color="primary"
                          onClick={() => {
                            setAction('D');
                            setOpenDialog(true);
                          }}
                          sx={{
                            maxHeight: 30.75,
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '5px',
                            paddingRight: '5px'
                          }}
                        >
                          Dispensar
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12}>
                        <Button
                          variant="outlined"
                          fullWidth
                          color="primary"
                          disabled
                          onClick={() => {}}
                          sx={{
                            maxHeight: 30.75,
                            paddingTop: '4px',
                            paddingBottom: '4px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            color: 'white! important',
                            fontWeight: 'bold !important',
                            backgroundColor: `${
                              situacaoDocumento === 'A'
                                ? theme.palette.success.main
                                : situacaoDocumento === 'R'
                                  ? theme.palette.error.main
                                  : 'black'
                            } !important`
                          }}
                        >
                          {`${situacaoDocumento === 'A' ? 'Aprovado' : situacaoDocumento === 'R' ? 'Recusado' : 'Dispensado'}`}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </FormCard>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disabled={
                      invalidarUsuario(usuario, ['RG', 'PC', 'UP'])
                        ? true
                        : isAnalisado
                    }
                    disableElevation
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    onClick={handleSalvar}
                  >
                    SALVAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}
    </MainLayout>
  );
};

export default Documentos;
