import { toast } from 'react-toastify';

const Toast = (type, msg) => {
  if (type === 'error') {
    return toast.error(msg, {
      theme: 'colored',
      icon: false,
      style: { backgroundColor: '#990a26' }
    });
  }
  if (type === 'info') {
    return toast.info(msg);
  }
}

export default Toast;
