import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  documentos: {
    loading: false,
    error: false,
    data: []
  },
  documento: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  }
};

const DocumentosSlice = createSlice({
  name: 'configuracaoDocumentos',
  initialState,
  reducers: {
    listarDocumentosRequest: (state) => {
      state.documentos.loading = true;
      state.documentos.error = null;
      state.documentos.data = [];
    },
    listarDocumentosSuccess: (state, action) => {
      state.documentos.loading = false;
      state.documentos.error = false;
      state.documentos.data = action.payload.response.data;
    },
    listarDocumentosFailure: (state, action) => {
      state.documentos.loading = false;
      state.documentos.error = action.payload;
    },
    criarDocumentoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.loading = true;
      state.documento.error = null;
    },
    criarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.loading = false;
      state.documento.error = false;
    },
    criarDocumentoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    editarDocumentoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.loading = true;
      state.documento.error = null;
    },
    editarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.loading = false;
      state.documento.error = false;
    },
    editarDocumentoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    mostrarDocumentoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.data = null;
      state.documento.error = null;
    },
    mostrarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.data = action.payload.response.data;
      state.documento.error = false;
    },
    mostrarDocumentoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.documento.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.documento.isEditMode = true;
    },
    limparDocumento: (state) => {
      state.documento.data = null;
    },
    excluirDocumentoRequest: (state) => {
      state.documentos.loading = true;
      state.documentos.error = null;
    },
    excluirDocumentoSuccess: (state) => {
      state.documentos.loading = false;
      state.documentos.error = false;
    },
    excluirDocumentoFailure: (state, action) => {
      state.documentos.loading = false;
      state.documentos.error = action.payload;
    }
  }
});

export const {
  listarDocumentosRequest,
  listarDocumentosSuccess,
  listarDocumentosFailure,
  adicionarOportunidade,
  criarDocumentoRequest,
  criarDocumentoSuccess,
  criarDocumentoFailure,
  mostrarDocumentoRequest,
  mostrarDocumentoSuccess,
  mostrarDocumentoFailure,
  editarDocumentoRequest,
  editarDocumentoSuccess,
  editarDocumentoFailure,
  adicionarEtapa,
  reverterDocumentos,
  desativarModoEdicao,
  ativarModoEdicao,
  limparDocumento,
  excluirDocumentoRequest,
  excluirDocumentoSuccess,
  excluirDocumentoFailure
} = DocumentosSlice.actions;

export default DocumentosSlice.reducer;
