/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */
import logo from '../../assets/images/homefin-horizontal-branco-transparente-768x177.png';

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="Homefin" width="220" />;
};

export default Logo;
