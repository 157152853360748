// material-ui
import { DataGrid } from '../../components/grid/DataGrid';
import Box from '@mui/material/Box';

// project import
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  actions as routeActions,
  types as routes
} from '../../reducers/rotas.actions';
import { columns as columnsOportunidade } from './ColumnsOportunidade';
import { Card } from '../../../node_modules/@mui/material/index';
import { toast } from 'react-toastify';
import { alterarMenuSelecionado } from '../../reducers/oportunidade.reducer';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { Launch } from '@mui/icons-material';

const PesquisaOportunidades = () => {
  const temperatura = useSelector((state) => state.dominios.temperatura);
  const parceiros = useSelector((state) => state.dominios.parceiro);
  const regionais = useSelector((state) => state.dominios.regional);
  const usuariosParceiros = useSelector(
    (state) => state.dominios.usuarioParceiro
  );
  const usuarios = useSelector((state) => state.dominios.usuario);
  const bancos = useSelector((state) => state.dominios.bancos);
  const operacoes = useSelector((state) => state.dominios.operacao);
  const etapas = useSelector((state) => state.dominios.etapa);
  const oportunidades = useSelector(
    (state) => state.pesquisa.pesquisa.data.oportunidades
  );
  const loading = useSelector((state) => state.pesquisa.pesquisa.loading);
  const usuario = useSelector((state) => state.usuario);
  const rows = oportunidades?.map((i) => {
    return {
      ...i,
      id: i.idOportunidade,
      dataOportunidade: new Date(`${i.dataOportunidade}T00:00:00`),
      dataPrevistaEmissaoContrato: i.dataPrevistaEmissaoContrato
        ? new Date(`${i.dataPrevistaEmissaoContrato}T00:00:00`)
        : ''
    };
  });

  const dispatch = useDispatch();

  const columns = columnsOportunidade({
    operacoes,
    temperatura,
    regionais,
    parceiros,
    usuariosParceiros,
    usuarios,
    bancos,
    etapas,
    usuario
  });

  const handleRowClick = (params) => {
    const { idOportunidade } = params;
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, { id: idOportunidade })
    );
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    regional: false,
    usuarioParceiro: false,
    consultor: false,
    analista: false,
    cpfCnpj: false,
    atividades: false
  });

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.OPORTUNIDADE, {
            id: selectedRowData[0].idOportunidade
          })
        );
      }
    };

    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<Launch />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Acessar Oportunidade
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Card>
      <Box sx={{ marginTop: '5px' }}>
        <DataGrid
          rows={rows ?? []}
          columns={columns}
          density={'standard'}
          pageSize={10}
          pageSizeOptions={[10]}
          loading={loading}
          handleRowClick={handleRowClick}
          isOportunidade={true}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          noLineText="Nenhuma oportunidade localizada"
          toolbar={CustomToolbar}
        />
      </Box>
    </Card>
  );
};

export default PesquisaOportunidades;
