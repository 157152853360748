import React from 'react';
import { Modal, Box, Stack } from "@mui/material";
import { useForm } from 'react-hook-form';
import InputLabel from '../../components/form/InputLabel';
import Button from '../../components/Button';
import validators from "../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { editarDataPrevistaRequest, editarTemperaturaRequest } from "../../reducers/oportunidade.reducer";
import Autocomplete from "../../components/form/Autocomplete";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const TemperaturaModal = ({ open, handleClose, tipoTemperatura, idOportunidade }) => {
  const rules = {
    tipoTemperatura: validators.string({ required: true }),
  };
  const initialValues = {
    tipoTemperatura: tipoTemperatura,
  };
  const dispatch = useDispatch();
  const tiposTemperaturas = useSelector((state) => state.dominios.temperatura)

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const onSubmit = async (data) => {
    const body = {
      idOportunidade: idOportunidade,
      tipoTemperatura: data.tipoTemperatura.codigo
    }
    dispatch(editarTemperaturaRequest(body))
    handleClose()
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <Box sx={{ mt: 2 }}>
            <Stack spacing={1}>
              <InputLabel htmlFor="tipoTemperatura">Temperatura</InputLabel>
              <Autocomplete
                id="tipoTemperatura"
                name="tipoTemperatura"
                placeholder="Escolha a Temperatura"
                fullWidth
                labelField={"nome"}
                idField={"codigo"}
                domain={tiposTemperaturas}
                formProps={formProps}
              />
            </Stack>
          </Box>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleClose} sx={{ mr: 1 }}>
              Cancelar
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Confirmar
            </Button>
          </Box>
        </form>
      </Box>
    </Modal>
  );
};

export default TemperaturaModal;
