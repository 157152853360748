// assets
import { CalculatorOutlined, HomeOutlined } from '@ant-design/icons';
import { types as routes } from '../reducers/rotas.actions';

// icons
const icons = {
  CalculatorOutlined,
  HomeOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const simulacao = {
  id: 'group-simulacao',
  title: 'Lead',
  type: 'group',
  children: [
    {
      id: 'Simulacao',
      title: 'Cadastrar Oportunidade',
      type: 'item',
      url: '/simulacao',
      icon: icons.CalculatorOutlined,
      breadcrumbs: false,
      action: routes.SIMULACAO
    },
    {
      id: 'Simulador',
      title: 'Simulador Financiamento',
      type: 'item',
      icon: icons.HomeOutlined,
      urlToOpen:
        'https://simulador.homefin.com.br/simular/financiamento-imobiliario?corretor=crm',
      breadcrumbs: false
    },
    {
      id: 'SimuladorHomeEquity',
      title: 'Simulador Home Equity',
      type: 'item',
      icon: icons.HomeOutlined,
      urlToOpen:
        'https://simulador.homefin.com.br/simular/credito-com-garantia',
      breadcrumbs: false
    }
  ]
};

export default simulacao;
