import { DataGrid } from '../../components/grid/DataGrid';
import Box from '@mui/material/Box';

import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import {
  actions as routeActions,
  types as routes
} from '../../reducers/rotas.actions';
import { Card } from '../../../node_modules/@mui/material/index';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { toast } from 'react-toastify';
import { Launch } from '@mui/icons-material';
import { alterarMenuSelecionado } from 'reducers/oportunidade.reducer';

const PesquisaFup = () => {
  const representantes = useSelector(
    (state) => state.pesquisa.pesquisa.data.fups
  );

  const loading = useSelector((state) => state.pesquisa.pesquisa.loading);

  const rows = representantes ?? [];

  const dispatch = useDispatch();

  const columns = [
    {
      field: 'id',
      headerName: 'id',
      flex: 1
    },
    {
      field: 'oportunidade',
      headerName: 'Oportunidade',
      flex: 1
    },
    {
      field: 'comprador',
      headerName: 'Comprador',
      flex: 1
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'Interno', nome: 'Interno' },
              { codigo: 'Externo', nome: 'Externo' }
            ])
        }
      ]
    },
    {
      field: 'titulo',
      headerName: 'Título',
      flex: 1
    }
  ];

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false
  });

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.OPORTUNIDADE, {
            id: selectedRowData[0].idOportunidade
          })
        );
        dispatch(alterarMenuSelecionado(7));
      }
    };

    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<Launch />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Acessar FUP
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Card>
      <Box sx={{ marginTop: '5px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          density={'standard'}
          pageSize={10}
          pageSizeOptions={[10]}
          loading={loading}
          isOportunidade={true}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          toolbar={CustomToolbar}
          noLineText="Nenhum comentário localizado"
        />
      </Box>
    </Card>
  );
};

export default PesquisaFup;
