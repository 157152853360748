import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  produtos: {
    loading: false,
    error: false,
    data: []
  },
  produto: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  },
  buscarCep: {
    loading: false,
    error: null
  }
};

const ProdutosSlice = createSlice({
  name: 'produtos',
  initialState,
  reducers: {
    listarProdutosRequest: (state) => {
      state.produtos.loading = true;
      state.produtos.error = null;
      state.produtos.data = [];
    },
    listarProdutosSuccess: (state, action) => {
      state.produtos.loading = false;
      state.produtos.error = false;
      state.produtos.data = action.payload.response.data;
    },
    listarProdutosFailure: (state, action) => {
      state.produtos.loading = false;
      state.produtos.error = action.payload;
    },
    criarProdutoRequest: (state, action) => {
      state.produto.loading = true;
      state.produto.loading = true;
      state.produto.error = null;
    },
    criarProdutoSuccess: (state, action) => {
      state.produto.loading = false;
      state.produto.loading = false;
      state.produto.error = false;
    },
    criarProdutoFailure: (state, action) => {
      state.produto.loading = false;
      state.produto.loading = false;
      state.produto.error = action.payload;
    },
    editarProdutoRequest: (state, action) => {
      state.produto.loading = true;
      state.produto.loading = true;
      state.produto.error = null;
    },
    editarProdutoSuccess: (state, action) => {
      state.produto.loading = false;
      state.produto.loading = false;
      state.produto.error = false;
    },
    editarProdutoFailure: (state, action) => {
      state.produto.loading = false;
      state.produto.loading = false;
      state.produto.error = action.payload;
    },
    mostrarProdutoRequest: (state, action) => {
      state.produto.loading = true;
      state.produto.data = null;
      state.produto.error = null;
    },
    mostrarProdutoSuccess: (state, action) => {
      state.produto.loading = false;
      state.produto.data = action.payload.response.data;
      state.produto.error = false;
    },
    mostrarProdutoFailure: (state, action) => {
      state.produto.loading = false;
      state.produto.loading = false;
      state.produto.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.produto.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.produto.isEditMode = true;
    },
    limparProduto: (state) => {
      state.produto.data = null;
    },
    excluirProdutoRequest: (state) => {
      state.produtos.loading = true;
      state.produtos.error = null;
    },
    excluirProdutoSuccess: (state) => {
      state.produtos.loading = false;
      state.produtos.error = false;
    },
    excluirProdutoFailure: (state, action) => {
      state.produtos.loading = false;
      state.produtos.error = action.payload;
    }
  }
});

export const {
  listarProdutosRequest,
  listarProdutosSuccess,
  listarProdutosFailure,
  adicionarOportunidade,
  criarProdutoRequest,
  criarProdutoSuccess,
  criarProdutoFailure,
  mostrarProdutoRequest,
  mostrarProdutoSuccess,
  mostrarProdutoFailure,
  editarProdutoRequest,
  editarProdutoSuccess,
  editarProdutoFailure,
  desativarModoEdicao,
  ativarModoEdicao,
  limparProduto,
  excluirProdutoRequest,
  excluirProdutoSuccess,
  excluirProdutoFailure
} = ProdutosSlice.actions;

export default ProdutosSlice.reducer;
