import React from 'react';
import ButtonMui from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const Button = ({ isLoading, children, ...props }) => (
  <ButtonMui
    {...props}
    disabled={isLoading || props.disabled}
    style={{
      fontFamily: 'Museo Sans Rounded',
      fontSize: '14px',
      fontWeight: 600,
      minHeight: '40.5px'
    }}
  >
    {isLoading && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: '-12px',
          marginLeft: '-12px'
        }}
      />
    )}
    {!isLoading && children}
  </ButtonMui>
);

export default Button;
