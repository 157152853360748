import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@mui/material';

import { DataGrid } from 'components/grid/DataGrid';
import MainLayout from 'layout/MainLayout/index';
import MainCard from 'components/MainCard';
import { toast } from 'react-toastify';
import { Box, Grid, Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import AnimateButton from 'components/@extended/AnimateButton';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import formatters from 'utils/formatters';
import Loader from 'components/Loader';

import * as actions from 'reducers/parceiros.reducer';
import DocumentViewer from 'components/viewers/DocumentViewer';
import FormCard from 'components/FormCard';

export const getAcceptedTypes = (tipo) => {
  if (tipo === 'P') {
    return '.pdf,.jpg,.jpeg,.png';
  }
  return undefined;
};

const Documentos = () => {
  const [selectedFile, setSelectedFile] = useState(null);

  let initialRender = useRef(true);

  const dispatch = useDispatch();

  const parceiro = useSelector((state) => state.parceiros.parceiro.data);

  const arquivos = useSelector((state) => state.parceiros.arquivos.data);

  const loadingArquivos = useSelector(
    (state) => state.parceiros.arquivos.loading
  );
  const loadingArquivo = useSelector(
    (state) => state.parceiros.arquivo.loading
  );

  const loading = loadingArquivos || loadingArquivo;

  const theme = useTheme();

  const [open, setOpen] = useState(false);

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    useEffect(() => {
      if (selectedRows.size === 1) {
        setSelectedFile(selectedRowData[0]);
      } else {
        setSelectedFile(null);
      }
      // eslint-disable-next-line
    }, [selectedRows]);

    useEffect(() => {
      if (initialRender.current && arquivos?.length > 0) {
        apiRef.current.selectRow(arquivos[0]?.idArquivo);
        initialRender.current = false;
      }
      // eslint-disable-next-line
    }, [arquivos, apiRef]);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleDeleteClick = () => {
      setOpen(false);
      initialRender.current = true;
      dispatch(actions.deletarArquivoRequest(selectedRowData[0].idArquivo));
    };

    const handleClose = () => {
      setOpen(false);
    };

    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    const handleFileChange = async (event) => {
      const file = event.target.files[0];
      if (file) {
        const validExtensions = ['pdf', 'png', 'jpeg', 'jpg'];
        const maxSize = 10 * 1024 * 1024;

        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!validExtensions.includes(fileExtension)) {
          toast.error('Tipo de arquivo inválido');
          return;
        }

        if (file.size > maxSize) {
          toast.error('Tamanho do Arquivo não pode exceder 10MB');
          return;
        }
        initialRender.current = true;
        dispatch(
          actions.uploadArquivoRequest({
            value: {
              file,
              idParceiro: parceiro.idParceiro
            }
          })
        );
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Confirmação para Exclusão de Arquivo</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir o arquivo{' '}
              {selectedRowData[0]?.nomeArquivo}? Ao excluir um arquivo, não será
              mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
              accept=".pdf,.png,.jpeg,.jpg"
            />
            <Button
              variant="text"
              color="primary"
              onClick={handleButtonClick}
              startIcon={<PlusCircleOutlined />}
              disabled={parceiro?.tipoSituacao !== 'A'}
              sx={{
                maxHeight: 30.75,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '5px',
                paddingRight: '5px'
              }}
            >
              Adicionar Arquivo
            </Button>
            <Button
              variant="text"
              color="primary"
              disabled={
                selectedRows.size !== 1 || parceiro?.tipoSituacao !== 'A'
              }
              onClick={handleClickOpen}
              startIcon={<DeleteOutlined />}
              sx={{
                maxHeight: 30.75,
                paddingTop: '4px',
                paddingBottom: '4px',
                paddingLeft: '5px',
                paddingRight: '5px',
                color: `${
                  selectedRows.size !== 1 || parceiro?.tipoSituacao !== 'A'
                    ? theme.palette.grey.lighter
                    : theme.palette.error.main
                } !important`
              }}
            >
              Excluir
            </Button>
          </>
        </GridToolbarContainer>
      </>
    );
  };

  const columns = [
    {
      field: 'nomeArquivo',
      headerName: 'Arquivo',
      flex: 1
    },
    {
      field: 'dataHoraCriacao',
      headerName: 'Data',
      flex: 1,
      valueFormatter: (value) => {
        return formatters.dates.short(new Date(value));
      }
    }
  ];

  const handleGoBack = () => {
    initialRender.current = true;
    dispatch(routeActions.redirectTo(routes.PARCEIROS));
  };

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MainCard
            contentSX={{
              paddingLeft: 1,
              paddingRight: 1
            }}
            style={{
              border: 0
            }}
            title={
              <Box>
                <Typography variant="h2">{parceiro?.nomeParceiro}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Documentos
                </Typography>
              </Box>
            }
          >
            <Grid container spacing={2}>
              <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                <FormCard title="ARQUIVOS" style={{ flex: 1 }}>
                  <DataGrid
                    rows={arquivos ?? []}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    getRowId={(row) => row.idArquivo}
                    toolbar={CustomToolbar}
                    noLineText="Nenhum documento cadastrado"
                  />
                </FormCard>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                {selectedFile && (
                  <FormCard title="VISUALIZAÇÃO DO ARQUIVO" style={{ flex: 1 }}>
                    <DocumentViewer arquivo={selectedFile} />
                  </FormCard>
                )}
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      )}
    </MainLayout>
  );
};

export default Documentos;
