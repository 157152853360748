import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from '../../../../components/form/OutlinedInput';
import CpfCnpjTextField from '../../../../components/form/CpfCnpjTextField';
import { useSelector } from 'react-redux';
import ControlledAutocomplete from 'components/form/Autocomplete';
import DateTextField from 'components/form/DateTextField';

const DadosPessoais = ({ formProps, handleEstadoCivilChange, setCpf }) => {
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const tiposEstadoCivil = useSelector(
    (state) => state.dominios.tiposEstadoCivil
  );
  const tiposSexo = useSelector((state) => state.dominios.tiposSexo);
  const tiposDocumentos = useSelector(
    (state) => state.dominios.tiposDocumentos
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeParticipante">Nome</InputLabel>
          <OutlinedInput
            id="nomeParticipante"
            name="nomeParticipante"
            placeholder="Digite o nome"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfCnpj">CPF</InputLabel>
          <OutlinedInput
            id="cpfCnpj"
            name="cpfCnpj"
            placeholder="Digite o CPF"
            fullWidth
            type="cpf"
            required
            formProps={formProps}
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 15
            }}
            onChange={(e) => {
              setCpf(e);
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataNascimento">Data de Nascimento</InputLabel>
          <OutlinedInput
            id="dataNascimento"
            name="dataNascimento"
            fullWidth
            required
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
              maxLength: 14
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoSexo">Sexo</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoSexo"
            name="tipoSexo"
            placeholder="Selecione o Sexo"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposSexo}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoEstadoCivil">Estado Civil</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoEstadoCivil"
            name="tipoEstadoCivil"
            placeholder="Selecione o Estado de Civil"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposEstadoCivil}
            onChange={(e) => handleEstadoCivilChange(e?.id ?? null)}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoDocumentoIdentidade">
          Documento de Identidade
        </InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoDocumentoIdentidade"
            name="tipoDocumentoIdentidade"
            placeholder="Selecione o Tipo de Documento"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposDocumentos}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="numeroDocumento">Número do Documento</InputLabel>
          <OutlinedInput
            id="numeroDocumento"
            name="numeroDocumento"
            placeholder="Digite o número do documento"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 15 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataExpedicao">Data de Expedição</InputLabel>
          <OutlinedInput
            id="dataExpedicao"
            name="dataExpedicao"
            fullWidth
            required
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField,
              maxLength: 14
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="orgaoExpedidor">Órgão Expedidor</InputLabel>
          <OutlinedInput
            id="orgaoExpedidor"
            name="orgaoExpedidor"
            placeholder="Digite o órgão expedidor"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 15 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`ufExpedicao`}>UF Expedição</InputLabel>
          <ControlledAutocomplete
            id="ufExpedicao"
            name="ufExpedicao"
            placeholder="Selecione o Estado de Expedição"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DadosPessoais;
