// material-ui
import { DataGrid } from 'components/grid/DataGrid';
import Box from '@mui/material/Box';

// project import
import MainLayout from 'layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import MainCard from 'components/MainCard';
import { columns as columnsOportunidade } from './Columns';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { Launch } from '@mui/icons-material';
import { CheckCircleOutlined } from '@ant-design/icons';

import * as actions from 'reducers/minhasAtividades.reducer';
import useTheme from '../../../../node_modules/@mui/system/useTheme';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

const OportunidadesPage = () => {
  const minhasAtividades = useSelector(
    (state) => state.minhasAtividades.minhasAtividades
  );

  const dispatch = useDispatch();

  const tiposAtividades = [
    {
      codigo: 'OP',
      nome: 'Nova Oportunidade'
    },
    {
      codigo: 'CD',
      nome: 'Novo Documento'
    },
    {
      codigo: 'RD',
      nome: 'Recusa de Documento'
    },
    {
      codigo: 'CM',
      nome: 'Novo Comentário'
    },
    {
      codigo: 'ID',
      nome: 'Documento Indexado'
    }
  ];

  const columns = columnsOportunidade({ tiposAtividades });

  const theme = useTheme();

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleAcessarOportunidade = () => {
      const { idOportunidade } = selectedRowData[0];

      dispatch(
        routeActions.redirectTo(routes.OPORTUNIDADE, {
          id: idOportunidade
        })
      );
    };

    const handleEncerrarAtividade = () => {
      const { id } = selectedRowData[0];
      dispatch(
        actions.encerrarMinhasAtividadesRequest({
          value: { id: id }
        })
      );
    };

    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            startIcon={<Launch />}
            disabled={selectedRows.size !== 1}
            onClick={handleAcessarOportunidade}
          >
            Acessar Oportunidade
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            startIcon={<CheckCircleOutlined />}
            onClick={handleEncerrarAtividade}
            sx={{
              color: `${
                selectedRows.size !== 1
                  ? theme.palette.grey.lighter
                  : theme.palette.success.main
              } !important`
            }}
          >
            Encerrar Tarefa
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <MainLayout>
      <MainCard
        title="Minhas Tarefas"
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={minhasAtividades.data}
            columns={columns}
            density={'standard'}
            pageSize={10}
            pageSizeOptions={[10]}
            loading={minhasAtividades.loading}
            toolbar={CustomToolbar}
            isOportunidade={true}
            noLineText="Nenhuma atividade pendente"
          />
        </Box>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
