import { createSlice } from '@reduxjs/toolkit';

const initialState = null; // O estado inicial é nulo

const userSlice = createSlice({
  name: 'user',
  initialState,
  possuiNotificacao: false,
  reducers: {
    atualizarUsuarioLogado: (state, action) => {
      return action.payload;
    },
    checarNotificacaoRequest: (state, action) => {},
    checarNotificacaoSuccess: (state, action) => {
      state.possuiNotificacao =
        action?.payload?.response?.data?.possuiNotificacao ?? false;
    },
    checarNotificacaoFailure: (state, action) => {
      state.possuiNotificacao = false;
    }
  }
});

export const {
  atualizarUsuarioLogado,
  checarNotificacaoRequest,
  checarNotificacaoSuccess,
  checarNotificacaoFailure
} = userSlice.actions;

export default userSlice.reducer;
