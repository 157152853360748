import { call, put, takeEvery } from 'redux-saga/effects';
import { handleApiErrors } from '../utils/api';
import { logout } from '../reducers/autenticacao.reducer';
import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';
import Toast from '../components/Toast';

export const apiWrapper = (apiPromise) => {
  return apiPromise.then(handleApiErrors).catch((error) => {
    throw error;
  });
};

export const genericErrorHandler = function* ({ error }) {
  if (error.response?.status === 401) {
    Toast('info', 'Sua sessão expirou.\nFaça o login novamente.');
    yield put(logout);
    yield put(routeActions.redirectTo(routes.LOGIN));
  } else if (error.response?.status === 403) {
    Toast('error', 'Acesso negado.');
    yield put(routeActions.redirectTo(routes.LOGIN));
  } else if (error.code === 'ERR_NETWORK') {
    Toast('error', 'Servidor não está respondendo');
  } else if (error.code === 'ERR_TIMEOUT') {
    Toast('error', 'Requisição demorou muito');
  } else {
    const message =
      error.response?.data?.error?.message || error.message || error.toString();
    Toast('error', message);
  }
};

const basicFlow = ({
  actionGenerator,
  actionSuccess,
  actionFailure,
  transform = (p) => p,
  api,
  preSuccess = () => {},
  postSuccess = () => {},
  preFailure = () => {},
  postFailure = genericErrorHandler
}) => {
  return {
    handler: function* (action) {
      let values, response;
      try {
        values = yield transform(action.payload);
        response = yield call(apiWrapper, api(values));
        yield preSuccess({
          original: action.payload,
          values,
          response: {
            data: response.data,
            config: {
              method: response.config.method,
              params: response.config.params,
              url: response.config.url
            },
            status: response.status
          }
        });
        yield put(
          actionSuccess({
            original: action.payload,
            values,
            response: {
              data: response.data,
              config: {
                method: response.config.method,
                params: response.config.params,
                url: response.config.url
              },
              status: response.status
            }
          })
        );
        yield postSuccess({
          original: action.payload,
          values,
          response: {
            data: response.data,
            config: {
              method: response.config.method,
              params: response.config.params,
              url: response.config.url
            },
            status: response.status
          }
        });
      } catch (error) {
        const abort = yield preFailure({
          original: action.payload,
          values,
          response,
          error: error
        });
        if (abort === true) return;
        yield put(
          actionFailure({
            original: action.payload,
            values,
            response,
            error: error
          })
        );
        yield postFailure({
          original: action.payload,
          values,
          response,
          error: error
        });
      }
    },
    watcher: function* () {
      yield takeEvery(actionGenerator, this.handler);
    }
  };
};

export default basicFlow;
