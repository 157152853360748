import React, { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import { removeNonDigitsFromString } from '../../utils/basic';

const formatPhone = (value, isMobile) => {
  const stripped = removeNonDigitsFromString(value);

  let finalValue = '(' + stripped.slice(0, 2);
  if (stripped.length > 2) {
    finalValue += ') ' + stripped.slice(2, isMobile ? 7 : 6);
  }
  if (stripped.length > (isMobile ? 7 : 6)) {
    finalValue += '-' + stripped.slice(isMobile ? 7 : 6, isMobile ? 11 : 10);
  }
  return finalValue;
};

const formatPhoneV2 = (value) => {
  const stripped = removeNonDigitsFromString(value);
  const isMobile = stripped.length > 10;

  let finalValue = '(' + stripped.slice(0, 2);
  if (stripped.length > 2) {
    finalValue += ') ' + stripped.slice(2, isMobile ? 7 : 6);
  }
  if (stripped.length > (isMobile ? 7 : 6)) {
    finalValue += '-' + stripped.slice(isMobile ? 7 : 6, isMobile ? 11 : 10);
  }
  return finalValue;
};

const PhoneTextField = forwardRef(({ onChange, isMobile, ...other }, ref) => (
  <NumberFormat
    {...other}
    getInputRef={ref}
    onValueChange={(values) => {
      onChange(values.formattedValue);
    }}
    format={(value) => formatPhoneV2(value)}
  />
));

export default PhoneTextField;
