import { FormControl, FormHelperText, Skeleton, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import React from 'react';

const ControlledOutlinedInput = ({
  formProps,
  name,
  ignoreError = false,
  loading,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  if (loading) {
    return (
      <Skeleton sx={{ height: 37 }} animation="wave" variant={"rounded"}  />
    )
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({ field: { onChange, onBlur, value = '' } }) => (
        <FormControl error={isError} fullWidth >
          <TextField
            {...otherProps}
            value={value}
            error={isError}
            size="small"
            sx={{
              '& .MuiOutlinedInput-input': {
                minHeight: '22.13px',
              },
            }}
            onChange={(v) => {
              onChange(v);
              if (otherProps.onChange) {
                otherProps.onChange(v);
              }
            }}
            onBlur={() => {
              onBlur();
              if (otherProps.onBlur) {
                otherProps.onBlur();
              }
            }}
          />
          {!ignoreError && errors[name] && (
            <FormHelperText>{errors[name]?.message}</FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default ControlledOutlinedInput;
