// third party
import React from 'react';

// assets
import { Box, Stack } from '@mui/material';
import OutlinedInput from '../../components/form/OutlinedInput';
import CpfCnpjTextField from '../../components/form/CpfCnpjTextField';
import Switch from '../../components/form/Switch';
import CurrencyTextField from '../../components/form/CurrencyTextField';
import InputLabel from '../../components/form/InputLabel';
import DateTextField from '../../components/form/DateTextField';
import ControlledAutocomplete from '../../components/form/Autocomplete';
import { useSelector } from 'react-redux';

const Cliente = ({ formProps, conjuge, setConjuge }) => {

  const tiposEstadoCivil = useSelector(
    (state) => state.dominios.tiposEstadoCivil
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nome">Nome</InputLabel>
          <OutlinedInput
            id="nome"
            name="nome"
            placeholder="Digite o nome"
            fullWidth
            formProps={formProps}
            inputProps={{
              maxLength: 100
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfCnpj">CPF/CNPJ</InputLabel>
          <OutlinedInput
            id="cpfCnpj"
            name="cpfCnpj"
            placeholder="Digite o CPF/CNPJ"
            fullWidth
            formProps={formProps}
            type="both"
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 15
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataNascimento">Data de Nascimento</InputLabel>
          <OutlinedInput
            id="dataNascimento"
            name="dataNascimento"
            placeholder="Digite a Data de Nascimento"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="tipoEstadoCivil">Estado Civil</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoEstadoCivil"
            name="tipoEstadoCivil"
            placeholder="Selecione o Estado de Civil"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposEstadoCivil}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="rendaTotal">Renda</InputLabel>
          <OutlinedInput
            id="rendaTotal"
            name="rendaTotal"
            placeholder="Digite a Renda"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="utilizaFgtsSimulacao">
            Utilizará o FGTS?
          </InputLabel>
          <Switch
            formProps={formProps}
            fullWidth
            name="utilizaFgtsSimulacao"
            id="utilizaFgtsSimulacao"
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fgCompoeRenda">
            Haverá Composição de Renda?
          </InputLabel>
          <Switch
            formProps={formProps}
            fullWidth
            name="fgCompoeRenda"
            id="fgCompoeRenda"
            onChange={(e) => setConjuge(e.target.checked)}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1, display: conjuge ? 'block' : 'none' }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeConjuge">
            Nome
          </InputLabel>
          <OutlinedInput
            id="nomeConjuge"
            name="nomeConjuge"
            placeholder="Digite o nome"
            fullWidth
            formProps={formProps}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1, display: conjuge ? 'block' : 'none' }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cpfConjuge">CPF</InputLabel>
          <OutlinedInput
            id="cpfConjuge"
            name="cpfConjuge"
            placeholder="Digite o CPF"
            fullWidth
            formProps={formProps}
            type="both"
            InputProps={{
              inputComponent: CpfCnpjTextField,
              maxLength: 15
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1, display: conjuge ? 'block' : 'none' }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="dataNascimentoConjuge">Data de Nascimento</InputLabel>
          <OutlinedInput
            id="dataNascimentoConjuge"
            name="dataNascimentoConjuge"
            placeholder="Digite a Data de Nascimento"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: DateTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1, display: conjuge ? 'block' : 'none'  }}>
        <InputLabel htmlFor="tipoEstadoCivilConjuge">Estado Civil</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="tipoEstadoCivilConjuge"
            name="tipoEstadoCivilConjuge"
            placeholder="Selecione o Estado de Civil"
            required
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tiposEstadoCivil}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1, display: conjuge ? 'block' : 'none'  }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="rendaConjuge">Renda</InputLabel>
          <OutlinedInput
            id="rendaConjuge"
            name="rendaConjuge"
            placeholder="Digite a Renda"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Cliente;
