

// project import
import React from 'react';

// assets
import { Grid, Stack } from '@mui/material';
import OutlinedInput from '../../../components/form/OutlinedInput';
import InputLabel from '../../../components/form/InputLabel';

const HeaderResumo = ({ formProps, loading }) => {

  return (
    <Grid container spacing={2} sx={{p:2}}>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="operacao" loading={loading}>Operação</InputLabel>
          <OutlinedInput
            id="operacao"
            name="operacao"
            placeholder="Escolha a Operação"
            disabled={true}
            fullWidth
            labelField={'nomeOperacao'}
            formProps={formProps}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="regional" loading={loading}>Regional</InputLabel>
            <OutlinedInput
              id="regional"
              name="regional"
              placeholder="Escolha a Regional"
              fullWidth
              disabled={true}
              formProps={formProps}
              loading={loading}
            />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="parceiro" loading={loading}>Parceiro</InputLabel>
            <OutlinedInput
              id="parceiro"
              name="parceiro"
              placeholder="Escolha o parceiro"
              fullWidth
              disabled={true}
              formProps={formProps}
              loading={loading}
            />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="usuarioParceiro" loading={loading}>Usuário Parceiro</InputLabel>
            <OutlinedInput
              id="usuarioParceiro"
              name="usuarioParceiro"
              placeholder="Escolha o Usuário Parceiro"
              fullWidth
              disabled={true}
              formProps={formProps}
              loading={loading}
            />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="consultor" loading={loading}>Consultor</InputLabel>
          <OutlinedInput
            id="consultor"
            name="consultor"
            disabled={true}
            fullWidth
            formProps={formProps}
            loading={loading}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="analista" loading={loading}>Analista</InputLabel>
          <OutlinedInput
            id="analista"
            name="analista"
            disabled={true}
            fullWidth
            formProps={formProps}
            loading={loading}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default HeaderResumo;
