import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/usuarios.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarUsuarios = () => {
  return authenticatedRequest({
    url: `/configuracao-usuario/list`,
    method: 'get'
  });
};

const listarUsuarios = basicFlow({
  actionGenerator: actions.listarUsuariosRequest,
  actionFailure: actions.listarUsuariosFailure,
  actionSuccess: actions.listarUsuariosSuccess,
  api: apiListarUsuarios,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarUsuariosParceiros = () => {
  return authenticatedRequest({
    url: `/configuracao-usuario/list/usuarios-parceiros`,
    method: 'get'
  });
};

const listarUsuariosParceiros = basicFlow({
  actionGenerator: actions.listarUsuariosParceirosRequest,
  actionFailure: actions.listarUsuariosParceirosFailure,
  actionSuccess: actions.listarUsuariosParceirosSuccess,
  api: apiListarUsuariosParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarRegionais = () => {
  return authenticatedRequest({
    url: `/configuracao-usuario/list/regionais`,
    method: 'get'
  });
};

const listarRegionais = basicFlow({
  actionGenerator: actions.listarRegionaisRequest,
  actionFailure: actions.listarRegionaisFailure,
  actionSuccess: actions.listarRegionaisSuccess,
  api: apiListarRegionais,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiListarParceiros = () => {
  return authenticatedRequest({
    url: `/configuracao-usuario/list/parceiros`,
    method: 'get'
  });
};

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceirosRequest,
  actionFailure: actions.listarParceirosFailure,
  actionSuccess: actions.listarParceirosSuccess,
  api: apiListarParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarUsuario = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario/create`,
    method: 'POST',
    body: value
  });
};

const criarUsuarios = basicFlow({
  actionGenerator: actions.criarUsuarioRequest,
  actionFailure: actions.criarUsuarioFailure,
  actionSuccess: actions.criarUsuarioSuccess,
  api: apiCriarUsuario,
  postSuccess: function* ({ response }) {
    toast.success('Usuario incluído com sucesso.');
    yield put(
      routeActions.redirectTo(routes.USUARIO, {
        idUsuario: response.data.id
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarUsuario = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario/update/${value.id}`,
    method: 'PUT',
    body: value
  });
};

const editarUsuarios = basicFlow({
  actionGenerator: actions.editarUsuarioRequest,
  actionFailure: actions.editarUsuarioFailure,
  actionSuccess: actions.editarUsuarioSuccess,
  api: apiEditarUsuario,
  postSuccess: function* (value) {
    yield toast.success('Usuario editado com sucesso.');
    const { idUsuario } = yield select(getPayload);
    yield put(actions.mostrarUsuarioRequest(idUsuario));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarUsuario = (id) => {
  return authenticatedRequest({
    url: `/configuracao-usuario/show/${id}`,
    method: 'get'
  });
};

const mostrarUsuario = basicFlow({
  actionGenerator: actions.mostrarUsuarioRequest,
  actionFailure: actions.mostrarUsuarioFailure,
  actionSuccess: actions.mostrarUsuarioSuccess,
  api: apiMostrarUsuario,
  postFailure: function* ({ error }) {
    yield toast.error('Usuario não encontrado.');
  }
});

const apiExcluirUsuario = ({ value }) => {
  return authenticatedRequest({
    url: `/configuracao-usuario/delete/${value.id}`,
    method: 'delete'
  });
};

const excluirUsuario = basicFlow({
  actionGenerator: actions.excluirUsuarioRequest,
  actionFailure: actions.excluirUsuarioFailure,
  actionSuccess: actions.excluirUsuarioSuccess,
  api: apiExcluirUsuario,
  postSuccess: function* ({ original }) {
    const situacao = original.value.situacao === 'I' ? 'ativado' : 'inativado';

    yield toast.success(`Usuario ${situacao} com sucesso.`);
    yield put(actions.listarUsuariosRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarUsuarioRouteWatcher() {
  yield routeWatcher(routes.USUARIO, function* () {
    const { idUsuario } = yield select(getPayload);
    if (idUsuario === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparUsuario());
      yield put(actions.listarParceirosRequest());
      yield put(actions.listarUsuariosParceirosRequest());
      yield put(actions.listarRegionaisRequest());
      return;
    }
    if (!idUsuario) return;
    yield put(actions.mostrarUsuarioRequest(idUsuario));
    yield put(actions.ativarModoEdicao());
    yield put(actions.listarParceirosRequest());
    yield put(actions.listarUsuariosParceirosRequest());
    yield put(actions.listarRegionaisRequest());
  });
}

function* listarUsuariosRouteWatcher() {
  yield routeWatcher(routes.USUARIOS, function* () {
    yield put(actions.listarUsuariosRequest());
  });
}

export const sagas = [
  listarUsuarios.watcher(),
  criarUsuarios.watcher(),
  editarUsuarios.watcher(),
  mostrarUsuario.watcher(),
  excluirUsuario.watcher(),
  listarUsuariosRouteWatcher(),
  mostrarUsuarioRouteWatcher(),
  listarParceiros.watcher(),
  listarUsuariosParceiros.watcher(),
  listarRegionais.watcher()
];
