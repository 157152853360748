// third-party
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

// project import
import reducers from './reducers';
import { loadState } from './utils/localStorage';
import rootSagas from './sagas';
import { middleware as routesMiddleware, enhancer as routesEnhancer } from './routes';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //
const sagaMiddleware = createSagaMiddleware();

const initializeStore = () => {
  const store = configureStore({
    reducer: reducers,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: loadState() ?? {},
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(routesMiddleware).concat(sagaMiddleware).concat(logger),
    enhancers: (defaultEnhancers) => [routesEnhancer, ...defaultEnhancers()],
  });

  sagaMiddleware.run(rootSagas);
  return store;
}

const store = initializeStore();
const { dispatch } = store;

export { store, dispatch };