import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  queryString: '',
  menuSelecionado: 0,
  pesquisa: {
    loading: false,
    error: false,
    data: []
  }
};

const PesquisaSlice = createSlice({
  name: 'pesquisa',
  initialState,
  reducers: {
    pesquisaRequest: (state) => {
      state.pesquisa.loading = true;
      state.pesquisa.error = null;
    },
    pesquisaSuccess: (state, action) => {
      state.pesquisa.loading = false;
      state.pesquisa.error = false;
      state.pesquisa.data = action.payload?.response?.data;
    },
    pesquisaFailure: (state, action) => {
      state.pesquisa.loading = false;
      state.pesquisa.error = action.payload;
    },
    alterarMenuSelecionado: (state, action) => {
      state.menuSelecionado = action.payload;
    },
    alterarQueryString: (state, action) => {
      state.queryString = action.payload;
    }
  }
});

export const {
  pesquisaRequest,
  pesquisaSuccess,
  pesquisaFailure,
  alterarMenuSelecionado,
  alterarQueryString
} = PesquisaSlice.actions;

export default PesquisaSlice.reducer;
