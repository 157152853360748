import { authenticatedRequest, unauthenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/regionais.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';

const apiListarRegionais = () => {
  return authenticatedRequest({
    url: `/regional/list`,
    method: 'get'
  });
};

const listarRegionais = basicFlow({
  actionGenerator: actions.listarRegionaisRequest,
  actionFailure: actions.listarRegionaisFailure,
  actionSuccess: actions.listarRegionaisSuccess,
  api: apiListarRegionais,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarRegional = ({ value }) => {
  return authenticatedRequest({
    url: `/regional/create`,
    method: 'POST',
    body: value
  });
};

const criarRegionais = basicFlow({
  actionGenerator: actions.criarRegionalRequest,
  actionFailure: actions.criarRegionalFailure,
  actionSuccess: actions.criarRegionalSuccess,
  api: apiCriarRegional,
  postSuccess: function* ({ response }) {
    toast.success('Regional incluída com sucesso.');
    yield put(
      routeActions.redirectTo(routes.REGIONAL, {
        idRegional: response.data.idRegional
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarRegional = ({ value }) => {
  return authenticatedRequest({
    url: `/regional/update/${value.idRegional}`,
    method: 'PUT',
    body: value
  });
};

const editarRegionais = basicFlow({
  actionGenerator: actions.editarRegionalRequest,
  actionFailure: actions.editarRegionalFailure,
  actionSuccess: actions.editarRegionalSuccess,
  api: apiEditarRegional,
  postSuccess: function* (value) {
    yield toast.success('Regional editada com sucesso.');
    const { idRegional } = yield select(getPayload);
    yield put(actions.mostrarRegionalRequest(idRegional));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarRegional = (id) => {
  return authenticatedRequest({
    url: `/regional/show/${id}`,
    method: 'get'
  });
};

const mostrarRegional = basicFlow({
  actionGenerator: actions.mostrarRegionalRequest,
  actionFailure: actions.mostrarRegionalFailure,
  actionSuccess: actions.mostrarRegionalSuccess,
  api: apiMostrarRegional,
  postFailure: function* ({ error }) {
    yield toast.error('Regional não encontrada.');
  }
});

const apiExcluirRegional = ({ value }) => {
  return authenticatedRequest({
    url: `/regional/delete/${value.idRegional}`,
    method: 'delete'
  });
};

const excluirRegional = basicFlow({
  actionGenerator: actions.excluirRegionalRequest,
  actionFailure: actions.excluirRegionalFailure,
  actionSuccess: actions.excluirRegionalSuccess,
  api: apiExcluirRegional,
  postSuccess: function* () {
    yield toast.success('Regional excluída com sucesso.');
    yield put(actions.listarRegionaisRequest());
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

const apiListarParceiros = (idRegional) => {
  return authenticatedRequest({
    url: `/regional/${idRegional}/parceiros`,
    method: 'get'
  });
};

const listarParceiros = basicFlow({
  actionGenerator: actions.listarParceirosRequest,
  actionFailure: actions.listarParceirosFailure,
  actionSuccess: actions.listarParceirosSuccess,
  api: apiListarParceiros,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarParceirosRouteWatcher() {
  yield routeWatcher(routes.REGIONAL_PARCEIROS, function* () {
    const { idRegional } = yield select(getPayload);
    yield put(actions.listarParceirosRequest(idRegional));
    yield put(actions.mostrarRegionalRequest(idRegional));
  });
}

function* mostrarRegionalRouteWatcher() {
  yield routeWatcher(routes.REGIONAL, function* () {
    const { idRegional } = yield select(getPayload);
    if (idRegional === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparRegional());
      return;
    }
    if (!idRegional) return;
    yield put(actions.mostrarRegionalRequest(idRegional));
    yield put(actions.ativarModoEdicao());
  });
}

function* listarRegionaisRouteWatcher() {
  yield routeWatcher(routes.REGIONAIS, function* () {
    yield put(actions.listarRegionaisRequest());
  });
}

const buscarCep = basicFlow({
  actionGenerator: actions.buscarCepRequest,
  actionFailure: actions.buscarCepFailure,
  actionSuccess: actions.buscarCepSuccess,
  api: ({ value }) => {
    return unauthenticatedRequest({
      url: `https://viacep.com.br/ws/${value}/json/`,
      method: 'get',
      isCep: true
    });
  },
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
  postFailure: function* (props) {
    if (!!props.original.failureCallback) {
      yield props.original.failureCallback(props);
    }
  }
});

export const sagas = [
  listarRegionais.watcher(),
  criarRegionais.watcher(),
  editarRegionais.watcher(),
  mostrarRegional.watcher(),
  excluirRegional.watcher(),
  buscarCep.watcher(),
  listarRegionaisRouteWatcher(),
  mostrarRegionalRouteWatcher(),
  listarParceiros.watcher(),
  listarParceirosRouteWatcher()
];
