// material-ui
import { Box, Typography } from '@mui/material';

// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return (
          <NavGroup key={item.id} item={item} style={{ display: 'none' }} />
        );
      default:
        return (
          <Typography
            key={item.id}
            variant="h6"
            style={{ display: 'none' }}
            color="error"
            align="center"
          >
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
