// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import MainLayout from '../../../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React, { useState } from 'react';
import MainCard from '../../../components/MainCard';
import { DeleteOutlined, EditOutlined, BankOutlined } from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import AnimateButton from 'components/@extended/AnimateButton';

import { Button, Box } from '@mui/material';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

import * as actions from 'reducers/produtosAtividadesBanco.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import useTheme from '@mui/system/useTheme';
import { toast } from 'react-toastify';
import { Grid } from '@mui/material';
import { Typography } from '../../../../node_modules/@mui/material/index';
import Loader from 'components/Loader';

const AtividadesBancoPage = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const etapas = useSelector(
    (state) => state.produtosAtividadesBanco.atividadesBanco.data
  );
  const loading = useSelector(
    (state) => state.produtosAtividadesBanco.atividadesBanco.loading
  );

  const idOperacao = useSelector(
    (state) => state.produtosAtividadesBanco.idOperacao
  );
  const idEtapa = useSelector((state) => state.produtosAtividadesBanco.idEtapa);

  const idAtividade = useSelector(
    (state) => state.produtosAtividadesBanco.idAtividade
  );

  const produto = useSelector(
    (state) => state.produtosAtividades.atividade.data
  );
  const produtoEtapa = useSelector((state) => state.produtosEtapas.etapa.data);
  const produtoOperacao = useSelector((state) => state.produtos.produto.data);

  const disabled =
    produto?.tipoSituacao !== 'A' ||
    produtoOperacao?.tipoSituacao !== 'A' ||
    produtoEtapa?.tipoSituacao !== 'A';

  const loadingProduto = useSelector(
    (state) => state.produtosAtividades.atividade.loading
  );

  const rows = etapas;

  const columns = [
    {
      field: 'nomeBanco',
      headerName: 'Banco',
      flex: 1
    },
    {
      field: 'nomeAtividadeBanco',
      headerName: 'Nome',
      flex: 1
    }
  ];

  const [open, setOpen] = useState(false);

  const handleClickOpen = (row) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNewProduto = () => {
    dispatch(
      routeActions.redirectTo(routes.PRODUTO_ATIVIDADE_BANCO, {
        idAtividadeBanco: 'novo',
        idEtapa,
        idOperacao,
        idAtividade
      })
    );
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.PRODUTO_ATIVIDADES, {
        idOperacao,
        idEtapa
      })
    );
  };

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleDeleteClick = () => {
      dispatch(
        actions.excluirAtividadeBancoRequest({ value: selectedRowData[0] })
      );
      setOpen(false);
    };

    const handleEdit = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        dispatch(
          routeActions.redirectTo(routes.PRODUTO_ATIVIDADE_BANCO, {
            idAtividadeBanco: selectedRowData[0].idAtividadeBanco,
            idEtapa,
            idOperacao,
            idAtividade
          })
        );
      }
    };

    return (
      <>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            Confirmação para Exclusão de Atividade Banco
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Tem certeza de que deseja excluir a Atividade Banco{' '}
              {selectedRowData[0]?.nomeAtividadeBanco}? Ao excluir uma atividade
              banco, não será mais possível recuperar suas informações.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleDeleteClick} color="error">
              Excluir
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            disabled={disabled}
            variant="text"
            color="primary"
            onClick={handleNewProduto}
            startIcon={<BankOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Incluir Atividade Banco
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleEdit}
            startIcon={<EditOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Editar
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickOpen}
            startIcon={<DeleteOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: `${
                selectedRows.size !== 1
                  ? theme.palette.grey.lighter
                  : theme.palette.error.main
              } !important`
            }}
          >
            Excluir
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  if (loadingProduto) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <MainCard
        title={
          <Box>
            <Typography variant="h2">{produto?.nomeAtividade}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Atividades Banco
            </Typography>
          </Box>
        }
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            getRowId={(row) => row.idAtividadeBanco}
            toolbar={CustomToolbar}
            loading={loading}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              density: 'standard'
            }}
            noLineText="Nenhuma atividade banco encontrada"
          />
        </Box>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default AtividadesBancoPage;
