import React from 'react';
import { Box, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from '../../../../components/form/OutlinedInput';
import { useSelector } from 'react-redux';
import ControlledAutocomplete from 'components/form/Autocomplete';

const DadosProfissionais = ({ formProps }) => {
  const bancos = useSelector((state) => state.dominios.listarBancos);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeProfissao">Profissão</InputLabel>
          <OutlinedInput
            id="nomeProfissao"
            name="nomeProfissao"
            placeholder="Digite a profissão"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeEmpresaProfissao">Empresa</InputLabel>
          <OutlinedInput
            id="nomeEmpresaProfissao"
            name="nomeEmpresaProfissao"
            placeholder="Digite o nome da empresa"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <InputLabel htmlFor="idBanco">Banco</InputLabel>
        <Stack spacing={0}>
          <ControlledAutocomplete
            id="idBanco"
            name="idBanco"
            placeholder="Selecione o Banco"
            required
            fullWidth
            formProps={formProps}
            labelField={'nomeBanco'}
            idField={'idBanco'}
            domain={bancos.data}
          />
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoAgencia">Agência</InputLabel>
          <OutlinedInput
            id="codigoAgencia"
            name="codigoAgencia"
            placeholder="Digite o código da agência"
            fullWidth
            formProps={formProps}
            type="number"
            inputProps={{ inputProps: { max: 9999, min: 0 } }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoContaCorrente">Conta Corrente</InputLabel>
          <OutlinedInput
            id="codigoContaCorrente"
            name="codigoContaCorrente"
            placeholder="Digite o número da conta corrente"
            fullWidth
            formProps={formProps}
            type="number"
            inputProps={{ inputProps: { max: 999999999999999, min: 0 } }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="digitoContaCorrente">Dígito da Conta</InputLabel>
          <OutlinedInput
            id="digitoContaCorrente"
            name="digitoContaCorrente"
            placeholder="Digite o dígito da conta"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 1 }}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default DadosProfissionais;
