// assets
import {
  DashboardOutlined,
  FormOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { types as routes } from '../reducers/rotas.actions';

// icons
const icons = {
  DashboardOutlined,
  FormOutlined,
  DollarOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Oportunidades',
  type: 'group',
  children: [
    {
      id: 'minhas-atividades',
      title: 'Minhas Tarefas',
      type: 'item',
      icon: icons.FormOutlined,
      breadcrumbs: false,
      url: '/oportunidades/minhas-atividades',
      action: routes.MINHAS_ATIVIDADES,
      params: { id: 2 },
      restricted: ['AL', 'CS', 'UP']
    },
    {
      id: 'oportunidades',
      title: 'Oportunidades',
      type: 'item',
      url: '/oportunidades',
      icon: icons.DollarOutlined,
      breadcrumbs: false,
      action: routes.OPORTUNIDADES
    }
  ]
};

export default dashboard;
