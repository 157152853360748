import formatters from '../../../utils/formatters';
import Chip from '../../../components/Chip';
import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';

const situacaoChips = {
  P: { label: "Erro ao Enviar Proposta", color: 'error' },
  N: { label: "Em Análise Crédito", color: 'info' },
  X: { label: "Sem Integração", color: 'info' },
  A: { label: "Crédito Aprovado", color: 'success' },
  R: { label: "Crédito Recusado", color: 'error' }
};

const opcoesFiltro = (props, itens) => {

  return (
    <>
      <InputLabel htmlFor="opcoes">Opções</InputLabel>
      <Select
        multiple
        value={props.item.value || []}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
        fullWidth
        id={'opcoes'}
        name={'opcoes'}
      >
        <MenuItem value="">
          <em>Nenhum</em>
        </MenuItem>
        {itens.map((option) => (
          <MenuItem key={option.codigo} value={option.codigo}>
            {option.nome}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const funcaoFiltro = (filterItem) => {
  if (!filterItem.value || filterItem.value.length === 0) {
    return null;
  }
  return (params) => {
    return filterItem.value.includes(params);
  };
};

export const columns = ({ bancos, situacaoIntegracao }) => {

  return [
    { field: 'id', headerName: 'ID', type: 'number', flex: 1 },
    {
      field: 'banco',
      headerName: 'Banco',
      flex: 1,
      valueGetter: (value, row) => {
        return `${row.banco?.nomeBanco ?? ''}`;
      },
      minWidth: 100,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, bancos.filter((f) => f.flagSimulacao === "S").map((f) => {return {codigo: f.nomeBanco, nome: f.nomeBanco}}))
        }
      ]
    },
    {
      field: 'bancoEscolhido',
      headerName: 'Banco Escolhido',
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => {
        return value === 'N' ? 'Não' : 'Sim';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'Sim', nome: 'Sim' },
              { codigo: 'Não', nome: 'Não' },
            ])
        }
      ]
    },
    {
      field: 'valorImovel',
      headerName: 'Valor Imóvel',
      type: 'number',
      flex: 1,
      valueFormatter: (value) =>
        value ? `R$ ${formatters.numbers.currency(value)}` : '',
      minWidth: 100
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const situacao = params.value;
        const chipProps = situacaoChips[situacao] || {
          label: situacao,
          color: 'default'
        };
        return (
          <Chip
            size="small"
            variant="outlined"
            label={chipProps.label}
            color={chipProps.color}
            sx={{ fontSize: '11px', fontWeight: 700 }}
          />
        );
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, situacaoIntegracao)
        }
      ]
    },
    {
      field: 'dataHoraEnvioIntegracao',
      headerName: 'Envio Integração',
      type: 'date',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleDateString('pt-BR') : ''
    },
    {
      field: 'dataHoraRetornoIntegracao',
      headerName: 'Retorno Integração',
      type: 'date',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) =>
        value ? new Date(value).toLocaleDateString('pt-BR') : ''
    },
    {
      field: 'codigoOportunidadeBanco',
      headerName: 'Oportunidade Banco',
      flex: 1,
      minWidth: 100
    },
    {
      field: 'valorTotalFinanciamento',
      headerName: 'R$ Financiamento',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) =>
        value ? `R$ ${formatters.numbers.currency(value)}` : ''
    },
    {
      field: 'valorParcelaBanco',
      headerName: 'Valor Parcela',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) =>
        value ? `R$ ${formatters.numbers.currency(value)}` : ''
    },
    {
      field: 'prazoPagamentoSimulacao',
      headerName: 'Prazo Pagamento',
      type: 'number',
      flex: 1,
      minWidth: 100
    },
    {
      field: 'taxaJurosAnoBanco',
      headerName: 'Taxa Juros Ano',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) => (value ? `${value}%` : '')
    },
    {
      field: 'valorIofBanco',
      headerName: 'Valor IOF',
      type: 'number',
      flex: 1,
      minWidth: 100,
      valueFormatter: (value) =>
        value ? `R$ ${formatters.numbers.currency(value)}` : ''
    },
    {
      field: 'codigoSistemaAmortizacaoBanco',
      headerName: 'Sistema Amortização',
      flex: 1,
      minWidth: 100,
      valueGetter: (value) => {
        return value === 'S' ? 'SAC' : 'Price';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'SAC', nome: 'SAC' },
              { codigo: 'Price', nome: 'Price' },
            ])
        }
      ]
    },
    {
      field: 'codigoIndexadorBanco',
      headerName: 'Indexador',
      flex: 1,
      minWidth: 100
    }
  ];
};
