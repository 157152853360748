// material-ui
import { DataGrid } from 'components/grid/DataGrid';
import Box from '@mui/material/Box';

// project import
import { useSelector, useDispatch } from 'react-redux';
import React from 'react';
import { columns as columnsOportunidade } from './ColumnsAtividades';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { Button } from '@mui/material';
import { Launch } from '@mui/icons-material';

import * as actions from 'reducers/minhasAtividades.reducer';
import { Card } from '../../../node_modules/@mui/material/index';

const PesquisaAtividades = () => {
  const minhasAtividades = useSelector(
    (state) => state.pesquisa.pesquisa.data.atividades
  );

  const dispatch = useDispatch();

  const tiposAtividades = [
    {
      codigo: 'OP',
      nome: 'Nova Oportunidade'
    },
    {
      codigo: 'CD',
      nome: 'Novo Documento'
    },
    {
      codigo: 'RD',
      nome: 'Recusa de Documento'
    },
    {
      codigo: 'CM',
      nome: 'Novo Comentário'
    }
  ];

  const columns = columnsOportunidade({ tiposAtividades });

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const handleAcessarOportunidade = () => {
      const { idOportunidade, id } = selectedRowData[0];
      dispatch(
        actions.encerrarMinhasAtividadesRequest({
          value: {
            id: id,
            isRedirect: true,
            idOportunidade
          }
        })
      );
    };

    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            startIcon={<Launch />}
            disabled={selectedRows.size !== 1}
            onClick={handleAcessarOportunidade}
          >
            Acessar Oportunidade
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  return (
    <Card>
      <Box sx={{ marginTop: '5px' }}>
        <DataGrid
          rows={minhasAtividades ?? []}
          columns={columns}
          density={'standard'}
          pageSize={10}
          pageSizeOptions={[10]}
          loading={false}
          toolbar={CustomToolbar}
          isOportunidade={true}
          noLineText="Nenhuma atividade localizada"
        />
      </Box>
    </Card>
  );
};

export default PesquisaAtividades;
