import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  idParticipante: null,
  loading: false,
  error: false,
  data: [],
  buscarCep: {
    loading: false,
    error: null
  },
  pessoaFisica: {
    isEditMode: false,
    loading: false,
    error: false,
    data: null
  },
  pessoaJuridica: {
    isEditMode: false,
    loading: false,
    error: false,
    data: null,
    representantes: [],
    representante: {
      loading: false,
      error: false,
      data: null
    }
  }
};

const VendedoresSlice = createSlice({
  name: 'vendedores',
  initialState,
  reducers: {
    desativarModoEdicao: (state) => {
      state.pessoaFisica.isEditMode = false;
      state.pessoaJuridica.isEditMode = false;
      state.pessoaJuridica.representante.isEditMode = false;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    adicionarParticipante: (state, action) => {
      state.idParticipante = Number(action.payload);
    },
    listarVendedoresRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    listarVendedoresSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data = action.payload.response.data;
    },
    listarVendedoresFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    mostrarVendedorPessoaFisicaRequest: (state, action) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    mostrarVendedorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload.response.data;
      state.pessoaFisica.isEditMode = true;
    },
    mostrarVendedorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    criarVendedorPessoaFisicaRequest: (state) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    criarVendedorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload;
    },
    criarVendedorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    editarVendedorPessoaFisicaRequest: (state) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    editarVendedorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload;
    },
    editarVendedorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    formatarPessoaFisica: (state, action) => {
      state.pessoaFisica.data = action.payload;
    },
    mostrarVendedorPessoaJuridicaRequest: (state, action) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    mostrarVendedorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload.response.data;
      state.pessoaJuridica.isEditMode = true;
    },
    mostrarVendedorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    formatarPessoaJuridica: (state, action) => {
      state.pessoaJuridica.data = action.payload;
    },
    criarVendedorPessoaJuridicaRequest: (state) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    criarVendedorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload;
    },
    criarVendedorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    editarVendedorPessoaJuridicaRequest: (state) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    editarVendedorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload;
    },
    editarVendedorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    buscarCepRequest: (state) => {
      state.buscarCep.loading = true;
      state.buscarCep.error = null;
    },
    buscarCepSuccess: (state) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = false;
    },
    buscarCepFailure: (state, action) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = action.payload;
    },
    removerRepresentanteRequest: (state, action) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    removerRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
    },
    removerRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    criarRepresentanteRequest: (state) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    criarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload;
    },
    criarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    editarRepresentanteRequest: (state) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    editarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload;
    },
    editarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    mostrarRepresentanteRequest: (state, action) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    mostrarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload.response.data;
      state.pessoaJuridica.representante.isEditMode = true;
    },
    mostrarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    formatarRepresentante: (state, action) => {
      state.pessoaJuridica.representante.data = action.payload;
    },
    removerParticipanteRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    removerParticipanteSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
    },
    removerParticipanteFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    limparPessoaFisica: (state) => {
      state.pessoaFisica.data = {
        isEditMode: false,
        loading: false,
        error: false,
        data: null
      };
    },
    limparPessoaJuridica: (state) => {
      state.pessoaJuridica.data = {
        isEditMode: false,
        loading: false,
        error: false,
        data: null
      };
    },
    limparRepresentante: (state) => {
      state.pessoaJuridica.representante.data = {
        loading: false,
        error: false,
        data: null
      };
    }
  }
});

export const {
  desativarModoEdicao,
  adicionarOportunidade,
  adicionarParticipante,
  listarVendedoresRequest,
  listarVendedoresSuccess,
  listarVendedoresFailure,
  mostrarVendedorPessoaFisicaRequest,
  mostrarVendedorPessoaFisicaSuccess,
  mostrarVendedorPessoaFisicaFailure,
  criarVendedorPessoaFisicaRequest,
  criarVendedorPessoaFisicaSuccess,
  criarVendedorPessoaFisicaFailure,
  editarVendedorPessoaFisicaRequest,
  editarVendedorPessoaFisicaSuccess,
  editarVendedorPessoaFisicaFailure,
  mostrarVendedorPessoaJuridicaRequest,
  mostrarVendedorPessoaJuridicaSuccess,
  mostrarVendedorPessoaJuridicaFailure,
  criarVendedorPessoaJuridicaRequest,
  criarVendedorPessoaJuridicaSuccess,
  criarVendedorPessoaJuridicaFailure,
  editarVendedorPessoaJuridicaRequest,
  editarVendedorPessoaJuridicaSuccess,
  editarVendedorPessoaJuridicaFailure,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  listarRepresentantesRequest,
  listarRepresentantesSuccess,
  listarRepresentantesFailure,
  removerRepresentanteRequest,
  removerRepresentanteSuccess,
  removerRepresentanteFailure,
  criarRepresentanteRequest,
  criarRepresentanteSuccess,
  criarRepresentanteFailure,
  editarRepresentanteRequest,
  editarRepresentanteSuccess,
  editarRepresentanteFailure,
  mostrarRepresentanteRequest,
  mostrarRepresentanteSuccess,
  mostrarRepresentanteFailure,
  formatarRepresentante,
  formatarPessoaJuridica,
  formatarPessoaFisica,
  removerParticipanteRequest,
  removerParticipanteSuccess,
  removerParticipanteFailure,
  limparPessoaFisica,
  limparPessoaJuridica,
  limparRepresentante
} = VendedoresSlice.actions;

export default VendedoresSlice.reducer;
