import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  operacao: {
    loading: false,
    error: false,
    data: []
  },
  parceiro: {
    loading: false,
    error: false,
    data: []
  },
  regional: {
    loading: false,
    error: false,
    data: []
  },
  usuario: {
    loading: false,
    error: false,
    data: []
  },
  usuarioParceiro: {
    loading: false,
    error: false,
    data: []
  },
  listarBancos: {
    loading: false,
    error: false,
    data: []
  },
  etapa: {
    loading: false,
    error: false,
    data: []
  },
  tiposSituacaoAtividade: [
    { value: 'N', label: 'Não Iniciada' },
    { value: 'E', label: 'Em Andamento' },
    { value: 'C', label: 'Concluída' }
  ],

  tipoImovel: {
    loading: false,
    error: false,
    data: [
      { id: 'AP', nome: 'Apartamento' },
      { id: 'CS', nome: 'Casa' },
      { id: 'GA', nome: 'Galpão' },
      { id: 'TE', nome: 'Terreno' },
      { id: 'TC', nome: 'Terreno em condomínio' }
    ]
  },
  tiposEmpresa: [
    { value: 'EPP', label: 'Empresa de Pequeno Porte (EPP)' },
    {
      value: 'EIRELI',
      label: 'Empresa Individual de Responsabilidade Limitada (EIRELI)'
    },
    { value: 'MEI', label: 'Microempreendedor Individual (MEI)' },
    { value: 'ME', label: 'Microempresa (ME)' },
    { value: 'SA', label: 'Sociedade Anônima (SA)' }
  ],
  tiposSexo: [
    { id: 'M', nome: 'Masculino' },
    { id: 'F', nome: 'Feminino' }
  ],
  sistemaAmortizacao: [
    { id: 'S', nome: 'SAC' },
    { id: 'P', nome: 'Price' }
  ],
  tiposImovel: [
    { id: 'AP', nome: 'Apartamento' },
    { id: 'CS', nome: 'Casa' },
    { id: 'GA', nome: 'Galpão' },
    { id: 'TE', nome: 'Terreno' },
    { id: 'TC', nome: 'Terreno em Condomínio' }
  ],
  tiposUtilizacao: [
    { id: 'C', nome: 'Comercial' },
    { id: 'R', nome: 'Residencial' }
  ],
  tiposDocumentos: [
    { id: 'RG', nome: 'RG' },
    { id: 'CNH', nome: 'CNH' }
  ],
  tiposEstadoCivil: [
    { id: 'CA', nome: 'Casado(a)' },
    { id: 'DI', nome: 'Divorciado(a)' },
    { id: 'SL', nome: 'Separado(a) Legalmente' },
    { id: 'S', nome: 'Solteiro(a)' },
    { id: 'UE', nome: 'União Estável' },
    { id: 'VI', nome: 'Viúvo(a)' }
  ],
  tiposRegimeCasamento: [
    { id: 'CP', nome: 'Comunhão Parcial de Bens' },
    { id: 'CU', nome: 'Comunhão Universal de Bens' },
    { id: 'PA', nome: 'Participação Final nos Aquestos' },
    { id: 'SC', nome: 'Separação Convencional de Bens' },
    { id: 'SO', nome: 'Separação Obrigatória de Bens' }
  ],
  utilizacaoImovel: {
    loading: false,
    error: false,
    data: [
      { id: 'C', nome: 'Comercial' },
      { id: 'R', nome: 'Residencial' }
    ]
  },
  unidadesFederativa: [
    {
      codigo: 'RO',
      nome: 'Rondônia',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'AC',
      nome: 'Acre',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'AM',
      nome: 'Amazonas',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'RR',
      nome: 'Roraima',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'PA',
      nome: 'Pará',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'AP',
      nome: 'Amapá',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'TO',
      nome: 'Tocantins',
      regiao: { id: 1, sigla: 'N', nome: 'Norte' }
    },
    {
      codigo: 'MA',
      nome: 'Maranhão',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'PI',
      nome: 'Piauí',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'CE',
      nome: 'Ceará',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'RN',
      nome: 'Rio Grande Do Norte',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'PB',
      nome: 'Paraíba',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'PE',
      nome: 'Pernambuco',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'AL',
      nome: 'Alagoas',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'SE',
      nome: 'Sergipe',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'BA',
      nome: 'Bahia',
      regiao: { id: 2, sigla: 'NE', nome: 'Nordeste' }
    },
    {
      codigo: 'MG',
      nome: 'Minas Gerais',
      regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' }
    },
    {
      codigo: 'ES',
      nome: 'Espírito Santo',
      regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' }
    },
    {
      codigo: 'RJ',
      nome: 'Rio De Janeiro',
      regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' }
    },
    {
      codigo: 'SP',
      nome: 'São Paulo',
      regiao: { id: 3, sigla: 'SE', nome: 'Sudeste' }
    },
    {
      codigo: 'PR',
      nome: 'Paraná',
      regiao: { id: 4, sigla: 'S', nome: 'Sul' }
    },
    {
      codigo: 'SC',
      nome: 'Santa Catarina',
      regiao: { id: 4, sigla: 'S', nome: 'Sul' }
    },
    {
      codigo: 'RS',
      nome: 'Rio Grande Do Sul',
      regiao: { id: 4, sigla: 'S', nome: 'Sul' }
    },
    {
      codigo: 'MS',
      nome: 'Mato Grosso Do Sul',
      regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' }
    },
    {
      codigo: 'MT',
      nome: 'Mato Grosso',
      regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' }
    },
    {
      codigo: 'GO',
      nome: 'Goiás',
      regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' }
    },
    {
      codigo: 'DF',
      nome: 'Distrito Federal',
      regiao: { id: 5, sigla: 'CO', nome: 'Centro-Oeste' }
    }
  ].sort((a, b) => (a.nome > b.nome ? 1 : -1)),
  temperatura: [
    { codigo: 'Q', nome: 'Quente' },
    { codigo: 'M', nome: 'Morna' },
    { codigo: 'F', nome: 'Fria' }
  ],
  bancos: [
    { codigo: 45, nome: 'Bradesco' },
    { codigo: 61, nome: 'Itaú' },
    { codigo: 9, nome: 'Santander' }
  ],
  tipoSituacaoOportunidade: [
    { codigo: 'A', nome: 'Ativa' },
    { codigo: 'C', nome: 'Cancelada' },
    { codigo: 'T', nome: 'Contrato Emitido' }
  ],
  situacaoImovel: [
    { codigo: 'N', nome: 'Novo', integracao: 1 },
    { codigo: 'U', nome: 'Usado', integracao: 2 },
  ]
};

const DominiosSlice = createSlice({
  name: 'dominios',
  initialState,
  reducers: {
    operacaoRequest: (state) => {
      state.operacao.loading = true;
      state.operacao.error = null;
    },
    operacaoSuccess: (state, action) => {
      state.operacao.loading = false;
      state.operacao.error = false;
      state.operacao.data = action.payload?.response?.data;
    },
    operacaoFailure: (state, action) => {
      state.operacao.loading = false;
      state.operacao.error = action.payload;
    },

    parceiroRequest: (state) => {
      state.parceiro.loading = true;
      state.parceiro.error = null;
    },
    parceiroSuccess: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.error = false;
      state.parceiro.data = action.payload?.response?.data;
    },
    parceiroFailure: (state, action) => {
      state.parceiro.loading = false;
      state.parceiro.error = action.payload;
    },

    regionalRequest: (state) => {
      state.regional.loading = true;
      state.regional.error = null;
    },
    regionalSuccess: (state, action) => {
      state.regional.loading = false;
      state.regional.error = false;
      state.regional.data = action.payload?.response?.data;
    },
    regionalFailure: (state, action) => {
      state.regional.loading = false;
      state.regional.error = action.payload;
    },

    usuariosRequest: (state) => {
      state.usuario.loading = true;
      state.usuario.error = null;
    },
    usuariosSuccess: (state, action) => {
      state.usuario.loading = false;
      state.usuario.error = false;
      state.usuario.data = action.payload?.response?.data;
    },
    usuariosFailure: (state, action) => {
      state.usuario.loading = false;
      state.usuario.error = action.payload;
    },

    usuariosParceirosRequest: (state) => {
      state.usuarioParceiro.loading = true;
      state.usuarioParceiro.error = null;
    },
    usuariosParceirosSuccess: (state, action) => {
      state.usuarioParceiro.loading = false;
      state.usuarioParceiro.error = false;
      state.usuarioParceiro.data = action.payload?.response?.data.sort(
        (a, b) => {
          return a.nome > b.nome ? 1 : -1;
        }
      );
    },
    usuariosParceirosFailure: (state, action) => {
      state.usuarioParceiro.loading = false;
      state.usuarioParceiro.error = action.payload;
    },
    bancosRequest: (state) => {
      state.listarBancos.loading = true;
      state.listarBancos.error = null;
    },
    bancosSuccess: (state, action) => {
      state.listarBancos.loading = false;
      state.listarBancos.error = false;
      state.listarBancos.data = action.payload?.response?.data;
    },
    bancosFailure: (state, action) => {
      state.listarBancos.loading = false;
      state.listarBancos.error = action.payload;
    },
    etapasRequest: (state) => {
      state.etapa.loading = true;
      state.etapa.error = null;
    },
    etapasSuccess: (state, action) => {
      state.etapa.loading = false;
      state.etapa.error = false;
      state.etapa.data = action.payload?.response?.data;
    },
    etapasFailure: (state, action) => {
      state.etapa.loading = false;
      state.etapa.error = action.payload;
    }
  }
});

export const {
  operacaoRequest,
  operacaoSuccess,
  operacaoFailure,
  parceiroRequest,
  parceiroSuccess,
  parceiroFailure,
  regionalFailure,
  regionalRequest,
  regionalSuccess,
  usuariosFailure,
  usuariosRequest,
  usuariosSuccess,
  bancosFailure,
  bancosRequest,
  bancosSuccess,
  usuariosParceirosFailure,
  usuariosParceirosRequest,
  usuariosParceirosSuccess,
  etapasRequest,
  etapasSuccess,
  etapasFailure
} = DominiosSlice.actions;

export default DominiosSlice.reducer;
