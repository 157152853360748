import { createSyncAction } from '../utils/actionCreators';
import { NOT_FOUND } from 'redux-first-router';

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: '@@route/OPEN_WINDOW',
  LOGIN: '@@route/login',
  ESQUECI_SENHA: '@@route/esqueci-minha-senha',
  TROCAR_SENHA: '@@route/trocar-senha',
  OPORTUNIDADES: '@@route/oportunidades',
  TOKEN: '@@route/token',
  SIMULACAO: '@@route/simulacao',
  COMPRADORES: '@@route/compradores',
  PESSOA_FISICA: '@@route/pessoa-fisica',
  PESSOA_JURIDICA: '@@route/pessoa-juridica',
  REPRESENTANTE: '@@route/representante',
  OPORTUNIDADE: '@@route/oportunidade',
  IMOVEL: '@@route/imovel',
  INTERVENIENTE_QUITANTE: '@@route/interveniente-quitante',
  VENDEDORES: '@@route/vendedores',
  VENDEDOR_PESSOA_FISICA: '@@route/vendedores-pessoa-fisica',
  VENDEDOR_PESSOA_JURIDICA: '@@route/vendedores-pessoa-juridica',
  VENDEDOR_REPRESENTANTE: '@@route/vendedores-representante',
  ATIVIDADES: '@@route/atividades',
  FUP: '@@route/fup',
  DOCUMENTOS: '@@route/documentos',
  DOCUMENTO: '@@route/documento',
  SIMULACAOBANCO: '@@route/simulacaoBanco',
  MINHAS_ATIVIDADES: '@@route/minhas-atividades',
  NOVASIMULACAO: '@@route/novaSimulacao',
  PESQUISA: '@@route/pesquisa',
  BANCOS: '@@route/bancos',
  BANCO: '@@route/banco',
  REGIONAIS: '@@route/regionais',
  REGIONAL: '@@route/regional',
  REGIONAL_PARCEIROS: '@@route/regional-parceiro',
  CONFIGURACAO_DOCUMENTOS: '@@route/configuracao-documentos',
  CONFIGURACAO_DOCUMENTO: '@@route/configuracao-documento',
  PRODUTOS: '@@route/produtos',
  PRODUTO: '@@route/produto',
  PRODUTO_ETAPA: '@@route/produto-etapa',
  PRODUTO_ETAPAS: '@@route/produto-etapas',
  PRODUTO_ATIVIDADE: '@@route/produto-atividade',
  PRODUTO_ATIVIDADES: '@@route/produto-atividades',
  PRODUTO_ATIVIDADE_BANCO: '@@route/produto-atividade-banco',
  PRODUTO_ATIVIDADES_BANCO: '@@route/produto-atividades-banco',
  PARCEIROS: '@@route/parceiros',
  PARCEIRO: '@@route/parceiro',
  PARCEIRO_DOCUMENTO: '@@route/parceiro-documento',
  PARCEIRO_FUP: '@@route/parceiro-fup',
  USUARIOS_PARCEIROS: '@@route/usuarios-parceiros',
  USUARIOS_PARCEIRO: '@@route/usuarios-parceiro',
  USUARIOS_PARCEIRO_DOCUMENTO: '@@route/usuarios-parceiro-documento',
  USUARIOS_PARCEIRO_FUP: '@@route/usuarios-parceiro-fup',
  USUARIOS: '@@route/usuarios',
  USUARIO: '@@route/usuario',
  FUNIL_DE_VENDAS: '@@route/funil-de-vendas'
};

export const actions = {
  redirectTo: (route, params = {}) => {
    return createSyncAction(route, params);
  },

  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: 'redirect'
        }
      }
    })
};
