import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect } from 'react';
import * as actions from 'reducers/produtosAtividadesBanco.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import DadosDaAtividadeBanco from './DadosDaAtividadeBanco';

const AtividadeBanco = () => {
  const dispatch = useDispatch();
  const produto = useSelector(
    (state) => state.produtosAtividadesBanco.atividadeBanco.data
  );

  const produtoAtividade = useSelector(
    (state) => state.produtosAtividades.atividade.data
  );
  const produtoEtapa = useSelector((state) => state.produtosEtapas.etapa.data);
  const produtoOperacao = useSelector((state) => state.produtos.produto.data);

  const disabled =
    produtoOperacao?.tipoSituacao !== 'A' ||
    produtoEtapa?.tipoSituacao !== 'A' ||
    produtoAtividade?.tipoSituacao !== 'A';

  console.log({ produto, produtoOperacao, produtoEtapa, produtoAtividade });

  const loading = useSelector(
    (state) => state.produtosAtividadesBanco.atividadeBanco.loading
  );

  const idOperacao = useSelector(
    (state) => state.produtosAtividadesBanco.idOperacao
  );
  const idEtapa = useSelector((state) => state.produtosAtividadesBanco.idEtapa);

  const idAtividade = useSelector(
    (state) => state.produtosAtividadesBanco.idAtividade
  );

  const isEditMode = useSelector(
    (state) => state.produtosAtividadesBanco.atividadeBanco.isEditMode
  );

  const bancos = useSelector(
    (state) => state.produtosAtividadesBanco.bancos.data
  );

  const initialValues = {
    // Dados do Atividade
    nomeAtividadeBanco: '',
    banco: ''
  };

  const rules = {
    // Dados do Atividade
    nomeAtividadeBanco: validators.string({ required: true }),
    banco: validators.object({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: produto
  };

  useEffect(() => {
    if (!!produto) {
      formProps.reset({
        ...produto,
        banco: produto?.idBanco
          ? bancos.find((b) => b.idBanco === produto.idBanco)
          : null
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [produto, bancos]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      idBanco: values.banco?.idBanco,
      idAtividade
    };

    if (isEditMode) {
      dispatch(actions.editarAtividadeBancoRequest({ value: payload }));
    } else {
      dispatch(actions.criarAtividadeBancoRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.PRODUTO_ATIVIDADES_BANCO, {
        idOperacao,
        idEtapa,
        idAtividade
      })
    );
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !produto?.idAtividade) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Atividade Banco não encontrada'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Nova Atividade Banco'
            ) : (
              <Box>
                <Typography variant="h2">
                  {produto?.nomeAtividadeBanco}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Atividade Banco
                </Typography>
              </Box>
            )
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DA ATIVIDADE BANCO" style={{ flex: 1 }}>
                <DadosDaAtividadeBanco
                  formProps={formProps}
                  disabled={disabled}
                />
              </FormCard>
            </Box>
          </Box>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disabled={disabled}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default AtividadeBanco;
