import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useCallback, useEffect } from 'react';
import DadosDoParceiro from './DadosDoParceiro';
import Endereco from './Endereco';
import * as actions from 'reducers/usuariosParceiros.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import { removeNonDigitsFromString } from 'utils/basic';
import DadosDoProprietario from './DadosDoProprietario';
import DadosDoFaturamento from './DadosDoFaturamento';
import { validateCpfCnpj } from 'utils/validateCpfCnpj ';

const Parceiro = () => {
  const dispatch = useDispatch();
  const parceiro = useSelector(
    (state) => state.usuariosParceiros.parceiro.data
  );

  const parceiroPai = useSelector((state) => state.parceiros.parceiro.data);

  const idParceiro = useSelector((state) => state.usuariosParceiros.idParceiro);

  const loadingParceiros = useSelector(
    (state) => state.usuariosParceiros.parceiro.loading
  );

  const consultores =
    useSelector((state) => state.usuariosParceiros.usuarios.consultores.data) ??
    [];

  const analistas =
    useSelector((state) => state.usuariosParceiros.usuarios.analistas.data) ??
    [];

  const loadingUsuarios = useSelector(
    (state) => state.usuariosParceiros.usuarios.loading
  );

  const loading = loadingParceiros || loadingUsuarios;

  const origensParceiro = [
    { codigo: 'RM', nome: 'RE/MAX' },
    { codigo: 'IN', nome: 'Indicação' },
    { codigo: 'PR', nome: 'Prospecção' },
    { codigo: 'ST ', nome: 'Site' },
    { codigo: 'MK', nome: 'Marketing' }
  ];

  const perfis = [
    { codigo: 'AS', nome: 'Assessoria' },
    { codigo: 'IM', nome: 'Imobiliária' },
    { codigo: 'CO', nome: 'Corretor' },
    { codigo: 'CA', nome: 'Cartório' }
  ];

  const isEditMode = useSelector(
    (state) => state.usuariosParceiros.parceiro.isEditMode
  );

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const initialValues = {
    // Dados do Parceiro
    nomeUsuarioParceiro: parceiro?.nomeUsuarioParceiro ?? '',
    cpfCnpj: parceiro?.cpfCnpj ?? '',
    tipoOrigem: parceiro?.tipoOrigem ?? '',
    nomeProprietario: parceiro?.nomeProprietario ?? '',
    emailProprietario: parceiro?.emailProprietario ?? '',
    celularProprietario: parceiro?.celularProprietario ?? '',
    nomeFaturamento: parceiro?.nomeFaturamento ?? '',
    emailFaturamento: parceiro?.emailFaturamento ?? '',
    celularFaturamento: parceiro?.celularFaturamento ?? '',
    cep: parceiro?.cep ?? '',
    logradouro: parceiro?.logradouro ?? '',
    numeroLogradouro: parceiro?.numeroLogradouro ?? '',
    complementoLogradouro: parceiro?.complementoLogradouro ?? '',
    bairro: parceiro?.bairro ?? '',
    municipio: parceiro?.municipio ?? '',
    uf: parceiro?.uf ?? '',
    idUsuarioCriacao: parceiro?.idUsuarioCriacao ?? null,
    dataHoraCriacao: parceiro?.dataHoraCriacao ?? '',
    idUsuarioAlteracao: parceiro?.idUsuarioAlteracao ?? null,
    dataHoraAlteracao: parceiro?.dataHoraAlteracao ?? '',
    idRegional: parceiro?.idRegional ?? null,
    tipoSituacao: parceiro?.tipoSituacao ?? '',
    origem: origensParceiro.find((el) => el.codigo === parceiro?.tipoOrigem),
    consultor: consultores?.find(
      (el) => el.id === parceiro?.idUsuarioConsultor
    ),
    analista: analistas?.find((el) => el.id === parceiro?.idUsuarioAnalista),
    perfil: perfis.find((el) => el.codigo === parceiro?.perfil)
  };

  const rules = {
    // Dados do Parceiro
    nomeUsuarioParceiro: validators.string({ required: true, maxLength: 100 }),

    cpfCnpj: validators.string({ required: true }),
    emailProprietario: validators.email({ required: false }),
    emailFaturamento: validators.email({ required: false }),
    celularFaturamento: validators.phone({
      required: false
    }),
    celularProprietario: validators.phone({
      required: false
    }),

    tipoOrigem: validators.string({ required: true, maxLength: 2 }),
    origem: validators.object({ required: true }),
    regional: validators.object({ required: true }),
    nomeProprietario: validators.string({ required: false, maxLength: 100 }),
    nomeFaturamento: validators.string({ required: false, maxLength: 100 }),
    cep: validators.string({ required: false, maxLength: 8 }),
    logradouro: validators.string({ required: false, maxLength: 40 }),
    numeroLogradouro: validators.string({ required: false, maxLength: 7 }),
    complementoLogradouro: validators.string({
      required: false,
      maxLength: 30
    }),
    bairro: validators.string({ required: false, maxLength: 40 }),
    municipio: validators.string({ required: false, maxLength: 60 }),
    uf: validators.string({ required: false, maxLength: 2 }),
    idUsuarioCriacao: validators.number({ required: true, min: 1 }),
    dataHoraCriacao: validators.date({ required: true }),
    idUsuarioAlteracao: validators.number({ required: true, min: 1 }),
    dataHoraAlteracao: validators.date({ required: true }),
    idRegional: validators.number({ required: true, min: 1 }),
    tipoSituacao: validators.string({ required: true, maxLength: 1 }),
    consultor: validators.object({ required: false }),
    analista: validators.object({ required: false })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: parceiro
  };

  useEffect(() => {
    if (!!parceiro) {
      formProps.reset({
        ...parceiro,
        origem: origensParceiro.find(
          (el) => el.codigo === parceiro?.tipoOrigem
        ),
        consultor: consultores?.find(
          (el) => el.id === parceiro?.idUsuarioConsultor
        ),
        analista: analistas?.find(
          (el) => el.id === parceiro?.idUsuarioAnalista
        ),
        flagSimulacao: parceiro.flagSimulacao === 'S' ? true : false,
        uf: ufs.find((el) => el.codigo === parceiro.uf) ?? null,
        perfil: perfis.find((el) => el.codigo === parceiro?.perfil)
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parceiro, consultores, analistas]);

  const handleSubmit = (values) => {
    const formattedCpfCnpj = removeNonDigitsFromString(values.cpfCnpj);

    const cpfCnpjError = validateCpfCnpj(formattedCpfCnpj);

    if (cpfCnpjError !== true) {
      formProps.setError('cpfCnpj', {
        type: 'manual',
        message: cpfCnpjError
      });
      return;
    }

    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      cpfCnpj: formattedCpfCnpj,
      cep: removeNonDigitsFromString(values.cep),
      idUsuarioConsultor: values.consultor?.id ?? null,
      idUsuarioAnalista: values.analista?.id ?? null,
      tipoOrigem: values.origem?.codigo,
      perfil: values.perfil?.codigo,
      idParceiro: values?.idParceiro ?? Number(idParceiro)
    };

    if (typeof payload.uf === 'object') {
      payload.uf = payload?.uf?.codigo;
    }

    if (isEditMode) {
      dispatch(actions.editarParceiroRequest({ value: payload }));
    } else {
      dispatch(actions.criarParceiroRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.USUARIOS_PARCEIROS, {
        idParceiro: parceiro?.idParceiro ?? idParceiro
      })
    );
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  const disabled =
    (isEditMode && parceiro?.tipoSituacao !== 'A') |
    (parceiroPai?.tipoSituacao !== 'A');

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (
    isEditMode &&
    !parceiro?.idUsuarioParceiro |
      !parceiroPai?.idParceiro |
      (parceiro?.idParceiro !== parceiroPai?.idParceiro)
  ) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Parceiro não encontrada'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Novo Usuário Parceiro'
            ) : (
              <Box>
                <Typography variant="h2">
                  {parceiro?.nomeUsuarioParceiro}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Usuário Parceiro
                </Typography>
              </Box>
            )
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              gap: 2
            }}
          >
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO PARCEIRO" style={{ flex: 1 }}>
                <DadosDoParceiro
                  disabled={disabled}
                  consultores={consultores}
                  analistas={analistas}
                  origensParceiro={origensParceiro}
                  formProps={formProps}
                  perfis={perfis}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO PROPRIETÁRIO" style={{ flex: 1 }}>
                <DadosDoProprietario
                  formProps={formProps}
                  disabled={disabled}
                />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="DADOS DO FATURAMENTO" style={{ flex: 1 }}>
                <DadosDoFaturamento formProps={formProps} disabled={disabled} />
              </FormCard>
            </Box>
            <Box sx={{ flex: 1 }}>
              <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                <Endereco
                  formProps={formProps}
                  handleZipCodeBlurred={handleZipCodeBlurred}
                  disabled={disabled}
                />
              </FormCard>
            </Box>
          </Box>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disabled={disabled}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Parceiro;
