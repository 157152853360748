import React from "react";
import { InputLabel as IL, Skeleton } from "@mui/material";

const InputLabel = ({htmlFor, children, loading}) => {

  return (
    <IL htmlFor={htmlFor} sx={{fontWeight: 600, pb: "3px"}}>{loading ? <Skeleton /> : children}</IL>
  )
}

export default InputLabel;