import React, { useEffect } from 'react';

// material-ui
import { Card, Grid } from '@mui/material';

// project import
import HeaderResumo from './HeaderResumo';
import validators from '../../../utils/validators';
import { useForm } from 'react-hook-form';
import Financiamento from './Financiamento';
import { useSelector } from 'react-redux';
import Simulacoes from './Simulacoes';

const ResumoPage = () => {
  const { loading, data } = useSelector(state => state.oportunidade);

  const usuarios = useSelector((state) => state.dominios.usuario);
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const tiposImovel = useSelector((state) => state.dominios.tiposImovel);
  const tiposUtilizacaoImovel = useSelector((state) => state.dominios.utilizacaoImovel.data);

  const initialValues = {
    operacao: "",
    parceiro: "",
    regional: "",
    usuarioParceiro: "",
    consultor: "",
    analista: "",

    tipoImovel: "",
    usoImovel: "",
    uf: "",
    valorImovel: "",
    valorFinanciamento: "",
    prazo: "",
  };

  const rules = {
    operacao: validators.string({ required: true }),
    parceiro: validators.string({ required: true }),
    regional: validators.string({ required: true }),
    usuarioParceiro: validators.string({ required: true }),

    tipoImovel: validators.string({ required: true }),
    usoImovel: validators.string({ required: true }),
    uf: validators.string({ required: true }),
    valorImovel: validators.number({ required: true, min: 0}),
    valorFinanciamento: validators.number({
      required: true,
      min: 0,
      custom: {
        rules: (value) => {
          return (value <= formProps.getValues("valorImovel") || `Valor deve ser menor/igual ao valor do imóvel`)
        }
      }
    }),
    prazo: validators.number({
      required: true,
      custom: {
        rules: (value) => {
          return ((value >= 20 && value <= 360) || `Prazo deve ser um número entre 20 e 360`)
        }
      }
    }),

  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  useEffect(() => {
    if (!!data.oportunidade) {
      formProps.reset({
        ...data.oportunidade,
        tipoImovel: tiposImovel.find((i) => i.id === data.oportunidade.tipoImovel),
        usoImovel: tiposUtilizacaoImovel.find((i) => i.id === data.oportunidade.tipoUtilizacaoImovel),
        uf: ufs.find((i) => i.codigo === data.oportunidade.uf),
        valorFinanciamento: data?.oportunidade?.valorFinanciamentoSimulacao,
        prazo: data?.oportunidade?.prazoPagamentoSimulacao,
        operacao: data?.oportunidade?.operacao?.nomeOperacao,
        parceiro: data?.oportunidade?.parceiro?.nomeParceiro,
        regional: data?.oportunidade?.regional?.nomeRegional,
        usuarioParceiro: data?.oportunidade?.usuarioParceiro?.nomeUsuarioParceiro,
        consultor: usuarios?.data.find((u) => u.id === data.oportunidade.usuarioParceiro?.idUsuarioConsultor)?.nome ?? "",
        analista: usuarios?.data.find((u) => u.id === data.oportunidade.usuarioParceiro?.idUsuarioAnalista)?.nome ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.oportunidade]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ mt: "20px" }}>
          <HeaderResumo formProps={formProps} loading={loading}/>
        </Card>
      </Grid>
      <Grid item xs={12} sx={{display: 'none'}}>
        <Card sx={{ mt: 2 }}>
          <Financiamento formProps={formProps}  loading={loading}/>
        </Card>
      </Grid>
      <Grid item xs={12} style={{paddingTop: 0}}>
        <Card sx={{ mt: "20px" }}>
          <Simulacoes />
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResumoPage;
