import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select, MenuItem } from '@mui/material';
import {
  UserAddOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';
import { DataGrid } from 'components/grid/DataGrid';
import * as actions from 'reducers/documentos.reducer';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import Loader from 'components/Loader';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import { Box, Grid, Stack, Typography } from '@mui/material';
import formatters from 'utils/formatters';
import InputLabel from 'components/form/InputLabel';
import { funcaoFiltro } from 'utils/funcaoFiltro';
import { opcoesFiltro } from 'utils/opcoesFiltro';

const EntidadesRelacionadas = () => {
  const dispatch = useDispatch();

  const loading = useSelector(
    (state) => state.documentos.entidadesRelacionadas.loading
  );

  const documentosAdicionaveis = useSelector(
    (state) => state.documentos.entidadesRelacionadas.participantes
  );

  const idOportunidade = useSelector(
    (state) => state.documentos.idOportunidade
  );

  const [openAddDocument, setOpenAddDocument] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [selectedParticipante, setSelectedParticipante] = useState('');
  const [documentList, setDocumentList] = useState([]);
  const [open, setOpen] = useState(false);

  const handleCloseAddDocument = () => {
    setOpenAddDocument(false);
  };

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    const selectedRowData = Array.from(selectedRows.values());

    const detalhes = selectedRowData[0] || {};

    const handleOpenDocumento = () => {
      dispatch(actions.adicionarReferencia(selectedRowData[0].tipo));
      dispatch(
        routeActions.openNewWindowWith(routes.DOCUMENTO, {
          idDocumento: selectedRowData[0].idDocumentoOportunidade,
          id: idOportunidade
        })
      );
    };

    const handleUpdate = () => {
      dispatch(actions.listarEntidadesRelacionadasRequest(idOportunidade));
    };

    const handleSaveDocument = async () => {
      const payload = {
        idDocumento: selectedDocument,
        idParticipante:
          documentosAdicionaveis.find((el) => el.id === selectedParticipante)
            ?.idParticipante ?? null,
        idRepresentante:
          documentosAdicionaveis.find((el) => el.id === selectedParticipante)
            ?.idRepresentante ?? null,
        tipoDocumento: documentosAdicionaveis.find(
          (el) => el.id === selectedParticipante
        )?.tipoDocumento,
        idOportunidade: idOportunidade
      };

      dispatch(actions.criarDocumentoRequest({ value: payload }));

      handleCloseAddDocument();
    };

    const handleOpenAddDocument = () => {
      setOpenAddDocument(true);
      if (selectedRows.size === 1) {
        const referente = documentosAdicionaveis.find(
          (el) => el.referente === selectedRowData[0].referente
        );
        if (referente) {
          setSelectedParticipante(referente.id);
          setDocumentList(referente.documentosAdicionaveis);
          setSelectedDocument(null);
        }
      } else {
        setSelectedParticipante('');
        setDocumentList([]);
        setSelectedDocument(null);
      }
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };

    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle variant="h3">Detalhes do Documento</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h5">Participante</Typography>
                <Typography variant="h6">{detalhes.referente}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Tipo</Typography>
                <Typography variant="h6">{detalhes.tipo}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">Documento</Typography>
                <Typography variant="h6">{detalhes.nomeDocumento}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Situação</Typography>
                <Typography variant="h6">
                  {detalhes.tipoSituacao === 'P'
                    ? 'Pendente'
                    : detalhes.tipoSituacao === 'I'
                      ? 'Analisar'
                      : detalhes.tipoSituacao === 'A'
                        ? 'Analisado Aprovado'
                        : detalhes.tipoSituacao === 'R'
                          ? 'Analisado Recusado'
                          : detalhes.tipoSituacao === 'D'
                            ? 'Dispensado'
                            : ''}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5">Tipo de Inclusão</Typography>
                <Typography variant="h6">
                  {detalhes.tipoFormaInclusao === 'A' ? 'Automática' : 'Manual'}
                </Typography>
              </Grid>
              {detalhes.dataHoraEnvio && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5">Data Envio</Typography>
                    <Typography variant="h6">
                      {detalhes.dataInclusao
                        ? formatters.dates.withHours(
                            new Date(detalhes.dataHoraEnvio)
                          )
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Enviado por</Typography>
                    <Typography variant="h6">
                      {detalhes.usuarioEnvio?.nome || 'N/A'}
                    </Typography>
                  </Grid>
                </>
              )}
              {detalhes.dataHoraAnalise && (
                <>
                  <Grid item xs={6}>
                    <Typography variant="h5">Data Análise</Typography>
                    <Typography variant="h6">
                      {detalhes.dataHoraAnalise
                        ? formatters.dates.withHours(
                            new Date(detalhes.dataHoraAnalise)
                          )
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h5">Analisado por</Typography>
                    <Typography variant="h6">
                      {detalhes.usuarioAnalise?.nome || 'N/A'}
                    </Typography>
                  </Grid>
                </>
              )}
              {detalhes.comentarioAnalise && (
                <Grid item xs={12}>
                  <Typography variant="h5">Comentário</Typography>
                  <Typography variant="h6" style={{ whiteSpace: 'pre-wrap' }}>
                    {detalhes.comentarioAnalise}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openAddDocument} onClose={handleCloseAddDocument}>
          <DialogTitle>Adicionar Documento</DialogTitle>
          <DialogContent
            sx={{
              minWidth: 400
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2
              }}
            >
              <Box sx={{ flex: 1 }}>
                <Stack spacing={0}>
                  <InputLabel htmlFor="participantes">Participantes</InputLabel>
                  <Select
                    id="participantes"
                    value={selectedParticipante}
                    onChange={(e) => {
                      setSelectedParticipante(e.target.value);
                      setDocumentList(
                        documentosAdicionaveis.find(
                          (el) => el.id === e.target.value
                        ).documentosAdicionaveis
                      );
                    }}
                    fullWidth
                  >
                    <MenuItem value={null}></MenuItem>
                    {documentosAdicionaveis.map((doc) => (
                      <MenuItem key={doc.id} value={doc.id}>
                        {`${doc.referente} ${doc.tipo && `(${doc.tipo})`}`}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Stack spacing={0}>
                  <InputLabel htmlFor="documentos">Documentos</InputLabel>
                  <Select
                    id="documentos"
                    value={selectedDocument}
                    onChange={(e) => setSelectedDocument(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value={null}></MenuItem>
                    {documentList.map((doc) => (
                      <MenuItem key={doc.idDocumento} value={doc.idDocumento}>
                        {doc.nomeDocumento}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddDocument} color="secondary">
              Cancelar
            </Button>
            <Button
              onClick={handleSaveDocument}
              color="primary"
              disabled={typeof selectedDocument !== 'number'}
            >
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>

        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />
          <GridToolbarExport
            csvOptions={{ includeHeaders: true, allColumns: true }}
          />
          <Button
            variant="text"
            color="primary"
            onClick={handleOpenAddDocument}
            startIcon={<UserAddOutlined />}
          >
            Adicionar Documento
          </Button>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleOpenDocumento}
            startIcon={<FileSearchOutlined />}
          >
            Indexar/Analisar Documento
          </Button>
          <Button
            variant="text"
            color="secondary"
            disabled={selectedRows.size !== 1}
            onClick={handleClickOpen}
            startIcon={<InfoCircleOutlined />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px',
              color: 'green'
            }}
          >
            Detalhes
          </Button>
          <Button
            variant="text"
            color="primary"
            onClick={handleUpdate}
            startIcon={<ReloadOutlined />}
          >
            Atualizar
          </Button>
        </GridToolbarContainer>
      </>
    );
  };

  const documentos = useSelector(
    (state) => state.documentos.entidadesRelacionadas.data
  );

  const uniqueTipos = documentos
    .filter(
      (doc, index, self) => index === self.findIndex((t) => t.tipo === doc.tipo)
    )
    .map((doc) => ({
      codigo: doc.tipo,
      nome: doc.tipo
    }))
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const uniqueDocumentos = documentos
    .filter(
      (doc, index, self) =>
        index === self.findIndex((t) => t.nomeDocumento === doc.nomeDocumento)
    )
    .map((doc) => ({
      codigo: doc.nomeDocumento,
      nome: doc.nomeDocumento
    }))
    .sort((a, b) => a.nome.localeCompare(b.nome));

  const columns = [
    {
      field: 'referente',
      headerName: 'Participante',
      flex: 1
    },
    {
      field: 'tipo',
      headerName: 'Tipo',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, [...uniqueTipos])
        }
      ]
    },
    {
      field: 'nomeDocumento',
      headerName: 'Documento',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) => opcoesFiltro(props, [...uniqueDocumentos])
        }
      ]
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      valueFormatter: (value) => {
        return value === 'P'
          ? 'Pendente'
          : value === 'I'
            ? 'Analisar'
            : value === 'A'
              ? 'Analisado Aprovado'
              : value === 'R'
                ? 'Analisado Recusado'
                : value === 'D'
                  ? 'Dispensado'
                  : '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'A', nome: 'Analisado Aprovado' },
              { codigo: 'R', nome: 'Analisado Recusado' },
              { codigo: 'I', nome: 'Analisar' },
              { codigo: 'D', nome: 'Dispensado' },
              { codigo: 'P', nome: 'Pendente' }
            ])
        }
      ]
    }
  ];

  return (
    <DataGrid
      rows={documentos}
      columns={columns}
      disableSelectionOnClick
      pageSize={100}
      rowsPerPageOptions={[100]}
      autoHeight={false}
      getRowId={(row) => row.idDocumentoOportunidade}
      toolbar={CustomToolbar}
      infiniteScroll
    />
  );
};

export default EntidadesRelacionadas;
