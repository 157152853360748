import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
import 'simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { LicenseInfo } from '@mui/x-license';

// apex-chart
import 'assets/third-party/apex-chart.css';

// project import
import App from './App';
import { store } from './store';
import reportWebVitals from './reportWebVitals';

// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root');
if (container) {
  LicenseInfo.setLicenseKey('25e200fdae705b1260745f521dd06c2dTz05NTg1NCxFPTE3NTQ3NzI5ODgwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(
    <ReduxProvider store={store}>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </ReduxProvider>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals(console.log);
} else {
  console.error('Root element not found');
}
