import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bancos: {
    loading: false,
    error: false,
    data: []
  },
  banco: {
    loading: false,
    error: false,
    data: null,
    isEditMode: false
  }
};

const BancosSlice = createSlice({
  name: 'bancos',
  initialState,
  reducers: {
    listarBancosRequest: (state) => {
      state.bancos.loading = true;
      state.bancos.error = null;
      state.bancos.data = [];
    },
    listarBancosSuccess: (state, action) => {
      state.bancos.loading = false;
      state.bancos.error = false;
      state.bancos.data = action.payload.response.data;
    },
    listarBancosFailure: (state, action) => {
      state.bancos.loading = false;
      state.bancos.error = action.payload;
    },
    criarBancoRequest: (state, action) => {
      state.banco.loading = true;
      state.banco.loading = true;
      state.banco.error = null;
    },
    criarBancoSuccess: (state, action) => {
      state.banco.loading = false;
      state.banco.loading = false;
      state.banco.error = false;
    },
    criarBancoFailure: (state, action) => {
      state.banco.loading = false;
      state.banco.loading = false;
      state.banco.error = action.payload;
    },
    editarBancoRequest: (state, action) => {
      state.banco.loading = true;
      state.banco.loading = true;
      state.banco.error = null;
    },
    editarBancoSuccess: (state, action) => {
      state.banco.loading = false;
      state.banco.loading = false;
      state.banco.error = false;
    },
    editarBancoFailure: (state, action) => {
      state.banco.loading = false;
      state.banco.loading = false;
      state.banco.error = action.payload;
    },
    mostrarBancoRequest: (state, action) => {
      state.banco.loading = true;
      state.banco.data = null;
      state.banco.error = null;
    },
    mostrarBancoSuccess: (state, action) => {
      state.banco.loading = false;
      state.banco.data = action.payload.response.data;
      state.banco.error = false;
    },
    mostrarBancoFailure: (state, action) => {
      state.banco.loading = false;
      state.banco.loading = false;
      state.banco.error = action.payload;
    },
    desativarModoEdicao: (state) => {
      state.banco.isEditMode = false;
    },
    ativarModoEdicao: (state) => {
      state.banco.isEditMode = true;
    },
    limparBanco: (state) => {
      state.banco.data = null;
    },
    excluirBancoRequest: (state) => {
      state.bancos.loading = true;
      state.bancos.error = null;
    },
    excluirBancoSuccess: (state) => {
      state.bancos.loading = false;
      state.bancos.error = false;
    },
    excluirBancoFailure: (state, action) => {
      state.bancos.loading = false;
      state.bancos.error = action.payload;
    }
  }
});

export const {
  listarBancosRequest,
  listarBancosSuccess,
  listarBancosFailure,
  adicionarOportunidade,
  criarBancoRequest,
  criarBancoSuccess,
  criarBancoFailure,
  mostrarBancoRequest,
  mostrarBancoSuccess,
  mostrarBancoFailure,
  editarBancoRequest,
  editarBancoSuccess,
  editarBancoFailure,
  adicionarEtapa,
  reverterBancos,
  desativarModoEdicao,
  ativarModoEdicao,
  limparBanco,
  excluirBancoRequest,
  excluirBancoSuccess,
  excluirBancoFailure
} = BancosSlice.actions;

export default BancosSlice.reducer;
