import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/produtosAtividades.reducer';
import { routeWatcher } from './rotas.saga';
import { put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { getPayload } from 'selectors/routes.selectors';
import { mostrarEtapaRequest } from 'reducers/produtosEtapas.reducer';
import { mostrarProdutoRequest } from 'reducers/produtosOperacoes.reducer';

const apiListarAtividades = (idEtapa) => {
  return authenticatedRequest({
    url: `/produto-atividade/list?idEtapa=${idEtapa}`,
    method: 'get'
  });
};

const listarAtividades = basicFlow({
  actionGenerator: actions.listarAtividadesRequest,
  actionFailure: actions.listarAtividadesFailure,
  actionSuccess: actions.listarAtividadesSuccess,
  api: apiListarAtividades,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiCriarAtividade = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade/create`,
    method: 'POST',
    body: value
  });
};

const criarAtividades = basicFlow({
  actionGenerator: actions.criarAtividadeRequest,
  actionFailure: actions.criarAtividadeFailure,
  actionSuccess: actions.criarAtividadeSuccess,
  api: apiCriarAtividade,
  postSuccess: function* ({ response }) {
    toast.success('Atividade incluída com sucesso.');
    const { idOperacao, idEtapa } = yield select(getPayload);
    yield put(
      routeActions.redirectTo(routes.PRODUTO_ATIVIDADE, {
        idAtividade: response.data.idAtividade,
        idEtapa,
        idOperacao
      })
    );
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiEditarAtividade = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade/update/${value.idAtividade}`,
    method: 'PUT',
    body: value
  });
};

const editarAtividades = basicFlow({
  actionGenerator: actions.editarAtividadeRequest,
  actionFailure: actions.editarAtividadeFailure,
  actionSuccess: actions.editarAtividadeSuccess,
  api: apiEditarAtividade,
  postSuccess: function* (value) {
    yield toast.success('Atividade editada com sucesso.');
    const { idAtividade } = yield select(getPayload);
    yield put(actions.mostrarAtividadeRequest(idAtividade));
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const apiMostrarAtividade = (id) => {
  return authenticatedRequest({
    url: `/produto-atividade/show/${id}`,
    method: 'get'
  });
};

const mostrarAtividade = basicFlow({
  actionGenerator: actions.mostrarAtividadeRequest,
  actionFailure: actions.mostrarAtividadeFailure,
  actionSuccess: actions.mostrarAtividadeSuccess,
  api: apiMostrarAtividade,
  postFailure: function* ({ error }) {
    yield toast.error('Atividade não encontrada.');
  }
});

const apiExcluirAtividade = ({ value }) => {
  return authenticatedRequest({
    url: `/produto-atividade/delete/${value.idAtividade}`,
    method: 'delete'
  });
};

const excluirAtividade = basicFlow({
  actionGenerator: actions.excluirAtividadeRequest,
  actionFailure: actions.excluirAtividadeFailure,
  actionSuccess: actions.excluirAtividadeSuccess,
  api: apiExcluirAtividade,
  postSuccess: function* () {
    yield toast.success('Atividade excluída com sucesso.');
    const { idOperacao, idEtapa } = yield select(getPayload);
    yield put(actions.listarAtividadesRequest(idEtapa));
    yield put(actions.adicionarIdOperacao(idOperacao));
  },
  postFailure: function* ({ error }) {
    yield toast.error('Exclusão não permitida (registros relacionados).');
  }
});

function* mostrarAtividadeRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ATIVIDADE, function* () {
    const { idAtividade, idEtapa, idOperacao } = yield select(getPayload);
    if (idAtividade === 'novo') {
      yield put(actions.desativarModoEdicao());
      yield put(actions.limparAtividade());
      yield put(actions.adicionarIdEtapa(idEtapa));
      yield put(actions.adicionarIdOperacao(idOperacao));
      yield put(mostrarEtapaRequest(idEtapa));
      yield put(mostrarProdutoRequest(idOperacao));

      return;
    }
    if (!idAtividade) return;
    yield put(actions.mostrarAtividadeRequest(idAtividade));
    yield put(actions.ativarModoEdicao());
    yield put(actions.adicionarIdEtapa(idEtapa));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(mostrarEtapaRequest(idEtapa));
    yield put(mostrarProdutoRequest(idOperacao));
  });
}

function* listarAtividadesRouteWatcher() {
  yield routeWatcher(routes.PRODUTO_ATIVIDADES, function* () {
    const { idEtapa, idOperacao } = yield select(getPayload);
    yield put(actions.listarAtividadesRequest(idEtapa));
    yield put(actions.adicionarIdEtapa(idEtapa));
    yield put(actions.adicionarIdOperacao(idOperacao));
    yield put(mostrarEtapaRequest(idEtapa));
    yield put(mostrarProdutoRequest(idOperacao));
  });
}

export const sagas = [
  listarAtividades.watcher(),
  criarAtividades.watcher(),
  editarAtividades.watcher(),
  mostrarAtividade.watcher(),
  excluirAtividade.watcher(),
  listarAtividadesRouteWatcher(),
  mostrarAtividadeRouteWatcher()
];
