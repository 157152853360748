// third party
import { useSelector } from 'react-redux';
import React from 'react';

// assets
import { Box, Stack } from '@mui/material';
import OutlinedInput from '../../components/form/OutlinedInput';
import CurrencyTextField from '../../components/form/CurrencyTextField';
import Autocomplete from '../../components/form/Autocomplete';
import InputLabel from '../../components/form/InputLabel';
import Switch from '../../components/form/Switch';

const Cliente = ({ formProps, setVlImovel }) => {
  const tipoImoveis = useSelector((state) => state.dominios.tipoImovel);
  const situacaoImovel = useSelector((state) => state.dominios.situacaoImovel);
  const utilizacaoImoveis = useSelector(
    (state) => state.dominios.utilizacaoImovel
  );
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);
  const sistemasAmortizacao = useSelector((state) => state.dominios.sistemaAmortizacao);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`tipoImovel`}>Tipo do Imóvel</InputLabel>
          <Autocomplete
            id="tipoImovel"
            name="tipoImovel"
            placeholder="Selecione o Tipo do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={tipoImoveis.data}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`usoImovel`}>Utilização do Imóvel</InputLabel>
          <Autocomplete
            id="usoImovel"
            name="usoImovel"
            placeholder="Selecione o Uso do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={utilizacaoImoveis.data}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`situacaoImovel`}>Situação do Imóvel</InputLabel>
          <Autocomplete
            id="situacaoImovel"
            name="situacaoImovel"
            placeholder="Selecione a situação do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={situacaoImovel}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`uf`}>UF do Imóvel</InputLabel>
          <Autocomplete
            id="uf"
            name="uf"
            placeholder="Selecione a UF do Imóvel"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`valorImovel`}>Valor do Imóvel</InputLabel>
          <OutlinedInput
            id="valorImovel"
            name="valorImovel"
            placeholder="Digite o Valor do Imóvel"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
            onChange={(v) => setVlImovel(v)}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`valorFinanciamento`}>
            Valor do Financiamento
          </InputLabel>
          <OutlinedInput
            id="valorFinanciamento"
            name="valorFinanciamento"
            placeholder="Digite o Valor do Financiamento"
            fullWidth
            formProps={formProps}
            InputProps={{
              inputComponent: CurrencyTextField
            }}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="fgFinanciarDespesas">
            Gostaria de Financiar as Despesas Cartorárias?
          </InputLabel>
          <Switch
            formProps={formProps}
            fullWidth
            name="fgFinanciarDespesas"
            id="fgFinanciarDespesas"
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`prazo`}>
            Prazo de Pagamento (em meses)
          </InputLabel>
          <OutlinedInput
            id="prazo"
            name="prazo"
            placeholder="Digite o Prazo"
            fullWidth
            formProps={formProps}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoSistemaAmortizacaoBanco" >Sistema de Amortização</InputLabel>
          <Autocomplete
            id="codigoSistemaAmortizacaoBanco"
            name="codigoSistemaAmortizacaoBanco"
            placeholder="Selecione o Sistema de Amortização"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'id'}
            domain={sistemasAmortizacao}
            skeleton={true}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Cliente;
