import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  operacao: '',
  parceiro: '',
  regional: '',
  consultor: '',
  analista: '',
  cpfCnpj: '',
  nome: '',
  rendaTotal: '',
  utilizaFgtsSimulacao: '',
  tipoImovel: '',
  usoImovel: '',
  cep: '',
  valorImovel: '',
  valorFinanciamentoSimulacao: '',
  prazoPagamentoSimulacao: '',
  loading: '',
  error: '',
  oportunidades: {
    loading: false,
    error: false,
    data: []
  },
  imovelIq: {
    loading: false,
    error: false,
    data: []
  },
  buscarCep: {
    loading: false,
    error: null
  },
  data: '',
  menuSelecionado: 0,
  etapas: {
    loading: false,
    error: false
  },
  funilDeVendas: {
    loading: false,
    error: false,
    data: []
  }
};

const OportunidadeSlice = createSlice({
  name: 'oportunidade',
  initialState,
  reducers: {
    oportunidadesRequest: (state) => {
      state.oportunidades.loading = true;
      state.oportunidades.error = null;
    },
    oportunidadesSuccess: (state, action) => {
      state.oportunidades.loading = false;
      state.oportunidades.error = false;
      state.oportunidades.data = action.payload?.response?.data;
    },
    oportunidadesFailure: (state, action) => {
      state.oportunidades.loading = false;
      state.oportunidades.error = action.payload;
    },
    oportunidadeRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    oportunidadeSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      //state.oportunidade.data = action.payload?.response?.data;
      state.data = { ...action.payload?.response?.data };
    },
    oportunidadeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    oportunidadeImovelIqRequest: (state) => {
      state.imovelIq.loading = true;
      state.imovelIq.error = null;
    },
    oportunidadeImovelIqSuccess: (state, action) => {
      state.imovelIq.loading = false;
      state.imovelIq.error = false;
      state.imovelIq.data = { ...action.payload?.response?.data };
    },
    oportunidadeImovelIqFailure: (state, action) => {
      state.imovelIq.loading = false;
      state.imovelIq.error = action.payload;
    },
    formatarOportunidade: (state, action) => {
      state.data = { ...action.payload };
    },
    editarOportunidadeRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    editarOportunidadeSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data.oportunidade = { ...action.payload?.response?.data };
    },
    editarOportunidadeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editarOportunidadeImovelIqRequest: (state) => {
      state.imovelIq.loading = true;
      state.imovelIq.error = false;
    },
    editarOportunidadeImovelIqSuccess: (state, action) => {
      state.imovelIq.loading = false;
      state.imovelIq.error = false;
      state.imovelIq.data = { ...action.payload?.response?.data };
    },
    editarOportunidadeImovelIqFailure: (state, action) => {
      state.imovelIq = false;
      state.imovelIq = action.payload;
    },
    buscarCepRequest: (state) => {
      state.buscarCep.loading = true;
      state.buscarCep.error = null;
    },
    buscarCepSuccess: (state) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = false;
    },
    buscarCepFailure: (state, action) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = action.payload;
    },
    editarDataPrevistaRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    editarDataPrevistaSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data.oportunidade = {
        ...state.data.oportunidade,
        ...action.payload?.response?.data
      };
    },
    editarDataPrevistaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editarTemperaturaRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    editarTemperaturaSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data.oportunidade = {
        ...state.data.oportunidade,
        ...action.payload?.response?.data
      };
    },
    editarTemperaturaFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editarSituacaoRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    editarSituacaoSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data.oportunidade = {
        ...state.data.oportunidade,
        ...action.payload?.response?.data
      };
    },
    editarSituacaoFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    alterarMenuSelecionado: (state, action) => {
      state.menuSelecionado = action.payload;
    },
    obterEtapasRequest: (state) => {
      state.etapas.loading = true;
      state.etapas.error = null;
    },
    obterEtapasSuccess: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = null;
      state.data.etapa = action.payload?.response?.data;
    },
    obterEtapasFailure: (state, action) => {
      state.etapas.loading = false;
      state.etapas.error = action.payload;
    },
    atualizarBancoSelecionado: (state, action) => {
      state.data.oportunidade.simulacoes = action.payload;
    },
    obterFunilDeVendasRequest: (state, action) => {
      state.funilDeVendas.loading = true;
      state.funilDeVendas.error = null;
    },
    obterFunilDeVendasSuccess: (state, action) => {
      state.funilDeVendas.loading = false;
      state.funilDeVendas.error = false;
      state.funilDeVendas.data = action.payload?.response?.data;
    },
    obterFunilDeVendasFailure: (state, action) => {
      state.funilDeVendas.loading = false;
      state.funilDeVendas.error = action.payload;
    }
  }
});

export const {
  oportunidadesRequest,
  oportunidadesSuccess,
  oportunidadesFailure,
  oportunidadeFailure,
  oportunidadeRequest,
  oportunidadeSuccess,
  oportunidadeImovelIqFailure,
  oportunidadeImovelIqRequest,
  oportunidadeImovelIqSuccess,
  editarOportunidadeRequest,
  editarOportunidadeFailure,
  editarOportunidadeSuccess,
  editarOportunidadeImovelIqRequest,
  editarOportunidadeImovelIqFailure,
  editarOportunidadeImovelIqSuccess,
  formatarOportunidade,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  editarDataPrevistaFailure,
  editarDataPrevistaSuccess,
  editarDataPrevistaRequest,
  editarSituacaoFailure,
  editarSituacaoSuccess,
  editarTemperaturaSuccess,
  editarTemperaturaRequest,
  editarSituacaoRequest,
  editarTemperaturaFailure,
  alterarMenuSelecionado,
  obterEtapasFailure,
  obterEtapasSuccess,
  obterEtapasRequest,
  atualizarBancoSelecionado,
  obterFunilDeVendasRequest,
  obterFunilDeVendasSuccess,
  obterFunilDeVendasFailure
} = OportunidadeSlice.actions;

export default OportunidadeSlice.reducer;
