import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  idParticipante: null,
  loading: false,
  error: false,
  data: [],
  buscarCep: {
    loading: false,
    error: null
  },
  pessoaFisica: {
    isEditMode: false,
    loading: false,
    error: false,
    data: null
  },
  pessoaJuridica: {
    isEditMode: false,
    loading: false,
    error: false,
    data: null,
    representantes: [],
    representante: {
      loading: false,
      error: false,
      data: null
    }
  },
  dadosConjuge: {
    loading: false,
    error: false,
    data: null
  }
};

const CompradoresSlice = createSlice({
  name: 'oportunidade',
  initialState,
  reducers: {
    desativarModoEdicao: (state) => {
      state.pessoaFisica.isEditMode = false;
      state.pessoaJuridica.isEditMode = false;
      state.pessoaJuridica.representante.isEditMode = false;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    adicionarParticipante: (state, action) => {
      state.idParticipante = Number(action.payload);
    },
    listarCompradoresRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    listarCompradoresSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
      state.data = action.payload.response.data;
    },
    listarCompradoresFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    mostrarCompradorPessoaFisicaRequest: (state, action) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    mostrarCompradorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload.response.data;
      state.pessoaFisica.isEditMode = true;
    },
    mostrarCompradorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    criarCompradorPessoaFisicaRequest: (state) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    criarCompradorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload;
    },
    criarCompradorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    editarCompradorPessoaFisicaRequest: (state) => {
      state.pessoaFisica.loading = true;
      state.pessoaFisica.error = null;
    },
    editarCompradorPessoaFisicaSuccess: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = false;
      state.pessoaFisica.data = action.payload;
    },
    editarCompradorPessoaFisicaFailure: (state, action) => {
      state.pessoaFisica.loading = false;
      state.pessoaFisica.error = action.payload;
    },
    formatarPessoaFisica: (state, action) => {
      state.pessoaFisica.data = action.payload;
    },
    mostrarCompradorPessoaJuridicaRequest: (state, action) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    mostrarCompradorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload.response.data;
      state.pessoaJuridica.isEditMode = true;
    },
    mostrarCompradorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    formatarPessoaJuridica: (state, action) => {
      state.pessoaJuridica.data = action.payload;
    },
    criarCompradorPessoaJuridicaRequest: (state) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    criarCompradorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload;
    },
    criarCompradorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    editarCompradorPessoaJuridicaRequest: (state) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    editarCompradorPessoaJuridicaSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
      state.pessoaJuridica.data = action.payload;
    },
    editarCompradorPessoaJuridicaFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    buscarCepRequest: (state) => {
      state.buscarCep.loading = true;
      state.buscarCep.error = null;
    },
    buscarCepSuccess: (state) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = false;
    },
    buscarCepFailure: (state, action) => {
      state.buscarCep.loading = false;
      state.buscarCep.error = action.payload;
    },
    removerRepresentanteRequest: (state, action) => {
      state.pessoaJuridica.loading = true;
      state.pessoaJuridica.error = null;
    },
    removerRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = false;
    },
    removerRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.loading = false;
      state.pessoaJuridica.error = action.payload;
    },
    criarRepresentanteRequest: (state) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    criarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload;
    },
    criarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    editarRepresentanteRequest: (state) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    editarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload;
    },
    editarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    mostrarRepresentanteRequest: (state, action) => {
      state.pessoaJuridica.representante.loading = true;
      state.pessoaJuridica.representante.error = null;
    },
    mostrarRepresentanteSuccess: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = false;
      state.pessoaJuridica.representante.data = action.payload.response.data;
      state.pessoaJuridica.representante.isEditMode = true;
    },
    mostrarRepresentanteFailure: (state, action) => {
      state.pessoaJuridica.representante.loading = false;
      state.pessoaJuridica.representante.error = action.payload;
    },
    formatarRepresentante: (state, action) => {
      state.pessoaJuridica.representante.data = action.payload;
    },
    removerParticipanteRequest: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    removerParticipanteSuccess: (state, action) => {
      state.loading = false;
      state.error = false;
    },
    removerParticipanteFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    buscarConjugeRequest: (state, action) => {
      state.dadosConjuge.loading = true;
      state.dadosConjuge.error = null;
    },
    buscarConjugeSuccess: (state, action) => {
      state.dadosConjuge.loading = false;
      state.dadosConjuge.error = false;
      state.dadosConjuge.data = action.payload.response.data;
    },
    buscarConjugeFailure: (state, action) => {
      state.dadosConjuge.loading = false;
      state.dadosConjuge.error = action.payload;
    },
    limparPessoaFisica: (state) => {
      state.pessoaFisica.data = {
        isEditMode: false,
        loading: false,
        error: false,
        data: null
      };
    },
    limparPessoaJuridica: (state) => {
      state.pessoaJuridica.data = {
        isEditMode: false,
        loading: false,
        error: false,
        data: null
      };
    },
    limparRepresentante: (state) => {
      state.pessoaJuridica.representante.data = {
        loading: false,
        error: false,
        data: null
      };
    }
  }
});

export const {
  desativarModoEdicao,
  adicionarOportunidade,
  adicionarParticipante,
  listarCompradoresRequest,
  listarCompradoresSuccess,
  listarCompradoresFailure,
  mostrarCompradorPessoaFisicaRequest,
  mostrarCompradorPessoaFisicaSuccess,
  mostrarCompradorPessoaFisicaFailure,
  criarCompradorPessoaFisicaRequest,
  criarCompradorPessoaFisicaSuccess,
  criarCompradorPessoaFisicaFailure,
  editarCompradorPessoaFisicaRequest,
  editarCompradorPessoaFisicaSuccess,
  editarCompradorPessoaFisicaFailure,
  mostrarCompradorPessoaJuridicaRequest,
  mostrarCompradorPessoaJuridicaSuccess,
  mostrarCompradorPessoaJuridicaFailure,
  criarCompradorPessoaJuridicaRequest,
  criarCompradorPessoaJuridicaSuccess,
  criarCompradorPessoaJuridicaFailure,
  editarCompradorPessoaJuridicaRequest,
  editarCompradorPessoaJuridicaSuccess,
  editarCompradorPessoaJuridicaFailure,
  buscarCepRequest,
  buscarCepSuccess,
  buscarCepFailure,
  listarRepresentantesRequest,
  listarRepresentantesSuccess,
  listarRepresentantesFailure,
  removerRepresentanteRequest,
  removerRepresentanteSuccess,
  removerRepresentanteFailure,
  criarRepresentanteRequest,
  criarRepresentanteSuccess,
  criarRepresentanteFailure,
  editarRepresentanteRequest,
  editarRepresentanteSuccess,
  editarRepresentanteFailure,
  mostrarRepresentanteRequest,
  mostrarRepresentanteSuccess,
  mostrarRepresentanteFailure,
  formatarRepresentante,
  formatarPessoaJuridica,
  formatarPessoaFisica,
  removerParticipanteRequest,
  removerParticipanteSuccess,
  removerParticipanteFailure,
  buscarConjugeRequest,
  buscarConjugeSuccess,
  buscarConjugeFailure,
  limparPessoaFisica,
  limparPessoaJuridica,
  limparRepresentante
} = CompradoresSlice.actions;

export default CompradoresSlice.reducer;
