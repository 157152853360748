import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import MainCard from 'components/MainCard';
import FormCard from 'components/FormCard';
import validators from 'utils/validators';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import DadosPessoais from './DadosPessoais';
import DadosProfissionais from './DadosProfissionais';
import Endereco from './Endereco';
import { removeNonDigitsFromString } from 'utils/basic';
import Contatos from './Contatos';
import Conjuge from './Conjuge';
import * as actions from 'reducers/compradores.reducer';
import Loader from 'components/Loader';
import MainLayout from '../../../../layout/MainLayout';
import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import formatters from 'utils/formatters';

const PessoaFisica = () => {
  const dispatch = useDispatch();
  const tiposEstadoCivil = useSelector(
    (state) => state.dominios.tiposEstadoCivil
  );

  const tiposRegimeCasamento = useSelector(
    (state) => state.dominios.tiposRegimeCasamento
  );

  const pessoaFisica = useSelector((state) => state.compradores.pessoaFisica);
  const idOportunidade = useSelector(
    (state) => state.compradores.idOportunidade
  );
  const isEditMode = useSelector(
    (state) => state.compradores.pessoaFisica.isEditMode
  );
  const loading = useSelector(
    (state) => state.compradores.pessoaFisica.loading
  );

  const usuario = useSelector((state) => state.usuario);

  const dadosConjuge = useSelector((state) => state.compradores.dadosConjuge);

  const [estadoCivil, setEstadoCivil] = useState('');
  const [cpf, setCpf] = useState('');

  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  const handleEstadoCivilChange = (status) => {
    setEstadoCivil(status);
  };

  const tiposDocumentos = useSelector(
    (state) => state.dominios.tiposDocumentos
  );

  const initialValues = {
    nomeParticipante: '',
    cpfCnpj: '',
    dataNascimento: '',
    tipoSexo: null,
    tipoEstadoCivil: null,
    tipoRegimeCasamento: null,
    tipoDocumentoIdentidade: null,
    numeroDocumento: '',
    dataExpedicao: '',
    orgaoExpedidor: '',
    ufExpedicao: null,
    utilizaFgts: false,

    // Dados Profissionais
    nomeProfissao: '',
    nomeEmpresaProfissao: '',
    renda: '',
    idBanco: null,
    codigoAgencia: '',
    codigoContaCorrente: '',
    digitoContaCorrente: '',

    // Endereço
    cep: '',
    logradouro: '',
    numeroLogradouro: '',
    complementoLogradouro: '',
    bairro: '',
    municipio: '',
    uf: null,

    // Contatos
    email: '',
    celular: '',

    // Cônjuge
    nomeConjuge: '',
    cpfConjuge: '',
    dataNascimentoConjuge: '',
    tipoEstadoCivilConjuge: null,
    tipoDocumentoIdentidadeConjuge: null,
    numeroDocumentoConjuge: '',
    dataExpedicaoConjuge: null,
    orgaoExpedidorConjuge: '',
    ufExpedicaoConjuge: null
  };

  const rules = {
    // Dados Pessoais
    nomeParticipante: validators.string({ required: true }),
    cpfCnpj: validators.cpf({ required: true }),
    dataNascimento: validators.date({ required: true }),
    tipoSexo: validators.string({ required: true }),
    tipoEstadoCivil: validators.string({ required: true }),
    tipoDocumentoIdentidade: validators.string({ required: false }),
    numeroDocumento: validators.string({ required: false }),
    dataExpedicao: validators.date({ required: false }),
    orgaoExpedidor: validators.string({ required: false }),
    ufExpedicao: validators.string({ required: false }),

    // Dados Profissionais
    nomeProfissao: validators.string({ required: false }),
    nomeEmpresaProfissao: validators.string({ required: false }),
    renda: validators.number({ required: true, min: 0 }),
    idBanco: validators.string({ required: false }),
    codigoAgencia: validators.number({ required: false, max: 9999 }),
    codigoContaCorrente: validators.number({
      required: false,
      max: 999999999999999
    }),
    digitoContaCorrente: validators.number({ required: false }),

    // Endereço
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    numeroLogradouro: validators.string({ required: true }),
    complementoLogradouro: validators.string({ required: false }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),

    // Contatos
    email: validators.email({ required: false }),
    celular: validators.string({ required: true }),

    // Cônjuge
    tipoRegimeCasamento: validators.string({
      required: estadoCivil === 'CA' ? true : false
    }),
    nomeConjuge: validators.string({
      required: estadoCivil === 'CA' ? true : false
    }),
    cpfConjuge: validators.cpf({ required: false }),
    dataNascimentoConjuge: validators.date({
      required: estadoCivil === 'CA' ? true : false
    }),
    tipoEstadoCivilConjuge: validators.string({ required: false }),
    tipoDocumentoIdentidadeConjuge: validators.string({ required: false }),
    numeroDocumentoConjuge: validators.string({ required: false }),
    dataExpedicaoConjuge: validators.date({ required: false }),
    orgaoExpedidorConjuge: validators.string({ required: false }),
    ufExpedicaoConjuge: validators.string({ required: false }),
    utilizaFgts: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
    defaultValues: isEditMode ? pessoaFisica.data : initialValues
  };

  useEffect(() => {
    if (dadosConjuge.data && !isEditMode) {
      formProps.setValue('nomeParticipante', dadosConjuge.data.nomeConjuge);
      formProps.setValue(
        'dataNascimento',
        formatters.dates.revert(dadosConjuge.data.dataNascimentoConjuge)
      );

      formProps.setValue(
        'tipoDocumentoIdentidade',
        tiposDocumentos.find(
          (el) => el.id === dadosConjuge.data.tipoDocumentoIdentidadeConjuge
        )
      );
      formProps.setValue(
        'numeroDocumento',
        dadosConjuge.data.numeroDocumentoConjuge
      );
      formProps.setValue(
        'dataExpedicao',
        formatters.dates.revert(dadosConjuge.data.dataExpedicaoConjuge)
      );
      formProps.setValue(
        'orgaoExpedidor',
        dadosConjuge.data.orgaoExpedidorConjuge
      );
      formProps.setValue(
        'ufExpedicao',
        ufs.find((el) => el.codigo === dadosConjuge.data.ufExpedicaoConjuge)
      );

      formProps.setValue(
        'tipoEstadoCivil',
        tiposEstadoCivil.find(
          (el) => el.id === dadosConjuge.data?.tipoEstadoCivilConjuge
        ) ?? null
      );

      setEstadoCivil(dadosConjuge.data?.tipoEstadoCivilConjuge ?? '');

      formProps.setValue('nomeConjuge', dadosConjuge.data.nomeParticipante);
      formProps.setValue(
        'dataNascimentoConjuge',
        formatters.dates.revert(dadosConjuge.data.dataNascimento)
      );

      formProps.setValue(
        'tipoDocumentoIdentidadeConjuge',
        tiposDocumentos.find(
          (el) => el.id === dadosConjuge.data.tipoDocumentoIdentidade
        )
      );
      formProps.setValue(
        'numeroDocumentoConjuge',
        dadosConjuge.data.numeroDocumento
      );
      formProps.setValue(
        'dataExpedicaoConjuge',
        formatters.dates.revert(dadosConjuge.data.dataExpedicao)
      );
      formProps.setValue(
        'orgaoExpedidorConjuge',
        dadosConjuge.data.orgaoExpedidor
      );
      formProps.setValue(
        'ufExpedicaoConjuge',
        ufs.find((el) => el.codigo === dadosConjuge.data.ufExpedicao)
      );

      formProps.setValue('cpfConjuge', dadosConjuge.data.cpfCnpj);

      formProps.setValue(
        'tipoEstadoCivilConjuge',
        tiposEstadoCivil.find(
          (el) => el.id === dadosConjuge.data?.tipoEstadoCivil
        ) ?? null
      );

      formProps.setValue(
        'tipoRegimeCasamento',
        tiposRegimeCasamento.find(
          (el) => el.id === dadosConjuge.data?.tipoRegimeCasamento
        ) ?? null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dadosConjuge.data]);

  useEffect(() => {
    if (pessoaFisica.data) {
      setEstadoCivil(
        pessoaFisica.data?.tipoEstadoCivil?.id ??
          pessoaFisica.data?.tipoEstadoCivil
      );
    }
    if (isEditMode) {
      formProps.reset(pessoaFisica.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoaFisica.data, formProps.reset]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      utilizaFgts: values.utilizaFgts ? 'S' : 'N',
      idOportunidade: values.idOportunidade || idOportunidade,
      tipoSituacao: 'A',
      tipoQualificacao: 'CO',
      tipoPessoa: 'F',
      idUsuarioCriacao: usuario.id,
      idUsuarioAlteracao: usuario.id,
      dataHoraAlteracao: new Date().toISOString()
    };

    isEditMode
      ? dispatch(actions.editarCompradorPessoaFisicaRequest({ value: payload }))
      : dispatch(actions.criarCompradorPessoaFisicaRequest({ value: payload }));
  };

  const { setValue } = formProps;

  const successCallback = useCallback(
    ({ data }) => {
      setValue('logradouro', data?.logradouro || '', {
        shouldValidate: true
      });
      setValue('bairro', data?.bairro || '', { shouldValidate: true });

      setValue('uf', ufs.find((uf) => uf.codigo === data?.uf) ?? null, {
        shouldValidate: true
      });
      setValue('municipio', data?.localidade || '', {
        shouldValidate: true
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleZipCodeBlurred = (value) => {
    if (removeNonDigitsFromString(value)?.length === 8) {
      dispatch(
        actions.buscarCepRequest({
          value,
          successCallback
        })
      );
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      formProps.reset(initialValues);
      setEstadoCivil('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode]);

  const handleGoBack = () => {
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, {
        id: idOportunidade
      })
    );
  };

  useEffect(() => {
    if (cpf.length === 14) {
      dispatch(
        actions.buscarConjugeRequest({
          value: { cpf: removeNonDigitsFromString(cpf), idOportunidade }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cpf]);

  if (!loading && isEditMode && !pessoaFisica?.data?.idParticipante) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Comprador não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (
        <>
          <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
            <MainCard
              contentSX={{
                paddingLeft: 1,
                paddingRight: 1
              }}
              border={false}
              title={
                !isEditMode ? (
                  'Novo Comprador'
                ) : (
                  <Box>
                    <Typography variant="h2">
                      {pessoaFisica.data.nomeParticipante}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                      Comprador
                    </Typography>
                  </Box>
                )
              }
            >
              <Grid container spacing={2} alignItems="stretch">
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS PESSOAIS" style={{ flex: 1 }}>
                    <DadosPessoais
                      formProps={formProps}
                      handleEstadoCivilChange={handleEstadoCivilChange}
                      setCpf={setCpf}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="DADOS PROFISSIONAIS" style={{ flex: 1 }}>
                    <DadosProfissionais formProps={formProps} />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="ENDEREÇO" style={{ flex: 1 }}>
                    <Endereco
                      formProps={formProps}
                      handleZipCodeBlurred={handleZipCodeBlurred}
                    />
                  </FormCard>
                </Grid>
                <Grid item xs={12} md={4} sm={6} style={{ display: 'flex' }}>
                  <FormCard title="CONTATO" style={{ flex: 1 }}>
                    <Contatos formProps={formProps} />
                  </FormCard>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sm={6}
                  style={{ display: estadoCivil === 'CA' ? 'flex' : 'none' }}
                >
                  <FormCard title="CÔNJUGE" style={{ flex: 1 }}>
                    <Conjuge formProps={formProps} estadoCivil={estadoCivil} />
                  </FormCard>
                </Grid>
              </Grid>
            </MainCard>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    SALVAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        </>
      )}
    </MainLayout>
  );
};

export default PessoaFisica;
