import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  entidadesRelacionadas: {
    loading: false,
    error: false,
    data: [],
    participantes: []
  },
  documentos: {
    loading: false,
    error: false,
    data: []
  },
  documento: {
    loading: false,
    error: false,
    data: null,
    referencia: null
  },
  upload: {
    loading: false,
    error: false,
    data: null
  }
};

const DocumentosSlice = createSlice({
  name: 'documentos',
  initialState,
  reducers: {
    listarEntidadesRelacionadasRequest: (state) => {
      state.entidadesRelacionadas.loading = true;
      state.entidadesRelacionadas.error = null;
      state.entidadesRelacionadas.data = [];
      state.entidadesRelacionadas.participantes = [];
    },
    listarEntidadesRelacionadasSuccess: (state, action) => {
      state.entidadesRelacionadas.loading = false;
      state.entidadesRelacionadas.error = false;
      state.entidadesRelacionadas.data = action.payload.response.data.grid;
      state.entidadesRelacionadas.participantes =
        action.payload.response.data.participantes;
    },
    listarEntidadesRelacionadasFailure: (state, action) => {
      state.entidadesRelacionadas.loading = false;
      state.entidadesRelacionadas.error = action.payload;
    },
    listarDocumentosRequest: (state, action) => {
      state.documentos.loading = true;
      state.documentos.error = null;
      state.documentos.data = [];
    },
    listarDocumentosSuccess: (state, action) => {
      state.documentos.loading = false;
      state.documentos.error = false;
      state.documentos.data = action.payload.response.data;
    },
    listarDocumentosFailure: (state, action) => {
      state.documentos.loading = false;
      state.documentos.error = action.payload;
    },
    adicionarOportunidade: (state, action) => {
      state.idOportunidade = Number(action.payload);
    },
    criarDocumentoRequest: (state, action) => {
      state.entidadesRelacionadas.loading = true;
      state.entidadesRelacionadas.error = null;
    },
    criarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.data = action.payload.response.data;
    },
    criarDocumentoFailure: (state, action) => {
      state.entidadesRelacionadas.loading = false;
      state.documento.error = action.payload;
    },
    mostrarDocumentoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.error = null;
    },
    mostrarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.data = action.payload.response.data;
    },
    mostrarDocumentoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    uploadArquivoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.error = null;
    },
    uploadArquivoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.data = action.payload.response.data;
    },
    uploadArquivoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    deletarArquivoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.error = null;
    },
    deletarArquivoSuccess: (state, action) => {
      state.documento.loading = false;
    },
    deletarArquivoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    atualizarDocumentoRequest: (state, action) => {
      state.documento.loading = true;
      state.documento.error = null;
    },
    atualizarDocumentoSuccess: (state, action) => {
      state.documento.loading = false;
      state.documento.data = action.payload.response.data;
    },
    atualizarDocumentoFailure: (state, action) => {
      state.documento.loading = false;
      state.documento.error = action.payload;
    },
    reverterDocumentos: (state, action) => {
      state.entidadesRelacionadas.data =
        state.entidadesRelacionadas.data.reverse();
    },
    limparGridDocumentos: (state) => {
      state.documentos.data = [];
    },
    adicionarReferencia: (state, action) => {
      state.documento.referencia = action.payload;
    }
  }
});

export const {
  listarEntidadesRelacionadasRequest,
  listarEntidadesRelacionadasSuccess,
  listarEntidadesRelacionadasFailure,
  adicionarOportunidade,
  criarDocumentoRequest,
  criarDocumentoSuccess,
  criarDocumentoFailure,
  listarDocumentosFailure,
  listarDocumentosRequest,
  listarDocumentosSuccess,
  limparGridDocumentos,
  reverterDocumentos,
  mostrarDocumentoRequest,
  mostrarDocumentoSuccess,
  mostrarDocumentoFailure,
  uploadArquivoRequest,
  uploadArquivoSuccess,
  uploadArquivoFailure,
  deletarArquivoRequest,
  deletarArquivoSuccess,
  deletarArquivoFailure,
  atualizarDocumentoRequest,
  atualizarDocumentoSuccess,
  atualizarDocumentoFailure,
  adicionarReferencia
} = DocumentosSlice.actions;

export default DocumentosSlice.reducer;
