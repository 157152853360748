import { authenticatedRequest } from 'utils/api';
import basicFlow, { genericErrorHandler } from './asyncHandler';
import * as actions from 'reducers/minhasAtividades.reducer';
import { routeWatcher } from './rotas.saga';
import { put } from 'redux-saga/effects';

import {
  actions as routeActions,
  types as routes
} from '../reducers/rotas.actions';

import { toast } from 'react-toastify';
import {
  checarNotificacaoFailure,
  checarNotificacaoRequest,
  checarNotificacaoSuccess
} from 'reducers/user.reducer';

const apiChecarNotificacao = () => {
  return authenticatedRequest({
    url: '/minhas-atividades/possui-notificacao',
    method: 'get'
  });
};

const apiListarMinhasAtividades = () => {
  return authenticatedRequest({
    url: `/minhas-atividades/list`,
    method: 'get'
  });
};

const apiEncerrarMinhasAtividades = ({ value }) => {
  return authenticatedRequest({
    url: `/minhas-atividades/encerrar/${value.id}`,
    method: 'put'
  });
};

const checkarNotificacao = basicFlow({
  actionGenerator: checarNotificacaoRequest,
  actionFailure: checarNotificacaoFailure,
  actionSuccess: checarNotificacaoSuccess,
  api: apiChecarNotificacao,
  postSuccess: function* (value) {
    yield put(actions.listarMinhasAtividadesRequest());
  },
  postFailure: function* (error) {
    if (error.response && error.response.status === 403) {
      yield;
    }
  }
});

const encerrarMinhasAtividades = basicFlow({
  actionGenerator: actions.encerrarMinhasAtividadesRequest,
  actionFailure: actions.encerrarMinhasAtividadesFailure,
  actionSuccess: actions.encerrarMinhasAtividadesSuccess,
  api: apiEncerrarMinhasAtividades,
  postSuccess: function* ({ response, values }) {
    if (values.value.isRedirect) {
      yield put(
        routeActions.redirectTo(routes.OPORTUNIDADE, {
          id: values.value.idOportunidade
        })
      );
    } else {
      yield put(actions.listarMinhasAtividadesRequest());
    }
    toast.success('Atividade encerrada com sucesso');
    yield put(checarNotificacaoRequest());
  },
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

const listarMinhasAtividades = basicFlow({
  actionGenerator: actions.listarMinhasAtividadesRequest,
  actionFailure: actions.listarMinhasAtividadesFailure,
  actionSuccess: actions.listarMinhasAtividadesSuccess,
  api: apiListarMinhasAtividades,
  postFailure: function* ({ error }) {
    yield genericErrorHandler({ error });
  }
});

function* listarMinhasAtividadesRouteWatcher() {
  yield routeWatcher(routes.MINHAS_ATIVIDADES, function* () {
    yield put(actions.listarMinhasAtividadesRequest());
  });
}

export const sagas = [
  listarMinhasAtividadesRouteWatcher(),
  listarMinhasAtividades.watcher(),
  encerrarMinhasAtividades.watcher(),
  checkarNotificacao.watcher()
];
