import React from 'react';

// material-ui
import { Box, Card } from '@mui/material';

// project import
import MainLayout from '../../layout/MainLayout';
import HeaderOportunidade from './HeaderOportunidade';
import { useSelector } from 'react-redux';
import {
  Compradores,
  Vendedores,
  IntervenienteQuitante,
  Imovel,
  Atividades,
  Fup,
  Documentos,
  Resumo
} from '../pages';

const OportunidadePage = () => {
  const menuSelecionado = useSelector(
    (state) => state.oportunidade.menuSelecionado
  );

  return (
    <MainLayout>
      <div>
        <Card sx={{ pl: 0, pr: 0 }}>
          <HeaderOportunidade menuSelecionado={menuSelecionado} />
        </Card>
        <Box sx={{ display: menuSelecionado === 0 ? 'flex' : 'none' }}>
          <Resumo />
        </Box>
        <Box sx={{ display: menuSelecionado === 1 ? 'flex' : 'none' }}>
          <Card sx={{ width: '100%', marginTop: '20px' }}>
            <Compradores />
          </Card>
        </Box>
        <Box sx={{ display: menuSelecionado === 2 ? 'flex' : 'none' }}>
          <Card sx={{ width: '100%', marginTop: '20px' }}>
            <Vendedores />
          </Card>
        </Box>
        <Box sx={{ display: menuSelecionado === 3 ? 'flex' : 'none' }}>
          <IntervenienteQuitante />
        </Box>
        <Box sx={{ display: menuSelecionado === 4 ? 'flex' : 'none' }}>
          <Imovel />
        </Box>
        <Box sx={{ display: menuSelecionado === 5 ? 'flex' : 'none' }}>
          <Card sx={{ width: '100%', marginTop: '20px' }}>
            <Documentos />
          </Card>
        </Box>
        <Box sx={{ display: menuSelecionado === 6 ? 'flex' : 'none' }}>
          <Card sx={{ width: '100%', marginTop: '20px' }}>
            <Atividades />
          </Card>
        </Box>
        <Box sx={{ display: menuSelecionado === 7 ? 'flex' : 'none' }}>
          <Fup />
        </Box>
      </div>
    </MainLayout>
  );
};

export default OportunidadePage;
