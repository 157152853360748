import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Typography, Grid } from '@mui/material';
import { useForm } from 'react-hook-form';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

import FormCard from 'components/FormCard';
import DadosFup from './DadosFup';
import validators from 'utils/validators';
import formatters from 'utils/formatters';
import Button from 'components/Button';
import * as actions from 'reducers/parceiros.reducer';
import Loader from 'components/Loader';
import MainLayout from 'layout/MainLayout/index';
import AnimateButton from 'components/@extended/AnimateButton';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import MainCard from 'components/MainCard';

const Fup = () => {
  const dispatch = useDispatch();

  const fups = useSelector((state) => state.parceiros.fups.data);
  const loading = useSelector((state) => state.parceiros.fups.loading);
  const parceiro = useSelector((state) => state.parceiros.parceiro.data);

  const [orderUp, setOrderUp] = useState(true);

  const handleChangeOrder = () => {
    setOrderUp((prev) => !prev);
    dispatch(actions.reverterFups());
  };

  const initialValues = {
    // Dados do Interveniente Quitante
    titulo: '',
    comentario: ''
  };

  const rules = {
    // Dados do Interveniente Quitante
    titulo: validators.string({ required: true }),
    comentario: validators.string({ required: true })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.PARCEIROS));
  };

  const handleSubmit = (values) => {
    const comentario = values.comentario.replace(/\n/g, '<br>');

    const payload = {
      ...values,
      comentario,
      idParceiro: parceiro.idParceiro
    };

    dispatch(actions.criarFupRequest({ value: payload }));
    formProps.reset(initialValues);
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <MainCard
        contentSX={{
          paddingLeft: 1,
          paddingRight: 1
        }}
        style={{
          border: 0
        }}
        title={
          <Box>
            <Typography variant="h2">{parceiro?.nomeParceiro}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Comentários
            </Typography>
          </Box>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
              <FormCard title="INCLUIR COMENTÁRIO" style={{ flex: 1 }}>
                <DadosFup
                  formProps={formProps}
                  disabled={parceiro?.tipoSituacao !== 'A'}
                />
              </FormCard>
            </form>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FormCard title="HISTÓRICO DE COMENTÁRIOS" style={{ flex: 1 }}>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleChangeOrder}
                  endIcon={
                    !orderUp ? <ArrowDownOutlined /> : <ArrowUpOutlined />
                  }
                >
                  Ordem
                </Button>
              </Box>
              <Box
                sx={{
                  height: '283px',
                  overflowY: 'scroll',
                  p: 2
                }}
              >
                <Grid container>
                  {fups.map((item) => (
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: '800' }} variant="h5">
                        {item.titulo}
                      </Typography>
                      <Typography variant="h6" color="secondary">
                        {formatters.dates.longFirstLetterCapitalized(
                          new Date(item.dataHoraCriacao)
                        )}{' '}
                        - Por: {item?.usuarioCriacao?.nome}
                      </Typography>
                      <Typography
                        sx={{ mt: 1, mb: 1 }}
                        variant="h5"
                        color="secondary"
                        style={{ whiteSpace: 'pre-wrap' }}
                        dangerouslySetInnerHTML={{ __html: item.comentario }}
                      ></Typography>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </FormCard>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default Fup;
