import React from 'react';
import { Box, Tabs, Tab, Typography, tabsClasses } from '@mui/material';
import { actions as routeActions } from '../../reducers/rotas.actions';
import { alterarMenuSelecionado } from '../../reducers/pesquisa.reducer';
import { useDispatch, useSelector } from 'react-redux';

const PesquisaNavbar = ({ idOportunidade, menuSelecionado }) => {
  const { oportunidades, fups, participantes, representantes, atividades } =
    useSelector((state) => state.pesquisa.pesquisa.data);

  const participantesLength = participantes?.length ?? 0;
  const representantesLength = representantes?.length ?? 0;

  const tituloOportunidade = `OPORTUNIDADES (${oportunidades?.length ?? 0})`;
  const tituloParticipantes = `PARTICIPANTES (${
    participantesLength + representantesLength
  })`;

  const atividadesLength = `MINHAS TAREFAS (${atividades?.length ?? 0})`;

  const fupLength = `FUPS (${fups?.length ?? 0})`;

  const perfil = useSelector((state) => state.usuario.perfil);

  let menuItems = [
    {
      titulo: tituloOportunidade,
      rota: '',
      id: 0
    },
    {
      titulo: tituloParticipantes,
      rota: '',
      id: 1
    },
    {
      titulo: atividadesLength,
      rota: '',
      id: 2
    },
    {
      titulo: fupLength,
      rota: '',
      id: 3
    }
  ];

  const naoMostrarAtividades = !['AL', 'CS', 'UP'].includes(perfil);

  if (naoMostrarAtividades) {
    menuItems = menuItems.filter((item) => item.id !== 2);
  }

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    //setValue(newValue);

    dispatch(alterarMenuSelecionado(newValue));

    if (menuItems.at(newValue).rota) {
      dispatch(
        routeActions.redirectTo(menuItems.at(newValue).rota, {
          id: idOportunidade
        })
      );
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs
        value={menuSelecionado ?? 0}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons
        allowScrollButtonsMobile
        sx={{
          borderTop: '1px solid #262626',
          '.MuiTabs-flexContainer': {
            justifyContent: 'space-around'
          },
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 }
          }
        }}
      >
        {menuItems.map((item, index) => (
          <Tab
            key={index}
            label={<Typography variant="subtitle2">{item.titulo}</Typography>}
            sx={{
              '&:hover': {
                backgroundColor: '#f0f0f0'
              }
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default PesquisaNavbar;
