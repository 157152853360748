import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import Switch from 'components/form/Switch';
import { Grid } from '@mui/material';

const DadosDoBanco = ({ formProps }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={2}>
        <Stack spacing={0}>
          <InputLabel htmlFor="codigoBanco">Número</InputLabel>
          <OutlinedInput
            id="codigoBanco"
            name="codigoBanco"
            placeholder="Número do banco"
            fullWidth
            formProps={formProps}
            type="number"
            inputProps={{ inputProps: { max: 9999, min: 0 } }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        <Stack spacing={0}>
          <InputLabel htmlFor="contatoAvaliacao">Nome</InputLabel>
          <OutlinedInput
            id="nomeBanco"
            name="nomeBanco"
            placeholder="Nome do banco"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        sx={{ display: 'inline-flex', justifyContent: 'center' }}
      >
        <Stack spacing={0}>
          <InputLabel htmlFor="flagSimulacao">Habilitar Simulação?</InputLabel>
          <Switch
            formProps={formProps}
            name="flagSimulacao"
            id="flagSimulacao"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDoBanco;
