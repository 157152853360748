// third party
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import Reaptcha from 'reaptcha';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import React, { useCallback } from 'react';
import validators from '../../../utils/validators';
import { loginRequest } from '../../../reducers/autenticacao.reducer';
import OutlinedInput from '../../../components/form/OutlinedInput';
import Button from '../../../components/Button';
import {
  actions as routeActions,
  types as routes
} from '../../../reducers/rotas.actions';

// assets
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  UserOutlined
} from '@ant-design/icons';
import { Grid, IconButton, InputAdornment, Link, Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

const StyledLink = styled(Link)`
  font-size: 14px;
  cursor: pointer;
  color: #3f484a;
`;
const StyledLinkGrid = styled(Grid)`
  text-align: right;
  align-items: end;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const AuthLogin = () => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.auth.loading);

  const rules = {
    user: validators.string({ required: true }),
    password: validators.string({ required: true })
  };
  const initialValues = {
    user: '',
    password: ''
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues
  };

  const [showPassword, setShowPassword] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const verifyCallback = useCallback((t) => {
    setToken(t ?? null);
  }, []);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {
    if (token) {
      dispatch(loginRequest({ tokenRecaptcha: token, ...values}));
    }
  };

  const handleClickForgotPassword = () => {
    dispatch(routeActions.redirectTo(routes.ESQUECI_SENHA));
  };

  return (
    <>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack spacing={0}>
              <InputLabel htmlFor="email-login">Usuário</InputLabel>
              <OutlinedInput
                id="email-login"
                type="email"
                name="user"
                placeholder="Digitar o usuário"
                fullWidth
                formProps={formProps}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <UserOutlined />
                    </InputAdornment>
                  )
                }}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={0}>
              <InputLabel htmlFor="password-login">Senha</InputLabel>
              <OutlinedInput
                fullWidth
                id="-password-login"
                type={showPassword ? 'text' : 'password'}
                name="password"
                formProps={formProps}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="large"
                      >
                        {showPassword ? (
                          <EyeOutlined />
                        ) : (
                          <EyeInvisibleOutlined />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                placeholder="Digitar a senha"
              />
            </Stack>
          </Grid>
          <StyledLinkGrid item xs={12} md={12}>
            <StyledLink underline="hover" onClick={handleClickForgotPassword}>
              Esqueci minha senha
            </StyledLink>
          </StyledLinkGrid>
          <Grid item xs={12} justifyContent="center" alignItems="center">
            <Reaptcha
              onVerify={verifyCallback}
              onExpire={verifyCallback}
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
            />
          </Grid>
          <Grid item xs={12}>
            <AnimateButton>
              <Button
                disableElevation
                disabled={loading}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                isLoading={loading}
              >
                Entrar
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default AuthLogin;
