import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';
import OutlinedInput from 'components/form/OutlinedInput';
import ControlledAutocomplete from 'components/form/Autocomplete';
import { useSelector } from 'react-redux';
import ZipCodeTextField from 'components/form/ZipCodeTextField';
import { Grid } from '@mui/material';

const Endereco = ({ formProps, handleZipCodeBlurred, disabled }) => {
  const ufs = useSelector((state) => state.dominios.unidadesFederativa);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={2}>
        <Stack spacing={0}>
          <InputLabel htmlFor="cep">CEP</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="cep"
            name={'cep'}
            formProps={formProps}
            onBlur={() => handleZipCodeBlurred(formProps.getValues('cep'))}
            InputProps={{
              inputComponent: ZipCodeTextField
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={8}>
        <Stack spacing={0}>
          <InputLabel htmlFor="logradouro">Endereço</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="logradouro"
            name="logradouro"
            placeholder="Digite o endereço"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2}>
        <Stack spacing={0}>
          <InputLabel htmlFor="numeroLogradouro">Número</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="numeroLogradouro"
            name="numeroLogradouro"
            placeholder="Digite o número"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 7 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="complementoLogradouro">Complemento</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="complementoLogradouro"
            name="complementoLogradouro"
            placeholder="Digite o complemento"
            fullWidth
            formProps={formProps}
            inputProps={{ maxLength: 30 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="bairro">Bairro</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="bairro"
            name="bairro"
            placeholder="Digite o bairro"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 40 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="municipio">Cidade</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="municipio"
            name="municipio"
            placeholder="Digite a cidade"
            fullWidth
            required
            formProps={formProps}
            inputProps={{ maxLength: 60 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2}>
        <Stack spacing={0}>
          <InputLabel htmlFor={`uf`}>UF</InputLabel>
          <ControlledAutocomplete
            disabled={disabled}
            id="uf"
            name="uf"
            placeholder="Selecione a UF"
            fullWidth
            formProps={formProps}
            labelField={'nome'}
            idField={'codigo'}
            domain={ufs}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Endereco;
