// material-ui
import { DataGrid } from '../../../components/grid/DataGrid';

// project import
import MainLayout from '../../../layout/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import MainCard from '../../../components/MainCard';
import { Grid } from '@mui/material';
import Button from 'components/Button';
import AnimateButton from 'components/@extended/AnimateButton';
import Loader from 'components/Loader';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';

import { Box, Typography } from '@mui/material';
import { opcoesFiltro } from 'utils/opcoesFiltro';
import { funcaoFiltro } from 'utils/funcaoFiltro';

const OportunidadesPage = () => {
  const dispatch = useDispatch();

  const parceiros = useSelector((state) => state.regionais.parceiros.data);
  const loading = useSelector((state) => state.regionais.parceiros.loading);

  const regional = useSelector((state) => state.regionais.regional.data);
  const loadingRegional = useSelector(
    (state) => state.regionais.regional.loading
  );

  const rows = parceiros;

  const columns = [
    {
      field: 'nomeParceiro',
      headerName: 'Nome',
      flex: 1
    },
    {
      field: 'tipoOrigem',
      headerName: 'Origem',
      flex: 1,
      valueFormatter: (value) => {
        return [
          { codigo: 'RM', nome: 'RE/MAX' },
          { codigo: 'IN', nome: 'Indicação' },
          { codigo: 'PR', nome: 'Prospecção' },
          { codigo: 'ST', nome: 'Site' },
          { codigo: 'MK', nome: 'Marketing' }
        ].find((item) => item.codigo === value)?.nome;
      },

      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'RM', nome: 'RE/MAX' },
              { codigo: 'IN', nome: 'Indicação' },
              { codigo: 'PR', nome: 'Prospecção' },
              { codigo: 'ST ', nome: 'Site' },
              { codigo: 'MK', nome: 'Marketing' }
            ])
        }
      ]
    },
    {
      field: 'tipoSituacao',
      headerName: 'Situação',
      flex: 1,
      valueFormatter: (value) => {
        return value === 'A' ? 'Ativo' : value === 'I' ? 'Inativo' : '';
      },
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(props, [
              { codigo: 'A', nome: 'Ativo' },
              { codigo: 'I', nome: 'Inativo' }
            ])
        }
      ]
    }
  ];

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.REGIONAIS));
  };

  if (loading || loadingRegional) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (!regional?.idRegional) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Regional não encontrada'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <MainCard
        title={
          <Box>
            <Typography variant="h2">{regional?.nomeRegional}</Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Parceiros
            </Typography>
          </Box>
        }
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            getRowId={(row) => row.idParceiro}
            loading={loading}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              density: 'standard'
            }}
          />
        </Box>
        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
