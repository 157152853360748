// third party
import { useSelector } from 'react-redux';

// project import

import React, { useEffect } from 'react';
import Autocomplete from '../../components/form/Autocomplete';

// assets
import { Box, Stack } from '@mui/material';
import OutlinedInput from '../../components/form/OutlinedInput';
import InputLabel from '../../components/form/InputLabel';

const Operacao = ({ formProps, setOperacao, isSmallScreen }) => {
  const operacoes = useSelector((state) => state.dominios.operacao);
  const regionais = useSelector((state) => state.dominios.regional);
  const parceiros = useSelector((state) => state.dominios.parceiro);
  const usuarios = useSelector((state) => state.dominios.usuario);
  const usuariosParceiros = useSelector(
    (state) => state.dominios.usuarioParceiro
  );

  const usuario = useSelector((state) => state.usuario);

  const [regional, setRegional] = React.useState(usuario.idRegional);
  const [parceiro, setParceiro] = React.useState(usuario.idParceiro);
  const [usuarioParceiro, setUsuarioParceiro] = React.useState(
    usuario.idUsuarioParceiro
  );
  const [consultor, setConsultor] = React.useState('');
  const [analista, setAnalista] = React.useState('');

  const handleRegionalChange = (values) => {
    setRegional(values);
    formProps.setValue('parceiro', '');
    formProps.setValue('usuarioParceiro', '');
    formProps.setValue('consultor', '');
    formProps.setValue('analista', '');
  };

  const handleParceiroChange = (values) => {
    setParceiro(values);
    formProps.setValue('usuarioParceiro', '');
    formProps.setValue('consultor', '');
    formProps.setValue('analista', '');
  };

  const handleUsuarioParceiroChange = (values) => {
    setUsuarioParceiro(values);
    formProps.setValue(
      'consultor',
      values.usuarioConsultor?.nome
    );
    formProps.setValue(
      'analista',
      values.usuarioAnalista?.nome
    );
    setConsultor(
      values.usuarioConsultor?.nome
    );
    setAnalista(
      values.usuarioAnalista?.nome
    );
  };

  useEffect(() => {
    if (
      usuario.idUsuarioParceiro &&
      usuarios.data.length > 0 &&
      usuariosParceiros.data.length > 0
    ) {
      const c = usuariosParceiros.data.find(
        (u) => u.idUsuarioParceiro === usuario.idUsuarioParceiro
      ).idUsuarioConsultor;
      const a = usuariosParceiros.data.find(
        (u) => u.idUsuarioParceiro === usuario.idUsuarioParceiro
      ).idUsuarioAnalista;
      formProps.setValue(
        'consultor',
        usuarios.data.find((u) => u.id === c)?.nome
      );
      formProps.setValue(
        'analista',
        usuarios.data.find((u) => u.id === a)?.nome
      );
      setConsultor(usuarios.data.find((u) => u.id === c)?.nome);
      setAnalista(usuarios.data.find((u) => u.id === a)?.nome);
    }
  }, [usuarios, usuariosParceiros, usuario, formProps]);

  useEffect(() => {
    if (usuario.idRegional && regionais.data.length > 0) {
      const c = regionais.data.find((r) => r.idRegional === regional);
      formProps.setValue('regional', c.nomeRegional, formProps);
    }
  }, [regionais, usuario, formProps, regional]);

  useEffect(() => {
    if (usuario.idParceiro && parceiros.data.length > 0) {
      const c = parceiros.data.find((r) => r.idParceiro === parceiro);
      formProps.setValue('parceiro', c.nomeParceiro);
    }
  }, [parceiros, usuario, formProps, parceiro]);

  useEffect(() => {
    if (usuario.idUsuarioParceiro && usuariosParceiros.data.length > 0) {
      const c = usuariosParceiros.data.find(
        (r) => r.idUsuarioParceiro === usuarioParceiro
      );
      formProps.setValue('usuarioParceiro', c.nomeUsuarioParceiro);
    }
  }, [usuariosParceiros, usuario, formProps, usuarioParceiro]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: "column",
        gap: 2
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="operacao">Operação</InputLabel>
          <Autocomplete
            id="operacao"
            name="operacao"
            placeholder="Escolha a Operação"
            fullWidth
            labelField={'nomeOperacao'}
            idField={'idOperacao'}
            domain={operacoes.data}
            loading={operacoes.loading}
            formProps={formProps}
            onChange={(e) => setOperacao(e.idOperacao)}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="regional">Regional</InputLabel>
          {usuario.idRegional && (
            <OutlinedInput
              id="regional"
              name="regional"
              placeholder="Escolha a Regional"
              fullWidth
              disabled={!!usuario.idRegional}
              loading={regionais.loading}
              formProps={formProps}
              values={regionais.data.find((r) => r.id === regional)}
            />
          )}
          {!usuario.idRegional && (
            <Autocomplete
              id="regional"
              name="regional"
              placeholder="Escolha a Regional"
              fullWidth
              disabled={!!usuario.idRegional}
              labelField={'nomeRegional'}
              idField={'idRegional'}
              domain={regionais.data}
              loading={regionais.loading}
              formProps={formProps}
              onChange={(v) => handleRegionalChange(v.idRegional)}
              values={regional}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="parceiro">Parceiro</InputLabel>
          {usuario.idParceiro && (
            <OutlinedInput
              id="parceiro"
              name="parceiro"
              placeholder="Escolha o parceiro"
              fullWidth
              disabled={!!usuario.idParceiro}
              loading={parceiros.loading}
              formProps={formProps}
              values={parceiros.data.find((r) => r.id === parceiro)}
            />
          )}
          {!usuario.idParceiro && (
            <Autocomplete
              id="parceiro"
              name="parceiro"
              placeholder="Escolha o Parceiro"
              fullWidth
              disabled={!!usuario.idParceiro}
              labelField={'nomeParceiro'}
              idField={'idParceiro'}
              domain={
                !regional
                  ? []
                  : parceiros.data.filter((p) => p.idRegional === regional)
              }
              loading={parceiros.loading}
              formProps={formProps}
              onChange={(v) => handleParceiroChange(v.idParceiro)}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="usuarioParceiro">Usuário Parceiro</InputLabel>
          {usuario.idUsuarioParceiro && (
            <OutlinedInput
              id="usuarioParceiro"
              name="usuarioParceiro"
              placeholder="Escolha o Usuário Parceiro"
              fullWidth
              disabled={!!usuario.idUsuarioParceiro}
              loading={usuariosParceiros.loading}
              formProps={formProps}
              values={usuariosParceiros.data.find(
                (r) => r.idUsuarioParceiro === usuarioParceiro
              )}
            />
          )}
          {!usuario.idUsuarioParceiro && (
            <Autocomplete
              id="usuarioParceiro"
              name="usuarioParceiro"
              placeholder="Escolha o Usuário Parceiro"
              fullWidth
              disabled={!!usuario.idUsuarioParceiro}
              labelField={'nomeUsuarioParceiro'}
              idField={'idUsuarioParceiro'}
              domain={
                !parceiro
                  ? []
                  : usuariosParceiros.data.filter(
                      (p) => p.idParceiro === parceiro
                    )
              }
              loading={usuariosParceiros.loading}
              formProps={formProps}
              onChange={(v) => handleUsuarioParceiroChange(v)}
            />
          )}
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="consultor">Consultor</InputLabel>
          <OutlinedInput
            id="consultor"
            name="consultor"
            disabled={true}
            fullWidth
            formProps={formProps}
            values={consultor}
          />
        </Stack>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Stack spacing={0}>
          <InputLabel htmlFor="analista">Analista</InputLabel>
          <OutlinedInput
            id="analista"
            name="analista"
            disabled={true}
            fullWidth
            formProps={formProps}
            values={analista}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default Operacao;
