import formatters from 'utils/formatters';
import React from 'react';
import { InputLabel, MenuItem, Select } from '@mui/material';

const opcoesFiltro = (props, itens) => {
  return (
    <>
      <InputLabel htmlFor="opcoes">Opções</InputLabel>
      <Select
        multiple
        value={props.item.value || []}
        onChange={(event) =>
          props.applyValue({ ...props.item, value: event.target.value })
        }
        fullWidth
        id={'opcoes'}
        name={'opcoes'}
      >
        <MenuItem value="">
          <em>Nenhum</em>
        </MenuItem>
        {itens.map((option) => (
          <MenuItem key={option.codigo} value={option.codigo}>
            {option.nome}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

const funcaoFiltro = (filterItem) => {
  if (!filterItem.value || filterItem.value.length === 0) {
    return null;
  }
  return (params) => {
    return filterItem.value.includes(params);
  };
};

export const columns = ({ tiposAtividades }) => {
  return [
    {
      field: 'tipoAtividade',
      headerName: 'Tipo de Atividade',
      flex: 1,
      filterOperators: [
        {
          label: 'igual à',
          value: 'is',
          getApplyFilterFn: (filterItem) => funcaoFiltro(filterItem),
          InputComponent: (props) =>
            opcoesFiltro(
              props,
              tiposAtividades.map((i) => ({
                codigo: i.idOperacao,
                nome: i.nomeOperacao
              }))
            )
        }
      ],
      valueFormatter: (value) =>
        `${tiposAtividades.find((i) => i.codigo === value).nome}`
    },
    {
      field: 'comentario',
      headerName: 'Comentário',
      flex: 1
    },
    {
      field: 'oportunidade',
      headerName: 'Oportunidade',
      flex: 1
    },
    {
      field: 'comprador',
      headerName: 'Comprador',
      flex: 1
    },
    {
      field: 'data',
      headerName: 'Data',
      flex: 1,
      valueFormatter: (value) => {
        return !value ? '' : formatters.dates.withHours(new Date(value));
      }
    },
    {
      field: 'diasNaFila',
      headerName: 'Dias na Fila',
      flex: 1
    }
  ];
};
