import React from 'react';
import { Stack } from '@mui/material';
import InputLabel from 'components/form/InputLabel';

import OutlinedInput from 'components/form/OutlinedInput';
import { Grid } from '@mui/material';

const DadosDaEtapa = ({ formProps, disabled }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <Stack spacing={0}>
          <InputLabel htmlFor="nomeEtapa">Nome</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="nomeEtapa"
            name="nomeEtapa"
            placeholder="Nome"
            fullWidth
            formProps={formProps}
            required
            inputProps={{ maxLength: 100 }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={0}>
          <InputLabel htmlFor="ordemEtapa">Ordem</InputLabel>
          <OutlinedInput
            disabled={disabled}
            id="ordemEtapa"
            name="ordemEtapa"
            placeholder="Ordem"
            fullWidth
            formProps={formProps}
            required
            type="number"
            inputProps={{ inputProps: { max: 32767, min: 0 } }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default DadosDaEtapa;
