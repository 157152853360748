// material-ui
import { DataGrid } from '../../components/grid/DataGrid';
import { Button, Box } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton
} from '@mui/x-data-grid-pro';

import { Launch } from '@mui/icons-material';

// project import
import MainLayout from '../../layout/MainLayout';
import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  actions as routeActions,
  types as routes
} from '../../reducers/rotas.actions';
import MainCard from '../../components/MainCard';
import { columns as columnsOportunidade } from './Columns';
import { obterFunilDeVendasRequest } from 'reducers/oportunidade.reducer';
import { toast } from 'react-toastify';
import {
  Card,
  Divider,
  Grid,
  Skeleton
} from '../../../node_modules/@mui/material/index';
import formatters from 'utils/formatters';

const OportunidadesPage = () => {
  const temperatura = useSelector((state) => state.dominios.temperatura);
  const parceiros = useSelector((state) => state.dominios.parceiro);
  const regionais = useSelector((state) => state.dominios.regional);
  const usuariosParceiros = useSelector(
    (state) => state.dominios.usuarioParceiro
  );
  const usuarios = useSelector((state) => state.dominios.usuario);
  const bancos = useSelector((state) => state.dominios.listarBancos.data);
  const operacoes = useSelector((state) => state.dominios.operacao);
  const etapas = useSelector((state) => state.dominios.etapa);
  const oportunidades = useSelector(
    (state) => state.oportunidade.oportunidades
  );
  const usuario = useSelector((state) => state.usuario);
  const rows = oportunidades.data.map((i) => {
    return {
      ...i,
      id: i.idOportunidade,
      dataOportunidade: new Date(`${i.dataOportunidade}T00:00:00`),
      dataPrevistaEmissaoContrato: i.dataPrevistaEmissaoContrato
        ? new Date(`${i.dataPrevistaEmissaoContrato}T00:00:00`)
        : ''
    };
  });

  const funilDeVendas = useSelector(
    (state) => state.oportunidade.funilDeVendas.data
  );

  const loading = useSelector(
    (state) => state.oportunidade.funilDeVendas.loading
  );

  const dispatch = useDispatch();

  const columns = columnsOportunidade({
    operacoes,
    temperatura,
    regionais,
    parceiros,
    usuariosParceiros,
    usuarios,
    bancos,
    etapas,
    usuario
  });

  const handleRowClick = (params) => {
    const { idOportunidade } = params;
    dispatch(
      routeActions.redirectTo(routes.OPORTUNIDADE, { id: idOportunidade })
    );
  };

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    id: false,
    regional: false,
    usuarioParceiro: false,
    consultor: false,
    analista: false,
    cpfCnpj: false,
    atividades: false
  });

  const handleFunilDeVendas = (apiRef) => {
    setTimeout(() => {
      let filteredDataRowIDs = [];
      if (apiRef.current?.state?.filter?.filteredRowsLookup) {
        filteredDataRowIDs = Object.entries(
          apiRef.current.state.filter.filteredRowsLookup
        )
          .filter(([_, value]) => value === true)
          .map(([key, _]) => Number(key));
      }
      dispatch(obterFunilDeVendasRequest({ value: filteredDataRowIDs }));
    }, 200);
  };

  let ref;

  const CustomToolbar = ({ apiRef }) => {
    const selectedRows = apiRef.current.getSelectedRows();
    ref = apiRef;

    const handleButtonClick = () => {
      if (selectedRows.size === 0) {
        toast.error('Selecione pelo menos uma linha.');
      } else {
        const selectedRowData = Array.from(selectedRows.values());
        handleRowClick(selectedRowData[0]);
      }
    };

    // const handleFunilDeVendas = () => {
    //   let filteredDataRowIDs = [];
    //   if (apiRef.current?.state?.filter?.filteredRowsLookup) {
    //     filteredDataRowIDs = Object.entries(
    //       apiRef.current.state.filter.filteredRowsLookup
    //     )
    //       .filter(([_, value]) => value === true)
    //       .map(([key, _]) => Number(key));
    //   }
    //   dispatch(routesActions.redirectTo(routes.FUNIL_DE_VENDAS));
    //   dispatch(obterFunilDeVendasRequest({ value: filteredDataRowIDs }));
    // };

    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport
          csvOptions={{
            includeHeaders: true,
            allColumns: true,
            utf8WithBom: true
          }}
        />
        <>
          <Button
            variant="text"
            color="primary"
            disabled={selectedRows.size !== 1}
            onClick={handleButtonClick}
            startIcon={<Launch />}
            sx={{
              maxHeight: 30.75,
              paddingTop: '4px',
              paddingBottom: '4px',
              paddingLeft: '5px',
              paddingRight: '5px'
            }}
          >
            Acessar Oportunidade
          </Button>
          {/* <Button
                variant="text"
                color="primary"
                onClick={handleFunilDeVendas}
                startIcon={<FilterOutlined />}
                disabled={loading}
                sx={{
                  maxHeight: 30.75,
                  paddingTop: '4px',
                  paddingBottom: '4px',
                  paddingLeft: '5px',
                  paddingRight: '5px'
                }}
              >
                Funil de Vendas
              </Button> */}
        </>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    if (oportunidades?.data?.length) {
      const filteredDataRowIDs = oportunidades.data.map(
        (oportunidade) => oportunidade.idOportunidade
      );

      dispatch(obterFunilDeVendasRequest({ value: filteredDataRowIDs }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oportunidades]);

  return (
    <MainLayout>
      <MainCard
        title="Oportunidades"
        border={false}
        sx={{ paddingBottom: '0px' }}
      >
        <Grid container spacing={2}>
          {loading && (
            <Grid container spacing={2}>
              {[...Array(6)].map((_, index) => (
                <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                  <Card
                    style={{
                      padding: '20px',
                      marginTop: '5px',
                      marginBottom: '5px'
                    }}
                  >
                    <Skeleton variant="text" height={40} />
                    <Skeleton variant="rectangular" height={60} />
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}

          {!loading &&
            funilDeVendas.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} lg={2} key={index}>
                <Card
                  title={item.etapa}
                  border={false}
                  style={{
                    padding: '20px',
                    marginBottom: '5px'
                  }}
                >
                  <div
                    style={{
                      fontWeight: 'bold',
                      fontSize: '1rem',
                      marginBottom: '10px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {item.nomeEtapa}
                  </div>
                  <Divider />

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                      marginTop: '10px'
                    }}
                  >
                    <div style={{ fontSize: '1.3rem', fontWeight: '500' }}>
                      {item.quantidadeOportunidades}
                    </div>
                    <div style={{ fontSize: '1.3rem', fontWeight: '600' }}>
                      {formatters.numbers.simpleCurrency(
                        item.valorTotalFinanciado
                      )}
                    </div>
                  </div>
                </Card>
              </Grid>
            ))}
        </Grid>

        <Box sx={{ marginTop: '5px' }}>
          <DataGrid
            onFilterModelChange={(filter) => {
              const filtersEmpty = filter.items.length === 0;

              if (filtersEmpty) {
                handleFunilDeVendas(ref);
              } else {
                const filtered = filter.items.find((item) => {
                  return item.value !== undefined;
                });

                if (filtered) {
                  handleFunilDeVendas(ref);
                }
              }
            }}
            rows={rows}
            columns={columns}
            pageSizeOptions={[10]}
            loading={oportunidades.loading}
            handleRowClick={handleRowClick}
            isOportunidade={true}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            noLineText="Nenhuma oportunidade cadastrada"
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10
                }
              },
              aggregation: {
                model: {
                  valorTotalFinanciamento: 'sum',
                  codigoOportunidade: 'size'
                }
              },
              density: 'standard'
            }}
            toolbar={CustomToolbar}
          />
        </Box>
      </MainCard>
    </MainLayout>
  );
};

export default OportunidadesPage;
