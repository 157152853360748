import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';
import PesquisaNavbar from './PesquisaNavBar';
import PesquisaOportunidades from './PesquisaOportunidades';
import PesquisaParticipantes from './PesquisaParticipantes';
import MainCard from 'components/AuthCard';
import { Card, Typography } from '../../../node_modules/@mui/material/index';
import PesquisaAtividades from './PesquisaAtividades';
import PesquisaFup from './PesquisaFup';
import { alterarQueryString, pesquisaRequest } from 'reducers/pesquisa.reducer';

const Pesquisa = () => {
  const dispatch = useDispatch();

  const queryString = useSelector((state) => state.pesquisa.queryString);

  const perfil = useSelector((state) => state.usuario.perfil);

  const [display, setDisplay] = React.useState('');

  useEffect(() => {
    if (queryString !== '') {
      dispatch(pesquisaRequest(queryString));
      setDisplay(queryString);
      dispatch(alterarQueryString(''));
    }
    console.log('@#@#@#@#' + queryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  const menuSelecionado = useSelector(
    (state) => state.pesquisa.menuSelecionado
  );

  const naoMostrarAtividades = !['AL', 'CS', 'UP'].includes(perfil);

  return (
    <MainLayout>
      <>
        <MainCard
          title={
            <Box>
              <Typography variant="h2">Resultados da pesquisa</Typography>
              <Typography variant="h3" color="textSecondary">
                {display}
              </Typography>
            </Box>
          }
          border={true}
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1,
            marginBottom: -4
          }}
        ></MainCard>
        <Card sx={{ pl: 0, pr: 0 }}>
          <PesquisaNavbar menuSelecionado={menuSelecionado} />
        </Card>
        <Card sx={{ width: '100%', marginTop: '20px' }}>
          {menuSelecionado === 0 && <PesquisaOportunidades />}
          {menuSelecionado === 1 && <PesquisaParticipantes />}
          {naoMostrarAtividades ? (
            <></>
          ) : (
            menuSelecionado === 2 && <PesquisaAtividades />
          )}
          {naoMostrarAtividades && menuSelecionado === 2 ? (
            <PesquisaFup />
          ) : (
            menuSelecionado === 3 && <PesquisaFup />
          )}
        </Card>
      </>
    </MainLayout>
  );
};

export default Pesquisa;
