import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idOportunidade: null,
  idEtapa: null,
  minhasAtividades: {
    loading: false,
    error: false,
    data: []
  }
};

const MinhasAtividadesSlice = createSlice({
  name: 'minhasAtividades',
  initialState,
  reducers: {
    listarMinhasAtividadesRequest: (state) => {
      state.minhasAtividades.loading = true;
      state.minhasAtividades.error = null;
    },
    listarMinhasAtividadesSuccess: (state, action) => {
      state.minhasAtividades.loading = false;
      state.minhasAtividades.error = false;
      state.minhasAtividades.data = action.payload.response.data;
    },
    listarMinhasAtividadesFailure: (state, action) => {
      state.minhasAtividades.loading = false;
      state.minhasAtividades.error = action.payload;
    },
    encerrarMinhasAtividadesRequest: (state) => {
      state.minhasAtividades.loading = true;
      state.minhasAtividades.error = null;
    },
    encerrarMinhasAtividadesSuccess: (state, action) => {
      state.minhasAtividades.loading = false;
      state.minhasAtividades.error = false;
    },
    encerrarMinhasAtividadesFailure: (state, action) => {
      state.minhasAtividades.loading = false;
      state.minhasAtividades.error = action.payload;
    }
  }
});

export const {
  listarMinhasAtividadesRequest,
  listarMinhasAtividadesSuccess,
  listarMinhasAtividadesFailure,
  encerrarMinhasAtividadesRequest,
  encerrarMinhasAtividadesSuccess,
  encerrarMinhasAtividadesFailure
} = MinhasAtividadesSlice.actions;

export default MinhasAtividadesSlice.reducer;
