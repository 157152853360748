import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { useForm } from 'react-hook-form';

import validators from 'utils/validators';
import FormCard from 'components/FormCard';
import AnimateButton from 'components/@extended/AnimateButton';
import Button from 'components/Button';
import React, { useEffect } from 'react';
import DadosDoUsuario from './DadosDoUsuario';
import * as actions from 'reducers/usuarios.reducer';
import Loader from 'components/Loader';
import MainCard from 'components/MainCard';
import { Box, Typography } from '@mui/material';
import MainLayout from 'layout/MainLayout/index';

import {
  actions as routeActions,
  types as routes
} from 'reducers/rotas.actions';
import Relacionamento from './Relacionamento';
import DadosDeAcesso from './DadosDeAcesso';
import { useState } from 'react';
import formatters from 'utils/formatters';

const Usuario = () => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuarios.usuario.data);
  const loading = useSelector((state) => state.usuarios.usuario.loading);

  const parceiros = useSelector((state) => state.usuarios.parceiros.data);
  const regionais = useSelector((state) => state.usuarios.regionais.data);
  const usuariosParceiros = useSelector(
    (state) => state.usuarios.usuariosParceiros.data
  );

  const isEditMode = useSelector((state) => state.usuarios.usuario.isEditMode);

  const disabled = !isEditMode ? false : usuario?.situacao !== 'A';

  const [perfil, setPerfil] = useState('');

  const perfilUsuario = [
    {
      codigo: 'AD',
      nome: 'Administrador'
    },
    {
      codigo: 'GT',
      nome: 'Gestor'
    },
    {
      codigo: 'CS',
      nome: 'Consultor'
    },
    {
      codigo: 'AL',
      nome: 'Analista'
    },
    {
      codigo: 'RG',
      nome: 'Regional'
    },
    {
      codigo: 'PC',
      nome: 'Parceiro'
    },
    {
      codigo: 'UP',
      nome: 'Usuário Parceiro'
    }
  ];

  const initialValues = {
    // Dados do Usuario
    nome: usuario?.nome ?? '',
    codigo: usuario?.codigo ?? '',
    email: usuario?.email ?? '',
    perfil: perfilUsuario.find(
      (perfilUsuario) => perfilUsuario?.codigo === usuario?.perfil
    ),
    situacao:
      usuario?.situacao === 'I'
        ? { value: 'I', label: 'Inativo' }
        : { value: 'A', label: 'Ativo' },
    idParceiro: usuario?.idParceiro ?? '',
    idUsuarioParceiro: usuario?.idUsuarioParceiro ?? '',
    idRegional: usuario?.idRegional ?? '',
    parceiro: parceiros.find(
      (parceiro) => parceiro?.idParceiro === usuario?.idParceiro
    ),
    usuarioParceiro: usuariosParceiros.find(
      (usuarioParceiro) =>
        usuarioParceiro?.idUsuarioParceiro === usuario?.idUsuarioParceiro
    ),
    regional: regionais.find(
      (regional) => regional?.idRegional === usuario?.idRegional
    ),
    dataHoraUltimoAcesso: '-',
    dataUltimaTrocaSenha: '-',
    quantidadeTentativaSenha: 0
  };

  const rules = {
    // Dados do Usuario
    nome: validators.string({ required: true }),
    codigo: validators.string({ required: true }),
    email: validators.email({ required: true }),
    perfil: validators.object({ required: true }),
    parceiro: validators.object({
      required: perfil === 'PC' || perfil === 'UP' ? true : false
    }),
    usuarioParceiro: validators.object({
      required: perfil === 'UP' ? true : false
    }),
    regional: validators.object({
      required:
        perfil === 'RG' || perfil === 'PC' || perfil === 'UP' ? true : false
    })
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues: {
      ...initialValues
    },
    defaultValues: usuario
  };

  useEffect(() => {
    if (perfil === 'RG') {
      formProps.setValue('parceiro', null);
      formProps.setValue('usuarioParceiro', null);
    }
    if (perfil === 'PC') {
      formProps.setValue('usuarioParceiro', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perfil]);

  useEffect(() => {
    if (!!usuario) {
      formProps.reset({
        ...usuario,

        perfil: perfilUsuario.find(
          (perfil) => perfil?.codigo === usuario?.perfil
        ),
        parceiro:
          parceiros.find(
            (parceiro) => parceiro?.idParceiro === usuario?.idParceiro
          ) ?? null,
        usuarioParceiro:
          usuariosParceiros.find(
            (usuarioParceiro) =>
              usuarioParceiro?.idUsuarioParceiro === usuario?.idUsuarioParceiro
          ) ?? null,
        regional:
          regionais.find(
            (regional) => regional?.idRegional === usuario?.idRegional
          ) ?? null,

        dataUltimaTrocaSenha: usuario?.dataUltimaTrocaSenha
          ? formatters.dates.short(new Date(usuario?.dataUltimaTrocaSenha))
          : '-',
        dataHoraUltimoAcesso: usuario?.dataHoraUltimoAcesso
          ? formatters.dates.short(new Date(usuario?.dataHoraUltimoAcesso))
          : '-'
      });
      setPerfil(usuario?.perfil ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usuario, regionais, parceiros, usuariosParceiros]);

  const handleSubmit = (values) => {
    delete values.dataHoraUltimoAcesso;
    delete values.dataUltimaTrocaSenha;
    delete values.quantidadeTentativaSenha;

    if (!['RG', 'PC', 'UP'].includes(values?.perfil?.codigo)) {
      values.parceiro = null;
      values.usuarioParceiro = null;
      values.regional = null;
    } else if (values?.perfil?.codigo === 'RG') {
      values.parceiro = null;
      values.usuarioParceiro = null;
    } else if (values?.perfil?.codigo === 'PC') {
      values.usuarioParceiro = null;
    } else if (values?.perfil?.codigo === 'UP') {
    }

    const payload = {
      ...values,
      dataAlteracao: new Date().toISOString(),
      dataCriacao: new Date().toISOString(),
      situacao: 'A',
      perfil: values?.perfil?.codigo,
      idParceiro: values?.parceiro?.idParceiro ?? null,
      idUsuarioParceiro: values?.usuarioParceiro?.idUsuarioParceiro ?? null,
      idRegional: values?.regional?.idRegional ?? null
    };

    if (isEditMode) {
      dispatch(actions.editarUsuarioRequest({ value: payload }));
    } else {
      dispatch(actions.criarUsuarioRequest({ value: payload }));
    }
  };

  const handleGoBack = () => {
    dispatch(routeActions.redirectTo(routes.USUARIOS));
  };

  const handleChangePerfil = (status) => {
    setPerfil(status);
  };

  if (loading) {
    return (
      <MainLayout>
        <Loader />
      </MainLayout>
    );
  }

  if (isEditMode && !usuario?.id) {
    return (
      <MainLayout>
        <>
          <MainCard border={false} title={'Usuario não encontrado'}>
            <Grid
              container
              style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="button"
                    variant="outlined"
                    color="secondary"
                    onClick={handleGoBack}
                  >
                    VOLTAR
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </MainCard>
        </>
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <form noValidate onSubmit={formProps.handleSubmit(handleSubmit)}>
        <MainCard
          contentSX={{
            paddingLeft: 1,
            paddingRight: 1
          }}
          border={false}
          title={
            !isEditMode ? (
              'Novo Usuario'
            ) : (
              <Box>
                <Typography variant="h2">{usuario?.nome}</Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Usuário
                </Typography>
              </Box>
            )
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
              <FormCard title="DADOS DO USUÁRIO" style={{ flex: 1 }}>
                <DadosDoUsuario
                  formProps={formProps}
                  perfis={perfilUsuario}
                  handleChangePerfil={handleChangePerfil}
                  disabled={disabled}
                />
              </FormCard>
            </Grid>
            {['UP', 'RG', 'PC'].includes(perfil) &&
              parceiros &&
              usuariosParceiros &&
              regionais && (
                <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
                  <FormCard title="RELACIONAMENTO" style={{ flex: 1 }}>
                    <Relacionamento
                      formProps={formProps}
                      perfil={perfil}
                      parceiros={parceiros}
                      usuariosParceiros={usuariosParceiros}
                      regionais={regionais}
                      disabled={disabled}
                    />
                  </FormCard>
                </Grid>
              )}
            <Grid item xs={12} style={{ display: 'flex', margin: '0px' }}>
              <FormCard title="DADOS DE ACESSO" style={{ flex: 1 }}>
                <DadosDeAcesso
                  formProps={formProps}
                  perfis={perfilUsuario}
                  perfil={perfil}
                  disabled={disabled}
                />
              </FormCard>
            </Grid>
          </Grid>
        </MainCard>

        <Grid
          container
          style={{ marginTop: 20, justifyContent: 'end', marginBottom: 20 }}
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="button"
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
              >
                VOLTAR
              </Button>
            </AnimateButton>
          </Grid>
          <Grid item xs={12} md={4}>
            <AnimateButton>
              <Button
                disabled={disabled}
                disableElevation
                fullWidth
                size="large"
                variant="contained"
                color="primary"
                type="submit"
              >
                SALVAR
              </Button>
            </AnimateButton>
          </Grid>
        </Grid>
      </form>
    </MainLayout>
  );
};

export default Usuario;
